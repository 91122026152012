import React, { useCallback, useEffect } from 'react';
import { Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  fetchSubscriptionDetails,
  fetchSubscriptionCancelReason,
  cancelSubscription,
} from '../../../../store/actions';
import {
  black,
  redOrange,
  tundora,
  pippin,
  pirateGold,
  oldLace,
  alto,
} from '../../../../themes/colors';
import { WarningIcon } from '../../../../components/icons';
import Loading from '../../../../components/Loading';
import { BackButton, BackButtonMobile } from '../../../../components/material';

import { getTenantToken } from '../../../../store/services/auth';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    boxShadow: `1px 3px 6px ${alto}`,
    minHeight: '100vh',
  },
  mainBox: {
    background: pippin,
    padding: '1.5rem 2rem',
  },
  titleBox: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '2rem',
  },
  title: {
    color: black,
    fontSize: '2em',
    padding: '0 2.5rem',
    [theme.breakpoints.up('lg')]: {
      padding: '0 1rem',
    },
  },
  subtitle: {
    color: tundora,
    fontSize: '1.2em',
  },
  contentBox: {
    padding: '2rem',
    [theme.breakpoints.up('md')]: {
      maxWidth: '60%',
    },
  },
  contentButtonBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '1.5rem 0',
    '& button': {
      margin: '0.5rem 0',
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start',
      flexDirection: 'row',
      '& button': {
        marginRight: '0.5rem',
      },
    },
  },
  warningBox: {
    color: pirateGold,
    background: oldLace,
    border: `1px solid ${pirateGold}`,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    margin: '1rem 0',
    display: 'flex',
    '& span': {
      color: redOrange,
      fontWeight: 800,
    },
  },
  warningIconBox: {
    marginRight: theme.spacing(2),
    '& svg': {
      width: '2em',
      fill: pirateGold,
    },
  },
  cancelBlockDescription: {
    fontSize: '1rem',
    padding: '1rem 0.3rem',
    margin: '1rem 0 2rem',
    lineHeight: '1.7rem',
    textAlign: 'justify',
    '& a': {
      color: black,
    },
  },
}));

const Unsubscribe = ({
  subscription,
  onFetchSubscriptionDetails,
  onFetchSubscriptionCancelReason,
  navigateTo,
  cancelReasons,
  loading,
  submiting,
}) => {
  const classes = useStyles();
  const showArrow = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  useEffect(() => {
    onFetchSubscriptionCancelReason();
  }, [onFetchSubscriptionCancelReason]);

  useEffect(() => {
    if (!subscription?.id) {
      onFetchSubscriptionDetails();
    }
  }, [subscription, onFetchSubscriptionDetails]);

  const getTenantName = useCallback(
    () => (getTenantToken() === 'scarlet' ? 'Scarlet' : 'UAUBox'),
    []
  );

  const getTenantEmail = useCallback(
    () => (getTenantToken() === 'uaubox' ? 'eai@uaubox.com.br' : 'ficacomigo@uaubox.com.br'),
    []
  );

  return (
    <Loading isLoading={loading || submiting}>
      <div className={classes.root}>
        <BackButtonMobile onClick={() => navigateTo('/perfil/assinatura')} visible={!showArrow} />
        <div className={classes.mainBox}>
          <div className={classes.titleBox}>
            <BackButton onClick={() => navigateTo('/perfil/assinatura')} visible={showArrow} />
            <Typography className={classes.title}>Cancelamento</Typography>
          </div>
          <Typography className={classes.subtitle} gutterBottom>
            Aviso sobre o pedido de cancelamento
          </Typography>
        </div>

        <div className={classes.contentBox}>
          <div className={classes.warningBox}>
            <div className={classes.warningIconBox}>
              <WarningIcon />
            </div>
            <Typography>
              Antes do cancelamento, é importante saber que os planos trimestral, semestral e anual,
              possuem um desconto em sua mensalidade. Ao cancelar esses planos antes de seu término
              de fidelidade, uma cobrança adicional, proporcional ao desconto será realizada.
              <br />
              {cancelReasons.cancellationFee ? (
                <span>Cobrança adicional: R${cancelReasons.cancellationFee.feeTotalAmount}</span>
              ) : null}
            </Typography>
          </div>
          <Typography className={classes.cancelBlockDescription}>
            O site da {getTenantName()} passou por algumas mudanças e atualizações e por isso, o
            cancelamento será realizado somente através do e-mail{' '}
            <a href="mailto:ficacomigo@uaubox.com.br">{getTenantEmail()}</a>. O time de
            relacionamento da UAU estará a disposição para ajudar.💖
          </Typography>

          <Button
            variant="outlined"
            color="secondary"
            onClick={() => navigateTo(`/perfil/assinatura`)}
          >
            voltar
          </Button>
        </div>
      </div>
    </Loading>
  );
};

const mapStateToProps = (state) => {
  const { cancelReasons, loading, submiting, subscription } = state.subscription;
  return { cancelReasons, loading, submiting, subscription };
};

const mapDispatchToProps = (dispatch) => ({
  onFetchSubscriptionDetails: () => dispatch(fetchSubscriptionDetails()),
  onFetchSubscriptionCancelReason: () => dispatch(fetchSubscriptionCancelReason()),
  onCancelSubscription: (cancel) => dispatch(cancelSubscription(cancel)),
  navigateTo: (path) => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Unsubscribe);
