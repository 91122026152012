import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import * as fromTypes from '../types';
import * as fromActions from '../actions';

const BASE_API = `${process.env.REACT_APP_BASE_API_ADDRESS}/customer-area`;

export function* fetchUserNotifications() {
  try {
    const response = yield axios.get(`${BASE_API}/user-notification`);

    if (response.status === 200) {
      yield put(fromActions.fetchUserNotificationsSuccess(response.data));
    }
  } catch (error) {
    console.error(error);
    yield put(fromActions.fetchUserNotificationsError(error));
  }
}

export function* fetchUserNotification({ id }) {
  try {
    const response = yield axios.get(`${BASE_API}/user-notification/${id}`);

    if (response.status === 200) {
      yield put(fromActions.fetchUserNotificationSuccess(response.data));
    }
  } catch (error) {
    console.error(error);
    yield put(fromActions.fetchUserNotificationError(error));
  }
}

export function* markUserNotificationAsRead({ id }) {
  try {
    const response = yield axios.put(`${BASE_API}/user-notification/${id}/read`);

    if (response.status === 200) {
      yield put(fromActions.markUserNotificationAsReadSuccess(id));
    }
  } catch (error) {
    console.error(error);
    yield put(fromActions.markUserNotificationAsReadError(error));
  }
}

export function* watchNotification() {
  yield takeLatest(fromTypes.FETCH_USER_NOTIFICATIONS, fetchUserNotifications);
  yield takeLatest(fromTypes.FETCH_USER_NOTIFICATION, fetchUserNotification);
  yield takeLatest(fromTypes.MARK_USER_NOTIFICATION_AS_READ, markUserNotificationAsRead);
}
