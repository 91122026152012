// @flow
import * as fromTypes from '../types/notification';

export const fetchUserNotifications = (): fromTypes.NotificationAction => ({
  type: fromTypes.FETCH_USER_NOTIFICATIONS,
});

export const fetchUserNotificationsSuccess = (payload: any): fromTypes.NotificationAction => ({
  type: fromTypes.FETCH_USER_NOTIFICATIONS_SUCCESS,
  payload,
});

export const fetchUserNotificationsError = (payload: any): fromTypes.NotificationAction => ({
  type: fromTypes.FETCH_USER_NOTIFICATIONS_ERROR,
  payload,
});

export const fetchUserNotification = (id: Number): fromTypes.NotificationAction => ({
  type: fromTypes.FETCH_USER_NOTIFICATION,
  id,
});

export const fetchUserNotificationSuccess = (payload: any): fromTypes.NotificationAction => ({
  type: fromTypes.FETCH_USER_NOTIFICATION_SUCCESS,
  payload,
});

export const fetchUserNotificationError = (payload: any): fromTypes.NotificationAction => ({
  type: fromTypes.FETCH_USER_NOTIFICATION_ERROR,
  payload,
});

export const markUserNotificationAsRead = (id: Number): fromTypes.NotificationAction => ({
  type: fromTypes.MARK_USER_NOTIFICATION_AS_READ,
  id,
});

export const markUserNotificationAsReadSuccess = (payload: any): fromTypes.NotificationAction => ({
  type: fromTypes.MARK_USER_NOTIFICATION_AS_READ_SUCCESS,
  payload,
});

export const markUserNotificationAsReadError = (payload: any): fromTypes.NotificationAction => ({
  type: fromTypes.MARK_USER_NOTIFICATION_AS_READ_ERROR,
  payload,
});
