// @flow

export const FETCH_WALLET_POINTS = '[WALLET] Fetch wallet points';
export const FETCH_WALLET_POINTS_SUCCESS = '[WALLET] Fetch wallet points success';
export const FETCH_WALLET_POINTS_ERROR = '[WALLET] Fetch wallet points error';

export const FETCH_BANK_DATA = '[WALLET] Fetch bank data';
export const FETCH_BANK_DATA_SUCCESS = '[WALLET] Fetch bank data success';
export const FETCH_BANK_DATA_ERROR = '[WALLET] Fetch bank data error';

export const FETCH_BANK_STATEMENT = '[WALLET] Fetch bank statement';
export const FETCH_BANK_STATEMENT_SUCCESS = '[WALLET] Fetch bank statement success';
export const FETCH_BANK_STATEMENT_ERROR = '[WALLET] Fetch bank statement error';

export const UPDATE_BANK_DATA = '[WALLET] Update bank data';
export const UPDATE_BANK_DATA_SUCCESS = '[WALLET] Update bank data success';
export const UPDATE_BANK_DATA_ERROR = '[WALLET] Update bank data error';

export const REQUEST_WITHDRAWAL = '[WALLET] Request withdrawal';
export const REQUEST_WITHDRAWAL_SUCCESS = '[WALLET] Request withdrawal success';
export const REQUEST_WITHDRAWAL_ERROR = '[WALLET] Request withdrawal error';

type FetchWalletPoints = {
  type: typeof FETCH_WALLET_POINTS,
};

type FetchWalletPointsSuccess = {
  type: typeof FETCH_WALLET_POINTS_SUCCESS,
  payload: any,
};

type FetchWalletPointsError = {
  type: typeof FETCH_WALLET_POINTS_ERROR,
  payload: any,
};

type FetchBankData = {
  type: typeof FETCH_BANK_DATA,
};

type FetchBankDataSuccess = {
  type: typeof FETCH_BANK_DATA_SUCCESS,
  payload: any,
};

type FetchBankDataError = {
  type: typeof FETCH_BANK_DATA_ERROR,
  payload: any,
};

type FetchBankStatement = {
  type: typeof FETCH_BANK_STATEMENT,
  payload: any,
};

type FetchBankStatementSuccess = {
  type: typeof FETCH_BANK_STATEMENT_SUCCESS,
  payload: any,
};

type FetchBankStatementError = {
  type: typeof FETCH_BANK_STATEMENT_ERROR,
  payload: any,
};

type UpdateBankData = {
  type: typeof UPDATE_BANK_DATA,
  payload: any,
};

type UpdateBankDataSuccess = {
  type: typeof UPDATE_BANK_DATA_SUCCESS,
  payload: any,
};

type UpdateBankDataError = {
  type: typeof UPDATE_BANK_DATA_ERROR,
  payload: any,
};

type RequestWithdrawal = {
  type: typeof REQUEST_WITHDRAWAL,
};

type RequestWithdrawalSuccess = {
  type: typeof REQUEST_WITHDRAWAL_SUCCESS,
  payload: any,
};

type RequestWithdrawalError = {
  type: typeof REQUEST_WITHDRAWAL_ERROR,
  payload: any,
};

export type WalletAction =
  | FetchWalletPoints
  | FetchWalletPointsSuccess
  | FetchWalletPointsError
  | FetchBankData
  | FetchBankDataSuccess
  | FetchBankDataError
  | FetchBankStatement
  | FetchBankStatementSuccess
  | FetchBankStatementError
  | UpdateBankData
  | UpdateBankDataSuccess
  | UpdateBankDataError
  | RequestWithdrawal
  | RequestWithdrawalSuccess
  | RequestWithdrawalError;
