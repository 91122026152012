import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { doveGray, white } from '../../themes/colors';

const useStyles = makeStyles((theme) => ({
  arrow: {
    color: doveGray,
  },
  tooltip: {
    backgroundColor: white,
    color: doveGray,
    border: `1px solid ${doveGray}`,
    padding: theme.spacing(2),
    fontSize: '1em',
    borderRadius: theme.spacing(1),
  },
}));

const CustomTooltip = (props) => {
  const classes = useStyles();

  return <Tooltip arrow classes={classes} {...props} />;
};

export default CustomTooltip;
