import React, { useEffect, useCallback } from 'react';

import { Box, Typography, Button } from '@material-ui/core';

import { toast } from 'react-toastify';

import useStyles from '../styles/Popup';

const Popup = ({ setIsVisible }) => {
  const classes = useStyles();

  const handleCancelPermission = useCallback(() => {
    toast.info('Ops.. sem aceitar os termos não poderemos continuar :(');
  }, []);

  useEffect(() => {
    const isApproved = localStorage.getItem('recognition-facial-popup');

    if (isApproved) {
      setIsVisible(false);
    }
  }, [setIsVisible]);

  function handleAccept() {
    setIsVisible(false);
    localStorage.setItem('recognition-facial-popup', true);
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.close}>
        <img
          className={classes.logo}
          src="https://d2ivdm85owtv7v.cloudfront.net/face/close.svg"
          alt="Imagem de um X"
          onClick={() => handleCancelPermission()}
        />
      </Box>
      <Typography className={classes.title}>
        TERMOS & CONDIÇÕES E POLÍTICA DE PRIVACIDADE
      </Typography>
      <Typography className={classes.paragraph}>
        Você precisa ter no <strong>mínimo 18 anos</strong> para usar este serviço.
      </Typography>
      <Typography className={classes.paragraph}>Selfies serão armazenadas.</Typography>
      <Typography className={classes.paragraph}>
        A utilização deste website é governada pela UAUBox. Veja nossos{' '}
        <a
          className={classes.terms}
          href="https://uaubox.com.br/termos-de-uso"
          rel="noopener noreferrer"
          target="_blank"
        >
          TERMOS DE USO
        </a>
      </Typography>
      <Typography className={classes.paragraph}>
        Usar os termos de uso e política de privacidade da UAU.
      </Typography>
      <Button color="primary" variant="outlined" onClick={handleAccept}>
        ESTOU CIENTE E QUERO CONTINUAR
      </Button>
    </Box>
  );
};

export default Popup;
