import { Grid, Tab, Tabs } from '@material-ui/core';
import React from 'react';
import useStyles from '../checkout.style';
import { Field } from 'redux-form';
import { RenderedTextField } from '../../../components/wrapped';
import { required, normalizeAll, trim } from '../../../components/utils/validators';

import { PaymentElement } from '@stripe/react-stripe-js';

export default function PaymentSection({ reset, tabSelected, setTabSelected, tenantName }) {
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    reset();
    setTabSelected(newValue);
  };

  return (
    <div className={classes.paymentMethods}>
      <Tabs value={tabSelected} onChange={handleChange}>
        <Tab
          label="Informações de pagamento"
          className={tabSelected === 0 ? classes.selectedTab : classes.secondaryTab}
        />
        <Tab
          label="Cartão presente"
          className={tabSelected === 1 ? classes.selectedTab : classes.secondaryTab}
        />
      </Tabs>
      {tabSelected === 1 && (
        <Grid className={classes.giftCode} item xs={6}>
          <Field
            name="giftCode"
            type="text"
            component={RenderedTextField}
            label="Codigo do vale presente"
            validate={[required]}
            normalize={normalizeAll([trim])}
            placeholder="Codigo do vale presente"
          />
        </Grid>
      )}
      {tabSelected === 0 && (
        <>
          <div style={{ marginTop: '20px' }}>
            <PaymentElement />
          </div>
        </>
      )}
    </div>
  );
}
