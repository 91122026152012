import { getAuthCustomerId } from '../store/services/auth';

export const parseCardPayload = (cardDetails) => ({
  holder_name: cardDetails.holderName,
  card_expiration: cardDetails.expirationDate,
  card_number: cardDetails.cardNumber,
  card_cvv: cardDetails.verificationCode,
  payment_method_code: 'credit_card',
  payment_company_code: cardDetails.company,
});

export const parseSubscription = ({
  registration: {
    selectedPlan: { id },
    selectedCoupon: { name },
    payment: { paymentMethod, cardToken },
    additionalItem,
  },
  profile: { customer: customerId },
  mediaTracking,
  specialBoxEdition,
}) => ({
  customerId: customerId.id ?? getAuthCustomerId(),
  payment: { paymentMethod, creditCardToken: cardToken },
  subscription: {
    planId: id,
    discountCode: name,
    additionalItem,
  },
  mediaTracking: mediaTracking?.utm_source
    ? {
        utmSource: mediaTracking?.utm_source,
        utmCampaign: mediaTracking?.utm_campaign,
        utmMedium: mediaTracking?.utm_medium,
      }
    : null,
  specialBoxEdition,
});

export const updateSelectedPlan = (state, selectedPlan) => ({
  ...state,
  registration: {
    ...state.registration,
    selectedPlan,
  },
});

export const updateSelectedCoupon = (state, couponData) => ({
  ...state,
  selectedCoupon: couponData,
});

export const updateAdditionalItem = (state, selectedAdditionalItem) => ({
  ...state,
  registration: {
    ...state.registration,
    selectedAdditionalItem: selectedAdditionalItem,
    additionalItem: selectedAdditionalItem,
  },
});
