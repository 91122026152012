import { Button, MenuItem, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { push } from 'react-router-redux';
import Loading from '../../../../components/Loading';
import { BackButton, BackButtonMobile } from '../../../../components/material';
import { getPlanName } from '../../../../models/subscription';
import {
  fetchPaymentProfiles,
  fetchSubscriptionDetails,
  fetchUpgradePlanOption,
  upgradeSubscriptionPlan,
} from '../../../../store/actions';

import {
  alto,
  black,
  oldLace,
  pippin,
  pirateGold,
  redOrange,
  tundora,
} from '../../../../themes/colors';

import UpgradeConfirmation from '../../../../components/popups/UpgradeConfirmation';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    boxShadow: `1px 3px 6px ${alto}`,
    minHeight: '100vh',
  },
  mainBox: {
    background: pippin,
    padding: '1.5rem 2rem',
  },
  titleBox: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '2rem',
  },
  title: {
    color: black,
    fontSize: '2em',
    padding: '0 2.5rem',
    [theme.breakpoints.up('lg')]: {
      padding: '0 1rem',
    },
  },
  subtitle: {
    color: tundora,
    fontSize: '1.2em',
  },
  contentBox: {
    padding: '2rem',
    [theme.breakpoints.up('md')]: {
      maxWidth: '60%',
    },
  },
  contentButtonBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '1.5rem 0',
    '& button': {
      marginBottom: '1rem',
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start',
      flexDirection: 'row',
      '& button': {
        marginRight: '0.5rem',
        '&': {
          marginBottom: '0',
        },
      },
    },
  },
  cardTitle: {
    color: tundora,
    fontSize: '1.3em',
  },
  cardHighlight: {
    color: black,
    fontWeight: 'bold',
  },
  cardSubtitle: {
    color: black,
    fontSize: '1.4em',
  },
  card: {
    borderBottom: `1px solid ${alto}`,
    padding: '1rem 0',
    color: black,
    '& svg': {
      height: '1.5em',
      width: '1.5em',
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  warningBox: {
    color: pirateGold,
    background: oldLace,
    border: `1px solid ${pirateGold}`,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    margin: '1rem 0',
    display: 'flex',
    '& a': {
      color: redOrange,
      textDecoration: 'none',
    },
  },
  warningIconBox: {
    marginRight: theme.spacing(2),
    '& svg': {
      width: '2em',
      fill: pirateGold,
    },
  },
}));

const Subscription = ({ onFetchPaymentProfiles }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [comboPlan, setComboPlan] = useState('');
  const { subscription, upgradePlanOptions, loading } = useSelector((state) => state.subscription);
  const { tenantName } = useSelector((state) => state?.auth);
  const [showModal, setShowModal] = useState(false);

  // const [planId, setPlanId] = useState('');
  const showArrow = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  const profileType = localStorage.getItem('profileType');

  useEffect(() => {
    dispatch(fetchSubscriptionDetails());
    dispatch(fetchUpgradePlanOption());
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(subscription)) {
      setComboPlan(subscription?.plan?.identifier);
    }
  }, [subscription]);

  useEffect(() => {
    onFetchPaymentProfiles();
  }, [onFetchPaymentProfiles]);

  return (
    <Loading isLoading={loading}>
      <div className={classes.root}>
        <BackButtonMobile onClick={() => dispatch(push('/perfil'))} visible={!showArrow} />
        <div className={classes.mainBox}>
          <div className={classes.titleBox}>
            <BackButton onClick={() => dispatch(push('/perfil'))} visible={showArrow} />
            <Typography className={classes.title}>Plano</Typography>
          </div>
          {/* <Typography className={classes.subtitle} gutterBottom>
            Altere seu plano caso precisar
          </Typography> */}
        </div>

        <div className={classes.contentBox}>
          <Typography className={classes.subtitle} gutterBottom>
            Atualmente, seu plano é o <strong>{getPlanName(subscription?.plan, tenantName)}</strong>
          </Typography>

          <TextField
            select
            label="Trocar plano"
            margin="dense"
            fullWidth
            variant="outlined"
            value={comboPlan}
          >
            {upgradePlanOptions?.map((item) => {
              if (item?.identifier.includes(profileType.toLowerCase())) {
                return (
                  <MenuItem
                    key={item?.identifier}
                    value={item?.identifier}
                    onClick={() => setComboPlan(item?.identifier)}
                  >
                    <span>{getPlanName(item)}</span>
                  </MenuItem>
                );
              }
            })}
          </TextField>

          <div className={classes.contentButtonBox}>
            <Button variant="outlined" color="secondary" onClick={() => dispatch(push(`/perfil`))}>
              voltar
            </Button>
            <Button
              variant="contained"
              color="secondary"
              disabled={comboPlan === subscription?.plan?.identifier || loading}
              onClick={() => setShowModal(true)}
            >
              atualizar plano
            </Button>
          </div>
        </div>

        <UpgradeConfirmation
          showModal={loading ? false : showModal}
          setShowModal={setShowModal}
          onUpgrade={() =>
            dispatch(upgradeSubscriptionPlan(subscription?.id, { planId: comboPlan }, profileType))
          }
        />
      </div>
    </Loading>
  );
};

const mapStateToProps = (state) => {
  const { paymentProfiles, loading } = state.subscription;
  localStorage.setItem('profileType', paymentProfiles[0]?.platform.type);
  return { paymentProfiles, loading };
};

const mapDispatchToProps = (dispatch) => ({
  onFetchPaymentProfiles: () => dispatch(fetchPaymentProfiles()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Subscription);
