import React from 'react';
import useStyles from '../checkout.style';
import { TextField } from '../../../components/material';
import {
  Button,
  CircularProgress,
  Divider,
  InputAdornment,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { useState } from 'react';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import queryString from 'query-string';
import { useElements, useStripe } from '@stripe/react-stripe-js';

export default function OrderSummary({
  tabSelected,
  planList,
  onSelectPlan,
  tenantName,
  selectedPlan,
  promotionalShippingCost,
  shippingCost,
  selectedCoupon,
  onFetchCoupon,
  customer,
  onClearCoupon,
  loading,
  onFetchCouponPlanAvailable,
  handleSubmit,
  onCreateVindiSubscription,
  onCreateStripeSubscription,
}) {
  const classes = useStyles();
  const [planInUrlId, setPlanInUrl] = useState(0);
  const [comboPlan, setComboPlan] = useState('');
  const [couponDiscount, setCouponDiscount] = useState(0);
  const [promotionalValue, setPromotionalValue] = useState(0);
  const [coupon, setCoupon] = useState();
  const [boxCost, setBoxCost] = useState(0);
  const [kit] = useState('');
  const [cycleCoupon, setCycleCoupon] = useState();
  const [mediaTracking, setMediaTracking] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const BASE_URL = process.env.REACT_APP_MAIN_ADDRESS;

  const onSubmit = async (payload) => {
    setIsLoading(true);
    if (!selectedPlan.id && tabSelected === 0) {
      setIsLoading(false);
      return toast.error('Você deve selecionar um plano!');
    }

    if (!stripe || !elements) {
      toast.error(
        'Estamos com algum problema com nosso serviço de pagamento, por favor, tente novamente mais tarde!'
      );
      setIsLoading(false);
      return;
    }

    let result = null;
    const specialBoxEdition = null;

    if (tabSelected === 0) {
      result = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: `${BASE_URL}/dashboard`,
        },
        redirect: 'if_required',
      });

      if (result.error) {
        // Show error to your customer (for example, payment details incomplete)
        toast.error(
          'Houve algum problema confirmando as informações do seu pagamento. Tente novamente em alguns minutos.'
        );
        console.error(result.error);
        setIsLoading(false);
        setTimeout(() => window.location.reload(), 4000);
        return;
      }
    }

    const subscription = {
      realPrice: selectedPlan.amount,
      realFreight: getShippingCost() * 100,
      planName: selectedPlan,
    };

    const cardDetails = {
      giftCode: tabSelected === 0 ? null : payload.giftCode,
    };
    const paymentMethod = result ? result.setupIntent?.payment_method : null;
    const plan = selectedPlan;

    await onCreateStripeSubscription(
      subscription,
      cardDetails,
      plan,
      paymentMethod,
      coupon,
      couponDiscount,
      mediaTracking,
      specialBoxEdition,
      customer
    );

    setIsLoading(false);

    //onCreateVindiSubscription(subscription, mediaTracking, null);
  };

  const parseMoneyValue = (value) =>
    value ? value.toFixed(2).replace('.', ',') : Number(0).toFixed(2);

  const getShippingCost = () => {
    return promotionalShippingCost === null || selectedCoupon.percentage > 0.1
      ? shippingCost
      : promotionalShippingCost;
  };

  const getTitle = ({ recurrenceNumberOfPeriods }) => {
    switch (recurrenceNumberOfPeriods) {
      case 1:
        return tenantName === 'uaubox' ? 'Testei (Mensal)' : 'Mensal';
      case 3:
        return tenantName === 'uaubox' ? 'Amei (Trimestral)' : 'Trimestral';
      case 6:
        return tenantName === 'uaubox' ? 'Viciei (Semestral)' : 'Semestral';
      case 12:
        return tenantName === 'uaubox' ? 'Não Vivo Sem (Anual)' : 'Anual';
      default:
        return tenantName === 'uaubox' ? 'Não Vivo Sem (Anual)' : 'Anual';
    }
  };

  const getInstallmentAmount = ({ hasInstallments, recurrenceNumberOfPeriods }) =>
    hasInstallments ? `${recurrenceNumberOfPeriods}x de ` : '';

  const calculateBoxCost = ({ amountInstallment }) => {
    return amountInstallment ? amountInstallment / 100 : 0;
  };

  const getPlanTitle = (plan) => {
    const cost = parseMoneyValue(calculateBoxCost(plan));
    const installmentAmount = getInstallmentAmount(plan);
    return `${tenantName !== 'uaubox' ? 'Plano' : ''} ${getTitle(
      plan
    )} - ${installmentAmount}R$${cost} + Frete`;
  };
  const isPromotionPlan = () => {
    return false;
    // return (
    //   tenantName === 'uaubox' &&
    //   (isEqual(selectedPlan.recurrencePeriod, 'ANNUAL') ||
    //     isEqual(selectedPlan.recurrencePeriod, 'ANNUAL_INSTALLMENT'))
    // );
  };

  const handleOnClearCoupon = () => {
    onClearCoupon();
    setCoupon('');
  };

  const handleOnCupomPress = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      if (coupon && selectedPlan.id) {
        onFetchCoupon(selectedPlan.id, coupon, customer?.cpf, customer?.email);
      } else {
        toast.error('Você deve selecionar um plano e digitar o nome do cupom!');
      }
    }
  };

  const handleOnValidateCoupon = () => {
    if (selectedPlan.id && coupon) {
      onFetchCoupon(selectedPlan.id, coupon, customer?.cpf, customer?.email);
    } else {
      toast.error('Selecione um plano e adicione um Cupom!');
    }
  };

  const handlePromotions = () => {
    // if (
    //   tenantName === 'uaubox' &&
    //   !isEqual(selectedCoupon?.name, 'UAUPRIMEIRO1REAL') &&
    //   (isEqual(selectedPlan.recurrencePeriod, 'ANNUAL') ||
    //     isEqual(selectedPlan.recurrencePeriod, 'ANNUAL_INSTALLMENT'))
    // ) {
    //   onFetchCoupon(selectedPlan?.id, 'UAUPRIMEIRO1REAL', customer?.cpf, customer?.email);
    // }
  };

  const totalCost = () => {
    const updateBoxCost = promotionalValue || boxCost;
    if (selectedPlan.id === 55) {
      let total =
        (Number(updateBoxCost) || 0) +
        (Number(getShippingCost()) || 0) -
        (Number(couponDiscount) || 0) +
        (kit !== '' && 109.9);
      return parseMoneyValue(total);
    }
    if (selectedPlan.id === 56) {
      let total =
        (Number(updateBoxCost) || 0) +
        (Number(getShippingCost()) || 0) -
        (Number(couponDiscount) || 0) +
        (kit !== '' && 109.9);
      return parseMoneyValue(total);
    }
    let total =
      (Number(updateBoxCost) || 0) +
      (Number(getShippingCost()) || 0) -
      (Number(couponDiscount) || 0) +
      (kit !== '' && 109.9);
    return parseMoneyValue(total);
  };

  const totalCostDescriptionAdditionalItem = () => {
    let total = (Number(calculateBoxCost(selectedPlan)) || 0) - (Number(couponDiscount) || 0);
    return parseMoneyValue(total);
  };

  const getMessagePromotional = () => {
    if (cycleCoupon === 'ONE_MONTH') {
      return (
        <Typography className={classes.totalObs}>
          * O valor da primeira mensalidade será de R$
          {parseMoneyValue(promotionalValue)}. As demais mensalidades, o valor do plano será de R$
          {totalCostDescriptionAdditionalItem()} + frete.
        </Typography>
      );
    }
    if (cycleCoupon === 'ONE_SUBSCRIPTION_CYCLE') {
      return (
        <Typography className={classes.totalObs}>
          * O valor R${parseMoneyValue(promotionalValue)} será válido durante príodo dessa
          assinatura.
        </Typography>
      );
    }
  };

  const calculateCouponDiscount = ({ amount, percentage, amountPromotional, cycle }, boxCost) => {
    setPromotionalValue(0);
    if (percentage) {
      return boxCost * percentage;
    }
    if (amount) {
      return amount;
    }
    if (amountPromotional) {
      setPromotionalValue(amountPromotional);
      setCycleCoupon(cycle);
    }
    return 0;
  };

  useEffect(() => {
    const paramsUrl = queryString.parse(window.location.search);
    setPlanInUrl(paramsUrl.plan);
  }, []);

  useEffect(() => {
    setCouponDiscount(calculateCouponDiscount(selectedCoupon, calculateBoxCost(selectedPlan)));
    setCoupon(selectedCoupon.name || '');
  }, [selectedCoupon, selectedPlan]);

  useEffect(() => {
    const paramsUrl = queryString.parse(window.location.search);
    const couponUrl = paramsUrl?.c;
    const planIdUrl = planInUrlId;
    if (
      Object.keys(customer).length > 0 &&
      !sessionStorage.removeItem('planIdByRegister') &&
      planList.length > 0
    ) {
      const planByIdUrl = planIdUrl ? planList.find((p) => p.id === Number(planIdUrl)) : null;
      const planToSet = planByIdUrl?.id ? planByIdUrl : planList[planList.length - 1];
      setComboPlan(planToSet.id);
      setBoxCost(calculateBoxCost(planToSet));
      onSelectPlan(planToSet);
      if (couponUrl) onFetchCoupon(planToSet.id, couponUrl, customer?.cpf, customer?.email);
      //solução paliativa para não mostrar alerta de cupom vazio caso seja passado o cupom sem valor
    }
    // eslint-disable-next-line
  }, [planList, customer]);

  useEffect(
    () => {
      if (selectedPlan.id) {
        if (selectedCoupon?.name && coupon) {
          onClearCoupon();
          onFetchCouponPlanAvailable(selectedPlan.id, coupon, customer?.cpf, customer?.email);
        }
        setBoxCost(calculateBoxCost(selectedPlan));
        setComboPlan(selectedPlan.id);
        handlePromotions();
      }
    },
    // eslint-disable-next-line
    [selectedPlan]
  );

  useEffect(() => {
    const data = localStorage.getItem('subscription-media-tracking');
    if (data) {
      setMediaTracking(JSON.parse(data));
    }
  }, []);

  useEffect(() => {
    let search = window.location.search;
    const token = localStorage.getItem('token');
    search = search + `&refreshToken=${token}`;
    const env = process.env.REACT_APP_NODE_ENV;
    const baseUrl =
      env === 'staging'
        ? 'https://checkout-staging.uaubox.com.br/auth'
        : 'https://checkout-uau.uaubox.com.br/auth';
    const url = `${baseUrl}?tenant=${tenantName}&${search.replace('?', '')}`;
    window.location.href = url;
  }, [tenantName]);

  return (
    <div className={classes.orderSummary}>
      <form noValidate={tabSelected === 0} onSubmit={handleSubmit(onSubmit)}>
        {tabSelected === 0 && (
          <>
            <h1 className={classes.openSans}>Resumo do pedido</h1>
            <TextField
              select
              label="Selecione um plano"
              margin="dense"
              fullWidth
              variant="outlined"
              value={comboPlan}
            >
              {planList
                .filter((plan) => plan.recurrencePeriod !== 'QUARTERLY')
                .map((item) => (
                  <MenuItem
                    key={item.id}
                    value={item.id}
                    onClick={() => {
                      onSelectPlan(item);
                    }}
                  >
                    {getPlanTitle(item)}
                  </MenuItem>
                ))}
            </TextField>

            {promotionalValue ? (
              <Typography className={classes.boxOnlyPrice}>
                R${parseMoneyValue(promotionalValue)} + frete{' '}
              </Typography>
            ) : null}

            {!promotionalValue && selectedPlan?.id ? (
              <Typography className={classes.boxOnlyPrice}>
                {planInUrlId === '55'
                  ? `R$${parseMoneyValue(calculateBoxCost(selectedPlan) / 12)}/mês + frete`
                  : `R$${parseMoneyValue(calculateBoxCost(selectedPlan))}/mês + frete`}
              </Typography>
            ) : null}
          </>
        )}
        <div className={classes.subtotal}>
          <Typography className={classes.subtotalTitle}>PREÇO DO FRETE</Typography>

          <Typography className={classes.subtotalPrice}>
            {
              // eslint-disable-next-line
              getShippingCost() != '0' ? (
                `R$ ${parseMoneyValue(getShippingCost())} / mês`
              ) : (
                <strong>FRETE GRÁTIS</strong>
              )
            }
          </Typography>
        </div>
        {tabSelected === 0 && (
          <>
            {!!couponDiscount && (
              <div className={classes.subtotal}>
                <Typography className={classes.subtotalTitle}>DESCONTO CUPOM</Typography>
                <Typography className={classes.subtotalPrice}>
                  {selectedPlan.id === 55 && `R$ -${parseMoneyValue(couponDiscount * 12)}`}
                  {selectedPlan.id === 56 && `R$ -${parseMoneyValue(couponDiscount * 6)}`}
                  {selectedPlan.id !== 55 &&
                    selectedPlan.id !== 56 &&
                    `R$ -${parseMoneyValue(couponDiscount)}`}
                </Typography>
              </div>
            )}
            <Divider style={{ marginBottom: '8px' }} />
            {!isPromotionPlan() && (
              <>
                <div className={classes.couponHolder}>
                  <TextField
                    label={isEmpty(selectedCoupon) ? 'Cupom de desconto' : selectedCoupon.name}
                    margin="dense"
                    fullWidth
                    variant="outlined"
                    value={coupon}
                    placeholder="Cupom de desconto"
                    onChange={(e) => setCoupon(e.target.value)}
                    onKeyDown={handleOnCupomPress}
                    disabled={!isEmpty(selectedCoupon)}
                    InputProps={{
                      style: { height: '50px' },
                      endAdornment: (
                        <InputAdornment position={'end'}>
                          <Button
                            className={classes.buttonCoupon}
                            onClick={
                              isEmpty(selectedCoupon) ? handleOnValidateCoupon : handleOnClearCoupon
                            }
                            disabled={!coupon}
                          >
                            {isEmpty(selectedCoupon) ? 'Aplicar' : 'Remover'}
                          </Button>
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
                <Divider style={{ marginTop: '8px' }} />
              </>
            )}
          </>
        )}

        <div className={classes.totalContainer}>
          <Typography className={classes.totalName}>Total</Typography>
          <Typography className={classes.totalNum}>
            {selectedPlan.id === 55 && `12x de R$ ${totalCost()}`}
            {selectedPlan.id === 56 && `6x de R$ ${totalCost()}`}
            {selectedPlan.id !== 55 && selectedPlan.id !== 56 && `R$ ${totalCost()}`}
          </Typography>
        </div>
        {selectedCoupon?.name?.toUpperCase() === 'GERACAOUAU' && (
          <Typography className={classes.totalObs}>
            {(selectedPlan.recurrencePeriod === 'BIANNUAL' ||
              selectedPlan.recurrencePeriod === 'BIANNUAL_INSTALLMENT') &&
              'Você receberá UMA box de presente!'}
            {(selectedPlan.recurrencePeriod === 'ANNUAL' ||
              selectedPlan.recurrencePeriod === 'ANNUAL_INSTALLMENT') &&
              'Você receberá DUAS box de presente!'}
          </Typography>
        )}
        {promotionalShippingCost !== null && selectedCoupon.percentage > 0.1 && (
          <Typography className={classes.totalObs}>
            ATENÇÃO: Promoção Frete Grátis desativada pois não é acumulativa para cupons com
            desconto acima de 10%.
          </Typography>
        )}
        {promotionalValue ? getMessagePromotional() : null}
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button
            className={classes.btnSignature}
            color="primary"
            variant="contained"
            type="submit"
            disabled={loading || isLoading}
          >
            {isLoading ? (
              <CircularProgress size={20} thickness={5} color="white" />
            ) : (
              'Concluir assinatura'
            )}
          </Button>
        </div>
      </form>
    </div>
  );
}
