import React, { useEffect } from 'react';
import { Typography, Grid, Button, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { RenderedSelect, RenderedTextareaField } from '../../components/wrapped';
import { Field, reduxForm } from 'redux-form';
import { push } from 'react-router-redux';
import { deliveryFeedback, fetchOrderId } from '../../store/actions';
import { required } from '../../components/utils/validators';
import { getTenantToken } from '../../store/services/auth';
import Loading from '../../components/Loading';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    height: '100vh',
    '& h1': {
      fontSize: '2em',
      margin: '2rem 0 1rem',
    },
  },
  mainBox: {
    maxWidth: '25rem',
    margin: 'auto',
  },
  logoUau: {
    display: 'flex',
    justifyContent: 'center',
    margin: '3rem',
    height: '110px',
    position: 'relative',
    '& img': {
      position: 'absolute',
      visibility: 'hidden',
      opacity: '0',
      transition: 'all 700ms',
      cursor: 'pointer',
    },
    '& svg': {
      width: '6rem',
    },
  },
  imgLogin: {
    display: 'flex',
    height: '768px',
    '& img': {
      position: 'absolute',
      visibility: 'hidden',
      opacity: '0',
      transition: 'all 700ms',
    },
  },
  imgActive: {
    opacity: '1 !important',
    visibility: 'visible !important',
  },
  formBox: {
    padding: '0 1.5rem',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '1rem',
    '& button': {
      marginTop: theme.spacing(1),
      padding: '1rem',
      fontSize: '1.3em',
      fontWeight: 'bold',
    },
  },
  grid: {
    height: '100%',
    overflowX: 'hidden',
  },
}));

const DeliveryFeedback = ({
  navigateTo,
  handleSubmit,
  loading,
  onFetchOrderId,
  onDeliveryFeedback,
}) => {
  const classes = useStyles();
  const highDevice = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const tenant = getTenantToken();

  const experienceList = [
    { id: 'EXCELLENT', name: 'Ótima' },
    { id: 'GOOD', name: 'Boa' },
    { id: 'BAD', name: 'Ruim' },
  ];

  useEffect(() => {
    onFetchOrderId();
  }, [onFetchOrderId]);

  const onSubmit = (form) => onDeliveryFeedback(form);

  return (
    <Loading isLoading={loading}>
      <div className={classes.root}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
          <Grid container justify="center" alignItems="center" className={classes.grid}>
            <Grid item xs={12} md={6}>
              <div className={classes.mainBox}>
                <div className={classes.logoUau}>
                  <img
                    src="https://d2ivdm85owtv7v.cloudfront.net/images/uaubox/logo_uau_coral.png"
                    alt="UAUBox Default"
                    onClick={() => navigateTo('/dashboard')}
                  />
                </div>
                <Typography variant="h1" align="center">
                  Feedback da Entrega
                </Typography>
                <div className={classes.formBox}>
                  <Field
                    name="experienceRating"
                    type="select"
                    component={RenderedSelect}
                    label="Como foi sua experiencia com a entrega?"
                    validate={[required]}
                  >
                    {experienceList.map((i) => (
                      <MenuItem key={i.id} value={i.id}>
                        {i.name}
                      </MenuItem>
                    ))}
                  </Field>
                  <Field
                    name="extraDetails"
                    type="text"
                    component={RenderedTextareaField}
                    placeholder="Nos conte em mais detalhes"
                  />

                  <div className={classes.formBox}>
                    <Button
                      variant="contained"
                      className={classes.loginBtn}
                      color="secondary"
                      type="submit"
                    >
                      Avaliar
                    </Button>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={6} style={{ position: 'relative' }}>
              {highDevice ? (
                <div className={classes.imgLogin}>
                  <img
                    className={`${tenant === 'uaubox' ? classes.imgActive : null}`}
                    src="https://d2ivdm85owtv7v.cloudfront.net/images/customer-area/sign_in_image.png"
                    alt="UAUBox Default"
                  />
                  <img
                    className={`${tenant === 'scarlet' ? classes.imgActive : null}`}
                    src="https://d2ivdm85owtv7v.cloudfront.net/images/customer-area/sign_in_image_scarlet.jpg"
                    alt="Scarlet by UAUBox"
                  />
                </div>
              ) : null}
            </Grid>
          </Grid>
        </form>
      </div>
    </Loading>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    navigateTo: (path) => dispatch(push(path)),
    onDeliveryFeedback: (form) => dispatch(deliveryFeedback(form)),
    onFetchOrderId: () => dispatch(fetchOrderId()),
  };
};

const mapStateToProps = (state, props) => {
  const { loading } = state.profile;
  return {
    loading,
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'deliveryFeedbackForm',
    enableReinitialize: true,
  })
)(DeliveryFeedback);
