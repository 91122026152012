import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from '../styles/Result';
import Loading from '../../../components/Loading';

import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import RotateLeftOutlinedIcon from '@material-ui/icons/RotateLeftOutlined';

import { Box, Typography } from '@material-ui/core';

import {
  handleGetResult,
  handleToggleLike,
  handleDeleteLikeRequest,
  handleGetFeedback,
} from '../../../store/actions';
import { Banner } from './Banner';

const Card = ({ item }) => {
  const getTotal = useCallback((item, number) => {
    const value_parsed = parseInt(item.value);

    if (['yes', 'no'].includes(item.value)) {
      if (number) return Math.round(item.confidence * 100);

      return `${Math.round(item.confidence * 100)}%`;
    }

    if (isNaN(value_parsed)) return 0;

    if (number) return Math.round(item.confidence * 100 * value_parsed);

    return `${Math.round(item.confidence * 100 * value_parsed)}%`;
  }, []);

  const classes = useStyles({ value: getTotal(item, true) });

  const dispatch = useDispatch();

  const { feedbacks } = useSelector((state) => state.face);

  const [seeMore, setSeeMore] = useState([]);

  const getTitleAndText = useCallback((name) => {
    if (name.includes('Pele ')) {
      const skin_texts = {
        'Pele normal': {
          name: 'Pele normal',
          smaller_text:
            'Mig sua pele é assim perfeita, tem uma textura saudável, produz gordura na quantidade certa...',
          text:
            'Mig sua pele é assim perfeita, tem uma textura saudável, produz gordura na quantidade certa, sem excesso de brilho ou de ressecamento, equilibrado com a quantidade de água adequada para a saúde da pele. Zero defeitos e para manter essa pele de pêssego é só continuar usando produtos com hidratação.',
        },
        'Pele seca': {
          name: 'Pele seca',
          smaller_text:
            'Mig, a pele seca é assim um pouco opaca, áspera e com pouco brilho. Mas mesmo tendo menos...',
          text:
            'Mig, a pele seca é assim um pouco opaca, áspera e com pouco brilho. Mas mesmo tendo menos hidratação e com tendência a craquelar com facilidade, é só saber cuidar direitinho que sua pele vai ficar bapho. Assim como uma plantinha, a sua pele precisa de muita hidratação e o principal, muuuita água. ',
        },
        'Pele oleosa': {
          name: 'Pele oleosa',
          smaller_text:
            'Pois é mig, sua pele é aquelas que já sai brilhando em tudo né. Mas tá tudo bem, a pele oleosa apresenta...',
          text:
            'Pois é mig, sua pele é aquelas que já sai brilhando em tudo né. Mas tá tudo bem, a pele oleosa apresenta excesso na produção de sebo, o que deixa a cútis brilhosa e pegajosa, além de mais propensa ao aparecimento da acne. Mas às vezes não tem como fugir, pele oleosa pode ser pode ser uma herança genética ou fatores externos que fomentam a produção do óleo em excesso, como a alimentação, etc.  A nossa pele é um todo, não só interno como externo, por isso cuide da sua saúde mental e física também. Para a pele oleosa, os melhores produtos são aqueles livres de óleo e com textura fluida, podendo também ser gel-creme, loção e sérum.           ',
        },
        'Pele mista': {
          name: 'Pele mista',
          smaller_text:
            'MIG, aquela pele que tu não sabe se é normal se é oleosa, aquele vai e vem, mas sim a sua pele é MISTA!...',
          text:
            'MIG, aquela pele que tu não sabe se é normal se é oleosa, aquele vai e vem, mas sim a sua pele é MISTA! É assim que funciona: apresenta aspecto oleoso, poros dilatados na região da testa, nariz e queixo (zona T), tem tendência a acne e o aspecto seco nas bochechas e extremidades do rosto, com facilidade para descamação e irritação. Agora, para ter um equilíbrio é preciso hidratar e controlar a oleosidade, além de evitar adstringência em excesso, soluções alcoólicas e texturas muito cremosas. Os produtos que assim só vão ajudar na sua pele é o sérum, gel e o fluido. Escolha sempre produtos oil-free, pois têm textura leve e não deixam a pele pegajosa.          ',
        },
      };

      return skin_texts[name] || '';
    }

    const texts = {
      eye_pouch: {
        name: 'Olheiras',
        smaller_text:
          'Uma das áreas mais sensíveis do nosso rosto, é onde surgirão os primeiros sinais da saúde da sua pele...',
        text:
          'Uma das áreas mais sensíveis do nosso rosto, é onde surgirão os primeiros sinais da saúde da sua pele. Seja por fatores externos, como muita exposição ao sol e poluição, ou fatores internos, como estresse e noites mal dormidas. Mas é fácil diminuir isso! Tenha uma ótima noite de sono pra ter uma boa saúde mental e física, cuide mais de você e mantenha uma rotina de cuidados diários com a sua pele.',
      },
      acne: {
        name: 'Espinhas',
        smaller_text:
          'Tão chatinha, que a gente queria que nem existisse né? Mas acne é super comum e tá tudo bem ter acne...',
        text:
          'Tão chatinha, que a gente queria que nem existisse né? Mas acne é super comum e tá tudo bem ter acne. Ela é causada por alterações hormonais, é mais comum durante a adolescência mas adultos também podem ter espinhas, seja por fatores externos (como estilo de vida) ou internos como genética. Agora, vamos aprender a cuidar dessa acne miga, investe em uma rotina de cuidado diário com a sua pele, lave o rosto pelo menos 2 vezes ao dia. A acne também pode estar relacionada a estresse e má alimentação, nossa pele funciona em conjunto com o corpo miga, então cuide também da sua alimentação e saúde mental que ó, vai dar bom. ',
      },
      pores_jaw: {
        name: 'Poros',
        smaller_text:
          'Super comuns também, mas não menos importante os poros dilatados são mais comum...',
        text:
          'Super comuns também, mas não menos importante os poros dilatados são mais comum em quem tem pele oleosa ou mista, embora também possam acontecer em peles secas e sensíveis, estes os poros dilatados são mais comuns nas regiões oleosas do rosto e estão intimamente ligados entre si. A limpeza de pele aqui vai ser a sua melhor amiga e é essencial manter a oleosidade da pele sob controle, apostando na água micelar e em um hidratante leve. ',
      },
      blackhead: {
        name: 'Cravos',
        smaller_text:
          'Mig do céu, você é aquelas que não pode ver um cravo que já quer esperar ou mesmo ficar...',
        text:
          'Mig do céu, você é aquelas que não pode ver um cravo que já quer esperar ou mesmo ficar horas vendo vídeos satisfatórios no Youtube dessa nobre arte? Vamo entender um pouco mais sobre eles?  Cravos surgem por  um aumento da produção do sebo e alterações na sua qualidade, ficando mais espessos e causando a obstrução dos poros, mas não só acontece em pele oleosa, cravos podem aparecer em outros tipos de pele sim, mas como você pode cuidar ? É simples, é investir em uma rotina de skincare (lavar, tonificar e hidratar)  todos ',
      },
      'straight hair': {
        name: 'Cabelo liso',
        smaller_text:
          'Mig, olha esses seus cabelos lisos e sedosos! Ameii, quer umas dicas pra ele?...',
        text:
          'Mig, olha esses seus cabelos lisos e sedosos! Ameii, quer umas dicas pra ele? O ideal é usar produtos leves. Mesmo se você fez química ou não, é preciso ficar atenta para não perder a hidratação natural. Sendo assim, evite a exposição ao sol e o uso constante de secadores, chapinhas ou babyliss. Não deixe também de fazer hidratação em casa ou no salão. ',
      },
      'wavy hair': {
        name: 'Cabelo enrolado',
        smaller_text:
          'Mig, olha esses seus cabelos cheio de vida e sedosos! Ameii, quer umas dicas pra ele?...',
        text:
          'Mig, olha esses seus cabelos cheio de vida e sedosos! Ameii, quer umas dicas pra ele? Normalmente quem tem cabelos cacheados já sabe que a aparência dos fios tende a ser mais seca. Isso acontece porque a estrutura dos fios é irregular e a oleosidade da raiz não consegue chegar às pontas causando ressecamento e, muitas vezes, fios quebradiços, além de frizz. Aposte em uma boa finalização com produtos certos para o seu tipo de cabelo e não deixe também de fazer hidratação em casa ou no salão. ',
      },
      'color skin': {
        name: 'Cor da pele',
        smaller_text: '',
        text: '',
      },
    };

    return texts[name] || '';
  }, []);

  return (
    <Box className={classes.card} key={item.name}>
      <Box className={classes.cardHeader} key={item.name}>
        <img
          className={classes.image}
          src="https://d2ivdm85owtv7v.cloudfront.net/face/pores.svg"
          alt=""
        />
        <Typography className={classes.title}>
          {getTitleAndText(item.name).name || item.name}
        </Typography>
        <Box className={classes.progressView}>
          <Box className={classes.progressContainer}>
            <Box className={classes.progress} total={getTotal(item)} />
            <Box className={classes.currentValue} />
            <Box className={classes.empty} total={getTotal(item)} />
          </Box>
          <Box className={classes.progressNumbers}>
            <Box className={classes.number} total={getTotal(item)}>
              {getTotal(item)}
            </Box>
          </Box>
        </Box>
        {!feedbacks?.some((name) => name === item.name) ? (
          <Typography className={classes.text}>Você concorda com esse resultado?</Typography>
        ) : (
          <Typography className={classes.text}>Deseja alterar o feedback?</Typography>
        )}
        {!feedbacks?.some((name) => name === item.name) ? (
          <>
            <Box className={classes.actions}>
              <ThumbUpAltIcon
                onClick={() =>
                  dispatch(handleToggleLike({ questionName: item.name, isCorrect: true }))
                }
                fontSize="small"
                style={{
                  color: '#94FBA9',
                }}
              />
              <ThumbDownIcon
                onClick={() =>
                  dispatch(handleToggleLike({ questionName: item.name, isCorrect: false }))
                }
                fontSize="small"
                style={{
                  color: '#F88989',
                }}
              />
            </Box>
          </>
        ) : (
          <Box className={classes.actions}>
            <RotateLeftOutlinedIcon
              onClick={() => dispatch(handleDeleteLikeRequest({ questionName: item.name }))}
              style={{
                color: '#A01235',
              }}
              fontSize="small"
            />
          </Box>
        )}
      </Box>
      {item.value.toString() === '1' && (
        <Typography className={classes.description}>
          {seeMore.includes(item.name)
            ? getTitleAndText(item.name).text
            : getTitleAndText(item.name).smaller_text}
          {seeMore.includes(item.name) ? (
            <Typography onClick={() => setSeeMore([...seeMore.filter((see) => see !== item.name)])}>
              ver menos
            </Typography>
          ) : (
            <Typography onClick={() => setSeeMore([...seeMore, item.name])}>ver mais</Typography>
          )}
        </Typography>
      )}
      {['yes', 'no'].includes(item.value) && (
        <Typography className={classes.description}>
          {seeMore.includes(item.name)
            ? getTitleAndText(item.name).text
            : getTitleAndText(item.name).smaller_text}
          {seeMore.includes(item.name) ? (
            <Typography onClick={() => setSeeMore([...seeMore.filter((see) => see !== item.name)])}>
              ver menos
            </Typography>
          ) : (
            <Typography onClick={() => setSeeMore([...seeMore, item.name])}>ver mais</Typography>
          )}
        </Typography>
      )}
      {item.value.toString() === '0' && (
        <Typography className={classes.description}>
          Mig, por aqui o resultado foi desconsiderado devido a baixa porcentagem.
        </Typography>
      )}
    </Box>
  );
};

const Result = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const { data, image } = useSelector((state) => state.face);

  useEffect(() => {
    setLoading(true);

    dispatch(handleGetResult());

    setLoading(false);
  }, [dispatch]);

  useEffect(() => {
    setLoading(true);

    dispatch(handleGetFeedback());

    setLoading(false);
  }, [dispatch]);

  return (
    <Loading isLoading={loading || !data.length}>
      <Box className={classes.container}>
        <Banner title={'Seus resultados'} description={'O que achou da jornada?'} />
        <Box display="flex" flexDirection="column" style={{ width: '100%' }}>
          <Box className={classes.avatarContent}>
            {!!data.length && image && <img src={image} alt="" className={classes.avatar} />}
            <Box
              style={{
                marginTop: image ? '' : '4rem',
              }}
              className={classes.cards}
            >
              {!!data.length &&
                data.map((item, index) => <Card key={item.name} index={index} item={item} />)}
            </Box>
            <Box className={classes.cardsMobile}>
              {data.length && data?.map((item) => <Card key={item.name} item={item} />)}
            </Box>
          </Box>
        </Box>
      </Box>
    </Loading>
  );
};

export default Result;
