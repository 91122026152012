import planList from './planList';

const login = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'login',
  });
};

const beginCheckout = (plan, price, freightPrice, coupon) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });

  const totalPrice = price / 100 + freightPrice;
  const tenantName = getTenantName(plan.id);
  const itemName = `Plano_${tenantName}_${convertRecurrenceName(plan.recurrencePeriod)}`;

  window.dataLayer.push({
    event: 'begin_checkout',
    ecommerce: {
      currency: 'BRL',
      value: totalPrice,
      coupon: coupon.name,
      items: [
        {
          item_id: plan.id,
          item_name: itemName,
          item_brand: tenantName,
          price: totalPrice,
        },
      ],
    },
  });
};

const purchase = (realPrice, realFreight, plan, customer, couponName, couponDiscount) => {
  const formattedRecurrence = convertRecurrenceName(plan.recurrencePeriod);
  const formattedPrice = realPrice / 100;
  const tenantName = getTenantName(plan.id);
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'purchase',
    ecommerce: {
      transaction_id: `${customer.id}_${plan.id}`,
      value: formattedPrice,
      ltv_value: (realPrice * plan.recurrenceNumberOfPeriods) / 100,
      shipping: realFreight / 100,
      currency: 'BRL',
      coupon: couponName,
      items: [
        {
          item_name: `Plano_${tenantName}_${formattedRecurrence}`,
          item_id: plan.id,
          price: formattedPrice,
          item_brand: tenantName,
          item_category: '469',
          coupon: couponName.length > 0 ? couponName : null,
          discount: couponDiscount ? couponDiscount : null,
        },
      ],
    },
  });
};

function convertRecurrenceName(recurrence) {
  let formattedRecurrence = '';

  switch (recurrence) {
    case 'MONTH':
    case 'NONE':
      formattedRecurrence = 'MENSAL';
      break;
    case 'QUARTERLY':
    case 'QUARTERLY_INSTALLMENT':
      formattedRecurrence = 'TRIMESTRAL';
      break;
    case 'BIANNUAL':
    case 'BIANNUAL_INSTALLMENT':
      formattedRecurrence = 'SEMESTRAL';
      break;
    case 'ANNUAL':
    case 'ANNUAL_INSTALLMENT':
      formattedRecurrence = 'ANUAL';
      break;
    default:
      formattedRecurrence = 'ANUAL';
      break;
  }
  return formattedRecurrence;
}

function getTenantName(planId) {
  let tenantName = '';
  const index = planList.findIndex((plan) => plan.ids.includes(`${planId}`));
  if (index !== -1) {
    tenantName = planList[index].tenant;
  }
  return tenantName;
}

const dataLayerEvents = {
  login,
  beginCheckout,
  purchase,
};

export default dataLayerEvents;
