import React from 'react';
import TitleAndDescription from '../TitleAndDescription/TitleAndDescription';
import { makeStyles } from '@material-ui/core/styles';
import { cinzaMaisClaro, redOrange } from '../../themes/colors';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-toastify';

type PixData = {
  pix: {
    vindiPixId: number,
    pixCode: string,
    pixImage: string,
    amount: number,
    subscriptionId: number,
    expireAt: Date,
    recurrence: number,
  },
  plan?: any,
  showExtract?: boolean,
  index: number,
};

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: cinzaMaisClaro,
    padding: '25px 30px',
    marginTop: '20px',
  },
  title: {
    color: redOrange,
    fontSize: '24px',
    fontWeight: 600,
  },
  description: {
    fontWeight: 400,
    fontSize: '14px',
  },
  components: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: '10px',
  },
  divButtons: {
    display: 'flex',
    marginTop: '40px',
    justifyContent: 'end',
  },
  extract: {
    color: redOrange,
    fontSize: '16px',
    fontWeight: 500,
    cursor: 'pointer',
    marginRight: '25px',
    marginTop: 'auto',
    marginBottom: 'auto',
    textDecoration: 'none',
  },
  button: {
    backgroundColor: '#67CE67',
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
    color: '#FFFDF4',
    width: '279px',
    height: '48px',
    justifyContent: 'center',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  '@media (max-width: 780px)': {
    main: {
      marginRight: '20px',
      marginLeft: '20px',
      borderRadius: '9px',
    },
    description: {
      fontSize: '16px',
    },
    components: {
      flexDirection: 'column',
    },
    divButtons: {
      flexDirection: 'column',
    },
    extract: {
      order: 1,
      marginTop: '25px',
      marginBottom: 0,
      marginRight: 'auto',
      marginLeft: 'auto',
    },
  },
}));

function getPaymentModel(plan) {
  let paymentModel = 'PIX';
  if (plan?.hasInstallments) {
    return (paymentModel = 'PIX Valor cheio');
  }
  switch (plan?.recurrencePeriod) {
    case 'ANNUAL':
      return (paymentModel += ' 12 vezes');
    case 'BIANNUAL':
      return (paymentModel += ' 6 vezes');
    case 'QUARTERLY':
      return (paymentModel += ' 3 vezes');
    case 'MONTH':
      return (paymentModel += ' Plano mensal');
    default:
      break;
  }
  return paymentModel;
}

function copyPixCode(code) {
  navigator.clipboard.writeText(`${code}`);
  toast.success('Código de PIX copiado!');
}

const PixContainer = ({ pix, plan, showExtract, index }: PixData) => {
  const classes = useStyles();
  const date = pix?.expireAt && moment(pix?.expireAt).format('DD/MM/YYYY');
  return (
    <div className={classes.main}>
      <p className={classes.title}>Minha conta atual</p>
      <p className={classes.description}>
        Lembre-se que seu pagamento é via pix, para receber sua box não se esqueça de pagar este
        mês, okay?{' '}
      </p>
      <div className={classes.components}>
        <TitleAndDescription
          props={{
            title: 'VENCIMENTO',
            description: `${date}`,
            fontWeight: '500',
            fontSize: '32px',
          }}
        />
        <TitleAndDescription
          props={{
            title: 'TOTAL A PAGAR',
            description: `R$ ${pix?.amount?.toFixed(2)?.replace('.', ',')}`,
            fontWeight: '600',
            fontSize: '32px',
          }}
        />
        <TitleAndDescription
          props={{
            title: 'MODELO DE PAGAMENTO',
            description: getPaymentModel(plan),
            fontWeight: '500',
            fontSize: '20px',
          }}
        />
        <TitleAndDescription
          props={{
            title: 'PARCELA',
            description: `${pix?.recurrence ? pix?.recurrence : index}A`,
            fontWeight: '500',
            fontSize: '20px',
          }}
        />
      </div>
      <div className={classes.divButtons}>
        {showExtract && (
          <Link to={'/pix-extrato'} className={classes.extract}>
            <span>Ver meu extrato</span>
          </Link>
        )}
        {plan?.hasInstallments === false && (
          <div className={classes.button} onClick={() => copyPixCode(pix?.pixCode)}>
            <span>EFETUAR PAGAMENTO</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default PixContainer;
