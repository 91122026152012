import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#FFE6D5',
    borderRadius: '0.5rem',
    padding: '1rem',
    width: '95%',
    height: '100%',
    maxWidth: 400,
  },
  title: {
    color: '#A01235',
    fontWeight: 500,
    fontStyle: 'italic',
    fontSize: '1.5rem',
    textAlign: 'center',
    marginBottom: '0.5rem',
    width: '90%',
  },
  paragraph: {
    color: '#707070',
    marginBottom: '0.8rem',
    textAlign: 'center',
    fontSize: '1rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  submitButton: {
    background: '#A01235',
    borderRadius: '0.5rem',
    padding: '0.5rem 1rem',
    color: '#fff',
    fontSize: '1rem',
    fontStyle: 'italic',
    fontWeight: 500,
    marginTop: '1rem',
    border: 0,
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  terms: {
    fontStyle: 'italic',
    fontweight: 600,
    cursor: 'pointer',
    color: '#707070',
  },
  close: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    zIndex: 100,
    cursor: 'pointer',
  },
  logo: {},
}));

export default useStyles;
