// @flow
import React from 'react';
import { Typography, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { white, redOrange, silver } from '../../themes/colors';
import { boxStatus } from '../utils/boxUtils';
import moment from 'moment';
import 'moment/locale/pt-br';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '1rem 0 2rem',
    display: (props) => (props.horizontal ? 'flex' : 'block'),
  },
  title: {
    color: silver,
    padding: (props) => (props.horizontal ? theme.spacing(0.5, 0) : theme.spacing(0.5, 2)),
  },
  titleHighlight: {
    color: redOrange,
  },
  boxStep: {
    display: 'flex',
    flexDirection: (props) => (props.horizontal ? 'row' : 'column'),
    alignItems: 'center',
  },
  boxDivider: {
    flex: 1,
    '& hr': {
      flexDirection: (props) => (props.horizontal ? 'row' : 'column'),
      width: (props) => (props.horizontal ? 'auto' : theme.spacing(0.3)),
      margin: theme.spacing(0.5, 0),
      height: (props) => (props.horizontal ? theme.spacing(0.4) : theme.spacing(2)),
      marginLeft: (props) => (props.horizontal ? '1rem' : 0),
    },
  },
  boxIcon: {
    height: '2rem',
    width: '2rem',
    display: 'flex',
    justifyContent: 'center',
    background: silver,
    padding: theme.spacing(0.5),
    borderRadius: '50%',
    '& svg': {
      width: '1.2rem',
      fill: white,
    },
  },
  active: {
    background: redOrange,
  },
  dateCreated: {
    display: 'block',
    marginTop: '0.3rem',
  },
  boxContent: {
    display: 'flex',
    flexDirection: (props) => (props.horizontal ? 'column' : 'row'),
    width: (props) => (props.horizontal ? '7rem' : 'inherit'),
    marginRight: (props) => (props.horizontal ? '1rem' : '0rem'),
  },
}));

const Step = ({ orientation, dateCreated, box, active, classes }) => {
  if (!box) {
    box = { icon: '', title: '', last: '' };
  }
  const { icon, title, last } = box;

  const date = dateCreated && moment(dateCreated).format('DD/MM/YYYY');

  return (
    <div className={classes.boxContent}>
      <div className={classes.boxStep}>
        <div className={classNames(classes.boxIcon, { [classes.active]: active })}>{icon}</div>
        <div className={classes.boxDivider}>
          {!last ? (
            <Divider
              className={classNames({ [classes.active]: active })}
              orientation={orientation}
              variant="middle"
            />
          ) : null}
        </div>
      </div>
      <Typography
        className={classNames(classes.title, { [classes.titleHighlight]: active })}
        align="left"
        gutterBottom
      >
        {title}

        <span className={classes.dateCreated}>{date}</span>
      </Typography>
    </div>
  );
};

const BoxTrackingDetail = ({ orientation = 'vertical', timeline = [] }) => {
  const props = { horizontal: orientation === 'horizontal' };
  const classes = useStyles(props);

  const getBox = (status) => boxStatus.find((i) => i.status === status);

  const isActive = (createdAt) => !!createdAt;

  const canceledBox = timeline.find((time) => time.status === 'CANCELED');

  return (
    <div className={classes.root}>
      {canceledBox ? (
        <Step
          orientation={orientation}
          box={getBox(canceledBox.status)}
          active={isActive(canceledBox.createdAt)}
          classes={classes}
        />
      ) : (
        timeline.map((i, index) => (
          <Step
            dateCreated={i.createdAt}
            key={index}
            orientation={orientation}
            box={getBox(i.status)}
            active={isActive(i.createdAt)}
            classes={classes}
          />
        ))
      )}
    </div>
  );
};

export default BoxTrackingDetail;
