// @flow

export const FETCH_SUBSCRIPTION_CANCEL_REASON = '[SUBSCRIPTION] Fetch subscription cancel reason';
export const FETCH_SUBSCRIPTION_CANCEL_REASON_SUCCESS =
  '[SUBSCRIPTION] Fetch subscription cancel reason success';
export const FETCH_SUBSCRIPTION_CANCEL_REASON_ERROR =
  '[SUBSCRIPTION] Fetch subscription cancel reason error';

export const FETCH_SUBSCRIPTION_BILLING_DATE = '[SUBSCRIPTION] Fetch subscription billing date';
export const FETCH_SUBSCRIPTION_BILLING_DATE_SUCCESS =
  '[SUBSCRIPTION] Fetch subscription billing date success';
export const FETCH_SUBSCRIPTION_BILLING_DATE_ERROR =
  '[SUBSCRIPTION] Fetch subscription billing date error';

export const FETCH_SUBSCRIPTION_DETAILS = '[SUBSCRIPTION] Fetch subscription details';
export const FETCH_SUBSCRIPTION_DETAILS_SUCCESS =
  '[SUBSCRIPTION] Fetch subscription details success';
export const FETCH_SUBSCRIPTION_DETAILS_ERROR = '[SUBSCRIPTION] Fetch subscription details error';

export const CANCEL_SUBSCRIPTION = '[SUBSCRIPTION] Cancel subscription';
export const CANCEL_SUBSCRIPTION_SUCCESS = '[SUBSCRIPTION] Cancel subscription success';
export const CANCEL_SUBSCRIPTION_ERROR = '[SUBSCRIPTION] Cancel subscription error';

export const UPDATE_SUBSCRIPTION_BILLING_DATE = '[SUBSCRIPTION] Update subscription billing date';
export const UPDATE_SUBSCRIPTION_BILLING_DATE_SUCCESS =
  '[SUBSCRIPTION] Update subscription billing date success';
export const UPDATE_SUBSCRIPTION_BILLING_DATE_ERROR =
  '[SUBSCRIPTION] Update subscription billing date error';

export const FETCH_PAYMENT_METHODS = '[SUBSCRIPTION] Fetch payment methods';
export const FETCH_PAYMENT_METHODS_SUCCESS = '[SUBSCRIPTION] Fetch payment methods success';
export const FETCH_PAYMENT_METHODS_ERROR = '[SUBSCRIPTION] Fetch payment methods error';

export const FETCH_PAYMENT_PROFILES = '[SUBSCRIPTION] Fetch payment profiles';
export const FETCH_PAYMENT_PROFILES_SUCCESS = '[SUBSCRIPTION] Fetch payment profiles success';
export const FETCH_PAYMENT_PROFILES_ERROR = '[SUBSCRIPTION] Fetch payment profiles error';

export const DELETE_PAYMENT_PROFILE = '[SUBSCRIPTION] Delete payment profile';
export const DELETE_PAYMENT_PROFILE_SUCCESS = '[SUBSCRIPTION] Delete payment profile success';
export const DELETE_PAYMENT_PROFILE_ERROR = '[SUBSCRIPTION] Delete payment profile error';

export const UPDATE_PAYMENT_PROFILE = '[SUBSCRIPTION] Update payment profile';
export const UPDATE_PAYMENT_PROFILE_SUCCESS = '[SUBSCRIPTION] Update payment profile success';
export const UPDATE_PAYMENT_PROFILE_ERROR = '[SUBSCRIPTION] Update payment profile error';

export const CREATE_PAYMENT_PROFILE = '[SUBSCRIPTION] Create payment profile';
export const CREATE_PAYMENT_PROFILE_SUCCESS = '[SUBSCRIPTION] Create payment profile success';
export const CREATE_PAYMENT_PROFILE_ERROR = '[SUBSCRIPTION] Create payment profile error';

export const FETCH_PAYMENT_PROFILE = '[SUBSCRIPTION] Fetch payment profile';
export const FETCH_PAYMENT_PROFILE_SUCCESS = '[SUBSCRIPTION] Fetch payment profile success';
export const FETCH_PAYMENT_PROFILE_ERROR = '[SUBSCRIPTION] Fetch payment profile error';

export const FETCH_PAYMENT_HISTORY = '[SUBSCRIPTION] Fetch payment history';
export const FETCH_PAYMENT_HISTORY_SUCCESS = '[SUBSCRIPTION] Fetch payment history success';
export const FETCH_PAYMENT_HISTORY_ERROR = '[SUBSCRIPTION] Fetch payment history error';

export const FETCH_UPGRADE_PLAN_OPTION = '[SUBSCRIPTION] Fetch upgrade plans';
export const FETCH_UPGRADE_PLAN_OPTION_SUCCESS = '[SUBSCRIPTION] Fetch upgrade plans success';
export const FETCH_UPGRADE_PLAN_OPTION_ERROR = '[SUBSCRIPTION] Fetch upgrade plans error';

export const UPGRADE_SUBSCRIPTION_PLAN = '[SUBSCRIPTION] Upgrade subscription plan';
export const UPGRADE_SUBSCRIPTION_PLAN_SUCCESS = '[SUBSCRIPTION] Upgrade subscription plan success';
export const UPGRADE_SUBSCRIPTION_PLAN_ERROR = '[SUBSCRIPTION] Upgrade subscription plan error';

type FetchSubscriptionCancelReason = {
  type: typeof FETCH_SUBSCRIPTION_CANCEL_REASON,
};
type FetchSubscriptionCancelReasonSuccess = {
  type: typeof FETCH_SUBSCRIPTION_CANCEL_REASON_SUCCESS,
  payload: any,
};

type FetchSubscriptionCancelReasonError = {
  type: typeof FETCH_SUBSCRIPTION_CANCEL_REASON_ERROR,
  payload: any,
};

type FetchSubscriptionBillingDate = {
  type: typeof FETCH_SUBSCRIPTION_BILLING_DATE,
};
type FetchSubscriptionBillingDateSuccess = {
  type: typeof FETCH_SUBSCRIPTION_BILLING_DATE_SUCCESS,
  payload: any,
};

type FetchSubscriptionBillingDateError = {
  type: typeof FETCH_SUBSCRIPTION_BILLING_DATE_ERROR,
  payload: any,
};

type FetchSubscriptionDetails = {
  type: typeof FETCH_SUBSCRIPTION_DETAILS,
};

type FetchSubscriptionDetailsSuccess = {
  type: typeof FETCH_SUBSCRIPTION_DETAILS_SUCCESS,
  payload: any,
};

type FetchSubscriptionDetailsError = {
  type: typeof FETCH_SUBSCRIPTION_DETAILS_ERROR,
  payload: any,
};

type CancelSubscription = {
  type: typeof CANCEL_SUBSCRIPTION,
};
type CancelSubscriptionSuccess = {
  type: typeof CANCEL_SUBSCRIPTION_SUCCESS,
  payload: any,
};

type CancelSubscriptionError = {
  type: typeof CANCEL_SUBSCRIPTION_ERROR,
  payload: any,
};

type UpdateSubscriptionBillingDate = {
  type: typeof UPDATE_SUBSCRIPTION_BILLING_DATE,
  dayOfMonth: number,
};
type UpdateSubscriptionBillingDateSuccess = {
  type: typeof UPDATE_SUBSCRIPTION_BILLING_DATE_SUCCESS,
  payload: any,
};

type UpdateSubscriptionBillingDateError = {
  type: typeof UPDATE_SUBSCRIPTION_BILLING_DATE_ERROR,
  payload: any,
};

type FetchPaymentMethods = {
  type: typeof FETCH_PAYMENT_METHODS,
};
type FetchPaymentMethodsSuccess = {
  type: typeof FETCH_PAYMENT_METHODS_SUCCESS,
  payload: any,
};

type FetchPaymentMethodsError = {
  type: typeof FETCH_PAYMENT_METHODS_ERROR,
  payload: any,
};

type FetchPaymentProfiles = {
  type: typeof FETCH_PAYMENT_PROFILES,
};
type FetchPaymentProfilesSuccess = {
  type: typeof FETCH_PAYMENT_PROFILES_SUCCESS,
  payload: any,
};

type FetchPaymentProfilesError = {
  type: typeof FETCH_PAYMENT_PROFILES_ERROR,
  payload: any,
};

type DeletePaymentProfile = {
  type: typeof DELETE_PAYMENT_PROFILE,
  id: number,
};
type DeletePaymentProfileSuccess = {
  type: typeof DELETE_PAYMENT_PROFILE_SUCCESS,
  payload: any,
};

type DeletePaymentProfileError = {
  type: typeof DELETE_PAYMENT_PROFILE_ERROR,
  payload: any,
};

type UpdatePaymentProfile = {
  type: typeof UPDATE_PAYMENT_PROFILE,
  profile: Object,
};
type UpdatePaymentProfileSuccess = {
  type: typeof UPDATE_PAYMENT_PROFILE_SUCCESS,
  payload: any,
};

type UpdatePaymentProfileError = {
  type: typeof UPDATE_PAYMENT_PROFILE_ERROR,
  payload: any,
};

type CreatePaymentProfile = {
  type: typeof CREATE_PAYMENT_PROFILE,
  profile: Object,
};
type CreatePaymentProfileSuccess = {
  type: typeof CREATE_PAYMENT_PROFILE_SUCCESS,
  payload: any,
};

type CreatePaymentProfileError = {
  type: typeof CREATE_PAYMENT_PROFILE_ERROR,
  payload: any,
};

type FetchPaymentHistory = {
  type: typeof FETCH_PAYMENT_HISTORY,
};
type FetchPaymentHistorySuccess = {
  type: typeof FETCH_PAYMENT_HISTORY_SUCCESS,
  payload: any,
};

type FetchPaymentHistoryError = {
  type: typeof FETCH_PAYMENT_HISTORY_ERROR,
  payload: any,
};

type FetchUpgradePlanOption = {
  type: typeof FETCH_UPGRADE_PLAN_OPTION,
};
type FetchUpgradePlanOptionSuccess = {
  type: typeof FETCH_UPGRADE_PLAN_OPTION_SUCCESS,
  payload: any,
};

type FetchUpgradePlanOptionError = {
  type: typeof FETCH_UPGRADE_PLAN_OPTION_ERROR,
  payload: any,
};

type UpgradeSubscriptionPlan = {
  type: typeof UPGRADE_SUBSCRIPTION_PLAN,
  upgradePayload: Object,
  profileType: string,
};
type UpgradeSubscriptionPlanSuccess = {
  type: typeof UPGRADE_SUBSCRIPTION_PLAN_SUCCESS,
  payload: any,
};

type UpgradeSubscriptionPlanError = {
  type: typeof UPGRADE_SUBSCRIPTION_PLAN_ERROR,
  payload: any,
};

export type SubscriptionAction =
  | FetchSubscriptionCancelReason
  | FetchSubscriptionCancelReasonSuccess
  | FetchSubscriptionCancelReasonError
  | FetchSubscriptionBillingDate
  | FetchSubscriptionBillingDateSuccess
  | FetchSubscriptionBillingDateError
  | FetchSubscriptionDetails
  | FetchSubscriptionDetailsSuccess
  | FetchSubscriptionDetailsError
  | CancelSubscription
  | CancelSubscriptionSuccess
  | CancelSubscriptionError
  | UpdateSubscriptionBillingDate
  | UpdateSubscriptionBillingDateSuccess
  | UpdateSubscriptionBillingDateError
  | FetchPaymentMethods
  | FetchPaymentMethodsSuccess
  | FetchPaymentMethodsError
  | FetchPaymentProfiles
  | FetchPaymentProfilesSuccess
  | FetchPaymentProfilesError
  | DeletePaymentProfile
  | DeletePaymentProfileSuccess
  | DeletePaymentProfileError
  | UpdatePaymentProfile
  | UpdatePaymentProfileSuccess
  | UpdatePaymentProfileError
  | CreatePaymentProfile
  | CreatePaymentProfileSuccess
  | CreatePaymentProfileError
  | FetchPaymentHistory
  | FetchPaymentHistorySuccess
  | FetchPaymentHistoryError
  | FetchUpgradePlanOption
  | FetchUpgradePlanOptionSuccess
  | FetchUpgradePlanOptionError
  | UpgradeSubscriptionPlan
  | UpgradeSubscriptionPlanSuccess
  | UpgradeSubscriptionPlanError;
