import React, { useState, useEffect } from 'react';
import { Typography, Button, Grid, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { push } from 'react-router-redux';
import { Field, reduxForm, getFormValues } from 'redux-form';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { RenderedTextField, RenderedSelect } from '../../../../components/wrapped';
import { createPaymentProfile, updatePaymentProfile } from '../../../../store/actions';
import { black, tundora, pippin, linkWater, stTropaz, alto } from '../../../../themes/colors';
import { brandList, getCardIconByCode } from '../../../../components/utils/cardUtils';
import {
  cpfMask,
  validateCPF,
  cardMask,
  trim,
  cardCvvMask,
  cardExpirationMask,
  required,
  toUpper,
  cardMaxLength,
  maxLength4,
  onlyChar,
  normalizeAll,
} from '../../../../components/utils/validators';
import Loading from '../../../../components/Loading';
import { scrollToFirstError } from '../../../../components/utils/errorScrolling';
import { BackButton, BackButtonMobile } from '../../../../components/material';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    boxShadow: `1px 3px 6px ${alto}`,
  },
  contentBox: {
    background: pippin,
    padding: '1.5rem 2rem',
  },
  titleBox: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '2rem',
  },
  title: {
    color: black,
    fontSize: '2em',
    padding: '0 2.5rem',
    [theme.breakpoints.up('lg')]: {
      padding: '0 1rem',
    },
  },
  subtitle: {
    color: tundora,
    fontSize: '1.2em',
  },
  mainBox: {
    minHeight: '100vh',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'row-reverse',
      justifyContent: 'flex-end',
    },
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    background: linkWater,
    borderRadius: theme.spacing(2.25),
    color: tundora,
    width: '100%',
    maxWidth: '18rem',
    height: '12rem',
    margin: '2rem',
    padding: '1rem',
  },
  cardheader: {
    display: 'flex',
    justifyContent: 'space-between',
    '& svg': {
      width: '3em',
    },
    '& img': {
      width: '3em',
    },
  },
  cardNumber: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& span': {
      fontSize: '2em',
      color: stTropaz,
    },
  },
  cardInfo: {
    marginRight: theme.spacing(2),
    '& h6': {
      fontSize: '0.5em',
    },
  },
  formBox: {
    padding: '2rem',
    maxWidth: '20rem',
  },
  buttonBox: {
    display: 'flex',
    flexDirection: 'column',
    margin: '2rem 0',
    '& button:first-child': {
      marginBottom: '1rem',
    },
    [theme.breakpoints.up('md')]: {
      margin: '1rem 0',
      flexDirection: 'row',
      '& button': {
        marginRight: theme.spacing(1),
        '&:first-child': {
          marginBottom: '0',
        },
      },
    },
  },
}));

const CardForm = ({
  handleSubmit,
  onCreatePaymentProfile,
  onUpdatePaymentProfile,
  navigateTo,
  submitting,
  formValues,
  loading,
  match,
}) => {
  const classes = useStyles();
  const showArrow = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  const [platform, setPlatform] = useState(null);

  const onSubmit = (profile) => onUpdatePaymentProfile(profile);
  const getBull = () => <span>&bull;&bull;&bull;&bull;</span>;
  const getLastFour = (value) => {
    return value?.length > 14 ? value.substr(14) : '0000';
  };

  const id = match?.params?.id;

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setPlatform(params?.get('platform'));
  }, []);

  return (
    <Loading isLoading={loading}>
      <div className={classes.root}>
        <BackButtonMobile
          onClick={() => navigateTo('/perfil/seus-pagamentos')}
          visible={!showArrow}
        />
        <div className={classes.contentBox}>
          <div className={classes.titleBox}>
            <BackButton onClick={() => navigateTo('/perfil/seus-pagamentos')} visible={showArrow} />

            <Typography className={classes.title}>
              {id ? 'Editar cartão de crédito' : 'Novo cartão de crédito'}
            </Typography>
          </div>
          <Typography className={classes.subtitle} gutterBottom>
            Informe os dados do cartão que utilizará para os próximos pagamentos
          </Typography>
        </div>

        <div className={classes.mainBox}>
          <div className={classes.card}>
            <div className={classes.cardheader}>
              <img
                src="https://d2ivdm85owtv7v.cloudfront.net/images/customer-area/chip.png"
                alt=""
              />
              {getCardIconByCode(formValues?.payment_company_id)}
            </div>

            <div className={classes.cardNumber}>
              {getBull()}
              {getBull()}
              {getBull()}
              {getLastFour(formValues?.card_number)}
            </div>

            <div style={{ display: 'flex' }}>
              <div className={classes.cardInfo}>
                <Typography variant="subtitle1">VALIDADE</Typography>
                <Typography>{cardExpirationMask(formValues?.card_expiration || '0000')}</Typography>
              </div>
              <div className={classes.cardInfo}>
                <Typography variant="subtitle1">CVV</Typography>
                <Typography>{formValues?.card_cvv || '000'}</Typography>
              </div>
            </div>
          </div>

          <div className={classes.formBox}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    name="payment_company_id"
                    component={RenderedSelect}
                    label="Bandeira"
                    placeholder="Selecionar bandeira"
                    validate={[required]}
                  >
                    {/*TODO: In future, the correctly is to create another utils "brandListStripe" and put all the cards that stripe have.*/}
                    {brandList.map((i) =>
                      i?.name?.toUpperCase() === 'ELO' && platform === 'STRIPE' ? (
                        ''
                      ) : (
                        <MenuItem key={i.id} value={i.id}>
                          {i.name}
                        </MenuItem>
                      )
                    )}
                  </Field>
                </Grid>

                <Grid item xs={12}>
                  <Field
                    name="holder_name"
                    type="text"
                    component={RenderedTextField}
                    label="Nome do titular do cartão"
                    placeholder="Informe o nome do titular"
                    validate={[required]}
                    normalize={normalizeAll([onlyChar, toUpper])}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    name="card_number"
                    type="text"
                    component={RenderedTextField}
                    label="Número do cartão"
                    placeholder="Informe o número do titular"
                    validate={[required, cardMaxLength]}
                    normalize={normalizeAll([cardMask, trim])}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    name="card_expiration"
                    type="text"
                    component={RenderedTextField}
                    label="Validade do cartão"
                    placeholder="MM/AA"
                    validate={[required]}
                    normalize={cardExpirationMask}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    name="card_cvv"
                    type="text"
                    component={RenderedTextField}
                    label="Código de segurança (CVV)"
                    placeholder="Informe o código de 3 ou 4 dígitos"
                    validate={[required, maxLength4]}
                    normalize={cardCvvMask}
                  />
                </Grid>

                {/* //TODO: api nao tem cpf */}
                <Grid item xs={12}>
                  <Field
                    name="complement"
                    type="text"
                    component={RenderedTextField}
                    label="CPF do titular"
                    placeholder="Informe o CPF do titular"
                    normalize={cpfMask}
                    validate={[required, validateCPF]}
                  />
                </Grid>
              </Grid>
              <div className={classes.buttonBox}>
                <Button variant="contained" color="secondary" type="submit" disabled={submitting}>
                  Salvar
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => navigateTo('/perfil/seus-pagamentos')}
                >
                  Voltar
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Loading>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { loading } = state.subscription;
  return { formValues: getFormValues('cardForm')(state), loading, match: ownProps?.match };
};

const mapDispatchToProps = (dispatch) => ({
  onCreatePaymentProfile: (profile) => dispatch(createPaymentProfile(profile)),
  onUpdatePaymentProfile: (profile) => dispatch(updatePaymentProfile(profile)),
  navigateTo: (path) => dispatch(push(path)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'cardForm',
    onSubmitFail: (errors) => scrollToFirstError(errors),
  })
)(CardForm);
