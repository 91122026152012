import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
    [theme.breakpoints.up('lg')]: {},
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  content: {
    display: 'flex',
  },
  cards: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  cardsMobile: {
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  logoLoading: {
    width: '25vw',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginBottom: '2rem',
    padding: '1rem',
    boxShadow: '1px 3px 6px rgba(0, 0, 0, 0.25)',
    [theme.breakpoints.up('sm')]: {
      marginLeft: '2rem',
    },
    [theme.breakpoints.up('md')]: {
      minWidth: 600,
    },
  },
  cardHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
    },
  },
  avatar: {
    borderRadius: '50%',
    margin: '1rem 0',
    width: 250,
    height: 250,
    minWidth: 250,
  },
  avatarContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  timeline: {
    margin: 'auto',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    [theme.breakpoints.up('md')]: {
      width: '70%',
      marginTop: '0.5rem',
    },
  },
  image: {
    width: 50,
    [theme.breakpoints.up('md')]: {
      marginRight: '0.5rem',
    },
  },
  title: {
    background: '#FFE6D5',
    borderRadius: '0.5rem',
    padding: '0.5rem',
    textAlign: 'center',
    fontWeight: 600,
    color: '#A01235',
    width: '60%',
    marginTop: '0.5rem',
    maxWidth: 150,
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
      marginRight: '0.5rem',
    },
  },
  description: {
    color: '#ccc',
    textAlign: 'center',
    marginTop: '0.5rem',
    '& p': {
      color: '#A01235',
      cursor: 'pointer',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1rem',
      marginTop: '0.5rem',
      maxWidth: '40vw',
    },
  },
  progressContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
    },
  },
  progressView: {
    width: 200,
    maxWidth: 200,
    marginTop: '1.4rem',
  },
  empty: ({ value }) => ({
    background: 'black',
    height: 1,
    width: `${100 - value}%`,
  }),
  currentValue: ({ value }) => ({
    background: 'white',
    border: '6px solid #f7c143',
    height: '1.5rem',
    borderRadius: '50%',
    marginLeft: '-5px',
    width: '1.5rem',
    color: '#ccc',
  }),
  currentNumber: {
    color: '#ccc',
    fontSize: '1rem',
  },
  progress: ({ value }) => ({
    background: '#303030',
    height: 1,
    borderRadius: '0.5rem',
    width: `${value}%`,
  }),
  progressNumbers: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '90%',
    marginTop: '0.5rem',
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
    },
  },
  number: ({ value }) => ({
    color: '#4E4E4E',
    fontSize: '0.8rem',
    fontWeight: 600,
    marginLeft: `${value - 3}%`,
  }),
  nextPage: {
    width: '100%',
    background: 'transparent',
    color: '#A01235',
    fontSie: '1.2rem',
    marginTop: '1rem',
    fontWeight: 600,
    textAlign: 'center',
    cursor: 'pointer',
    outline: 'none',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: '50%',
    marginTop: '1rem',
    [theme.breakpoints.up('lg')]: {
      width: '10%',
      justifyContent: 'space-between',
    },
    '& svg:first-child': {},
    '& svg': {
      cursor: 'pointer',
      marginBottom: '1.5rem',
      width: 30,
    },
  },
  text: {
    color: '#bababa',
    fontSize: '0.7rem',
    textAlign: 'center',
    marginLeft: '0.2rem',
  },
}));

export default useStyles;
