// @flow
import * as fromTypes from '../types/wallet';

export const fetchWalletPoints = (): fromTypes.WalletAction => ({
  type: fromTypes.FETCH_WALLET_POINTS,
});

export const fetchWalletPointsSuccess = (payload: any): fromTypes.WalletAction => ({
  type: fromTypes.FETCH_WALLET_POINTS_SUCCESS,
  payload,
});

export const fetchWalletPointsError = (payload: any): fromTypes.WalletAction => ({
  type: fromTypes.FETCH_WALLET_POINTS_ERROR,
  payload,
});

export const fetchBankData = (): fromTypes.WalletAction => ({
  type: fromTypes.FETCH_BANK_DATA,
});

export const fetchBankDataSuccess = (payload: any): fromTypes.WalletAction => ({
  type: fromTypes.FETCH_BANK_DATA_SUCCESS,
  payload,
});

export const fetchBankDataError = (payload: any): fromTypes.WalletAction => ({
  type: fromTypes.FETCH_BANK_DATA_ERROR,
  payload,
});

export const fetchBankStatement = (payload: any): fromTypes.WalletAction => ({
  type: fromTypes.FETCH_BANK_STATEMENT,
  payload,
});

export const fetchBankStatementSuccess = (payload: any): fromTypes.WalletAction => ({
  type: fromTypes.FETCH_BANK_STATEMENT_SUCCESS,
  payload,
});

export const fetchBankStatementError = (payload: any): fromTypes.WalletAction => ({
  type: fromTypes.FETCH_BANK_STATEMENT_ERROR,
  payload,
});

export const updateBankData = (payload: any): fromTypes.WalletAction => ({
  type: fromTypes.UPDATE_BANK_DATA,
  payload,
});

export const updateBankDataSuccess = (payload: any): fromTypes.WalletAction => ({
  type: fromTypes.UPDATE_BANK_DATA_SUCCESS,
  payload,
});

export const updateBankDataError = (payload: any): fromTypes.WalletAction => ({
  type: fromTypes.UPDATE_BANK_DATA_ERROR,
  payload,
});

export const requestWithdrawal = (payload: any): fromTypes.WalletAction => ({
  type: fromTypes.REQUEST_WITHDRAWAL,
  payload,
});

export const requestWithdrawalSuccess = (): fromTypes.WalletAction => ({
  type: fromTypes.REQUEST_WITHDRAWAL_SUCCESS,
});

export const requestWithdrawalError = (payload: any): fromTypes.WalletAction => ({
  type: fromTypes.REQUEST_WITHDRAWAL_ERROR,
  payload,
});
