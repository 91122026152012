import { put, takeLatest, select } from 'redux-saga/effects';
import axios from 'axios';
import * as fromTypes from '../types';
import * as fromActions from '../actions';
import { getTenantToken } from '../services/auth';

const BASE_API = `${process.env.REACT_APP_BASE_API_ADDRESS}/customer-area`;
const VIDEO_API = `${process.env.REACT_APP_BASE_API_ADDRESS}/uauplay/courses`;

export function* fetchDashboard() {
  try {
    const TENANT = yield select(getTenantToken);
    const response = yield axios.get(`${BASE_API}/${TENANT}/dashboard`);

    if (response.status === 200) {
      yield put(fromActions.fetchDashboardSuccess(response.data));
    }
  } catch (error) {
    console.error(error);
    yield put(fromActions.fetchDashboardError(error));
  }
}

export function* fetchReferrals() {
  try {
    const TENANT = yield select(getTenantToken);
    const response = yield axios.get(`${BASE_API}/${TENANT}/referrals`);

    if (response.status === 200) {
      yield put(fromActions.fetchReferralsSuccess(response.data));
    }
  } catch (error) {
    console.error(error);
    yield put(fromActions.fetchReferralsError(error));
  }
}

export function* fetchReferralRank({ payload }) {
  try {
    const TENANT = yield select(getTenantToken);
    const response = yield axios.get(`${BASE_API}/${TENANT}/referral/rank?edition=${payload}`);

    if (response.status === 200) {
      yield put(fromActions.fetchReferralRankSuccess(response.data));
    }
  } catch (error) {
    console.error(error);
    yield put(fromActions.fetchReferralRankError());
  }
}

export function* fetchBlogFeed() {
  try {
    const query = `query {
      posts(first: 5, where: { orderby: { field: DATE, order: DESC } }) {
        edges {
          node {
            title
            excerpt
            date
            link
            featuredImage {
              node {
                sourceUrl
              }
            }
          }
        }
      }
    }`;
    const response = yield axios({
      url: 'https://blog.uaubox.com.br/graphql',
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=utf-8',
        Accept: 'application/json; charset=utf-8',
      },
      data: { query },
    });
    if (response.status === 200) {
      yield put(fromActions.fetchBlogFeedSuccess(response.data.data.posts.edges));
    }
  } catch (error) {
    console.error(error);
    yield put(fromActions.fetchBlogFeedError(error));
  }
}

export function* fetchBlogSearch({ searchTerm }) {
  try {
    const query = `query {
      posts(first: 11, where: {orderby: {field: DATE, order: DESC}, search: "${searchTerm}"}) {
        edges {
          node {
            title
            excerpt
            date
            link
            featuredImage {
              node {
                sourceUrl
              }
            }
          }
        }
      }
    }`;
    const response = yield axios({
      url: 'https://blog.uaubox.com.br/graphql',
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=utf-8',
        Accept: 'application/json; charset=utf-8',
      },
      data: { query },
    });
    if (response.status === 200) {
      yield put(fromActions.fetchBlogFeedSuccess(response.data.data.posts.edges));
    }
  } catch (error) {
    console.error(error);
    yield put(fromActions.fetchBlogFeedError(error));
  }
}

export function* fetchVideoFeed() {
  try {
    const response = yield axios.get(VIDEO_API, {
      params: {
        limit: '10',
      },
    });

    if (response.status === 200) {
      yield put(fromActions.fetchVideoFeedSuccess(response.data));
    }
  } catch (error) {
    yield put(fromActions.fetchVideoFeedError(error));
  }
}

export function* fetchPix() {
  try {
    const response = yield axios.post(`${BASE_API}/pix/getPix`);

    yield put(fromActions.fetchPixSuccess(response.data));
  } catch (error) {
    if (error?.status === 404) {
      yield put(fromActions.fetchPixSuccess(null));
    }
    yield put(fromActions.fetchPixError(error));
  }
}

export function* fetchVideoSearch({ searchTerm }) {
  try {
    const response = yield axios.get(VIDEO_API, {
      params: {
        limit: '10',
        search: searchTerm,
      },
    });

    if (response.status === 200) {
      yield put(fromActions.fetchVideoSearchSuccess(response.data));
    }
  } catch (error) {
    yield put(fromActions.fetchVideoSearchError(error));
  }
}

export function* fetchVideoDetail({ id }) {
  try {
    const response = yield axios.get(VIDEO_API, {
      params: {
        limit: '1',
        search: id,
      },
    });

    if (response.status === 200) {
      yield put(fromActions.fetchVideoDetailSuccess(response.data));
    }
  } catch (error) {
    yield put(fromActions.fetchVideoDetailError(error));
  }
}

export function* updateTermsOfUse() {
  try {
    const TENANT = yield select(getTenantToken);
    yield axios.post(`${BASE_API}/${TENANT}/updateTermsOfUse`);
  } catch (error) {
    console.error(error);
  }
}

export function* watchDashboard() {
  yield takeLatest(fromTypes.FETCH_DASHBOARD, fetchDashboard);
  yield takeLatest(fromTypes.FETCH_REFERRALS, fetchReferrals);
  yield takeLatest(fromTypes.FETCH_REFERRAL_RANK, fetchReferralRank);
  yield takeLatest(fromTypes.FETCH_BLOG_FEED, fetchBlogFeed);
  yield takeLatest(fromTypes.FETCH_BLOG_SEARCH, fetchBlogSearch);
  yield takeLatest(fromTypes.FETCH_VIDEO_FEED, fetchVideoFeed);
  yield takeLatest(fromTypes.FETCH_VIDEO_SEARCH, fetchVideoSearch);
  yield takeLatest(fromTypes.FETCH_VIDEO_DETAIL, fetchVideoDetail);
  yield takeLatest(fromTypes.UPDATE_TERMS_OF_USE, updateTermsOfUse);
  yield takeLatest(fromTypes.FETCH_PIX, fetchPix);
}
