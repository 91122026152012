// @flow
import React from 'react';
import { result } from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  spinner: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  fullSize: {
    position: 'fixed',
  },
}));

const sizes = {
  small: 20,
  medium: 40,
  big: 60,
};
type Props = {
  size: 'small' | 'medium' | 'large',
  fullSize: boolean,
};
const getSizeValue = (size) => result(sizes, size || 'medium');

const Spinner = ({ size, fullSize }: Props) => {
  const classes = useStyles();
  return (
    <div
      className={classNames(classes.root, { [classes.fullSize]: fullSize })}
      key="item-background"
    >
      <div className={classes.spinner}>
        <CircularProgress size={getSizeValue(size)} />
      </div>
    </div>
  );
};

export default Spinner;
