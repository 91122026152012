// @flow
import * as fromTypes from '../types/purchase';

export const fetchExtraProducts = (): fromTypes.PurchaseAction => ({
  type: fromTypes.FETCH_EXTRA_PRODUCTS,
});

export const fetchExtraProductsSuccess = (payload: any): fromTypes.PurchaseAction => ({
  type: fromTypes.FETCH_EXTRA_PRODUCTS_SUCCESS,
  payload,
});

export const fetchExtraProductsError = (payload: any): fromTypes.PurchaseAction => ({
  type: fromTypes.FETCH_EXTRA_PRODUCTS_ERROR,
  payload,
});

export const buyExtraProduct = (id: number, sku: string): fromTypes.PurchaseAction => ({
  type: fromTypes.BUY_EXTRA_PRODUCT,
  id,
  sku,
});

export const buyExtraProductSuccess = (payload: any): fromTypes.PurchaseAction => ({
  type: fromTypes.BUY_EXTRA_PRODUCT_SUCCESS,
  payload,
});

export const buyExtraProductError = (payload: any): fromTypes.PurchaseAction => ({
  type: fromTypes.BUY_EXTRA_PRODUCT_ERROR,
  payload,
});

export const cancelExtraProduct = (id: any): fromTypes.PurchaseAction => ({
  type: fromTypes.CANCEL_EXTRA_PRODUCT,
  id,
});

export const cancelExtraProductSuccess = (payload: any): fromTypes.PurchaseAction => ({
  type: fromTypes.CANCEL_EXTRA_PRODUCT_SUCCESS,
  payload,
});

export const cancelExtraProductError = (payload: any): fromTypes.PurchaseAction => ({
  type: fromTypes.CANCEL_EXTRA_PRODUCT_ERROR,
  payload,
});

export const selectRetentionOffer = (id: Number, offer: Object): fromTypes.PurchaseAction => ({
  type: fromTypes.SELECT_RETENTION_OFFER,
  id,
  offer,
});

export const selectRetentionOfferSuccess = (payload: any): fromTypes.PurchaseAction => ({
  type: fromTypes.SELECT_RETENTION_OFFER_SUCCESS,
  payload,
});

export const selectRetentionOfferError = (payload: any): fromTypes.PurchaseAction => ({
  type: fromTypes.SELECT_RETENTION_OFFER_ERROR,
  payload,
});

export const buySingleProduct = (id: number): fromTypes.PurchaseAction => ({
  type: fromTypes.BUY_SINGLE_PRODUCT,
  id,
});

export const buySingleProductSuccess = (payload: any): fromTypes.PurchaseAction => ({
  type: fromTypes.BUY_SINGLE_PRODUCT_SUCCESS,
  payload,
});

export const buySingleProductError = (payload: any): fromTypes.PurchaseAction => ({
  type: fromTypes.BUY_SINGLE_PRODUCT_ERROR,
  payload,
});

export const cancelSingleProduct = (invoiceId: number): fromTypes.PurchaseAction => ({
  type: fromTypes.CANCEL_SINGLE_PRODUCT,
  invoiceId,
});

export const cancelSingleProductSuccess = (payload: any): fromTypes.PurchaseAction => ({
  type: fromTypes.CANCEL_SINGLE_PRODUCT_SUCCESS,
  payload,
});

export const cancelSingleProductError = (payload: any): fromTypes.PurchaseAction => ({
  type: fromTypes.CANCEL_SINGLE_PRODUCT_ERROR,
  payload,
});
