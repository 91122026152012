import React from 'react';

const InProductionBoxIcon = ({
  fill = '#fff',
  width = '100%',
  className = '',
  height = '100%',
}) => (
  <svg
    className={className}
    viewBox="0 0 19.933 21.267"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g transform="translate(-4.012 -1023.362)">
      <g transform="translate(4.012 1023.362)">
        <g transform="translate(0 0)">
          <path
            d="M13.961,3l0,0h-.008a3.344,3.344,0,0,0-.379.155L4.532,7a.959.959,0,0,0-.52.674V19.607a1.4,1.4,0,0,0,.035.318l0,0s.073.112.077.119c.044.088.14.118.231.155l9.371,3.987a.753.753,0,0,0,.28.075.759.759,0,0,0,.2-.065l9.481-4.054a.824.824,0,0,0,.189-.156.293.293,0,0,0,.062-.166q0-.011,0-.022t0-.019,0-.021s0,0,0-.006V7.488a.409.409,0,0,0-.339-.407L14.148,3.037l-.026-.011,0,0-.037-.01-.013,0c-.009,0-.095-.011-.109-.011Zm.187,1.054,1.681.724-8.185,3.5c-.008,0-.016.007-1.689-.717Zm2.779,1.152,2.433,1.04L10.8,9.9a.549.549,0,0,0-.248.465v4.375l-.886-1.33a.409.409,0,0,0-.522-.139L8.1,13.789l-.13-4.745Zm3.438,1.505,1.728.848-8.132,3.453L12.126,10.2C20.631,6.558,20.357,6.716,20.365,6.712ZM5.138,8.227l2.145.94V14.45a.409.409,0,0,0,.593.366l1.311-.656L10.6,16.279a.409.409,0,0,0,.209.19l.037.013,0,0a.409.409,0,0,0,.08.014l.042,0,.04,0,.042-.008.024-.006.014,0a.409.409,0,0,0,.283-.442V10.917l2.2.939V22.819L5.138,19.386Zm17.749-.11V19.184l-8.245,3.493V11.856ZM18,17.517l0,0a.409.409,0,0,0-.147.035l-2.385,1.019a.409.409,0,0,0,.321.752l2.386-1.019A.409.409,0,0,0,18,17.517Zm2.38.613a.409.409,0,0,0-.145.035l-2.386,1.019-2.385,1.021a.409.409,0,0,0,.321.752l2.386-1.021,2.385-1.021a.409.409,0,0,0-.176-.786Z"
            transform="translate(-4.012 -3)"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default InProductionBoxIcon;
