import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import { push } from 'react-router-redux';
import { Typography, Grid, Button, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { tundora } from '../../themes/colors';
import { RenderedTextField, RenderedSelect } from '../../components/wrapped';
import { resetPassword } from '../../store/actions';
import { required, validateEmail } from '../../components/utils/validators';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    height: '100vh',
    '& h1': {
      fontSize: '2em',
      margin: '2rem 0 1rem',
    },
  },
  mainBox: {
    maxWidth: '25rem',
    margin: 'auto',
  },
  logoUau: {
    display: 'flex',
    justifyContent: 'center',
    margin: '3rem',
    height: '110px',
    position: 'relative',
    '& img': {
      position: 'absolute',
      visibility: 'hidden',
      opacity: '0',
      transition: 'all 700ms',
    },
    '& svg': {
      width: '6rem',
    },
  },
  imgLogin: {
    position: 'relative',
    display: 'flex',
    height: '768px',
    overflow: 'hidden',
    '& img': {
      position: 'absolute',
      visibility: 'hidden',
      opacity: '0',
      transition: 'all 700ms',
      maxHeight: '768px',
      left: 'calc(50% - 341px)',
    },
  },
  imgActive: {
    opacity: '1 !important',
    visibility: 'visible !important',
  },
  formBox: {
    padding: '0 1.5rem',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '1rem',
    '& button': {
      marginTop: theme.spacing(1),
      padding: '1rem',
      fontSize: '1.3em',
      fontWeight: 'bold',
    },
  },
  infoBox: {
    display: 'flex',
    justifyContent: 'center',
    '& div': {
      display: 'flex',
      flexDirection: 'column',
      width: 'fit-content',
    },
    '& button': {
      margin: theme.spacing(1, 0),
    },
    '& p': {
      margin: theme.spacing(1, 0),
      color: tundora,
    },
  },
  grid: {
    height: '100%',
    overflow: 'hidden',
  },
  subscribeBtn: {
    padding: '0.8rem 1rem',
    fontSize: '1.2em',
  },
}));

const RecoverPassword = ({ handleSubmit, submitting }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const selector = formValueSelector('recoverPasswordForm');
  const tenantSelected = selector(state, 'tenant');
  const highDevice = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const onSubmit = ({ tenant, email }) => {
    dispatch(resetPassword(tenant, email));
  };

  useEffect(() => {
    dispatch(change('recoverPasswordForm', 'tenant', 'uaubox'));
  }, [dispatch]);

  const tenantList = [
    { tenant: 'uaubox', name: 'UAUBox' },
    { tenant: 'scarlet', name: 'Scarlet by UAUBox' },
  ];

  return (
    <div className={classes.root}>
      <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
        <Grid container justify="center" alignItems="center" className={classes.grid}>
          <Grid item xs={12} md={6}>
            <div className={classes.mainBox}>
              <div className={classes.logoUau}>
                <img
                  className={tenantSelected === 'uaubox' ? classes.imgActive : ''}
                  src="https://d2ivdm85owtv7v.cloudfront.net/images/uaubox/logo_uau_coral.png"
                  alt="UAUBox Default"
                />
                <img
                  className={tenantSelected === 'scarlet' ? classes.imgActive : ''}
                  src="https://d2ivdm85owtv7v.cloudfront.net/images/scarlet/logo-scarlet.png"
                  alt="Scarlet by UAUBox"
                />
              </div>
              <Typography variant="h1" align="center">
                Esqueci a senha
              </Typography>

              <div className={classes.formBox}>
                <Field
                  name="tenant"
                  type="select"
                  component={RenderedSelect}
                  label="Tipo de assinatura"
                  validate={[required]}
                >
                  {tenantList.map((i) => (
                    <MenuItem key={i.tenant} value={i.tenant}>
                      {i.name}
                    </MenuItem>
                  ))}
                </Field>
                <Field
                  name="email"
                  type="text"
                  component={RenderedTextField}
                  placeholder="E-mail"
                  validate={[required, validateEmail]}
                />
                <Button
                  variant="contained"
                  className={classes.loginBtn}
                  color="secondary"
                  type="submit"
                  disabled={submitting}
                >
                  recuperar senha
                </Button>
              </div>

              <div className={classes.infoBox}>
                <div>
                  <Button variant="text" color="secondary" onClick={() => dispatch(push('/login'))}>
                    Voltar para o login
                  </Button>
                </div>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} md={6} style={{ position: 'relative' }}>
            {highDevice ? (
              <div className={classes.imgLogin}>
                <img
                  className={`${tenantSelected === 'uaubox' ? classes.imgActive : null}`}
                  src="https://d2ivdm85owtv7v.cloudfront.net/images/customer-area/sign_in_image.png"
                  alt="UAUBox Default"
                />
                <img
                  className={`${tenantSelected === 'scarlet' ? classes.imgActive : null}`}
                  src="https://d2ivdm85owtv7v.cloudfront.net/images/customer-area/sign_in_image_scarlet.jpg"
                  alt="Scarlet by UAUBox"
                />
              </div>
            ) : null}
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default reduxForm({
  form: 'recoverPasswordForm',
})(RecoverPassword);
