import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  avatar: {
    width: '60%',
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
  },
  timeline: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    [theme.breakpoints.up('md')]: {
      width: '90%',
      marginTop: '0.5rem',
    },
  },
  title: {
    color: '#4e4e4e',
    fontSize: '1rem',
    textAlign: 'center',
    fontWeight: 500,
    margin: '0.5rem 0',
  },
  options: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  group: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '50%',
    padding: '1.2rem',
    boxShadow: '3px 5px 5px #00000029',
  },
  image: {
    color: '#4e4e4e',
    width: '100%',
  },
  inputFile: {
    opacity: 0,
  },
  label: {
    color: '#4e4e4e',
    cursor: 'pointer',
    border: '3px solid #ccc',
    padding: '0.5rem 1rem',
    borderRadius: '1rem',
    fontSize: '0.8rem',
    fontWeight: 500,
    marginTop: '0.5rem',
  },
  footerText: {
    color: '#4e4e4e',
    textAlign: 'center',
    margin: '0.5rem 0',
    fontSize: '1rem',
    letterSpacing: 0.5,
    lineHeight: 1.5,
    maxWidth: 450,
  },
  popupSpan: {
    position: 'absolute',
    top: '30%',
  },
}));

export default useStyles;
