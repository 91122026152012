export const logTikTokPurchase = (realPrice, identifier) => {
  window.ttq.track('Purchase', {
    content_id: identifier,
    content_type: 'subscription',
    content_name: 'box',
    quantity: 1,
    price: realPrice,
    value: realPrice,
    currency: 'BRL',
  });
};
