import React from 'react';

const ReadyToPostBoxIcon = ({ fill = '#fff', width = '100%', className = '', height = '100%' }) => (
  <svg
    className={className}
    viewBox="0 0 24.377 21.719"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      id="path4511-0-4-1-2"
      data-name="path4511-0-4-1"
      d="M-38.344,1018.872h-.007l-.042.008a.423.423,0,0,0-.1.029l-9.657,4.13a.418.418,0,0,0-.349.416v12.531a.419.419,0,0,0,0,.051s0,.006,0,.008,0,.017,0,.026a.418.418,0,0,0,.253.315l9.745,4.168a.422.422,0,0,0,.055.019.419.419,0,0,0,.077.014h.09l.041-.008a.428.428,0,0,0,.083-.024l5.424-2.321a4.6,4.6,0,0,0,4.008,2.357A4.6,4.6,0,0,0-24.123,1036a4.6,4.6,0,0,0-4.019-4.553v-7.987a.418.418,0,0,0-.354-.418l-9.652-4.129a.42.42,0,0,0-.109-.031h-.086Zm.065,1.059,1.783.805-8.455,3.592-.024.012-1.783-.807,8.479-3.6Zm2.79,1.279,2.484,1.064-8.562,3.641a.417.417,0,0,0-.251.511v4.429l-.905-1.357a.418.418,0,0,0-.535-.142l-1.065.532v-4.918Zm3.434,1.529,1.931.776-8.315,3.571-1.93-.776c8.684-3.715,8.306-3.567,8.313-3.571Zm-15.333,1.7,2.229,1.072v5.056a.418.418,0,0,0,.2.355.418.418,0,0,0,.406.019l1.341-.67,1.444,2.165a.417.417,0,0,0,.416.224l.044-.008h.013l.028-.008a.416.416,0,0,0,.111-.055.418.418,0,0,0,.176-.4v-4.925l2.09.87v11l-8.5-3.729v-10.963Zm18.176-.108v7.009a4.4,4.4,0,0,0-3.956,3.548l-1.205.516-2.435,1.041a.418.418,0,0,0-.22.548.418.418,0,0,0,.548.22l2.435-1.042.744-.318c0,.048-.008.095-.008.143a3.134,3.134,0,0,0,.225,1.217l-4.662,1.984.023-11.061,8.512-3.8Zm.5,7.907a3.752,3.752,0,0,1,3.758,3.758,3.752,3.752,0,0,1-3.758,3.758,3.752,3.752,0,0,1-3.758-3.758A3.752,3.752,0,0,1-28.717,1032.235Zm-.418.452v2.336a1.065,1.065,0,0,0-.643.971,1.067,1.067,0,0,0,1.06,1.06,1.067,1.067,0,0,0,.972-.643h1.693v-.835h-1.693a1.078,1.078,0,0,0-.555-.553v-2.336Zm-5.089,1.011a.417.417,0,0,0-.15.036l-2.435,1.042a.418.418,0,0,0-.22.548.418.418,0,0,0,.548.22l2.435-1.042a.418.418,0,0,0,.251-.477.418.418,0,0,0-.429-.327Zm5.507,2.07a.219.219,0,0,1,.225.225.219.219,0,0,1-.225.225.219.219,0,0,1-.225-.225A.219.219,0,0,1-28.717,1035.769Z"
      transform="translate(48.5 -1018.872)"
      fill="#fff"
    />
  </svg>
);

export default ReadyToPostBoxIcon;
