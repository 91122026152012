import React from 'react';

const WarningIcon = ({ fill = '#fff', width = '100%', className = '', height = '100%' }) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <rect opacity="0" width="24" height="24" />
    <path d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,15a1,1,0,1,1,1-1A1,1,0,0,1,12,17Zm1-4a1,1,0,0,1-2,0V8a1,1,0,0,1,2,0Z" />
  </svg>
);

export default WarningIcon;
