import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import queryString from 'query-string';
import Loading from '../../components/Loading';
import { StartCheckoutTaboola } from '../../components/utils/Taboola';

import { signIn } from '../../store/actions';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const AuthRedirect = ({ navigateTo, onSignIn }) => {
  const classes = useStyles();
  const [signInRefresh, setSignInRefresh] = useState(false);

  function getUrl(coupon, plan, mediaTracking) {
    const trackingUtms = ['utm_source', 'utm_medium', 'utm_campaign'];
    let urlParams = [];
    trackingUtms.forEach((utm) => {
      if (mediaTracking[utm]) urlParams.push(`${utm}=${mediaTracking[utm]}`);
    });
    urlParams.push(`plan=${plan}`);
    if (coupon && coupon !== 'null') urlParams.push(`c=${coupon}`);

    return '?' + urlParams.join('&');
  }

  useEffect(() => {
    localStorage.removeItem('token');
    if (window?.location?.search?.includes('refreshToken')) {
      if (!signInRefresh) {
        setSignInRefresh(true);
        const {
          tenant,
          refreshToken,
          plan,
          c,
          utm_source,
          utm_medium,
          utm_campaign,
          gift,
          redirect,
        } = queryString.parse(window.location.search);

        const taboolaId = tenant === 'uaubox' ? '1327929' : '1395918';
        StartCheckoutTaboola(taboolaId);

        if (gift) {
          sessionStorage.setItem(
            'pathRedirect',
            `/checkout?gift=${gift}&utm_source=${utm_source}&utm_medium=${utm_medium}&utm_campaign=${utm_campaign}`
          );
        } else if (redirect?.toString()?.length > 0) {
          if (redirect === '/assinatura-confirmada') {
            sessionStorage.setItem('subscriptionCreated', true);
          }
          sessionStorage.setItem('pathRedirect', redirect);
        } else {
          !c && sessionStorage.setItem('planIdByRegister', plan);

          sessionStorage.setItem(
            'pathRedirect',
            `/checkout${getUrl(c, plan, { utm_source, utm_medium, utm_campaign })}`
          );
        }

        onSignIn(tenant, { refreshToken, grantType: 'refresh_token' });
      }
    } else {
      navigateTo('/');
    }
  }, [onSignIn, signInRefresh, navigateTo]);

  return (
    <Loading isLoading={true}>
      <div className={classes.root}></div>
    </Loading>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    navigateTo: (path) => dispatch(push(path)),
    onSignIn: (tenant, payload) => dispatch(signIn(tenant, payload)),
  };
};

export default compose(connect(null, mapDispatchToProps))(AuthRedirect);
