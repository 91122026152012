// @flow
import * as fromTypes from '../types/profile';

type State = {
  loading: boolean,
  loadingQuestions: boolean,
  loadedQuestions: boolean,
  customer: Object,
  shippingAddress: Object,
  subscription: Object,
  paymentProfile: Object,
  answers: Object,
  answer: Object,
  shippingAddresses: Array<Object>,
  addressInfo: Object,
  boxOrders: Object,
  boxOrder: Object,
  feedbackQuestions: Object,
  feedbackSubmitted: boolean,
  beautyProfileAnswer: Object,
  orderId: number,
};

export const initialState = {
  loading: false,
  loadingQuestions: false,
  loadedQuestions: false,
  customer: {},
  shippingAddress: {},
  addressInfo: null,
  subscription: {},
  paymentProfile: {},
  answers: {},
  answer: {},
  shippingAddresses: [],
  boxOrders: {},
  boxOrder: {},
  feedbackQuestions: {},
  feedbackSubmitted: false,
  beautyProfileAnswer: {},
  orderId: null,
};

const updateDetails = (state, { payload }) => ({
  ...state,
  ...payload,
  loading: false,
});

const updateAnswers = (state, { payload }) => ({
  ...state,
  answers: payload,
  loading: false,
});

const updateAnswer = (state, { payload }) => ({
  ...state,
  answer: payload,
  loading: false,
});

const updateAddresses = (state, { payload }) => ({
  ...state,
  shippingAddresses: payload,
  loading: false,
});

const updateAddress = (state, { payload }) => {
  const shippingAddresses = state.shippingAddresses.map((i) =>
    i?.id === payload.id ? payload : { ...i, mainAddress: !payload?.mainAddress }
  );

  return {
    ...state,
    shippingAddress: payload,
    shippingAddresses,
    loading: false,
  };
};

const updateFetchAddress = (state, { payload }) => ({
  ...state,
  loading: false,
  addressInfo: payload,
});

const updateBoxOrders = (state, { payload }) => ({
  ...state,
  boxOrders: payload,
  loading: false,
});

const updateBoxOrder = (state, { payload }) => ({
  ...state,
  boxOrder: payload,
  loading: false,
});

const updateFeedbackQuestion = (state, { payload }) => ({
  ...state,
  feedbackQuestions: payload,
  loadedQuestions: true,
  loadingQuestions: false,
});

const setFeedbackSubmit = (state, { feedbackSubmitted }) => ({ ...state, feedbackSubmitted });

const updateFeedbackStatus = (state, { payload }) => {
  if (payload.page === 'box') {
    let boxOrders = {};
    if (state?.boxOrders?.previousEditions) {
      const previousEdition = state.boxOrders.previousEditions.map((boxOrder) => {
        if (boxOrder.orderId === payload.orderId) {
          boxOrder.feedback = payload;
        }
        return boxOrder;
      });

      let mostRecentEdition = state.boxOrders.mostRecentEdition;
      if (mostRecentEdition.orderId === payload.orderId) {
        mostRecentEdition.feedback = payload;
      }

      boxOrders = {
        mostRecentEdition: mostRecentEdition,
        previousEditions: previousEdition,
      };
    }

    return {
      ...state,
      loadingQuestions: false,
      boxOrders,
    };
  }
  return {
    ...state,
    loadingQuestions: false,
    boxOrder: {
      ...state.boxOrder,
      rated: true,
    },
  };
};

const onFetchProfileAnswer = (state) => ({
  ...state,
  loading: true,
  answer: {},
});

const setBeautyProfileAnswer = (state, { payload }) => ({
  ...state,
  beautyProfileAnswer: payload,
});

const setOrderId = (state, { payload }) => ({
  ...state,
  orderId: payload.orderId,
  loading: false,
});

const setLoading = (state, loading) => ({ ...state, loading });
const setLoadingQuestion = (state, loading) => ({
  ...state,
  loadingQuestions: loading,
  loadedQuestions: false,
});

const reducer = (state: State = initialState, action: fromTypes.ProfileAction) => {
  switch (action.type) {
    case fromTypes.FETCH_PROFILE_DETAILS:
    case fromTypes.FETCH_PROFILE_ANSWERS:
    case fromTypes.CREATE_SHIPPING_ADDRESS:
    case fromTypes.FETCH_SHIPPING_ADDRESSES:
    case fromTypes.FETCH_SHIPPING_ADDRESS:
    case fromTypes.UPDATE_SHIPPING_ADDRESS:
    case fromTypes.FETCH_BOX_ORDERS:
    case fromTypes.FETCH_BOX_ORDER:
    case fromTypes.UPDATE_PROFILE:
    case fromTypes.SET_DELIVERY_FEEDBACK:
    case fromTypes.FETCH_ORDER_ID:
      return setLoading(state, true);
    case fromTypes.FETCH_PROFILE_ANSWER:
      return onFetchProfileAnswer(state);
    case fromTypes.CREATE_FEEDBACK:
    case fromTypes.FETCH_FEEDBACK_QUESTIONS_BY_ORDER_ID:
      return setLoadingQuestion(state, true);
    case fromTypes.SET_FEEDBACK_SUBMIT:
      return setFeedbackSubmit(state, action);
    case fromTypes.SET_BEAUTY_PROFILE_ANSWER:
      return setBeautyProfileAnswer(state, action);
    // success
    case fromTypes.FETCH_ADDRESS_BY_ZIPCODE_SUCCESS:
      return updateFetchAddress(state, action);
    case fromTypes.FETCH_PROFILE_DETAILS_SUCCESS:
      return updateDetails(state, action);
    case fromTypes.FETCH_PROFILE_ANSWERS_SUCCESS:
      sessionStorage.setItem('idProfile', action.payload.id);
      return updateAnswers(state, action);
    case fromTypes.FETCH_PROFILE_ANSWER_SUCCESS:
      return updateAnswer(state, action);
    case fromTypes.FETCH_SHIPPING_ADDRESSES_SUCCESS:
      return updateAddresses(state, action);
    case fromTypes.CREATE_SHIPPING_ADDRESS_SUCCESS:
      return setLoading(state, false);
    case fromTypes.FETCH_SHIPPING_ADDRESS_SUCCESS:
      return updateAddress(state, action);
    case fromTypes.UPDATE_SHIPPING_ADDRESS_SUCCESS:
      return updateAddress(state, action);
    case fromTypes.FETCH_BOX_ORDERS_SUCCESS:
      return updateBoxOrders(state, action);
    case fromTypes.FETCH_BOX_ORDER_SUCCESS:
      return updateBoxOrder(state, action);
    case fromTypes.FETCH_FEEDBACK_QUESTIONS_BY_ORDER_ID_SUCCESS:
      return updateFeedbackQuestion(state, action);
    case fromTypes.UPDATE_PROFILE_SUCCESS:
    case fromTypes.SET_DELIVERY_FEEDBACK_SUCCESS:
      return setLoading(state, false);
    case fromTypes.CREATE_FEEDBACK_SUCCESS:
      return updateFeedbackStatus(state, action);
    case fromTypes.FETCH_ORDER_ID_SUCCESS:
      return setOrderId(state, action);
    // erros
    case fromTypes.FETCH_PROFILE_DETAILS_ERROR:
    case fromTypes.FETCH_PROFILE_ANSWERS_ERROR:
    case fromTypes.FETCH_PROFILE_ANSWER_ERROR:
    case fromTypes.CREATE_SHIPPING_ADDRESS_ERROR:
    case fromTypes.FETCH_SHIPPING_ADDRESSES_ERROR:
    case fromTypes.FETCH_SHIPPING_ADDRESS_ERROR:
    case fromTypes.UPDATE_SHIPPING_ADDRESS_ERROR:
    case fromTypes.FETCH_BOX_ORDERS_ERROR:
    case fromTypes.FETCH_BOX_ORDER_ERROR:
    case fromTypes.UPDATE_PROFILE_ERROR:
    case fromTypes.SET_DELIVERY_FEEDBACK_ERROR:
    case fromTypes.FETCH_ORDER_ID_ERROR:
    case fromTypes.FETCH_ADDRESS_BY_ZIPCODE_ERROR:
      return setLoading(state, false);
    case fromTypes.CREATE_FEEDBACK_ERROR:
    case fromTypes.FETCH_FEEDBACK_QUESTIONS_BY_ORDER_ID_ERROR:
      return setLoadingQuestion(state, false);
    default:
      return state;
  }
};

export default reducer;
