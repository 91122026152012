// @flow
import React from 'react';
import { FormHelperText, RadioGroup, FormControl } from '@material-ui/core';

type FieldProps = {
  input: Object,
  meta: Object,
  children: Object,
};

const RenderedRadioGroup = ({ input, meta: { touched, error }, children, ...rest }: FieldProps) => (
  <div>
    <FormControl>
      <RadioGroup {...input} {...rest}>
        {children}
      </RadioGroup>
    </FormControl>
    {touched && !!error ? (
      <FormHelperText error={touched && !!error}>{error}</FormHelperText>
    ) : null}
  </div>
);

export default RenderedRadioGroup;
