import React from 'react';

const CanceledBox = ({ fill = '#fff', width = '100%', className = '', height = '100%' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 512 512"
  >
    <path d="M407,273.463V135.55a7.03,7.03,0,0,0-3.535-6.081L211.5,18.638a7,7,0,0,0-7,0L12.617,129.422A7,7,0,0,0,9,135.55v240.9a7,7,0,0,0,3.5,6.062l192,110.85a7,7,0,0,0,7,0l103.122-59.53A103,103,0,1,0,407,273.463ZM319.126,96.942,141.141,199.7,106.21,179.53,284.191,76.772ZM134,211.624V339.933l-13.195-14.878a7.228,7.228,0,0,0-8.076-1.845L99,328.816V191.648ZM208,32.783l62.191,35.906L92.205,171.45,30.019,135.541Zm-7,442.393L23,372.409V147.654l62,35.917V339.28a7.135,7.135,0,0,0,9.822,6.47l18.653-7.691,22.057,25.046a7.016,7.016,0,0,0,5.264,2.375,7.552,7.552,0,0,0,2.572-.451A7.1,7.1,0,0,0,148,358.48V219.707l53,30.714ZM208,238.3l-52.859-30.518L333.127,105.025,385.98,135.54Zm7,236.879V250.421L393,147.653v125.81a103.013,103.013,0,0,0-85.412,148.264Zm185-9.956a89,89,0,1,1,89-89A89.322,89.322,0,0,1,400,465.22Z" />
    <path d="M266.864,250.634a7.009,7.009,0,0,0-11.266,1.492l-16.9,31.44a7,7,0,0,0,6.158,10.315,7.183,7.183,0,0,0,6.292-3.687L255,283.248V343.42a7,7,0,1,0,14,0V273.051l5.69,6.155a6.928,6.928,0,0,0,9.835.284,7,7,0,0,0,.255-9.9Z" />
    <path d="M41,338.45a7,7,0,0,0-7,7v25.92a7,7,0,0,0,14,0V345.45A7,7,0,0,0,41,338.45Z" />
    <path d="M69,357.65a7,7,0,0,0-7,7V388a7,7,0,0,0,14,0V364.65A7,7,0,0,0,69,357.65Z" />
    <path d="M98,376.85a7,7,0,0,0-7,7v20.56a7,7,0,0,0,14,0V383.85A7,7,0,0,0,98,376.85Z" />
    <path d="M126,396.05a7,7,0,0,0-7,7v17.77a7,7,0,0,0,14,0V403.05A7,7,0,0,0,126,396.05Z" />
    <path d="M148,437.61a7,7,0,0,0,14,0V422.25a7,7,0,0,0-14,0Z" />
    <path d="M276.16,353.467l-31.89,18.41a7,7,0,1,0,7,12.125l31.89-18.41a7,7,0,1,0-7-12.125Z" />
    <path d="M436.771,339.45a7,7,0,0,0-9.9,0L400,366.32l-26.87-26.87a7,7,0,1,0-9.9,9.9L390.1,376.22l-26.871,26.87a7,7,0,0,0,9.9,9.9L400,386.12l26.87,26.87a7,7,0,1,0,9.9-9.9L409.9,376.22l26.871-26.87A7,7,0,0,0,436.771,339.45Z" />
  </svg>
);

export default CanceledBox;
