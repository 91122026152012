import { getTenantToken, getEmailToken } from '../../store/services/auth';
import moment from 'moment';
import axios from 'axios';

const BASE_API = `${process.env.REACT_APP_BASE_API_ADDRESS}/customer-area`;

export const required = (value) => (value ? undefined : 'Campo obrigatório');

export const toUpper = (value) => value && value.toUpperCase();

export const trim = (value) => value && value.trim();

export const giftCodeMask = (value) =>
  value &&
  value
    .replace(/\D/g, '')
    .replace(/(\d{8})(\d)/, '$1 $2')
    .replace(/(\d{8})(\d)/, '$1 $2')
    .replace(/(\d{8})(\d)/, '$1 $2')
    .replace(/(\d{8})(\d)/, '$1 $2')
    .replace(/(\d{8})(\d)+?$/, '$1');

export const cardMask = (value) =>
  value &&
  value
    .replace(/\D/g, '')
    .replace(/(\d{4})(\d)/, '$1 $2')
    .replace(/(\d{4})(\d)/, '$1 $2')
    .replace(/(\d{4})(\d)/, '$1 $2')
    .replace(/(\d{4})(\d)+?$/, '$1');

export const phoneMask = (value) => {
  const cleanValue = value && value.replace(/\D/g, '');
  const isHouse = cleanValue && cleanValue.length <= 10;

  return (
    cleanValue &&
    cleanValue
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(isHouse ? /(\d{4})(\d)/ : /(\d{5})(\d)/, '$1-$2')
      .replace(/(\d{4})(\d)+?$/, '$1')
  );
};

export const dateMask = (value) =>
  value &&
  value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)+?$/, '$1');

export const onlyNumber = (value) => value.replace(/[^\d]/g, '');
export const onlyChar = (value) => value.replace(/[0-9]/g, '');

export const cardExpirationMask = (value) =>
  value &&
  value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)+?$/, '$1');

export const cardCvvMask = (value) =>
  value && value.replace(/\D/g, '').replace(/(\d{4})(\d)+?$/, '$1');

export const validateEmail = (value) =>
  /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/i.test(value) ? undefined : 'E-mail inválido';

export const cpfMask = (value) =>
  value &&
  value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');

export const validateCPF = (cpf) => {
  let isValid = false;
  cpf = cpf.replace(/\D/g, '');

  if (cpf.toString().length !== 11 || /^(\d)\1{10}$/.test(cpf)) {
    isValid = false;
  } else {
    isValid = true;
    [9, 10].forEach((j) => {
      let sum = 0,
        r;
      cpf
        .split(/(?=)/)
        .splice(0, j)
        .forEach((e, i) => {
          sum += parseInt(e) * (j + 2 - (i + 1));
        });

      r = sum % 11;
      r = r < 2 ? 0 : 11 - r;
      // eslint-disable-next-line
      if (r != cpf.substring(j, j + 1)) {
        isValid = false;
      }
    });
  }

  return isValid ? undefined : 'CPF inválido';
};

export const cnpjMask = (value) => {
  return value
    .replace(/\D+/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');
};

export const validateCNPJ = (cnpj) => {
  cnpj = cnpj.replace(/[^\d]+/g, '');

  if (cnpj === undefined || cnpj === '') return 'CNPJ inválido.';

  if (cnpj.length !== 14) return 'CNPJ inválido.';

  if (
    cnpj === '00000000000000' ||
    cnpj === '11111111111111' ||
    cnpj === '22222222222222' ||
    cnpj === '33333333333333' ||
    cnpj === '44444444444444' ||
    cnpj === '55555555555555' ||
    cnpj === '66666666666666' ||
    cnpj === '77777777777777' ||
    cnpj === '88888888888888' ||
    cnpj === '99999999999999'
  )
    return 'CNPJ inválido.';

  let size = cnpj.length - 2;
  let numbers = cnpj.substring(0, size);
  let digits = cnpj.substring(size);
  let sum = 0;
  let index = size - 7;
  for (let i = size; i >= 1; i--) {
    sum += numbers.charAt(size - i) * index--;
    if (index < 2) index = 9;
  }
  let response = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (response.toString() !== digits.charAt(0)) return 'CNPJ inválido.';

  size = size + 1;
  numbers = cnpj.substring(0, size);
  sum = 0;
  index = size - 7;
  for (let i = size; i >= 1; i--) {
    sum += numbers.charAt(size - i) * index--;
    if (index < 2) index = 9;
  }
  response = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (response.toString() !== digits.charAt(1)) return 'CNPJ inválido';

  return;
};

export const validateRandomKey = (random) => {
  const result = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/.test(random);

  if (result === false) return 'Chave PIX aleatória inválida.';

  return;
};

export const cepNormalize = (value) =>
  value &&
  value
    .replace(/\D/g, '')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(-\d{3})(\d)+?$/, '$1');

export const validateCEP = (cep) => (/^[0-9]{5}-[\d]{3}$$/.test(cep) ? undefined : 'CEP inválido');

export const minimumAge = (value) => {
  const date = moment(value, 'DD/MM/YYYY');

  if (!moment(date).isValid()) {
    return 'Data inválida, use dia/mês/ano (01/12/2000)';
  }
  if (moment().isBefore(moment(date))) {
    return 'Data não pode ser maior que a a data minima.';
  }
  if (moment(date).isBefore(moment('01-01-1940'))) {
    return 'Data inválida.';
  }
  if (moment().diff(moment(date), 'years') < 18) {
    return 'Precisa ser maior de 18 anos.';
  }
  return undefined;
};

export const minWithdrawn = (value) => (value < 300 ? 'Valor mínimo 300pts' : undefined);

// max length
const maxLength = (max) => (value) =>
  value && value.length > max ? `Devo possuir no máximo ${max} characteres.` : undefined;

export const maxLength3 = maxLength(3);
export const maxLength4 = maxLength(4);
export const maxLength11 = maxLength(11);
export const cardMaxLength = maxLength(19);

// min length
const minLength = (min) => (value) =>
  value && value.length < min ? `Devo pelo menos ${min} characteres.` : undefined;

export const minLength2 = minLength(2);
export const minLength6 = minLength(6);
export const minLength10 = minLength(10);

export const minLengthPhone = (value) =>
  value && value.replace(/\D/g, '').length < 10 ? `Digite no mínimo ${10} digitos.` : undefined;

export const normalizeAll = (normalizers) => (
  value,
  previousValue,
  allValues,
  previousAllValues
) => {
  let i = 0;
  const normalizersLength = normalizers.length;
  let currentValue = value;
  while (i < normalizersLength) {
    let currentNormalizer = normalizers[i];
    if (typeof currentNormalizer == 'function') {
      currentValue = currentNormalizer(currentValue, previousValue, allValues, previousAllValues);
    }
    i++;
  }

  return currentValue;
};

export const existingEmail = async ({ email }) => {
  const tenant = getTenantToken();
  const userEmail = getEmailToken();
  const response = await axios.get(
    `${BASE_API}/${tenant}/user?email=${window.encodeURIComponent(email)}`
  );
  if (response.status === 200 && email !== userEmail) {
    const error = { email: 'Email em uso.' };
    throw error;
  }
};
