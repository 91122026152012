// @flow
import React, { useEffect } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import NormalRoute from './Routes/NormalRoute';
import ProtectedRoute from './Routes/ProtectedRoute';
import AuthRedirect from '../AuthRedirect/AuthRedirect';
import { Box, BoxDetail } from '../Box';
import { Checkout, SignatureConfirmed } from '../Checkout';
import ConfirmEmail from '../ConfirmEmail/ConfirmEmail';
import Dashboard from '../Dashboard';
import DeliveryFeedback from '../DeliveryFeedback/DeliveryFeedback';
import Loading from '../FacialRecognition/components/Loading';
import Results from '../FacialRecognition/components/Result';
import TakePicture from '../FacialRecognition/components/TakePicture';
import Leaderboard from '../Leaderboard';
import Profile from '../Profile';
import BeautyProfile from '../Profile/PersonalData/CustomerData/BeautyProfile';
import BeautyProfileAnswer from '../Profile/PersonalData/CustomerData/BeautyProfileAnswer';
import ChangePasswordForm from '../Profile/PersonalData/CustomerData/ChangePasswordForm';
import CustomerForm from '../Profile/PersonalData/CustomerData/CustomerForm';
import PaymentDetails from '../Profile/PersonalData/PaymentDetails';
import CardForm from '../Profile/PersonalData/PaymentDetails/CardForm';
import PaymentHistory from '../Profile/PersonalData/PaymentDetails/PaymentHistory';
import ShippingAddresses from '../Profile/PersonalData/ShippingAddresses';
import ShippingAddressForm from '../Profile/PersonalData/ShippingAddresses/ShippingAddressForm';
import Subscription from '../Profile/PersonalData/Subscription';
import SubscriptionBillingDate from '../Profile/PersonalData/Subscription/SubscriptionBillingDate';
import Unsubscribe from '../Profile/PersonalData/Subscription/Unsubscribe';
import RecoverPassword from '../RecoverPassword';
import Referral from '../Referral';
import PixExtract from '../PixExtract';
import ResetPassword from '../ResetPassword';
import SignIn from '../SignIn';

import { initCriteo } from '../../components/utils/Criteo';
import { initGoogleAds } from '../../components/utils/GoogleAds';
import { initHotjar } from '../../components/utils/Hotjar';
import { initGoogleTagManager } from '../../components/utils/GoogleTagManager';
import { useSelector } from 'react-redux';

import 'react-toastify/dist/ReactToastify.css';
import './App.css';

const App = ({ history, location }) => {
  useEffect(() => {
    if (!window.HJ_INITIALIZED) {
      initHotjar();
      window.HJ_INITIALIZED = true;
    }
    if (!window.GTAG_INITIALIZED) {
      initGoogleTagManager();
      window.GTAG_INITIALIZED = true;
    }
    if (!window.CRITEO_INITIALIZED) {
      initCriteo();
      window.CRITEO_INITIALIZED = true;
    }
    if (!window.GADS_INITIALIZED) {
      initGoogleAds();
      window.GADS_INITIALIZED = true;
    }
  }, [history]);

  useEffect(() => {
    const params = new URLSearchParams(location);
    const utm_source = params.get('utm_source');
    const utm_campaign = params.get('utm_campaign');
    const utm_medium = params.get('utm_medium');

    if (!utm_source) return;

    const data = {
      utm_source,
      utm_campaign,
      utm_medium,
    };

    localStorage.setItem('subscription-media-tracking', JSON.stringify(data));
  }, [location]);

  const { tenantName } = useSelector((state) => state?.auth);

  return (
    <main>
      <div style={{ display: 'flex' }}>
        <Router history={history}>
          <Route
            render={({ location }) => (
              <div style={{ flex: 1 }}>
                <Switch location={location}>
                  <Redirect exact from="/" to="/dashboard" />
                  <ProtectedRoute
                    exact
                    path="/dashboard"
                    component={Dashboard}
                    title="Principal"
                    metaDescription="Essa é a sua página principal do Dashboard da UAUBox, aqui você pode encontrar posts e opções personalizadas para você"
                    menu
                  />
                  <ProtectedRoute
                    exact
                    path="/checkout"
                    component={Checkout}
                    title="Checkout"
                    menu
                  />
                  <ProtectedRoute
                    exact
                    path="/assinatura-confirmada"
                    component={SignatureConfirmed}
                    title="Assinatura Confirmada"
                    menu
                  />
                  <ProtectedRoute exact path="/sua-box" component={Box} title="Sua Box" menu />
                  <ProtectedRoute
                    exact
                    path="/sua-box/detalhes/:id"
                    component={BoxDetail}
                    title="Detalhes da sua Box"
                    menu
                  />
                  <ProtectedRoute
                    exact
                    path="/indicacoes"
                    component={Referral}
                    title="Indicações"
                    menu
                  />
                  <ProtectedRoute
                    exact
                    path="/rankingUAU"
                    component={Leaderboard}
                    title="RankingUAU"
                    menu
                  />
                  <ProtectedRoute
                    exact
                    path="/se-descubra/tirar-foto"
                    component={TakePicture}
                    title="Tirar foto"
                    menu
                  />
                  <ProtectedRoute
                    exact
                    path="/se-descubra/carregando"
                    component={Loading}
                    title="Carregando"
                    menu
                  />
                  <ProtectedRoute
                    exact
                    path="/se-descubra/resultados"
                    component={Results}
                    title="Resultados"
                    menu
                  />
                  <ProtectedRoute
                    exact
                    path="/perfil/dados-pessoais"
                    component={CustomerForm}
                    title={tenantName === 'scarlet' ? 'Dados Pessoais' : 'Perfil de Beleza'}
                    menu
                  />
                  <ProtectedRoute
                    exact
                    path="/perfil/perfil-de-beleza"
                    component={BeautyProfile}
                    title={tenantName === 'scarlet' ? 'Dados Pessoais' : 'Perfil de Beleza'}
                    menu
                  />
                  <ProtectedRoute
                    exact
                    path="/perfil/perfil-de-beleza/:id"
                    component={BeautyProfileAnswer}
                    title={tenantName === 'scarlet' ? 'Dados Pessoais' : 'Perfil de Beleza'}
                    menu
                  />
                  <ProtectedRoute
                    exact
                    path="/perfil/enderecos"
                    component={ShippingAddresses}
                    title="Endereços"
                    menu
                  />
                  <ProtectedRoute
                    exact
                    path="/perfil/enderecos/cadastro"
                    component={ShippingAddressForm}
                    title="Cadastrar Endereço"
                    menu
                  />
                  <ProtectedRoute
                    exact
                    path="/perfil/enderecos/:id"
                    component={ShippingAddressForm}
                    title="Editar endereços"
                    menu
                  />
                  <ProtectedRoute
                    exact
                    path="/perfil/assinatura"
                    component={Subscription}
                    title="Assinatura"
                    menu
                  />
                  <ProtectedRoute
                    exact
                    path="/perfil/assinatura/cancelamento"
                    component={Unsubscribe}
                    title="Cancelamento assinatura"
                    menu
                  />
                  <ProtectedRoute
                    exact
                    path="/perfil/assinatura/data-cobranca"
                    component={SubscriptionBillingDate}
                    title="Data de cobrança"
                    menu
                  />
                  <ProtectedRoute
                    exact
                    path="/perfil/seus-pagamentos"
                    component={PaymentDetails}
                    title="Seus pagamentos"
                    menu
                  />
                  <ProtectedRoute
                    exact
                    path="/perfil/seus-pagamentos/cartao"
                    component={CardForm}
                    title="Cartões"
                    menu
                  />
                  <ProtectedRoute
                    exact
                    path="/perfil/seus-pagamentos/cartao/:id"
                    component={CardForm}
                    title="Cartões"
                    menu
                  />
                  <ProtectedRoute
                    exact
                    path="/perfil/seus-pagamentos/historico"
                    component={PaymentHistory}
                    title="Histórico de Compras"
                    menu
                  />
                  <ProtectedRoute exact path="/perfil" component={Profile} title="Perfil" menu />
                  <ProtectedRoute
                    exact
                    path="/perfil/atualizar-senha"
                    component={ChangePasswordForm}
                    title="Atualizar senha"
                    menu
                  />
                  <NormalRoute
                    exact
                    path="/login"
                    component={SignIn}
                    title="Minha Conta"
                    checkAuth
                  />
                  <NormalRoute
                    exact
                    path="/:tenant/confirmar-email/:token"
                    component={ConfirmEmail}
                    title="Confirmar E-mail"
                  />
                  <NormalRoute
                    exact
                    path="/:tenant/recadastrar-senha/:token"
                    component={ResetPassword}
                    title="Cadastrar nova senha"
                  />
                  <NormalRoute
                    exact
                    path="/recuperar-senha"
                    component={RecoverPassword}
                    title="Recuperar senha"
                  />
                  <ProtectedRoute
                    exact
                    path="/como-foi-sua-entrega"
                    component={DeliveryFeedback}
                    title="Como foi sua entrega"
                  />
                  <NormalRoute
                    exact
                    path="/auth-redirect"
                    component={AuthRedirect}
                    title="Autenticação de redirecionamento"
                  />
                  <ProtectedRoute
                    exact
                    path="/pix-extrato"
                    component={PixExtract}
                    title="Pix meu extrato"
                    menu
                  />
                </Switch>
              </div>
            )}
          />
        </Router>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
      </div>
    </main>
  );
};

export default App;
