// @flow
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Spinner from './Spinner';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
  },
}));

type Props = {
  children: any,
  isLoading: boolean,
  size: 'small' | 'medium' | 'large',
  fullSize: boolean,
};

const Loading = ({ children, isLoading, size, fullSize }: Props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {children}
      {isLoading ? <Spinner size={size} fullSize={fullSize} /> : false}
    </div>
  );
};

export default Loading;
