import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { push } from 'react-router-redux';
import { Help } from '@material-ui/icons';
import { black, silverChalice, zumthor, tundora, alto } from '../../themes/colors';
import BoxTrackingDetail from '../../components/BoxTrackingDetail';
import { fetchBoxOrder } from '../../store/actions';
import moment from 'moment';
import 'moment/locale/pt-br';
import { getCardIcon } from '../../components/utils/cardUtils';
import { Tooltip } from '../../components/material';
import Loading from '../../components/Loading';
import Feedback from '../../components/Feedback';
import { BackButton, BackButtonMobile } from '../../components/material';
import { getPlanPeriod, getPlanCost, toMoney } from '../../models/subscription';
import { getTenantToken } from '../../store/services/auth';
import SingleProductCancelCard from '../../components/SingleProductCancelCard';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    boxShadow: `1px 3px 6px ${alto}`,
    minHeight: '100vh',
  },
  titleBox: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '2rem',
    textTransform: 'capitalize',
  },
  title: {
    color: black,
    fontSize: '2em',
    padding: '0 2.5rem',
    [theme.breakpoints.up('lg')]: {
      padding: '0 1rem',
    },
  },
  subtitle: {
    color: tundora,
    marginBottom: '1rem',
  },
  mainTitle: {
    color: tundora,
    fontSize: '1.5em',
    marginBottom: '2rem',
  },
  boxDetail: {
    background: zumthor,
    padding: '1.5rem 2rem',
    position: 'relative',
    '& button': {
      padding: '0.5rem 1rem',
      marginTop: '1rem',
      fontWeight: 'bold',
    },
  },
  mainBox: {
    marginTop: '1rem',
    padding: '2rem',
    '& a': {
      display: 'inline-block',
      fontSize: '0.75rem',
      minWidth: '64px',
      boxSizing: 'border-box',
      transition:
        'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      fontFamily: 'Open Sans,sans-serif',
      fontWeight: '500',
      lineHeight: '1.75',
      textTransform: 'uppercase',
      color: '#fff',
      textDecoration: 'none',
      margin: '0 0 2rem',
      padding: '0.5rem 1rem',
      borderRadius: '0.7rem',
      background: '#A01235',
      '&:hover': {
        backgroundColor: 'rgba(255, 51, 51, 0.8)',
      },
    },
  },
  imageBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& img': {
      width: '14rem',
      position: 'relative',
      right: '-1rem',
      bottom: '-3rem',
      [theme.breakpoints.up('md')]: {
        width: ' 17rem',
        right: '3rem',
      },
    },
  },
  mainBoxtitle: {
    color: silverChalice,
  },
  mainBoxTitleHighlight: {
    color: black,
    fontSize: '1.2em',
  },
  cardInfo: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(1, 0),
    color: black,
    fontSize: '1.2em',
    '& svg': {
      height: '1.5em',
      marginRight: theme.spacing(1),
    },
  },
  tooltipBox: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      fill: silverChalice,
      width: '0.8em',
      height: '0.8em',
      marginLeft: '0.5rem',
    },
  },
}));

const BoxDetail = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const highDevice = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const showArrow = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const { boxOrder, loading } = useSelector((state) => state.profile);
  const { loading: purchaseLoading } = useSelector((state) => state.purchase);
  const id = props.match?.params?.id;

  const canBeCancelled =
    boxOrder.kind === 'SINGLE_PURCHASE_BOX' &&
    (boxOrder.status === 'NEW' ||
      boxOrder.status === 'PRODUCTS_SELECTION' ||
      boxOrder.status === 'ASSIGNED_LOGISTICS_PLATFORM');

  useEffect(() => {
    dispatch(fetchBoxOrder(id));
  }, [dispatch, id]);

  return (
    <Loading isLoading={loading || purchaseLoading}>
      <div className={classes.root}>
        <BackButtonMobile onClick={() => dispatch(push('/sua-box'))} visible={!showArrow} />
        <Grid className={classes.boxDetail} container>
          <Grid item xs={12} sm={6}>
            <div className={classes.titleBox}>
              <BackButton onClick={() => dispatch(push('/sua-box'))} visible={showArrow} />
              <Typography className={classes.title}>
                {boxOrder.kind === 'SINGLE_PURCHASE_BOX'
                  ? `Box adicional - ${
                      boxOrder?.edition?.title
                        ? boxOrder?.edition?.title
                        : moment(boxOrder?.edition?.date).format('YYYY')
                    }`
                  : `Edição ${moment(boxOrder?.edition?.date).format('MMMM YYYY')}`}
              </Typography>
            </div>

            {boxOrder && !boxOrder?.rated && boxOrder?.lastTimelineEvent?.status === 'DELIVERED' && (
              <React.Fragment>
                <Typography className={classes.subtitle} gutterBottom>
                  Avalie e ajude a gente.
                </Typography>
                <Feedback
                  page="boxDetail"
                  buttonText="Dar Feedback"
                  orderId={boxOrder.boxOrderId}
                  edition={boxOrder.edition}
                />
              </React.Fragment>
            )}
          </Grid>

          {/* <Grid className={classes.imageBox} item xs={12} sm={6}>
            {`imagem da Box`}
          </Grid> */}
        </Grid>

        <div className={classes.mainBox}>
          <Typography className={classes.mainTitle} gutterBottom>
            Status da Box
          </Typography>

          <BoxTrackingDetail
            orientation={highDevice ? 'horizontal' : 'vertical'}
            timeline={boxOrder?.timeline}
          />

          {boxOrder?.shipping?.trackingUrl ? (
            <a href={boxOrder.shipping?.trackingUrl} target="_blank" rel="noopener noreferrer">
              Acompanhar entrega
            </a>
          ) : null}

          <Typography className={classes.mainTitle} gutterBottom>
            Outras informações
          </Typography>

          {boxOrder.shippingAddress && (
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Typography className={classes.mainBoxtitle} gutterBottom>
                  Endereço de entrega
                </Typography>
                <Typography className={classes.mainBoxtitleHighlight} gutterBottom>
                  {`${boxOrder?.shippingAddress?.street}, ${boxOrder?.shippingAddress?.number}`}
                  {boxOrder?.shippingAddress?.complement
                    ? ` - ${boxOrder?.shippingAddress?.complement}`
                    : null}
                  {` - ${boxOrder?.shippingAddress?.postalCode} - ${boxOrder?.shippingAddress?.district} -
                ${boxOrder?.shippingAddress?.city} - ${boxOrder?.shippingAddress?.state}`}
                </Typography>
              </Grid>

              <Grid item xs={12} md={2}>
                <Typography className={classes.mainBoxtitle} gutterBottom>
                  Edição
                </Typography>
                <Typography className={classes.mainBoxtitleHighlight} gutterBottom>
                  {boxOrder.kind === 'SINGLE_PURCHASE_BOX'
                    ? `Box adicional - ${
                        boxOrder?.edition?.title
                          ? boxOrder?.edition?.title
                          : moment(boxOrder?.edition?.date).format('YYYY')
                      }`
                    : moment(boxOrder?.edition?.date).format('MMMM YYYY')}
                </Typography>
              </Grid>

              <Grid item xs={12} md={2}>
                <Typography className={classes.mainBoxtitle} gutterBottom>
                  Pagamento
                </Typography>
                <div className={classes.cardInfo}>
                  {getCardIcon(boxOrder?.paymentProfile?.card?.brand)}
                  <span>{`final ${boxOrder?.paymentProfile?.card?.lastFour}`}</span>
                </div>
              </Grid>

              {boxOrder.kind !== 'SINGLE_PURCHASE_BOX' ? (
                <>
                  <Grid item xs={12} md={2}>
                    <div className={classes.tooltipBox}>
                      <Typography className={classes.mainBoxtitle} gutterBottom>
                        Plano
                      </Typography>
                      <Tooltip title="O plano funciona com renovação automática cobrada mensalmente no cartão de crédito.">
                        <Help />
                      </Tooltip>
                    </div>
                    <Typography className={classes.mainBoxtitleHighlight} gutterBottom>
                      {`${getPlanPeriod(
                        boxOrder?.subscriptionPlan?.recurrencePeriod,
                        getTenantToken()
                      )} R$${toMoney(getPlanCost(boxOrder?.subscriptionPlan))}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <div className={classes.tooltipBox}>
                      <Typography className={classes.mainBoxtitle} gutterBottom>
                        Valor total pago
                      </Typography>
                      <Tooltip
                        title={`O valor total é composto pelo valor do plano de assinatura mais o frete ${
                          boxOrder?.subscriptionPlan?.shippingCost === 0
                            ? `(Frete Gratis)`
                            : `(R$ ${toMoney(
                                boxOrder?.subscriptionPlan?.hasInstallments
                                  ? boxOrder?.subscriptionPlan?.shippingCost /
                                      boxOrder?.subscriptionPlan?.recurrenceNumberOfPeriods
                                  : boxOrder?.subscriptionPlan?.shippingCost
                              )})`
                        }`}
                      >
                        <Help />
                      </Tooltip>
                    </div>
                    <Typography className={classes.mainBoxtitleHighlight} gutterBottom>
                      {`R$${toMoney(
                        getPlanCost(boxOrder?.subscriptionPlan) +
                          (boxOrder?.subscriptionPlan?.hasInstallments
                            ? boxOrder?.subscriptionPlan?.shippingCost /
                              boxOrder?.subscriptionPlan?.recurrenceNumberOfPeriods
                            : boxOrder?.subscriptionPlan?.shippingCost)
                      )}`}
                    </Typography>
                  </Grid>
                </>
              ) : null}
              {boxOrder.kind === 'SINGLE_PURCHASE_BOX' && canBeCancelled ? (
                <Grid item xs={12} md={2}>
                  <Typography className={classes.mainBoxtitle} gutterBottom>
                    Cancelamento
                  </Typography>
                  <Button onClick={() => setOpenCancelDialog(true)}>Cancelar pedido</Button>
                </Grid>
              ) : null}
            </Grid>
          )}
        </div>
      </div>
      <SingleProductCancelCard
        open={openCancelDialog}
        onClose={() => setOpenCancelDialog(false)}
        cancel={true}
        productDetail={boxOrder}
      />
    </Loading>
  );
};

export default BoxDetail;
