import TagManager from 'react-gtm-module';

const GTAG_ID = process.env.REACT_APP_GTAGManager;

export const initGoogleTagManager = () => {
  const tagManagerArgs = {
    gtmId: GTAG_ID,
  };

  TagManager.initialize(tagManagerArgs);
};
