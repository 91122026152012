import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@material-ui/core';
import { cinzaMedioRGBA2, white, redOrange, cinzaEscuro } from '../../themes/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    fontFamily: 'open sans',
    left: 0,
  },
  popShadow: {
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    zIndex: '999999',
    background: cinzaMedioRGBA2,
  },
  popupHolder: {
    position: 'relative',
    width: '100vw',
    height: '100vh',
  },
  popup: {
    position: 'absolute',
    top: '20%',
    left: '50%',
    transform: 'translate(-50%, -20%)',
    width: '95%',
    [theme.breakpoints.up('md')]: {
      maxHeight: '600px',
      width: '510px',
    },
  },
  popCard: {
    padding: '1em',
    background: white,
    borderRadius: '1em',
  },
  popTitle: {
    background: redOrange,
    fontFamily: 'open sans',
    fontWeight: 'bold',
    fontSize: '1.5em',
    color: white,
    padding: theme.spacing(2),
    textAlign: 'center',
    borderRadius: '0.5em 0.5em 0 0',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.7em',
    },
  },
  popSub: {
    fontFamily: 'open sans',
    color: cinzaEscuro,
    padding: theme.spacing(2),
    fontSize: '1.2em',
    textAlign: 'justify',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.4em',
    },
    '& span': {
      color: cinzaEscuro,
      fontWeight: 'bold',
    },
    '& p': {
      padding: '0',
      margin: '0.6rem 0',
    },
    '& img': {
      margin: '0 0.5rem',
      height: '20px',
    },
  },
  popBtn: {
    margin: '1em auto 0.5em',
    background: redOrange,
    color: white,
    fontFamily: 'open sans',
    fontWeight: 'bold',
    fontSize: '1em',
    display: 'block',
    minWidth: '50%',
    padding: theme.spacing(1.5, 3),
    borderRadius: '2rem',
    '&:hover': {
      background: redOrange,
      color: white,
    },
  },
}));

const Popup = () => {
  const classes = useStyles();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowModal(true);
    }, 1500);
  }, []);

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className={classes.root} style={{ display: showModal ? 'block' : 'none' }}>
      <div className={classes.popShadow} onClick={closeModal}>
        <div className={classes.popupHolder}>
          <div className={classes.popup}>
            <div className={classes.popCard}>
              <Typography className={classes.popTitle}>Carteira de Pontos</Typography>
              <Typography className={classes.popSub}>
                Reparei que você possui mais de uma conta na UAU com o seu CPF
                <img src="/images/eye-message-wallet.png" alt="Icone de um olho" />e a Carteira é
                limitada a um único cadastro.
              </Typography>
              <Typography className={classes.popSub}>
                Me chama no chat pra gente resolver juntas! &lt;3
              </Typography>
              <Button
                variant="contained"
                color="primary"
                className={classes.popBtn}
                onClick={() => closeModal}
              >
                Entendi
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup;
