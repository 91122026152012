import React from 'react';

const GiftIcon = ({ fill = '#fff', width = '100%', className = '', height = '100%' }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 4.875V7.5M12 4.875C12 4.35583 12.154 3.84831 12.4424 3.41663C12.7308 2.98495 13.1408 2.6485 13.6205 2.44982C14.1001 2.25114 14.6279 2.19915 15.1371 2.30044C15.6463 2.40173 16.114 2.65173 16.4812 3.01885C16.8483 3.38596 17.0983 3.85369 17.1996 4.36289C17.3008 4.87209 17.2489 5.39989 17.0502 5.87955C16.8515 6.3592 16.5151 6.76917 16.0834 7.05761C15.6517 7.34605 15.1442 7.5 14.625 7.5H12M12 4.875C12 4.35583 11.846 3.84831 11.5576 3.41663C11.2692 2.98495 10.8592 2.6485 10.3795 2.44982C9.89989 2.25114 9.37209 2.19915 8.86289 2.30044C8.35369 2.40173 7.88596 2.65173 7.51885 3.01885C7.15173 3.38596 6.90173 3.85369 6.80044 4.36289C6.69915 4.87209 6.75114 5.39989 6.94982 5.87955C7.1485 6.3592 7.48495 6.76917 7.91663 7.05761C8.34831 7.34605 8.85583 7.5 9.375 7.5H12"
      stroke="#717171"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M19.5 7.5H4.5C3.67157 7.5 3 8.17157 3 9V11.25C3 12.0784 3.67157 12.75 4.5 12.75H19.5C20.3284 12.75 21 12.0784 21 11.25V9C21 8.17157 20.3284 7.5 19.5 7.5Z"
      stroke="#717171"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19.5 12.75V19.5C19.5 20.0967 19.2629 20.669 18.841 21.091C18.419 21.5129 17.8467 21.75 17.25 21.75H6.75C6.15326 21.75 5.58097 21.5129 5.15901 21.091C4.73705 20.669 4.5 20.0967 4.5 19.5V12.75M12 7.5V21.75"
      stroke="#717171"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default GiftIcon;
