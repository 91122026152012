import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import UserCard from './sections/UserCard';
import Box from './sections/Box';
import { white, black, redOrange, alto } from '../../../../themes/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '1rem',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      maxWidth: 320,
      boxShadow: `1px 3px 6px ${alto}`,
    },
    [theme.breakpoints.up('lg')]: {
      padding: '2rem',
      maxWidth: 320,
    },
  },
  avatar: {
    width: '6rem',
    height: '6rem',
    border: `2px solid ${white}`,
    margin: '1rem',
  },
  title: {
    color: black,
    fontSize: '1.3em',
    fontWeight: 'bold',
  },
  subtitle: {
    color: redOrange,
    fontSize: '1.3em',
  },
}));

const SideBar = () => {
  const classes = useStyles();
  const mobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const data = useSelector((state) => state.dashboard);

  return (
    <section className={classes.root}>
      {!mobile ? <UserCard customer={data.customer} /> : null}
      <Box
        box={data.box}
        subscription={data.subscription}
        referrals={data.customer.referralCode}
        termsOfUse={data.termsOfUse}
        loading={data.loading}
      />
    </section>
  );
};

export default SideBar;
