import { Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import moment from 'moment';
import 'moment/locale/pt-br';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'react-router-redux';
import BoxTrackingCard from '../../components/BoxTrackingCard';
import BoxTrackingDetail from '../../components/BoxTrackingDetail';
import { InProductionBoxIcon } from '../../components/icons';
import Loading from '../../components/Loading';
import { fetchBoxOrders } from '../../store/actions';
import {
  alto,
  black,
  cinzaMaisClaro,
  silverChalice,
  tundora,
  white,
  wildSand,
} from '../../themes/colors';

import { isEqual } from 'lodash';

import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    boxShadow: `1px 3px 6px ${alto}`,
    minHeight: '100vh',
  },
  title: {
    color: black,
    fontSize: '2em',
    padding: '0 2.5rem',
    marginBottom: '2rem',
    [theme.breakpoints.up('lg')]: {
      padding: 0,
    },
  },
  titleHighlight: {
    color: black,
    fontSize: '1.5em',
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
  subtitle: {
    color: silverChalice,
  },
  marginTop: {
    marginTop: '1rem',
  },
  previousBoxTitle: {
    color: tundora,
    fontSize: '1.2em',
  },
  previousBoxsubtitle: {
    color: tundora,
    fontWeight: 'bold',
  },
  trackingBox: {
    background: cinzaMaisClaro,
    padding: '1.5rem 2rem',
    position: 'relative',
    '& button': {
      margin: '0.25rem 0',
      padding: '0.5rem 1rem',
      borderRadius: '0.7rem',
      [theme.breakpoints.up('md')]: {
        margin: '0.25rem 1rem 0.25rem 0',
      },
    },
    '& a': {
      fontSize: '0.75rem',
      minWidth: '64px',
      boxSizing: 'border-box',
      transition:
        'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      fontFamily: 'Open Sans,sans-serif',
      fontWeight: '500',
      lineHeight: '1.75',
      textTransform: 'uppercase',
      color: '#A01235',
      textDecoration: 'none',
      margin: '0.25rem 0',
      padding: '0.5rem 1rem',
      borderRadius: '0.7rem',
      background: white,
      [theme.breakpoints.up('md')]: {
        margin: '0.25rem 1rem 0.25rem 0',
      },
      '&:hover': {
        backgroundColor: 'rgba(255, 51, 51, 0.04)',
      },
    },
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& button': {
      margin: '0.25rem 0',
      padding: '0.5rem 1rem',
      borderRadius: '0.7rem',
      background: white,
      [theme.breakpoints.up('md')]: {
        margin: '0.25rem 1rem 0.25rem 0',
      },
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  previousBox: {
    marginTop: '1rem',
    padding: '2rem',
  },
  imageBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& img': {
      position: 'relative',
      right: '1rem',
      bottom: '-1rem',
      [theme.breakpoints.up('md')]: {
        width: ' 17rem',
        right: '3rem',
      },
    },
  },
  noBoxContainer: {
    display: 'flex',
    margin: '2rem 0',
  },
  boxIcon: {
    height: '7rem',
    width: '7rem',
    display: 'flex',
    justifyContent: 'center',
    background: wildSand,
    padding: theme.spacing(0.5),
    borderRadius: '50%',
    '& svg': {
      width: '4rem',
      fill: black,
    },
  },
  noBoxDescription: {
    alignSelf: 'center',
    marginLeft: '2rem',
  },
  noBoxTitle: {
    color: black,
    fontSize: '2em',
  },
  noBoxSubtitle: {
    color: silverChalice,
    fontSize: '1em',
  },
  subscribeBox: {
    '& button': {
      fontWeight: 'bold',
      padding: '0.7rem 1rem',
      marginTop: '1rem',
    },
  },
  skeletonText: {
    marginTop: '2em',
    marginBottom: '2em',
  },
  skeletonBox: {
    display: 'flex',
  },
}));

const Box = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const highDevice = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const { boxOrders, loading } = useSelector((state) => state.profile);
  const { subscription, mostRecentEdition, previousEditions } = boxOrders;
  const hasPrevious = previousEditions && previousEditions.length > 0;

  useEffect(() => {
    dispatch(fetchBoxOrders());
  }, [dispatch]);

  return (
    <Loading isLoading={loading}>
      <div className={classes.root}>
        <Grid className={classes.trackingBox} container>
          <Grid item xs={12} sm={6}>
            <Typography className={classes.title} gutterBottom>
              Sua Box
            </Typography>
            {isEqual(subscription?.status, 'CANCELED') || !subscription ? (
              !loading ? (
                <div className={classes.subscribeBox}>
                  <Typography gutterBottom>
                    Na UAUBox você recebe os produtos de beleza escolhidos para você todos os meses.
                    Além de um conteúdo muito especial, preparado exclusivamente para as assinantes.
                  </Typography>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => dispatch(push('/checkout'))}
                  >
                    ASSINE AGORA
                  </Button>
                </div>
              ) : (
                <div className={classes.previousBox}>
                  <div className={classes.skeletonText}>
                    <Skeleton variant="text" height={25} />
                    <Skeleton variant="text" height={25} />
                    <Skeleton variant="text" height={25} />
                    <Skeleton variant="text" height={25} />
                  </div>
                </div>
              )
            ) : (
              <div>
                <Typography className={classes.subtitle} gutterBottom>
                  Edição Atual
                </Typography>
                {!loading ? (
                  <Typography className={classes.titleHighlight} gutterBottom>
                    {mostRecentEdition?.kind === 'SINGLE_PURCHASE_BOX'
                      ? 'Box adicional'
                      : `${moment(mostRecentEdition?.edition?.date).format('MMMM YYYY')}`}
                  </Typography>
                ) : (
                  <Skeleton variant="text" height={25} />
                )}
                {mostRecentEdition?.orderId && (
                  <div className={classes.buttonsContainer}>
                    <Button
                      variant="text"
                      color="primary"
                      onClick={() =>
                        dispatch(push(`/sua-box/detalhes/${mostRecentEdition?.orderId}`))
                      }
                    >
                      Ver detalhes da Box
                    </Button>
                    {mostRecentEdition.shipping?.trackingUrl ? (
                      <a
                        href={mostRecentEdition.shipping?.trackingUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Acompanhar entrega
                      </a>
                    ) : null}
                  </div>
                )}
                <Typography className={`${classes.subtitle} ${classes.marginTop}`} gutterBottom>
                  Status do Box
                </Typography>
                {!loading ? (
                  <BoxTrackingDetail
                    orientation={highDevice ? 'horizontal' : 'vertical'}
                    timeline={mostRecentEdition?.timeline}
                  />
                ) : (
                  <div className={classes.skeletonBox}>
                    <Skeleton variant="text" height={25} width={200} />
                  </div>
                )}
              </div>
            )}
          </Grid>
        </Grid>
        {!loading ? (
          <div className={classes.previousBox}>
            <Typography className={classes.previousBoxTitle} gutterBottom>
              Edições anteriores
            </Typography>

            {hasPrevious ? (
              <Typography className={classes.previousBoxsubtitle} gutterBottom>
                Avalie os produtos das suas boxes passadas.
              </Typography>
            ) : null}

            {hasPrevious ? (
              <>
                {isEqual(subscription?.status, 'CANCELED') || !subscription ? (
                  <BoxTrackingCard key={mostRecentEdition.orderId} {...mostRecentEdition} />
                ) : null}
                {previousEditions.map((i) => (
                  <BoxTrackingCard key={i.orderId} {...i} />
                ))}
              </>
            ) : (
              <div className={classes.noBoxContainer}>
                <div className={classes.boxIcon}>
                  <InProductionBoxIcon />
                </div>
                <div className={classes.noBoxDescription}>
                  <Typography className={classes.noBoxTitle} gutterBottom>
                    Você ainda não possui edições anteriores
                  </Typography>
                  <Typography className={classes.noBoxSubtitle} gutterBottom>
                    Em breve você receberá os produtos de beleza escolhidos para você todos os
                    meses.
                  </Typography>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className={classes.previousBox}>
            <div className={classes.skeletonText}>
              <Skeleton variant="text" height={25} />
              <Skeleton variant="text" height={25} />
              <Skeleton variant="text" height={25} />
              <Skeleton variant="text" height={25} />
            </div>
          </div>
        )}
      </div>
    </Loading>
  );
};

export default Box;
