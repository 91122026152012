import React from 'react';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { KeyboardArrowLeft } from '@material-ui/icons';
import { white, redOrange } from '../../themes/colors';

const useStyles = makeStyles((theme) => ({
  drawerIcon: {
    position: 'absolute',
    top: '1rem',
    zIndex: 999,
    display: 'flex',
    paddingRight: '0.5rem',
    color: redOrange,
    background: white,
    borderRadius: theme.spacing(0, 3, 3, 0),
    borderColor: redOrange,
    borderTop: '1px solid',
    borderRight: '1px solid',
    borderBottom: '1px solid',
    width: '3.5rem',
    '& button': {
      '&:focus': {},
    },
    '& svg': {
      fill: `${redOrange} !important`,
      stroke: `${redOrange} !important`,
    },
  },
  hidden: {
    display: 'none',
  },
}));

const BackButton = ({ onClick, visible }) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.drawerIcon, { [classes.hidden]: !visible })}>
      <IconButton onClick={onClick}>
        <KeyboardArrowLeft />
      </IconButton>
    </div>
  );
};

export default BackButton;
