import {
  MenuItem,
  Typography,
  Button,
  CircularProgress,
  Divider,
  InputAdornment,
} from '@material-ui/core';
import { TextField } from '../../../../../components/material';
import useStyles from './Styles.jsx';
import { connect } from 'react-redux';
import React from 'react';
import { useState } from 'react';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { push } from 'react-router-redux';
import {
  fetchMainAddress,
  fetchShippingCost,
  createVindiSubscription,
  createStripeSubscription,
  fetchPlanList,
  clearCoupon,
  fetchCoupon,
  fetchCouponPlanAvailable,
  fetchSubscriptionDetails,
  fetchProfileDetails,
} from '../../../../../store/actions';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { scrollToFirstError } from '../../../../../components/utils/errorScrolling';

function ToCheckout({
  onFetchMainAdress,
  onFetchShippingCost,
  onFetchPlanList,
  onFetchCoupon,
  onFetchProfileDetails,
  loading,
  customer,
  billingAddress,
  tenantName,
  onClearCoupon,
  planList,
  shippingCost,
  promotionalShippingCost,
  selectedCoupon,
}) {
  const classes = useStyles();
  const [comboPlan, setComboPlan] = useState('');
  const [kit] = useState('');
  const [isLoading] = useState(false);
  const [planInUrlId] = useState(0);
  const [promotionalValue, setPromotionalValue] = useState(0);
  const [couponDiscount, setCouponDiscount] = useState(0);
  const [coupon, setCoupon] = useState('');
  const [cycleCoupon, setCycleCoupon] = useState();
  const [selectedPlan, setSelectedPlan] = useState('');

  const totalCostDescriptionAdditionalItem = () => {
    let total = (Number(calculateBoxCost(selectedPlan)) || 0) - (Number(couponDiscount) || 0);
    return parseMoneyValue(total);
  };

  const getPlanTitle = (plan) => {
    const cost = parseMoneyValue(calculateBoxCost(plan));
    const installmentAmount = getInstallmentAmount(plan);
    return `${tenantName !== 'uaubox' ? 'Plano' : ''} ${getTitle(
      plan
    )} - ${installmentAmount}R$${cost} + Frete`;
  };

  const handleOnCupomPress = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      if (coupon && selectedPlan?.id) {
        onFetchCoupon(selectedPlan?.id, coupon, customer?.cpf, customer?.email);
      } else {
        toast.error('Você deve selecionar um plano e digitar o nome do cupom!');
      }
    }
  };

  const handleOnClearCoupon = () => {
    onClearCoupon();
    setCoupon('');
  };

  const handleOnValidateCoupon = () => {
    if (selectedPlan?.id && coupon) {
      onFetchCoupon(selectedPlan?.id, coupon, customer?.cpf, customer?.email);
    } else {
      toast.error('Selecione um plano e adicione um Cupom!');
    }
  };

  const calculateCouponDiscount = ({ amount, percentage, amountPromotional, cycle }, boxCost) => {
    setPromotionalValue(0);
    if (percentage) {
      return boxCost * percentage;
    }
    if (amount) {
      return amount;
    }
    if (amountPromotional) {
      setPromotionalValue(amountPromotional);
      setCycleCoupon(cycle);
    }
    return 0;
  };

  useEffect(() => {
    if (!selectedPlan) {
      return;
    }
    setCouponDiscount(calculateCouponDiscount(selectedCoupon, calculateBoxCost(selectedPlan)));
    setCoupon(selectedCoupon.name || '');
  }, [selectedCoupon, selectedPlan]);

  useEffect(() => {
    if (planList.length <= 0) {
      return;
    }
    setSelectedPlan(planList[1]);
    setComboPlan(planList[1].id);
  }, [planList]);

  const isPromotionPlan = () => {
    return false;
    // return (
    //   tenantName === 'uaubox' &&
    //   (isEqual(selectedPlan.recurrencePeriod, 'ANNUAL') ||
    //     isEqual(selectedPlan.recurrencePeriod, 'ANNUAL_INSTALLMENT'))
    // );
  };

  const parseMoneyValue = (value) =>
    value ? value.toFixed(2).replace('.', ',') : Number(0).toFixed(2);

  const calculateBoxCost = ({ amountInstallment }) => {
    return amountInstallment ? amountInstallment / 100 : 0;
  };

  const getInstallmentAmount = ({ hasInstallments, recurrenceNumberOfPeriods }) =>
    hasInstallments ? `${recurrenceNumberOfPeriods}x de ` : '';

  const getTitle = ({ recurrenceNumberOfPeriods }) => {
    switch (recurrenceNumberOfPeriods) {
      case 1:
        return tenantName === 'uaubox' ? 'Testei (Mensal)' : 'Mensal';
      case 3:
        return tenantName === 'uaubox' ? 'Amei (Trimestral)' : 'Trimestral';
      case 6:
        return tenantName === 'uaubox' ? 'Viciei (Semestral)' : 'Semestral';
      case 12:
        return tenantName === 'uaubox' ? 'Não Vivo Sem (Anual)' : 'Anual';
      default:
        return tenantName === 'uaubox' ? 'Não Vivo Sem (Anual)' : 'Anual';
    }
  };

  const getShippingCost = () => {
    return promotionalShippingCost === null || selectedCoupon.percentage > 0.1
      ? shippingCost
      : promotionalShippingCost;
  };

  const onSelectPlan = (plan) => {
    setSelectedPlan(plan);
    setComboPlan(plan.id);
  };

  useEffect(() => {
    onFetchProfileDetails();
    onFetchMainAdress();
    onFetchPlanList();
  }, [onFetchMainAdress, onFetchPlanList, onFetchProfileDetails]);

  useEffect(() => {
    if (!isEmpty(billingAddress)) {
      onFetchShippingCost(billingAddress);
    }
  }, [billingAddress, onFetchShippingCost]);

  const totalCost = () => {
    const updateBoxCost = calculateBoxCost(
      selectedPlan ? selectedPlan : { amountInstallment: '0' }
    );
    let total = 0;
    if (selectedCoupon?.cycle !== 'ONE_MONTH') {
      total =
        (Number(updateBoxCost) || 0) +
        (Number(getShippingCost()) || 0) -
        (Number(couponDiscount) || 0) +
        (kit !== '' && 109.9);
    } else {
      total =
        (Number(updateBoxCost) || 0) +
        (Number(getShippingCost()) || 0) -
        (Number(getCouponDiscountOneMonth(couponDiscount)) || 0) +
        (kit !== '' && 109.9);
    }
    return parseMoneyValue(total);
  };

  function getCouponDiscountOneMonth(couponDiscount) {
    if (!couponDiscount) return 0;
    const total = couponDiscount / selectedPlan?.recurrenceNumberOfPeriods;
    return total;
  }

  const getMessagePromotional = () => {
    if (cycleCoupon === 'ONE_MONTH') {
      return (
        <Typography className={classes.totalObs}>
          * O valor da primeira mensalidade será de R$
          {parseMoneyValue(promotionalValue)}. As demais mensalidades, o valor do plano será de R$
          {totalCostDescriptionAdditionalItem()} + frete.
        </Typography>
      );
    }
    if (cycleCoupon === 'ONE_SUBSCRIPTION_CYCLE') {
      return (
        <Typography className={classes.totalObs}>
          * O valor R${parseMoneyValue(promotionalValue)} será válido durante príodo dessa
          assinatura.
        </Typography>
      );
    }
  };

  const goToCheckout = () => {
    const queryParams = new URLSearchParams(window.location.search);

    const utm_source = queryParams.get('utm_source')
      ? `&utm_source=${queryParams.get('utm_source')}`
      : '';
    const utm_medium = queryParams.get('utm_medium')
      ? `&utm_medium=${queryParams.get('utm_medium')}`
      : '';
    const utm_campaign = queryParams.get('utm_campaign')
      ? `&utm_campaign=${queryParams.get('utm_campaign')}`
      : '';
    const utm_term = queryParams.get('utm_term') ? `&utm_term=${queryParams.get('utm_term')}` : '';
    const utm_content = queryParams.get('utm_content')
      ? `&utm_content=${queryParams.get('utm_content')}`
      : '';

    const token = localStorage.getItem('token');
    const env = process.env.REACT_APP_NODE_ENV;
    const baseUrl =
      env === 'staging'
        ? 'https://checkout-staging.uaubox.com.br/auth'
        : 'https://checkout-uau.uaubox.com.br/auth';
    const url = `${baseUrl}?tenant=${tenantName}&plan=${comboPlan}&c=${coupon}&refreshToken=${token}${utm_source}${utm_medium}${utm_campaign}${utm_term}${utm_content}&redirect=payment`;
    window.open(url, '_blank');
  };

  const getDynamicEditionImage = () => {
    if (tenantName === 'scarlet')
      return `https://d2ivdm85owtv7v.cloudfront.net/images/edicoes/scarlet-2023-09.png`;

    const today = new Date();
    return `https://d2ivdm85owtv7v.cloudfront.net/images/edicoes/${tenantName}-${today.getFullYear()}-${(
      today.getMonth() + 1
    )
      .toString()
      .padStart(2, '0')}.png`;
  };

  function showCoupon() {
    if (selectedCoupon?.cycle === 'ONE_MONTH') {
      return `R$ -${parseMoneyValue(couponDiscount)}`;
    } else {
      if (selectedPlan?.recurrencePeriod === 'ANNUAL') {
        return `R$ -${parseMoneyValue(couponDiscount * 12)}`;
      } else if (selectedPlan?.recurrencePeriod === 'BIANNUAL') {
        return `R$ -${parseMoneyValue(couponDiscount * 6)}`;
      } else {
        return `R$ -${parseMoneyValue(couponDiscount)}`;
      }
    }
  }

  return (
    <>
      <div className={classes.contentRightImage}>
        <img src={getDynamicEditionImage()} alt="UAUBox Default Products" />
      </div>

      <TextField
        select
        label="Selecione um plano"
        margin="dense"
        fullWidth
        variant="outlined"
        value={comboPlan}
        style={{ width: '300px' }}
      >
        {planList
          ?.filter((plan) => plan.recurrencePeriod !== 'QUARTERLY')
          .map((item) => (
            <MenuItem
              key={item.id}
              value={item.id}
              onClick={() => {
                onSelectPlan(item);
              }}
            >
              {getPlanTitle(item)}
            </MenuItem>
          ))}
      </TextField>

      {promotionalValue ? (
        <Typography className={classes.boxOnlyPrice}>
          R${parseMoneyValue(promotionalValue)} + frete{' '}
        </Typography>
      ) : null}

      {!promotionalValue && selectedPlan?.id ? (
        <Typography className={classes.boxOnlyPrice}>
          {planInUrlId === '67'
            ? `R$${parseMoneyValue(calculateBoxCost(selectedPlan) / 12)}/mês + frete`
            : `R$${parseMoneyValue(calculateBoxCost(selectedPlan))}/mês + frete`}
        </Typography>
      ) : null}

      <div className={classes.subtotal}>
        <Typography className={classes.subtotalTitle}>PREÇO DO FRETE</Typography>

        <Typography className={classes.subtotalPrice}>
          {
            // eslint-disable-next-line
            getShippingCost() != '0' ? (
              `R$ ${parseMoneyValue(getShippingCost())} / mês`
            ) : (
              <strong>FRETE GRÁTIS</strong>
            )
          }
        </Typography>
      </div>

      <>
        {!!couponDiscount && (
          <div className={classes.subtotal}>
            <Typography className={classes.subtotalTitle}>DESCONTO CUPOM</Typography>
            <Typography className={classes.subtotalPrice}>{showCoupon()}</Typography>
          </div>
        )}
        <Divider style={{ marginBottom: '8px' }} />
        {!isPromotionPlan() && (
          <>
            <div className={classes.couponHolder}>
              <TextField
                label={isEmpty(selectedCoupon) ? 'Cupom de desconto' : selectedCoupon.name}
                margin="dense"
                fullWidth
                variant="outlined"
                value={coupon}
                placeholder="Cupom de desconto"
                onChange={(e) => setCoupon(e.target.value)}
                onKeyDown={handleOnCupomPress}
                disabled={!isEmpty(selectedCoupon)}
                InputProps={{
                  style: { height: '50px', width: '300px' },
                  endAdornment: (
                    <InputAdornment position={'end'}>
                      <Button
                        className={classes.buttonCoupon}
                        onClick={
                          isEmpty(selectedCoupon) ? handleOnValidateCoupon : handleOnClearCoupon
                        }
                        disabled={!coupon}
                      >
                        {isEmpty(selectedCoupon) ? 'Aplicar' : 'Remover'}
                      </Button>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{ shrink: true }}
              />
            </div>
            <Divider style={{ marginTop: '8px' }} />
          </>
        )}
      </>

      <div className={classes.totalContainer}>
        <Typography className={classes.totalName}>Total</Typography>
        <Typography className={classes.totalNum}>
          {selectedPlan?.recurrencePeriod === 'ANNUAL' && `12x de R$ ${totalCost()}`}
          {selectedPlan?.recurrencePeriod === 'BIANNUAL' && `6x de R$ ${totalCost()}`}
          {selectedPlan?.recurrencePeriod !== 'ANNUAL' &&
            selectedPlan?.recurrencePeriod !== 'BIANNUAL' &&
            `12x de R$ ${totalCost()}`}
        </Typography>
      </div>
      {selectedCoupon?.name?.toUpperCase() === 'GERACAOUAU' && (
        <Typography className={classes.totalObs}>
          {(selectedPlan.recurrencePeriod === 'BIANNUAL' ||
            selectedPlan.recurrencePeriod === 'BIANNUAL_INSTALLMENT') &&
            'Você receberá UMA box de presente!'}
          {(selectedPlan.recurrencePeriod === 'ANNUAL' ||
            selectedPlan.recurrencePeriod === 'ANNUAL_INSTALLMENT') &&
            'Você receberá DUAS box de presente!'}
        </Typography>
      )}
      {promotionalShippingCost !== null && selectedCoupon.percentage > 0.1 && (
        <Typography className={classes.totalObs}>
          ATENÇÃO: Promoção Frete Grátis desativada pois não é acumulativa para cupons com desconto
          acima de 10%.
        </Typography>
      )}
      {promotionalValue ? getMessagePromotional() : null}
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Button
          className={classes.btnSignature}
          color="primary"
          variant="contained"
          type="submit"
          disabled={loading || isLoading}
          onClick={() => goToCheckout()}
        >
          {isLoading ? (
            <CircularProgress size={20} thickness={5} color="white" />
          ) : (
            'Concluir assinatura'
          )}
        </Button>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  const {
    auth: { tenantName },
    registration: {
      billingAddress,
      loading,
      planList,
      selectedPlan,
      shippingCost,
      promotionalShippingCost,
      selectedCoupon,
    },
    profile: { customer },
    subscription: { subscription },
  } = state;
  return {
    loading,
    subscription,
    tenantName,
    shippingCost,
    promotionalShippingCost,
    billingAddress,
    selectedCoupon,
    planList,
    selectedPlan,
    customer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    navigateTo: (path) => dispatch(push(path)),
    onFetchMainAdress: () => dispatch(fetchMainAddress()),
    onFetchShippingCost: (billingAddress) => dispatch(fetchShippingCost(billingAddress)),
    onCreateVindiSubscription: (cardDetails, mediaTracking, specialBoxEdition) =>
      dispatch(createVindiSubscription(cardDetails, mediaTracking, specialBoxEdition)),
    onCreateStripeSubscription: (
      subscription,
      cardDetails,
      planId,
      paymentMethod,
      coupon,
      couponDiscount,
      mediaTracking,
      specialBoxEdition,
      customer
    ) => {
      dispatch(
        createStripeSubscription(
          subscription,
          cardDetails,
          planId,
          paymentMethod,
          coupon,
          couponDiscount,
          mediaTracking,
          specialBoxEdition,
          customer
        )
      );
    },
    onClearCoupon: () => dispatch(clearCoupon()),
    onFetchCoupon: (planId, coupon, cpf, email) =>
      dispatch(fetchCoupon(planId, coupon, cpf, email)),
    onFetchCouponPlanAvailable: (planId, coupon, cpf, email) =>
      dispatch(fetchCouponPlanAvailable(planId, coupon, cpf, email)),
    onFetchPlanList: () => dispatch(fetchPlanList()),
    onFetchSubscriptionDetails: () => dispatch(fetchSubscriptionDetails()),
    onFetchProfileDetails: () => dispatch(fetchProfileDetails()),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'CheckoutForm',
    enableReinitialize: true,
    onSubmitFail: (errors) => scrollToFirstError(errors),
  })
)(ToCheckout);
