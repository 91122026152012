import * as fromTypes from '../types/pixExtract';

export const fetchPixExtract = (): fromTypes.PixExtractAction => ({
  type: fromTypes.FETCH_PIX_EXTRACT,
});

export const fetchPixExtractSuccess = (payload: any): fromTypes.PixExtractAction => ({
  type: fromTypes.FETCH_PIX_EXTRACT_SUCCESS,
  payload,
});

export const fetchPixExtractError = (payload: any): fromTypes.PixExtractAction => ({
  type: fromTypes.FETCH_PIX_EXTRACT_ERROR,
  payload,
});
