/*eslint no-mixed-operators: "error"*/
/* eslint-disable */
import React, { useEffect, useState } from 'react';
import './checkout.style.js';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { push } from 'react-router-redux';
import { CheckoutCriteo } from '../../components/utils/Criteo';
import { KdtEvent, KdtSetCustumerId } from '../../components/utils/Konduto';
import { isEmpty, isEqual } from 'lodash';
import { scrollToFirstError } from '../../components/utils/errorScrolling';
import {
  fetchMainAddress,
  fetchShippingCost,
  createVindiSubscription,
  createStripeSubscription,
  fetchPlanList,
  selectPlan,
  clearCoupon,
  fetchCoupon,
  fetchCouponPlanAvailable,
  fetchSubscriptionDetails,
  fetchProfileDetails,
} from '../../store/actions';
import { getEmailToken } from '../../store/services/auth';
import Loading from '../../components/Loading';
import Chat from '../../components/utils/chat';
import useStyles from './checkout.style.js';
import AddressCheckout from './sections/AddressSection.jsx';
import PaymentSection from './sections/PaymentSection.jsx';
import OrderSummary from './sections/OrderSummary.jsx';
import Promotional from './sections/Promotional.jsx';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import Axios from 'axios';
import dataLayerEvents from '../../components/utils/dataLayerEvents.js';

const STRIPE = process.env.REACT_APP_STRIPE_SECRET;
const BASE_API = `${process.env.REACT_APP_BASE_API_ADDRESS}/customer-area`;

const stripePromise = loadStripe(STRIPE);

const Checkout = ({
  handleSubmit,
  onFetchMainAdress,
  onFetchShippingCost,
  onFetchPlanList,
  onFetchSubscriptionDetails,
  onSelectPlan,
  selectedPlan,
  onFetchCoupon,
  onFetchCouponPlanAvailable,
  onFetchProfileDetails,
  onCreateVindiSubscription,
  onCreateStripeSubscription,
  navigateTo,
  loading,
  customer,
  billingAddress,
  tenantName,
  onClearCoupon,
  planList,
  subscription,
  shippingCost,
  promotionalShippingCost,
  selectedCoupon,
  location,
  reset,
}) => {
  const classes = useStyles();
  const [tabSelected, setTabSelected] = useState(0);
  const [options, setOptions] = useState(null);
  const [dataLayerSentEvents, setDataLayerSentEvents] = useState([]);

  const { REACT_APP_PIXEL_REG, REACT_APP_PIXEL_REG_TOKEN } = process.env;

  async function getClientSecret() {
    try {
      const response = await Axios.post(`${BASE_API}/subscriptions/getClientSecret`);
      setOptions(response?.data);
    } catch (err) {
      toast.error(`Ocorreu um erro, tente novamente.`);
      console.error(`ERROR: `);
      console.error(err);
    }
    return null;
  }

  useEffect(() => {
    if (options === null) {
      getClientSecret();
    }
  }, []);

  useEffect(() => {
    onFetchSubscriptionDetails();
  }, [onFetchSubscriptionDetails]);

  useEffect(() => {
    if (!subscription) {
      KdtSetCustumerId(getEmailToken());
      KdtEvent('checkout');

      if (!window.CRITEO_CHECKOUT_PAGE) {
        CheckoutCriteo();
        window.CRITEO_CHECKOUT_PAGE = true;
      }
    }
  }, [subscription, REACT_APP_PIXEL_REG, REACT_APP_PIXEL_REG_TOKEN]);

  useEffect(() => {
    const planIdByRegister = sessionStorage.getItem('planIdByRegister');
    if (planList.length > 0 && planIdByRegister) {
      const plan = planList.filter((x) => x.id === Number(planIdByRegister))[0];
      if (plan) {
        setComboPlan(plan.id);
        setBoxCost(calculateBoxCost(plan));
        onSelectPlan(plan);
        sessionStorage.removeItem('planIdByRegister');
      }
    }
  }, [planList, onSelectPlan]);

  useEffect(() => {
    onFetchProfileDetails();
    onFetchMainAdress();
    onFetchPlanList();
  }, [onFetchMainAdress, onFetchPlanList, onFetchProfileDetails]);

  useEffect(() => {
    if (!isEmpty(billingAddress)) {
      onFetchShippingCost(billingAddress);
    }
  }, [billingAddress, onFetchShippingCost]);

  useEffect(() => {
    if (subscription?.status && subscription?.status !== 'CANCELED') {
      toast.error('Você já possui um plano!');
      return navigateTo('/');
    }
  }, [subscription, navigateTo]);

  useEffect(() => {
    if (!selectedPlan) return;
    if (!shippingCost) return;
    const urlCoupon = new URLSearchParams(location.search).get('c');
    if (!selectedCoupon.name && urlCoupon && urlCoupon !== 'null') return;

    if (dataLayerSentEvents.includes('begin_checkout')) return;
    dataLayerEvents.beginCheckout(selectedPlan, selectedPlan.amount, shippingCost, selectedCoupon);
    setDataLayerSentEvents((dataLayerSentEvents) => [...dataLayerSentEvents, 'begin_checkout']);
  }, [selectedPlan, selectedCoupon, shippingCost]);

  return (
    <Loading isLoading={loading}>
      {options && (
        <Elements stripe={stripePromise} options={options}>
          <Chat />
          <div className={classes.checkoutPage}>
            <div className={classes.checkoutBanner}>
              <h1 className={classes.title}>Falta pouco para assinar!</h1>
            </div>
            <div className={classes.content}>
              <div className={classes.contentLeft}>
                <AddressCheckout billingAddress={billingAddress} navigateTo={navigateTo} />
                <Promotional
                  promotionalShippingCost={promotionalShippingCost}
                  customer={customer}
                  setTabSelected={setTabSelected}
                  location={location}
                />
                <PaymentSection
                  reset={reset}
                  tabSelected={tabSelected}
                  setTabSelected={setTabSelected}
                  tenantName={tenantName}
                />
              </div>
              <div className={classes.contentRight}>
                <div className={classes.contentRightImage}>
                  {isEqual(tenantName, 'uaubox') ? (
                    <img
                      src="https://d2ivdm85owtv7v.cloudfront.net/images/edicoes/uaubox-2023-11.png"
                      alt="UAUBox Default Products"
                    />
                  ) : (
                    <img
                      src="https://d2ivdm85owtv7v.cloudfront.net/images/scarlet/scarlet_box2023.png"
                      alt="Scarlet by UAUBox"
                      id
                    />
                  )}
                </div>
                <OrderSummary
                  tabSelected={tabSelected}
                  planList={planList}
                  onSelectPlan={onSelectPlan}
                  tenantName={tenantName}
                  selectedPlan={selectedPlan}
                  promotionalShippingCost={promotionalShippingCost}
                  shippingCost={shippingCost}
                  selectedCoupon={selectedCoupon}
                  onFetchCoupon={onFetchCoupon}
                  customer={customer}
                  onClearCoupon={onClearCoupon}
                  loading={loading}
                  onFetchCouponPlanAvailable={onFetchCouponPlanAvailable}
                  handleSubmit={handleSubmit}
                  onCreateVindiSubscription={onCreateVindiSubscription}
                  onCreateStripeSubscription={onCreateStripeSubscription}
                />
              </div>
            </div>
          </div>
        </Elements>
      )}
    </Loading>
  );
};

const mapStateToProps = (state) => {
  const {
    auth: { tenantName },
    registration: {
      billingAddress,
      loading,
      planList,
      selectedPlan,
      shippingCost,
      promotionalShippingCost,
      selectedCoupon,
    },
    profile: { customer },
    subscription: { subscription },
  } = state;
  return {
    loading,
    subscription,
    tenantName,
    shippingCost,
    promotionalShippingCost,
    billingAddress,
    selectedCoupon,
    planList,
    selectedPlan,
    customer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    navigateTo: (path) => dispatch(push(path)),
    onFetchMainAdress: () => dispatch(fetchMainAddress()),
    onFetchShippingCost: (billingAddress) => dispatch(fetchShippingCost(billingAddress)),
    onCreateVindiSubscription: (cardDetails, mediaTracking, specialBoxEdition) =>
      dispatch(createVindiSubscription(cardDetails, mediaTracking, specialBoxEdition)),
    onCreateStripeSubscription: (
      subscription,
      cardDetails,
      planId,
      paymentMethod,
      coupon,
      couponDiscount,
      mediaTracking,
      specialBoxEdition,
      customer
    ) => {
      dispatch(
        createStripeSubscription(
          subscription,
          cardDetails,
          planId,
          paymentMethod,
          coupon,
          couponDiscount,
          mediaTracking,
          specialBoxEdition,
          customer
        )
      );
    },
    onClearCoupon: () => dispatch(clearCoupon()),
    onFetchCoupon: (planId, coupon, cpf, email) =>
      dispatch(fetchCoupon(planId, coupon, cpf, email)),
    onFetchCouponPlanAvailable: (planId, coupon, cpf, email) =>
      dispatch(fetchCouponPlanAvailable(planId, coupon, cpf, email)),
    onFetchPlanList: () => dispatch(fetchPlanList()),
    onSelectPlan: (plan) => dispatch(selectPlan(plan)),
    onFetchSubscriptionDetails: () => dispatch(fetchSubscriptionDetails()),
    onFetchProfileDetails: () => dispatch(fetchProfileDetails()),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'CheckoutForm',
    enableReinitialize: true,
    onSubmitFail: (errors) => scrollToFirstError(errors),
  })
)(Checkout);
