import React from 'react';
import InnerHTML from 'dangerously-set-html-content';

const ChatString = `
  <!-- Chat Movidesk -->
  <script type="text/javascript">var mdChatClient="FCCD49B479334C379769469F2800F0A9";</script>
  <script src="https://chat.movidesk.com/Scripts/chat-widget.min.js"></script>
  <!-- Chat do Movidesk fim -->
`;

const Chat = ({ checkout }) => <InnerHTML html={checkout ? ChatString : ''} />;

export default Chat;
