import React, { useState, Fragment } from 'react';
import { Drawer, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Close } from '@material-ui/icons';
import { redOrange, cinzaMaisClaro } from '../../themes/colors';
import MenuItems from './MenuItems/MenuItems';
import { MenuIcon } from '../icons';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  drawerIcon: {
    position: 'absolute',
    top: '1rem',
    zIndex: 999,
    display: 'flex',

    color: redOrange,
    background: 'none',

    '& svg': {
      fill: `${redOrange} !important`,
      stroke: `${redOrange} !important`,
    },
  },
  drawer: {},
  drawerBox: {
    background: cinzaMaisClaro,
    display: 'flex',
    overflowY: 'auto',
    width: '80vw',
    flexDirection: 'column',
    height: '100%',
  },
  draweCloseBox: {
    top: '1rem',
    zIndex: 999,
    display: 'flex',
    color: redOrange,
    background: cinzaMaisClaro,
    '& svg': {
      fill: `${redOrange} !important`,
      stroke: `${redOrange} !important`,
    },
  },
  header: {
    display: 'flex',
    padding: '1rem',
    justifyContent: 'space-between',
    height: '2.5rem',
    alignItems: 'center',
    paddingTop: '2rem',
    paddingBottom: '2rem',
    background: cinzaMaisClaro,
    position: 'absolute',
    width: '100%',
  },
  logoUAU: {
    height: '50px',
    '& img': {
      width: '100%',
      height: '50px',
    },
  },
  body: {
    marginTop: '4rem',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    paddingBottom: '3rem',
  },
}));

const DrawerIcon = ({ icon, className, onClick }) => (
  <div className={className}>
    <IconButton onClick={onClick}>{icon}</IconButton>
  </div>
);

const MobileMenu = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { tenantName } = useSelector((state) => state?.auth);

  return (
    <Fragment>
      <DrawerIcon
        className={classes.drawerIcon}
        icon={<MenuIcon />}
        onClick={() => setOpen(true)}
      />

      <Drawer className={classes.drawer} anchor="left" open={open} onClose={() => setOpen(false)}>
        <div className={classes.drawerBox}>
          <div className={classes.header}>
            <div className={classes.logoUAU}>
              {tenantName === 'scarlet' ? (
                <img
                  src="https://d2ivdm85owtv7v.cloudfront.net/images/scarlet/logo_scarlet_render.png"
                  alt="Scarlet by UAUBox"
                />
              ) : (
                <img
                  src="https://d2ivdm85owtv7v.cloudfront.net/images/uaubox/uau_logo_coral_2.png"
                  alt="UAUBox"
                />
              )}
            </div>
            <div className={classes.close}>
              <DrawerIcon
                className={classes.draweCloseBox}
                icon={<Close />}
                onClick={() => setOpen(false)}
              />
            </div>
          </div>
          <div className={classes.body}>
            <MenuItems />
          </div>
        </div>
      </Drawer>
    </Fragment>
  );
};

export default MobileMenu;
