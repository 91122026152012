// @flow
import * as fromTypes from '../types/subscription';

export const fetchSubscriptionCancelReason = (): fromTypes.SubscriptionAction => ({
  type: fromTypes.FETCH_SUBSCRIPTION_CANCEL_REASON,
});

export const fetchSubscriptionCancelReasonSuccess = (
  payload: any
): fromTypes.SubscriptionAction => ({
  type: fromTypes.FETCH_SUBSCRIPTION_CANCEL_REASON_SUCCESS,
  payload,
});

export const fetchSubscriptionCancelReasonError = (payload: any): fromTypes.SubscriptionAction => ({
  type: fromTypes.FETCH_SUBSCRIPTION_CANCEL_REASON_ERROR,
  payload,
});

export const fetchSubscriptionBillingDate = (): fromTypes.SubscriptionAction => ({
  type: fromTypes.FETCH_SUBSCRIPTION_BILLING_DATE,
});

export const fetchSubscriptionBillingDateSuccess = (
  payload: any
): fromTypes.SubscriptionAction => ({
  type: fromTypes.FETCH_SUBSCRIPTION_BILLING_DATE_SUCCESS,
  payload,
});

export const fetchSubscriptionBillingDateError = (payload: any): fromTypes.SubscriptionAction => ({
  type: fromTypes.FETCH_SUBSCRIPTION_BILLING_DATE_ERROR,
  payload,
});

export const fetchSubscriptionDetails = (): fromTypes.SubscriptionAction => ({
  type: fromTypes.FETCH_SUBSCRIPTION_DETAILS,
});

export const fetchSubscriptionDetailsSuccess = (payload: any): fromTypes.SubscriptionAction => ({
  type: fromTypes.FETCH_SUBSCRIPTION_DETAILS_SUCCESS,
  payload,
});

export const fetchSubscriptionDetailsError = (payload: any): fromTypes.SubscriptionAction => ({
  type: fromTypes.FETCH_SUBSCRIPTION_DETAILS_ERROR,
  payload,
});

export const cancelSubscription = (cancel: object): fromTypes.SubscriptionAction => ({
  type: fromTypes.CANCEL_SUBSCRIPTION,
  cancel,
});

export const cancelSubscriptionSuccess = (payload: any): fromTypes.SubscriptionAction => ({
  type: fromTypes.CANCEL_SUBSCRIPTION_SUCCESS,
  payload,
});

export const cancelSubscriptionError = (payload: any): fromTypes.SubscriptionAction => ({
  type: fromTypes.CANCEL_SUBSCRIPTION_ERROR,
  payload,
});

export const updateSubscriptionBillingDate = (
  dayOfMonth: number
): fromTypes.SubscriptionAction => ({
  type: fromTypes.UPDATE_SUBSCRIPTION_BILLING_DATE,
  dayOfMonth,
});

export const updateSubscriptionBillingDateSuccess = (
  payload: any
): fromTypes.SubscriptionAction => ({
  type: fromTypes.UPDATE_SUBSCRIPTION_BILLING_DATE_SUCCESS,
  payload,
});

export const updateSubscriptionBillingDateError = (payload: any): fromTypes.SubscriptionAction => ({
  type: fromTypes.UPDATE_SUBSCRIPTION_BILLING_DATE_ERROR,
  payload,
});

export const fetchPaymentMethods = (): fromTypes.SubscriptionAction => ({
  type: fromTypes.FETCH_PAYMENT_METHODS,
});

export const fetchPaymentMethodsSuccess = (payload: any): fromTypes.SubscriptionAction => ({
  type: fromTypes.FETCH_PAYMENT_METHODS_SUCCESS,
  payload,
});

export const fetchPaymentMethodsError = (payload: any): fromTypes.SubscriptionAction => ({
  type: fromTypes.FETCH_PAYMENT_METHODS_ERROR,
  payload,
});

export const fetchPaymentProfiles = (): fromTypes.SubscriptionAction => ({
  type: fromTypes.FETCH_PAYMENT_PROFILES,
});

export const fetchPaymentProfilesSuccess = (payload: any): fromTypes.SubscriptionAction => ({
  type: fromTypes.FETCH_PAYMENT_PROFILES_SUCCESS,
  payload,
});

export const fetchPaymentProfilesError = (payload: any): fromTypes.SubscriptionAction => ({
  type: fromTypes.FETCH_PAYMENT_PROFILES_ERROR,
  payload,
});

export const deletePaymentProfile = (id: number): fromTypes.SubscriptionAction => ({
  type: fromTypes.DELETE_PAYMENT_PROFILE,
  id,
});

export const deletePaymentProfileSuccess = (payload: any): fromTypes.SubscriptionAction => ({
  type: fromTypes.DELETE_PAYMENT_PROFILE_SUCCESS,
  payload,
});

export const deletePaymentProfileError = (payload: any): fromTypes.SubscriptionAction => ({
  type: fromTypes.DELETE_PAYMENT_PROFILE_ERROR,
  payload,
});

export const updatePaymentProfile = (profile: Object): fromTypes.SubscriptionAction => ({
  type: fromTypes.UPDATE_PAYMENT_PROFILE,
  profile,
});

export const updatePaymentProfileSuccess = (payload: any): fromTypes.SubscriptionAction => ({
  type: fromTypes.UPDATE_PAYMENT_PROFILE_SUCCESS,
  payload,
});

export const updatePaymentProfileError = (payload: any): fromTypes.SubscriptionAction => ({
  type: fromTypes.UPDATE_PAYMENT_PROFILE_ERROR,
  payload,
});

export const createPaymentProfile = (profile: Object): fromTypes.SubscriptionAction => ({
  type: fromTypes.CREATE_PAYMENT_PROFILE,
  profile,
});

export const createPaymentProfileSuccess = (payload: any): fromTypes.SubscriptionAction => ({
  type: fromTypes.CREATE_PAYMENT_PROFILE_SUCCESS,
  payload,
});

export const createPaymentProfileError = (payload: any): fromTypes.SubscriptionAction => ({
  type: fromTypes.CREATE_PAYMENT_PROFILE_ERROR,
  payload,
});

export const fetchPaymentHistory = (): fromTypes.SubscriptionAction => ({
  type: fromTypes.FETCH_PAYMENT_HISTORY,
});

export const fetchPaymentHistorySuccess = (payload: any): fromTypes.SubscriptionAction => ({
  type: fromTypes.FETCH_PAYMENT_HISTORY_SUCCESS,
  payload,
});

export const fetchPaymentHistoryError = (payload: any): fromTypes.SubscriptionAction => ({
  type: fromTypes.FETCH_PAYMENT_HISTORY_ERROR,
  payload,
});

export const fetchUpgradePlanOption = (): fromTypes.SubscriptionAction => ({
  type: fromTypes.FETCH_UPGRADE_PLAN_OPTION,
});

export const fetchUpgradePlanOptionSuccess = (payload: any): fromTypes.SubscriptionAction => ({
  type: fromTypes.FETCH_UPGRADE_PLAN_OPTION_SUCCESS,
  payload,
});

export const fetchUpgradePlanOptionError = (payload: any): fromTypes.SubscriptionAction => ({
  type: fromTypes.FETCH_UPGRADE_PLAN_OPTION_ERROR,
  payload,
});

export const upgradeSubscriptionPlan = (
  subscriptionId: number,
  upgradePayload: Object,
  profileType: string
): fromTypes.SubscriptionAction => ({
  type: fromTypes.UPGRADE_SUBSCRIPTION_PLAN,
  subscriptionId,
  upgradePayload,
  profileType,
});

export const upgradeSubscriptionPlanSuccess = (payload: any): fromTypes.SubscriptionAction => ({
  type: fromTypes.UPGRADE_SUBSCRIPTION_PLAN_SUCCESS,
  payload,
});

export const upgradeSubscriptionPlanError = (payload: any): fromTypes.SubscriptionAction => ({
  type: fromTypes.UPGRADE_SUBSCRIPTION_PLAN_ERROR,
  payload,
});
