// @flow
import * as fromTypes from '../types/auth';
import { isAuthenticated, getToken, logout } from '../services/auth';

const AuthMiddleware = (store) => (next) => async (action: fromTypes.AuthAction) => {
  if (!isAuthenticated() && getToken()) {
    logout();
  }
  return next(action);
};

export default AuthMiddleware;
