const NODE_ENV = process.env.NODE_ENV;
const isProdEnv = NODE_ENV === 'production';

export const initTaboola = () => {
  const script = document.createElement('script');

  script.src = '//cdn.taboola.com/libtrc/unip/1327929/tfa.js';
  script.async = true;

  document.body.appendChild(script);
};

export const StartCheckoutTaboola = (id) => {
  if (isProdEnv) {
    const script = document.createElement('script');

    script.src = `//cdn.taboola.com/libtrc/unip/${id}/tfa.js`;
    script.async = true;

    if (typeof window !== 'undefined') {
      window._tfa = window._tfa || [];
      window._tfa.push({ notify: 'event', name: 'start_checkout', id: id });
    }
  }
};

export const regPurchaseTaboola = (id) => {
  if (isProdEnv) {
    const script = document.createElement('script');

    script.src = `//cdn.taboola.com/libtrc/unip/${id}/tfa.js`;
    script.async = true;

    document.body.appendChild(script);

    if (typeof window !== 'undefined') {
      window._tfa = window._tfa || [];
      window._tfa.push({ notify: 'event', name: 'make_purchase', id: id });
    }
  }
};
