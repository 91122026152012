// @flow
import React from 'react';
import { FormHelperText } from '@material-ui/core';
import { Rating } from '../material';

type FieldProps = {
  input: Object,
  meta: Object,
  custom: Object,
};

const RenderedTextField = ({ input, meta: { touched, error }, ...custom }: FieldProps) => (
  <div>
    <Rating {...input} {...custom} />
    {touched && !!error ? (
      <FormHelperText error={touched && !!error}>{error}</FormHelperText>
    ) : null}
  </div>
);

export default RenderedTextField;
