// @flow
import * as fromTypes from '../types/registration';

export const createSubscription = (
  mediaTracking: Object,
  specialBoxEdition: string
): fromTypes.RegistrationAction => ({
  type: fromTypes.CREATE_SUBSCRIPTION,
  payload: { mediaTracking, specialBoxEdition },
});

export const createSubscriptionSuccess = (payload: any): fromTypes.RegistrationAction => ({
  type: fromTypes.CREATE_SUBSCRIPTION_SUCCESS,
  payload,
});

export const createSubscriptionError = (payload: any): fromTypes.RegistrationAction => ({
  type: fromTypes.CREATE_SUBSCRIPTION_ERROR,
  payload,
});

export const createVindiSubscription = (
  cardDetails: Object,
  mediaTracking: Object,
  specialBoxEdition: string
): fromTypes.RegistrationAction => ({
  type: fromTypes.CREATE_VINDI_SUBSCRIPTION,
  payload: { cardDetails, mediaTracking, specialBoxEdition },
});

export const createVindiSubscriptionSuccess = (payload: any): fromTypes.RegistrationAction => ({
  type: fromTypes.CREATE_VINDI_SUBSCRIPTION_SUCCESS,
  payload,
});

export const createVindiSubscriptionError = (payload: any): fromTypes.RegistrationAction => ({
  type: fromTypes.CREATE_VINDI_SUBSCRIPTION_ERROR,
  payload,
});

export const createStripeSubscription = (
  subscription: any,
  cardDetails: Object,
  plan: any,
  paymentMethod: String,
  coupon: String,
  couponDiscount: Number,
  mediaTracking: Object,
  specialBoxEdition: String,
  customer: any
): fromTypes.RegistrationAction => ({
  type: fromTypes.CREATE_STRIPE_SUBSCRIPTION,
  payload: {
    subscription,
    cardDetails,
    plan,
    paymentMethod,
    coupon,
    couponDiscount,
    mediaTracking,
    specialBoxEdition,
    customer,
  },
});

export const createStripeSubscriptionSuccess = (payload: any): fromTypes.RegistrationAction => ({
  type: fromTypes.CREATE_STRIPE_SUBSCRIPTION_SUCCESS,
  payload,
});

export const createStripeSubscriptionError = (payload: any): fromTypes.RegistrationAction => ({
  type: fromTypes.CREATE_STRIPE_SUBSCRIPTION_ERROR,
  payload,
});

export const selectPlan = (plan: Object): fromTypes.RegistrationAction => ({
  type: fromTypes.SELECT_PLAN,
  plan,
});

export const selectPlanError = (payload: any): fromTypes.RegistrationAction => ({
  type: fromTypes.SELECT_PLAN_ERROR,
  payload,
});

export const selectPlanSuccess = (payload: any): fromTypes.RegistrationAction => ({
  type: fromTypes.SELECT_PLAN_SUCCESS,
  payload,
});

export const fetchPlanList = (): fromTypes.RegistrationAction => ({
  type: fromTypes.FETCH_PLAN_LIST,
});

export const fetchPlanListError = (payload: any): fromTypes.RegistrationAction => ({
  type: fromTypes.FETCH_PLAN_LIST_ERROR,
  payload,
});

export const fetchPlanListSuccess = (payload: any): fromTypes.RegistrationAction => ({
  type: fromTypes.FETCH_PLAN_LIST_SUCCESS,
  payload,
});

export const fetchMainAddress = (): fromTypes.RegistrationAction => ({
  type: fromTypes.FETCH_MAIN_ADDRESS,
});

export const fetchMainAddressError = (payload: any): fromTypes.RegistrationAction => ({
  type: fromTypes.FETCH_MAIN_ADDRESS_ERROR,
  payload,
});

export const fetchMainAddressSuccess = (payload: any): fromTypes.RegistrationAction => ({
  type: fromTypes.FETCH_MAIN_ADDRESS_SUCCESS,
  payload,
});

export const fetchShippingCost = (shippingAddress: Object): fromTypes.RegistrationAction => ({
  type: fromTypes.FETCH_SHIPPING_COST,
  shippingAddress,
});

export const fetchShippingCostSuccess = (payload: any): fromTypes.RegistrationAction => ({
  type: fromTypes.FETCH_SHIPPING_COST_SUCCESS,
  payload,
});

export const fetchShippingCostError = (payload: any): fromTypes.RegistrationAction => ({
  type: fromTypes.FETCH_SHIPPING_COST_ERROR,
  payload,
});

export const fetchCoupon = (
  planId: string,
  coupon: string,
  cpf: string,
  email: string
): fromTypes.RegistrationAction => ({
  type: fromTypes.FETCH_COUPON,
  planId,
  coupon,
  cpf,
  email,
});

export const fetchCouponSuccess = (payload: any): fromTypes.RegistrationAction => ({
  type: fromTypes.FETCH_COUPON_SUCCESS,
  payload,
});

export const fetchCouponError = (payload: any): fromTypes.RegistrationAction => ({
  type: fromTypes.FETCH_COUPON_ERROR,
  payload,
});

export const fetchCouponPlanAvailable = (
  planId: string,
  coupon: string,
  cpf: string,
  email: string
): fromTypes.RegistrationAction => ({
  type: fromTypes.FETCH_COUPON_PLAN_AVAILABLE,
  planId,
  coupon,
  cpf,
  email,
});

export const clearCoupon = (): fromTypes.RegistrationAction => ({
  type: fromTypes.CLEAR_COUPON,
});

export const fetchAdditionalItemAvailable = (): fromTypes.RegistrationAction => ({
  type: fromTypes.FETCH_ADDITIONAL_ITEM_AVAILABLE,
});

export const fetchAdditionalItemAvailableSuccess = (
  payload: number
): fromTypes.RegistrationAction => ({
  type: fromTypes.FETCH_ADDITIONAL_ITEM_AVAILABLE_SUCCESS,
  payload,
});

export const selectAdditionalItem = (isSelected: boolean): fromTypes.RegistrationAction => ({
  type: fromTypes.SELECT_ADDITIONAL_ITEM,
  isSelected,
});

export const updateRegistrationState = (payload: Object): fromTypes.RegistrationAction => ({
  type: fromTypes.UPDATE_REGISTRATION_STATE,
  payload,
});
