import React, { useEffect } from 'react';
import { Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { fetchProfileAnswers } from '../../../../store/actions';
import { isNil } from 'lodash';
import { black, tundora, pippin, alto } from '../../../../themes/colors';
import Loading from '../../../../components/Loading';
import { BackButton, BackButtonMobile } from '../../../../components/material';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    boxShadow: `1px 3px 6px ${alto}`,
  },
  mainBox: {
    background: pippin,
    padding: '1.5rem 2rem',
  },
  titleBox: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '2rem',
  },
  title: {
    color: black,
    fontSize: '2em',
    padding: '0 2.5rem',
    [theme.breakpoints.up('lg')]: {
      padding: '0 1rem',
    },
  },
  subtitle: {
    color: tundora,
    fontSize: '1.2em',
  },
  contentBox: {
    padding: '2rem',
    minHeight: '100vh',
  },
  cardTitle: {
    color: tundora,
    fontSize: '1.2em',
  },
  cardSubtitle: {
    color: black,
    fontSize: '1.4em',
  },
  card: {
    borderBottom: `1px solid ${alto}`,
    padding: '1rem 0',
    '& button': {
      margin: '0.5rem 0',
      fontSize: '0.8em',
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
}));

export function canShowQuestion(answers, question) {
  let canShow = true;
  if (question.skip) {
    question.skip.forEach((condition) => {
      const lookFor = parseInt(condition.question);
      const questionLookFor = answers.detailedAnswers[lookFor - 1];
      const answer = questionLookFor.answersGrouped[0];
      if (answer) {
        condition.answers.forEach((word) => {
          if (answer.answersInline.includes(word)) {
            canShow = false;
          }
        });
      }
    });
  }
  return canShow;
}

const AnswerCard = ({
  questionId,
  questionTitle,
  answersGrouped,
  answersInline,
  navigateTo,
  number,
}) => {
  const classes = useStyles();
  const [, formattedTitle] = questionTitle.split(' - ');

  return (
    <div className={classes.card}>
      <div style={{ maxWidth: '80%' }}>
        <Typography className={classes.cardTitle} gutterBottom>
          {number} - {formattedTitle}
        </Typography>
        {isNil(answersInline) ? (
          <div>
            {answersGrouped.map((i) => (
              <div key={`id_${i.title}`}>
                <Typography className={classes.cardSubtitle} gutterBottom>
                  <strong>{i.title}: </strong>
                  {i.answersInline}
                </Typography>
              </div>
            ))}
          </div>
        ) : (
          <Typography className={classes.cardSubtitle} gutterBottom>
            {answersInline}
          </Typography>
        )}
      </div>
      <div>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => navigateTo(`/perfil/perfil-de-beleza/${questionId}`)}
        >
          EDITAR
        </Button>
      </div>
    </div>
  );
};

const BeautyProfile = ({ onFetchProfileAnswers, navigateTo, answers, loading }) => {
  const classes = useStyles();
  const showArrow = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  useEffect(() => {
    onFetchProfileAnswers();
  }, [onFetchProfileAnswers]);

  let questionNumber = 0;
  return (
    <Loading isLoading={loading}>
      <div className={classes.root}>
        <BackButtonMobile onClick={() => navigateTo('/perfil')} visible={!showArrow} />
        <div className={classes.mainBox}>
          <div className={classes.titleBox}>
            <BackButton onClick={() => navigateTo('/perfil')} visible={showArrow} />
            <Typography className={classes.title}>Perfil de beleza</Typography>
          </div>
          <Typography className={classes.subtitle} gutterBottom>
            Mantenha suas características e preferências atualizadas para prepararmos a sua caixa.
          </Typography>
        </div>

        <div className={classes.contentBox}>
          {answers?.detailedAnswers?.map((i, idx) => {
            const canShow = canShowQuestion(answers, i);
            if (canShow) {
              questionNumber++;
            }
            return (
              canShow && (
                <AnswerCard
                  key={i.questionId}
                  {...i}
                  navigateTo={navigateTo}
                  number={questionNumber}
                />
              )
            );
          })}
        </div>
      </div>
    </Loading>
  );
};

const mapStateToProps = (state) => {
  const { answers, loading } = state.profile;
  return { answers, loading };
};

const mapDispatchToProps = (dispatch) => ({
  onFetchProfileAnswers: () => dispatch(fetchProfileAnswers()),
  navigateTo: (path) => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BeautyProfile);
