import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Typography, Paper, Divider, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { white, black, redOrange, wildSand, alto, doveGray } from '../../themes/colors';
import { setBeautyProfileAnswer } from '../../store/actions';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '2rem 0',
  },
  title: {
    color: black,
    fontSize: '1.5em',
    fontWeight: 'bold',
  },
  subtitle: {
    color: doveGray,
  },
  cardTitle: {
    color: black,
    fontSize: '1.3em',
    fontWeight: 'bold',
  },
  cardSubtitle: {
    color: black,
  },
  grid: {
    marginTop: '2rem',
  },
  paper: {
    borderRadius: theme.spacing(1),
    background: white,
    border: `1px solid ${alto}`,
    boxShadow: 'none',
    cursor: 'pointer',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  cardContent: {
    padding: theme.spacing(2),
  },
  divider: {
    height: theme.spacing(1),
    borderRadius: theme.spacing(0, 0, 1, 1),
    background: wildSand,
  },
  active: {
    background: redOrange,
  },
}));

const BeautyTextCard = ({ onSetBeautyProfileAnswer, answer }) => {
  const classes = useStyles();
  const [state, setState] = useState({});
  const [selecteds, setSelecteds] = useState({});

  useEffect(() => {
    setState(answer);
    setSelecteds({ [answer.slug]: [] });
  }, [answer]);

  useEffect(() => {
    onSetBeautyProfileAnswer(selecteds);
  }, [selecteds, onSetBeautyProfileAnswer]);

  useEffect(() => {
    if (state && state.options) {
      const newSelecteds = [];
      state.options.map((opt) => (opt.checked ? newSelecteds.push(opt.value) : null));
      const obj = { [answer.slug]: newSelecteds };
      setSelecteds(obj);
    }
  }, [state, answer]);

  const handleChange = (optionId) => {
    const options = state?.options?.map((i) =>
      i.value === optionId ? { ...i, checked: !i.checked } : { ...i, checked: false }
    );
    setState({
      ...state,
      options,
    });
  };

  return (
    <div className={classes.root}>
      <Typography className={classes.title} gutterBottom>
        {state?.title}
      </Typography>
      <Typography className={classes.subtitle}>Escolha uma opção:</Typography>

      <Grid container spacing={2} className={classes.grid}>
        {state?.options?.map((i) => (
          <Grid item xs={12} sm={3} key={i.value}>
            <Paper className={classes.paper} onClick={() => handleChange(i.value)}>
              <div className={classes.cardContent}>
                <Typography className={classes.cardTitle} align="center" gutterBottom>
                  {i?.title}
                </Typography>
                <Typography className={classes.cardSubtitle} align="center" gutterBottom>
                  {i?.subtitle}
                </Typography>
              </div>
              <Divider className={classNames(classes.divider, { [classes.active]: i.checked })} />
            </Paper>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { beautyProfileAnswer } = state.profile;
  return { beautyProfileAnswer };
};

const mapDispatchToProps = (dispatch) => ({
  onSetBeautyProfileAnswer: (answer) => dispatch(setBeautyProfileAnswer(answer)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BeautyTextCard);
