import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';
import { bittersweet, black, redOrange, tundora, white } from './colors';

// Create a theme instance.
const theme = createMuiTheme({
  palette: {
    primary: {
      light: '',
      main: redOrange,
      dark: '',
      contrastText: white,
    },
    secondary: {
      light: '',
      main: black,
      dark: '',
      contrastText: white,
    },
    error: {
      main: red.A400,
    },
    background: {
      default: white,
    },
  },
  typography: {
    fontSize: 12,
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        borderRadius: '2rem',
        '&:hover': {
          backgroundColor: bittersweet,
          color: black,
        },
      },
      containedSecondary: {
        borderRadius: '2rem',
        '&:hover': {
          backgroundColor: tundora,
        },
      },
      outlinedPrimary: {
        borderRadius: '2rem',
        '&:hover': {
          backgroundColor: bittersweet,
          color: white,
        },
      },
      outlinedSecondary: {
        borderRadius: '2rem',
        '&:hover': {
          backgroundColor: tundora,
          color: white,
        },
      },
    },
    MuiRadio: {
      root: {
        padding: 0,
        marginRight: '0.5rem',
        '& svg': {
          height: '1.5em',
          width: '1.5em',
        },
      },
    },
  },
});

export default theme;
