import React from 'react';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { KeyboardArrowLeft } from '@material-ui/icons';
import { white } from '../../themes/colors';

const useStyles = makeStyles((theme) => ({
  drawerIcon: {
    display: 'flex',
    color: theme.palette.primary.main,
    background: white,
    borderRadius: theme.spacing(3),
    border: `1px solid ${theme.palette.primary.main}`,
    width: 'fit-content',
    '& button': {
      margin: '0 !important',
      padding: '0.5rem !important',
    },
    '& svg': {
      fill: `${theme.palette.primary.main} !important`,
      stroke: `${theme.palette.primary.main} !important`,
    },
  },
  hidden: {
    display: 'none',
  },
}));

const BackButton = ({ onClick, visible }) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.drawerIcon, { [classes.hidden]: !visible })}>
      <IconButton onClick={onClick}>
        <KeyboardArrowLeft />
      </IconButton>
    </div>
  );
};

export default BackButton;
