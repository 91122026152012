import React, { useState, useEffect } from 'react';
import {
  Typography,
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  fetchPaymentProfiles,
  deletePaymentProfile,
  updatePaymentProfile,
} from '../../../../store/actions';
import { black, tundora, pippin, alto } from '../../../../themes/colors';
import { getCardIcon } from '../../../../components/utils/cardUtils';
import Loading from '../../../../components/Loading';
import { BackButton, BackButtonMobile } from '../../../../components/material';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    boxShadow: `1px 3px 6px ${alto}`,
    minHeight: '100vh',
  },
  mainBox: {
    background: pippin,
    padding: '1.5rem 2rem',
  },
  titleBox: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '2rem',
  },
  title: {
    color: black,
    fontSize: '2em',
    padding: '0 2.5rem',
    [theme.breakpoints.up('lg')]: {
      padding: '0 1rem',
    },
  },
  subtitle: {
    color: tundora,
    fontSize: '1.2em',
  },
  contentBox: {
    padding: '2rem',
  },
  card: {
    padding: '1rem 0',
    color: tundora,
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  cardHighlight: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    '& svg': {
      width: '1.7rem',
      marginRight: '0.5rem',
    },
    '& p': {
      fontWeight: 'bold',
    },
  },
  contentButtonBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: '1.5rem',
    '& button': {
      padding: '0.5rem 1.5rem',
      margin: '0.5rem 0',
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start',
      flexDirection: 'row',
      '& button': {
        marginLeft: '25%', //grid size
      },
    },
  },
}));

const PaymentDetails = ({
  onFetchPaymentProfiles,
  onDeletePaymentProfile,
  onUpdatePaymentProfile,
  navigateTo,
  paymentProfiles,
  loading,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(null);
  const showArrow = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  useEffect(() => {
    onFetchPaymentProfiles();
  }, [onFetchPaymentProfiles]);

  const handleDelete = () => {
    setOpen(false);
    onDeletePaymentProfile(id);
  };

  const handleOpen = (isOpen, id) => {
    setOpen(isOpen);
    setId(id);
  };

  return (
    <Loading isLoading={loading}>
      <div className={classes.root}>
        <BackButtonMobile onClick={() => navigateTo('/perfil')} visible={!showArrow} />
        <div className={classes.mainBox}>
          <div className={classes.titleBox}>
            <BackButton onClick={() => navigateTo('/perfil')} visible={showArrow} />
            <Typography className={classes.title}>Pagamento</Typography>
          </div>
          <Typography className={classes.subtitle} gutterBottom>
            Revise ou altere sua forma de pagamento
          </Typography>
        </div>

        <div className={classes.contentBox}>
          <Grid container justify="center" spacing={2}>
            <Grid item xs={12} md={3}>
              <Typography className={classes.subtitle} gutterBottom>
                Cartão de crédito
              </Typography>
            </Grid>

            <Grid item xs={12} md={7}>
              {paymentProfiles?.map((i) =>
                i === null || i === undefined ? (
                  <>
                    <Typography className={classes.titleHighlight} gutterBottom>
                      Adicione seu cartão de crédito para as próximas cobranças!
                    </Typography>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => navigateTo(`/perfil/seus-pagamentos/cartao/?&platform=STRIPE`)}
                    >
                      Adicionar
                    </Button>
                  </>
                ) : (
                  <div key={i?.id} className={classes.card}>
                    <div>
                      <div className={classes.cardHighlight}>
                        {/* <Radio
                        color="primary"
                        value={i?.id}
                        checked={i?.isDefault}
                        onChange={() => onUpdatePaymentProfile(i.id)}
                      /> */}
                        {getCardIcon(i?.card?.brand)}
                        <Typography>{`**** **** **** ${i?.card?.lastFour}`}</Typography>
                      </div>
                      <Typography className={classes.titleHighlight} gutterBottom>
                        {`Validade: ${i?.card?.expiresAt}`}
                      </Typography>
                    </div>
                    <div>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() =>
                          navigateTo(
                            `/perfil/seus-pagamentos/cartao/${i.id}?&platform=${i?.platform?.type}`
                          )
                        }
                      >
                        Editar
                      </Button>
                      {/* <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => handleOpen(true, i?.id)}
                    >
                      Excluir
                    </Button> */}
                    </div>
                  </div>
                )
              )}
            </Grid>
            <Grid item xs={12} md={2}></Grid>
          </Grid>
          {/* <div className={classes.contentButtonBox}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigateTo(`/perfil/seus-pagamentos/cartao`)}
            >
              Adicionar novo cartão
            </Button>
          </div> */}
        </div>
        <Dialog
          open={open}
          onClose={() => handleOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{'Deseja excluir este cartão?'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Ao excluir o cartão, você não conseguirá utilizá-lo nos próximos pagamentos. Para
              facilitar o pagamento e envio das próximas Box, é importante deixar sempre uma opção
              válida ativa.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleOpen(false)} variant="outlined" color="secondary">
              Cancelar
            </Button>
            <Button onClick={handleDelete} variant="contained" color="secondary" autoFocus>
              Sim, excluir cartão
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Loading>
  );
};

const mapStateToProps = (state) => {
  const { paymentProfiles, loading } = state.subscription;
  return { paymentProfiles, loading };
};

const mapDispatchToProps = (dispatch) => ({
  onFetchPaymentProfiles: () => dispatch(fetchPaymentProfiles()),
  onDeletePaymentProfile: (id) => dispatch(deletePaymentProfile(id)),
  onUpdatePaymentProfile: (id) => dispatch(updatePaymentProfile({ paymentProfileId: id })),
  navigateTo: (path) => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDetails);
