import { Button, Card, Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { alto, cinzaEscuro, white } from '../../themes/colors';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    width: '100%',
    boxShadow: `1px 3px 6px ${alto}`,
    '& h1': {
      color: cinzaEscuro,
      fontFamily: 'open sans',
      marginTop: '10vh',
      fontWeight: 600,
      fontSize: '2em',
      marginBottom: '2rem',
      [theme.breakpoints.up('sm')]: {
        marginTop: '5vh',
      },
    },
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 auto',
    boxShadow: 'none',
    marginTop: '1vh',
    padding: theme.spacing(2),
    '& h6': {
      wordBreak: 'break-all',
    },
  },
  boxImage: {
    '& img': {
      maxWidth: '100%',
    },
  },
  subtitle: {
    fontFamily: 'open sans',
    fontSize: '1.4rem',
    color: cinzaEscuro,
    marginBottom: '1.2rem',
  },
  description: {
    fontFamily: 'open sans',
    fontSize: '1.2rem',
    color: cinzaEscuro,
    margin: '1rem 0',
    [theme.breakpoints.up('md')]: {
      maxWidth: '70%',
    },
  },
  button: {
    padding: '0.8rem 3rem',
    borderRadius: '2em',
    margin: '2rem auto',
    fontSize: '1rem',
    fontFamily: 'open sans',
    fontWeight: 'bold',
    '&:hover': {
      color: white,
    },
  },
}));

const SignatureConfirmed = () => {
  const classes = useStyles();
  const history = useHistory();

  const { tenantName } = useSelector((state) => state?.auth);

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <Card className={classes.box}>
              <div className={classes.boxImage}>
                <img
                  src="https://d2ivdm85owtv7v.cloudfront.net/images/uaubox/confete.svg"
                  alt="confete"
                />
              </div>

              <Typography variant="h1" align="center">
                Parabéns
              </Typography>

              <Typography className={classes.description} align="center">
                Você está a um passo de fazer parte da Jornada de Autoestima UAU.{' '}
                <strong>O seu pagamento está sendo processado</strong> e, assim que estiver tudo
                certo, você vai receber um <strong>e-mail de confirmação</strong>.
              </Typography>

              {tenantName === 'uaubox' && (
                <Typography
                  className={classes.description}
                  variant="body1"
                  align="center"
                  gutterBottom
                >
                  Ahh, não se esqueça de preencher seu <strong>perfil de beleza</strong>, essa é uma
                  etapa <strong>muito importante</strong> para que você receba todo mês uma{' '}
                  <strong>Box repleta de produtos exclusivos para você</strong>, combinado?!
                </Typography>
              )}

              <Button
                className={classes.button}
                color={tenantName === 'uaubox' ? 'primary' : 'secondary'}
                variant="contained"
                onClick={() => {
                  tenantName === 'uaubox'
                    ? history.push('/perfil/perfil-de-beleza/1')
                    : history.push('/');
                }}
              >
                {tenantName === 'uaubox' ? 'Perfil de beleza' : 'Explorar'}
              </Button>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default SignatureConfirmed;
