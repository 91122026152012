export const KdtEvent = (page_category) =>
  (function () {
    var period = 300;
    var limit = 20 * 1e3;
    var nTry = 0;
    var intervalID = setInterval(function () {
      var clear = limit / period <= ++nTry;
      if (
        typeof window.Konduto !== 'undefined' &&
        typeof window.Konduto.sendEvent !== 'undefined'
      ) {
        window.Konduto.sendEvent('page', page_category);
        clear = true;
      }
      if (clear) {
        clearInterval(intervalID);
      }
    }, period);
  })(page_category);

export const KdtSetCustumerId = (customerID) =>
  (function () {
    var period = 300;
    var limit = 20 * 1e3;
    var nTry = 0;
    var intervalID = setInterval(function () {
      var clear = limit / period <= ++nTry;
      if (
        typeof window.Konduto !== 'undefined' &&
        typeof window.Konduto.setCustomerID !== 'undefined'
      ) {
        window.Konduto.setCustomerID(customerID);
        clear = true;
      }
      if (clear) {
        clearInterval(intervalID);
      }
    }, period);
  })(customerID);
