import * as fromTypes from '../types/pixExtract';

type State = {
  loading: boolean,
  pixExtract: any,
};

export const initialState = {
  loading: false,
  pixExtract: {},
};

const setLoading = (state, loading) => ({ ...state, loading });

const updatePixExtract = (state, { payload }) => ({
  ...state,
  pixExtract: payload,
  loading: false,
});

const reducer = (state: State = initialState, action: fromTypes.PixExtractAction) => {
  switch (action.type) {
    case fromTypes.FETCH_PIX_EXTRACT:
      return setLoading(state, true);
    case fromTypes.FETCH_PIX_EXTRACT_SUCCESS:
      return updatePixExtract(state, action);
    case fromTypes.FETCH_PIX_EXTRACT_ERROR:
      return setLoading(state, false);
    default:
      return state;
  }
};

export default reducer;
