import React, { useEffect } from 'react';
import { Typography, Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { push } from 'react-router-redux';
import { Field, reduxForm } from 'redux-form';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { RenderedTextField } from '../../../../components/wrapped';
import { fetchProfileDetails, updateProfile } from '../../../../store/actions';
import { white, black, tundora, pippin, alto } from '../../../../themes/colors';
import {
  required,
  cpfMask,
  validateCPF,
  minimumAge,
  validateEmail,
  phoneMask,
  minLengthPhone,
  dateMask,
  trim,
  existingEmail,
  minLength2,
} from '../../../../components/utils/validators.js';
import { scrollToFirstError } from '../../../../components/utils/errorScrolling';
import Loading from '../../../../components/Loading';
import { BackButton, BackButtonMobile } from '../../../../components/material';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    boxShadow: `1px 3px 6px ${alto}`,
    minHeight: '100vh',
  },
  contentBox: {
    background: pippin,
    padding: '1.5rem 2rem',
  },
  titleBox: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '2rem',
  },
  title: {
    color: black,
    fontSize: '2em',
    padding: '0 2.5rem',
    [theme.breakpoints.up('lg')]: {
      padding: '0 1rem',
    },
  },
  subtitle: {
    color: tundora,
    fontSize: '1.2em',
  },
  formBox: {
    padding: '2rem',
  },
  avatar: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '2rem',
  },
  avatarBox: {
    display: 'flex',
    width: 'fit-content',
    padding: theme.spacing(1),
    borderRadius: '50%',
    border: `1px solid ${alto}`,
    position: 'relative',
    '& img': {
      width: '10rem',
      height: '10rem',
      borderRadius: '50%',
    },
  },
  editImageIcon: {
    display: 'flex',
    color: theme.palette.primary.main,
    background: white,
    borderRadius: theme.spacing(3),
    border: `1px solid ${theme.palette.primary.main}`,
    width: 'fit-content',
    position: 'absolute',
    top: '95%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    '& button': {
      margin: '0 !important',
      padding: '0.5rem !important',
    },
    '& svg': {
      fill: `${theme.palette.primary.main} !important`,
      stroke: `${theme.palette.primary.main} !important`,
    },
  },
  buttonBox: {
    display: 'flex',
    flexDirection: 'column',
    margin: '2rem 0',
    '& button:first-child': {
      marginBottom: '1rem',
    },
    [theme.breakpoints.up('md')]: {
      margin: '1rem 0',
      flexDirection: 'row',
      '& button': {
        marginRight: theme.spacing(1),
        '&:first-child': {
          marginBottom: '0',
        },
      },
    },
  },
}));

const CustomerForm = ({
  handleSubmit,
  onFetchProfileDetails,
  onUpdateProfile,
  navigateTo,
  loading,
}) => {
  const classes = useStyles();
  const showArrow = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const tablet = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const onSubmit = (customerData) => {
    onUpdateProfile(customerData);
  };

  useEffect(() => {
    onFetchProfileDetails();
  }, [onFetchProfileDetails]);

  return (
    <Loading isLoading={loading}>
      <div className={classes.root}>
        <BackButtonMobile onClick={() => navigateTo('/perfil')} visible={!showArrow} />
        <div className={classes.contentBox}>
          <div className={classes.titleBox}>
            <BackButton onClick={() => navigateTo('/perfil')} visible={showArrow} />
            <Typography className={classes.title}> Perfil de Beleza</Typography>
          </div>
          <Typography className={classes.subtitle} gutterBottom>
            Mantenha seus dados sempre atualizados
          </Typography>
        </div>

        <div className={classes.formBox}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} direction={tablet ? 'row' : 'column-reverse'}>
              <Grid item xs={12} md={8}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Field
                      name="firstName"
                      type="text"
                      component={RenderedTextField}
                      label="Nome"
                      validate={[required]}
                      placeholder="Informe seu primeiro nome"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Field
                      name="lastName"
                      type="text"
                      component={RenderedTextField}
                      label="Sobrenome"
                      validate={[required]}
                      placeholder="Informe seu sobrenome"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Field
                      name="email"
                      type="email"
                      component={RenderedTextField}
                      label="Email"
                      validate={[required, validateEmail]}
                      normalize={trim}
                      placeholder="Informe seu e-mail"
                      disabled
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Field
                      name="cpf"
                      type="text"
                      component={RenderedTextField}
                      label="CPF"
                      validate={[required, validateCPF]}
                      normalize={cpfMask}
                      placeholder="Somente números"
                      disabled
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Field
                      name="birthdate"
                      component={RenderedTextField}
                      label="Data de nascimento"
                      validate={[required, minimumAge]}
                      normalize={dateMask}
                      placeholder="DD/MM/AAAA"
                      disabled
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Field
                      name="telephone"
                      type="text"
                      component={RenderedTextField}
                      label="Telefone"
                      validate={[required, minLengthPhone]}
                      normalize={phoneMask}
                      placeholder="Informe seu telefone"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Field
                      name="instagram"
                      type="text"
                      component={RenderedTextField}
                      label="Instagram"
                      validate={minLength2}
                      normalize={trim}
                      placeholder="Informe seu @ do instagram"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={4}>
                <div className={classes.avatar}>
                  <div className={classes.avatarBox}>
                    <img
                      alt="Profile on Edit"
                      src="https://d2ivdm85owtv7v.cloudfront.net/images/customer-area/people.png"
                    />
                    {/* TODO: precisa implemntar no backnd */}
                    {/* <div className={classes.editImageIcon}>
                      <IconButton onClick={() => {}}>
                        <Edit />
                      </IconButton>
                    </div> */}
                  </div>
                </div>
              </Grid>
            </Grid>

            <div className={classes.buttonBox}>
              <Button variant="contained" color="secondary" type="submit">
                Salvar
              </Button>
              <Button variant="outlined" color="secondary" onClick={() => navigateTo('/perfil')}>
                Voltar
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Loading>
  );
};

const mapStateToProps = (state) => {
  const { loading } = state.profile;
  return { loading };
};

const mapDispatchToProps = (dispatch) => ({
  onFetchProfileDetails: () => dispatch(fetchProfileDetails()),
  onUpdateProfile: (customerData) => dispatch(updateProfile(customerData)),
  navigateTo: (path) => dispatch(push(path)),
});

const asyncValidate = existingEmail;
const shouldAsyncValidate = ({ trigger }) => (trigger === 'blur' ? true : false);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'customerForm',
    asyncValidate,
    asyncBlurFields: ['email'],
    shouldAsyncValidate,
    onSubmitFail: (errors) => scrollToFirstError(errors),
  })
)(CustomerForm);
