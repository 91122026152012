// @flow
import * as fromTypes from '../types/wallet';

type State = {
  loading: boolean,
  loadingPoints: boolean,
  loadingBankData: boolean,
  loadingBankStatement: boolean,
  loadedPoints: boolean,
  loadedBankData: boolean,
  loadedBankStatement: boolean,
  points: Object,
  bank: Object,
  statement: Array,
};

export const initialState = {
  loading: false,
  loadingPoints: false,
  loadingBankData: false,
  loadingBankStatement: false,
  loadedPoints: false,
  loadedBankData: false,
  loadedBankStatement: false,
  points: null,
  bank: null,
  statement: [],
};

const getLoadingField = (type) => {
  let loading = ['loading'];
  if (
    type === fromTypes.FETCH_WALLET_POINTS ||
    type === fromTypes.FETCH_WALLET_POINTS_SUCCESS ||
    type === fromTypes.FETCH_WALLET_POINTS_ERROR
  ) {
    loading = ['loadingPoints', 'loadedPoints'];
  } else if (
    type === fromTypes.FETCH_BANK_DATA ||
    type === fromTypes.FETCH_BANK_DATA_SUCCESS ||
    type === fromTypes.FETCH_BANK_DATA_ERROR
  ) {
    loading = ['loadingBankData', 'loadedBankData'];
  } else if (
    type === fromTypes.FETCH_BANK_STATEMENT ||
    type === fromTypes.FETCH_BANK_STATEMENT_SUCCESS ||
    type === fromTypes.FETCH_BANK_STATEMENT_ERROR
  ) {
    loading = ['loadingBankStatement', 'loadedBankStatement'];
  }
  return loading;
};

const updateState = (state, field, { payload }) => ({
  ...state,
  ...payload,
  [field[0]]: false,
  [field[1]]: true,
});

const setLoading = (state, field, loading) => ({ ...state, [field[0]]: loading });

const reducer = (state: State = initialState, action: fromTypes.PurchaseAction) => {
  switch (action.type) {
    case fromTypes.FETCH_WALLET_POINTS:
    case fromTypes.FETCH_BANK_DATA:
    case fromTypes.FETCH_BANK_STATEMENT:
    case fromTypes.UPDATE_BANK_DATA:
    case fromTypes.REQUEST_WITHDRAWAL:
      return setLoading(state, getLoadingField(action.type), true);
    // success
    case fromTypes.FETCH_WALLET_POINTS_SUCCESS:
    case fromTypes.FETCH_BANK_DATA_SUCCESS:
    case fromTypes.FETCH_BANK_STATEMENT_SUCCESS:
      return updateState(state, getLoadingField(action.type), action);
    case fromTypes.UPDATE_BANK_DATA_SUCCESS:
    case fromTypes.REQUEST_WITHDRAWAL_SUCCESS:
      return setLoading(state, getLoadingField(action.type), false);
    // error
    case fromTypes.FETCH_WALLET_POINTS_ERROR:
    case fromTypes.FETCH_BANK_DATA_ERROR:
    case fromTypes.FETCH_BANK_STATEMENT_ERROR:
    case fromTypes.UPDATE_BANK_DATA_ERROR:
    case fromTypes.REQUEST_WITHDRAWAL_ERROR:
      return setLoading(state, getLoadingField(action.type), false);
    default:
      return state;
  }
};

export default reducer;
