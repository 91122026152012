// @flow
import * as fromTypes from '../types/purchase';

type State = {
  loading: boolean,
  singleProducts: Array,
  additionalProduct: Object,
};

export const initialState = {
  loading: false,
  singleProducts: [],
  additionalProduct: {},
};

const updateState = (state, { payload }) => ({
  ...state,
  ...payload,
  loading: false,
});

const setLoading = (state, loading) => ({ ...state, loading });

const reducer = (state: State = initialState, action: fromTypes.PurchaseAction) => {
  switch (action.type) {
    case fromTypes.FETCH_EXTRA_PRODUCTS:
    case fromTypes.BUY_EXTRA_PRODUCT:
    case fromTypes.BUY_SINGLE_PRODUCT:
    case fromTypes.CANCEL_EXTRA_PRODUCT:
    case fromTypes.SELECT_RETENTION_OFFER:
    case fromTypes.CANCEL_SINGLE_PRODUCT:
      return setLoading(state, true);
    // success
    case fromTypes.FETCH_EXTRA_PRODUCTS_SUCCESS:
      return updateState(state, action);
    // error
    case fromTypes.FETCH_EXTRA_PRODUCTS_ERROR:
    case fromTypes.SELECT_RETENTION_OFFER_SUCCESS:
    case fromTypes.SELECT_RETENTION_OFFER_ERROR:
    case fromTypes.BUY_EXTRA_PRODUCT_SUCCESS:
    case fromTypes.BUY_EXTRA_PRODUCT_ERROR:
    case fromTypes.CANCEL_EXTRA_PRODUCT_SUCCESS:
    case fromTypes.CANCEL_EXTRA_PRODUCT_ERROR:
    case fromTypes.CANCEL_SINGLE_PRODUCT_SUCCESS:
    case fromTypes.CANCEL_SINGLE_PRODUCT_ERROR:
      return setLoading(state, false);
    default:
      return state;
  }
};

export default reducer;
