import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Typography, Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { tundora } from '../../themes/colors';
import { RenderedTextField } from '../../components/wrapped';
import { Field, reduxForm } from 'redux-form';
import { checkToken, confirmResetPassword } from '../../store/actions';
import { required, minLength6 } from '../../components/utils/validators';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    height: '100vh',
    '& h1': {
      fontSize: '2em',
      margin: '2rem 0 1rem',
    },
    '& img': {
      maxHeight: '768px',
      left: 'calc(50% - 341px)',
    },
  },
  mainBox: {
    position: 'relative',
    maxWidth: '25rem',
    margin: 'auto',
  },
  logoUau: {
    display: 'flex',
    justifyContent: 'center',
    margin: '3rem 0',
    position: 'relative',
    '& img': {
      display: 'none',
    },
  },
  imgActive: {
    display: 'block !important',
  },
  formBox: {
    padding: '0 1.5rem',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '1rem',
    '& button': {
      marginTop: theme.spacing(1),
      padding: '1rem',
      fontSize: '1.3em',
      fontWeight: 'bold',
    },
  },
  infoBox: {
    display: 'flex',
    justifyContent: 'center',
    '& div': {
      display: 'flex',
      flexDirection: 'column',
      width: 'fit-content',
    },
    '& button': {
      margin: theme.spacing(1, 0),
    },
    '& p': {
      margin: theme.spacing(1, 0),
      color: tundora,
    },
    '& a': {
      textDecoration: 'none',
      justifyContent: 'center',
      display: 'flex',
    },
  },
  grid: {
    height: '100%',
    overflow: 'hidden',
  },
  subscribeBtn: {
    padding: '0.8rem 1rem',
    fontSize: '1.2em',
  },
}));

const validate = (values) => {
  const errors = {};
  if (values.newPassword && values.newPassword !== values.repeatPassword) {
    errors.repeatPassword = 'Senha não confere';
  }
  return errors;
};

const ResetPassword = ({ handleSubmit, submitting, match }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const highDevice = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const tenant = match?.params?.tenant;
  const token = match?.params?.token;

  const getImages = (type) => {
    let url = '';

    if (type === 'logo') {
      url =
        tenant === 'uaubox'
          ? 'https://d2ivdm85owtv7v.cloudfront.net/images/uaubox/logo_uau_coral.png'
          : 'https://d2ivdm85owtv7v.cloudfront.net/images/scarlet/logo-scarlet.png';
    } else {
      const imageData = {
        uaubox: 'https://d2ivdm85owtv7v.cloudfront.net/images/customer-area/sign_in_image.png',
        scarlet:
          'https://d2ivdm85owtv7v.cloudfront.net/images/customer-area/sign_in_image_scarlet.jpg',
      };
      url = imageData[tenant];
    }
    return url;
  };

  useEffect(() => {
    dispatch(checkToken(token));
  }, [dispatch, token]);

  const onSubmit = ({ newPassword }) => dispatch(confirmResetPassword(newPassword, token, tenant));

  return (
    <div className={classes.root}>
      <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
        <Grid container justify="center" alignItems="center" className={classes.grid}>
          <Grid item xs={12} md={6}>
            <div className={classes.mainBox}>
              <div className={classes.logoUau}>
                <img
                  className={tenant === 'uaubox' ? classes.imgActive : ''}
                  src="https://d2ivdm85owtv7v.cloudfront.net/images/uaubox/logo_uau_coral.png"
                  alt="UAUBox Default"
                />
                <img
                  className={tenant === 'scarlet' ? classes.imgActive : ''}
                  src="https://d2ivdm85owtv7v.cloudfront.net/images/scarlet/logo-scarlet.png"
                  alt="Scarlet by UAUBox"
                />
              </div>
              <Typography variant="h1" align="center">
                Troca da senha
              </Typography>

              <div className={classes.formBox}>
                <Field
                  name="newPassword"
                  type="password"
                  component={RenderedTextField}
                  placeholder="Senha"
                  validate={[required, minLength6]}
                />
                <Field
                  name="repeatPassword"
                  type="password"
                  noPaste
                  component={RenderedTextField}
                  placeholder="Confirmar senha"
                  validate={[required, minLength6]}
                />
                <Button
                  variant="contained"
                  className={classes.loginBtn}
                  color="secondary"
                  type="submit"
                  disabled={submitting}
                >
                  Enviar senha
                </Button>
              </div>
            </div>
          </Grid>

          {highDevice ? (
            <Grid item xs={12} md={6}>
              <img
                src={getImages('image')}
                alt={tenant === 'uaubox' ? 'UAUBox Default' : 'Scarlet by UAUBox'}
              />
            </Grid>
          ) : null}
        </Grid>
      </form>
    </div>
  );
};

export default reduxForm({
  form: 'resetPasswordForm',
  validate,
})(ResetPassword);
