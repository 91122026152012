// error parser
export const getVindiError = (error) =>
  error.errors.map((apiError) => {
    switch (apiError.parameter) {
      case 'card_expiration':
        return 'Data inválida ou cartão vencido.';
      case 'card_number':
        return 'Esse cartão é inválido.';
      case 'payment_company_id':
      case 'payment_company_code':
        return 'Bandeira inválida.';
      case 'card_cvv':
        return 'CVV inválido.';
      case 'holder_name':
        return 'Nome inválido.';
      default:
        return 'Houve um erro inesperado, tente novamente.';
    }
  });
