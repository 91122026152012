import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Typography, Paper, Grid, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { black, tundora, alto, doveGray } from '../../themes/colors';
import { setBeautyProfileAnswer } from '../../store/actions';
import { cloneDeep } from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '2rem 0',
  },
  title: {
    color: black,
    fontSize: '1.5em',
    fontWeight: 'bold',
  },
  subtitle: {
    color: tundora,
  },
  grid: {
    marginTop: '2rem',
  },
  paper: {
    width: 'fit-content',
    margin: 'auto',
    padding: theme.spacing(0.5),
    // boxShadow: 'none',
    boxShadow: `1px 3px 6px ${alto}`,
    cursor: 'pointer',
    '& img': {
      width: '12rem',
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-evenly',
      width: 'auto',
    },
  },
  paperTitle: {
    color: doveGray,
    fontWeight: 'bold',
    fontSize: '1.2em',
  },
  choicesBox: {
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    color: black,
  },
}));

const cloudFrontApi = process.env.REACT_APP_AWS_CLOUDFRONT;

function updateChecked(index, choice, state) {
  const optionsI = state?.options[index]?.choices.map((i) =>
    i.value === choice ? { ...i, checked: true } : i
  );
  const options = state?.options?.map((i, indexI) =>
    index === indexI
      ? {
          ...state.options[index],
          choices: optionsI.map((i) =>
            i.value === choice ? { ...i, checked: true } : { ...i, checked: false }
          ),
          checked: true,
        }
      : i
  );

  return options;
}

const BeautyChoicesCard = ({ onSetBeautyProfileAnswer, answer }) => {
  const classes = useStyles();
  const [state, setState] = useState({});
  const [selecteds, setSelecteds] = useState({});
  const [loadedSelecteds, setLoadedSelecteds] = useState(false);

  useEffect(() => {
    setState(answer);
  }, [answer]);

  useEffect(() => {
    if (!loadedSelecteds && Object.keys(answer).length > 0) {
      if (state && state.options) {
        let newState = cloneDeep(state);
        state.options.map((opt, index) => {
          opt.choices.map((c) => {
            if (c.checked) {
              const options = updateChecked(index, c.value, newState);
              newState = {
                ...newState,
                options,
              };
            }
            return true;
          });
          return true;
        });
        setLoadedSelecteds(true);
        setState(newState);
      }
    }
  }, [state, answer, loadedSelecteds]);

  useEffect(() => {
    onSetBeautyProfileAnswer(selecteds);
  }, [selecteds, onSetBeautyProfileAnswer]);

  useEffect(() => {
    if (state && state.options) {
      let obj = {};
      state.options.map((opt) => {
        const newSelecteds = [];

        if (opt.checked) {
          opt.choices.map((c) => (c.checked ? newSelecteds.push(c.value) : null));
        }
        obj = {
          ...obj,
          [opt.slug]: newSelecteds,
        };
        return true;
      });
      setSelecteds(obj);
    }
  }, [state]);

  const handleChange = (index, choice) => {
    const options = updateChecked(index, choice, state);

    setState({
      ...state,
      options,
    });
  };

  const formatTitle = (title) => {
    if (title === 'Não quero receber') {
      return 'Não';
    } else if (title === 'Sempre') {
      return 'Sim';
    } else {
      return title;
    }
  };

  return (
    <div className={classes.root}>
      <Typography className={classes.title} gutterBottom>
        {state?.title}
      </Typography>
      <Typography className={classes.subtitle}>Pode escolher quantas quiser.</Typography>

      <Grid container spacing={2} className={classes.grid}>
        {state?.options?.map((i, index) => (
          <Grid item xs={12} sm={6} key={i.index}>
            <Paper className={classes.paper}>
              <div>
                <img src={`${cloudFrontApi}/${i.image}`} alt={i.title} />
                <Typography className={classes.paperTitle} align="center">
                  {i?.title}
                </Typography>
              </div>
              <div className={classes.choicesBox}>
                <RadioGroup onChange={(e) => handleChange(index, e.target.value)}>
                  {i?.choices.map((choice) => (
                    <FormControlLabel
                      value={choice.value}
                      control={<Radio color="primary" />}
                      label={formatTitle(choice.title)}
                      checked={choice.checked}
                    />
                  ))}
                </RadioGroup>
              </div>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { beautyProfileAnswer } = state.profile;
  return { beautyProfileAnswer };
};

const mapDispatchToProps = (dispatch) => ({
  onSetBeautyProfileAnswer: (answer) => dispatch(setBeautyProfileAnswer(answer)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BeautyChoicesCard);
