import { all } from 'redux-saga/effects';
import { watchAuth } from './auth';
import { watchDashboard } from './dashboard';
import { watchProfile } from './profile';
import { watchRegistration } from './registration';
import { watchSubscription } from './subscription';
import { watchFace } from './face';
import { watchPurchase } from './purchase';
import { watchNotification } from './notification';
import { watchWallet } from './wallet';
import { watchPixExtract } from './pixExtract';

export default function* rootSaga() {
  yield all([
    watchAuth(),
    watchDashboard(),
    watchProfile(),
    watchRegistration(),
    watchSubscription(),
    watchFace(),
    watchPurchase(),
    watchNotification(),
    watchWallet(),
    watchPixExtract(),
  ]);
}
