import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { white } from '../../themes/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#A01235',
  },
  divHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  divTitle: {
    position: 'relative',
    marginTop: '2rem',
  },
  rubricaImage: {
    position: 'absolute',
    width: '12px',
    marginLeft: '3rem',
    left: 0,
    top: 0,
    [theme.breakpoints.up('md')]: {
      width: '15px',
      marginLeft: '0rem',
    },
  },
  title: {
    color: white,
    fontSize: '0.9rem',
    fontWeight: 800,
    marginLeft: '4rem',
    padding: '0.2rem 0.5rem',
    [theme.breakpoints.up('md')]: {
      width: '15px',
      fontWeight: 800,
      fontSize: '1.2rem',
      marginLeft: '1.4rem',
      marginTop: '1rem',
    },
  },
  header: {
    color: white,
    marginTop: '1rem',
    textAlign: 'center',
    fontSize: '0.9rem',
    fontWeight: 'bold',
    [theme.breakpoints.up('md')]: {
      fontSize: '1rem',
      marginLeft: '0.5rem',
    },
  },
  headerMessage: {
    color: white,
    textAlign: 'center',
    fontSize: '0.8rem',
    fontWeight: 'bold',
    marginTop: '1rem',
    marginBottom: '1rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '1rem',
      marginBottom: '2.5rem',
    },
  },
  color: {
    color: '#ffb8b8',
  },
  divContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  content: {
    width: '60%',
    display: 'flex',
    alignItens: 'center',
    justifyContent: 'space-between',
    margin: ' 1rem auto',
    maxWidth: 250,
    [theme.breakpoints.up('md')]: {
      marginLeft: '0.3rem',
      marginRight: '3rem',
      justifyContent: 'space-around',
    },
  },
  ranking: {
    lineHeight: '1rem',
    color: '#ffb8b8',
    textAlign: 'center',
    fontSize: '0.5rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
      marginLeft: '1rem',
    },
  },
  text: {
    color: white,
    textAlign: 'center',
    fontSize: '0.6rem',
    lineHeight: '1rem',
    fontWeight: 800,
    [theme.breakpoints.up('md')]: {
      marginLeft: '1rem',
      fontSize: '0.7rem',
    },
  },
  value: {
    fontWeight: 600,
    fontSize: '1rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.2rem',
    },
  },
  footer: {
    backgroundColor: '#ffb8b8',
    fontSize: '0.5rem',
    textAlign: 'right',
    color: white,
    marginTop: '1rem',
    padding: '0.8rem',
    [theme.breakpoints.up('md')]: {
      textAlign: 'right',
      fontSize: '0.6rem',
      padding: '1.6rem',
      marginTop: '2rem',
    },
  },
}));

const cloudFrontApi = process.env.REACT_APP_AWS_CLOUDFRONT;

const BannerRanking = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.divHeader}>
        <div className={classes.divTitle}>
          <strong className={classes.title}>INDICOU, ASSINOU, GANHOU!</strong>
        </div>

        <Typography className={classes.header}>
          <strong>Quanto mais amigas você indicar, mais chances de ganhar!</strong>
        </Typography>
        <Typography className={classes.headerMessage}>
          Confira os <span className={classes.color}>prêmios</span> do mês:
        </Typography>
      </div>
      <div className={classes.divContent}>
        <div className={classes.content}>
          <img
            src={`${cloudFrontApi}/images/customer-area/gold.svg`}
            alt="imagem do selo com o primeiro lugar"
          />
          <div>
            <Typography className={classes.ranking}>
              <strong>1º LUGAR</strong>
            </Typography>
            <Typography className={classes.text}>
              <span className={classes.value}>Se torne uma UAUTeam</span> <br />e receba comissão
              <br />+ R$50,00 de Cashback
            </Typography>
          </div>
        </div>
        <div className={classes.content}>
          <img
            src={`${cloudFrontApi}/images/customer-area/silver.svg`}
            alt="imagem do selo com o segundo lugar"
          />
          <div>
            <Typography className={classes.ranking}>
              <strong>2º LUGAR</strong>
            </Typography>
            <Typography className={classes.text}>
              <span className={classes.value}>R$35,00 de Cashback</span>
              <br /> + Cupom de 20% OFF na UAUStore
            </Typography>
          </div>
        </div>
        <div className={classes.content}>
          <img
            src={`${cloudFrontApi}/images/customer-area/bronze.svg`}
            alt="imagem do selo com o terceiro lugar"
          />
          <div>
            <Typography className={classes.ranking}>
              <strong>3º LUGAR</strong>
            </Typography>
            <Typography className={classes.text}>
              <span className={classes.value}>R$15,00 de Cashback</span>
              <br />+ Cupom de 10% OFF na UAUStore
            </Typography>
          </div>
        </div>
      </div>
      <div>
        <Typography className={classes.footer}>
          *Leia os termos do{' '}
          <a
            href="https://legacy.uaubox.com.br/termos-de-uso"
            target="_blank"
            rel="noopener noreferrer"
          >
            rankingUAU
          </a>
        </Typography>
      </div>
    </div>
  );
};

export default BannerRanking;
