import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Loading from '../../components/Loading';
import { useDispatch, useSelector } from 'react-redux';
import PixContainer from '../../components/PixContainer/PixContainer';
import { fetchPixExtract } from '../../store/actions';
import ExtractHistory from '../../components/ExtractHistory/ExtractHistory';

const useStyles = makeStyles((theme) => ({
  main: {
    paddingTop: '44px',
    paddingLeft: '32px',
  },
  title: {
    fontWeight: 500,
    fontSize: '32px',
    color: '#595959',
    textAlign: 'left',
  },
  history: {
    marginTop: '29px',
    paddingLeft: '32px',
    marginBottom: '14px',
  },
  titleHistory: {
    fontSize: '20px',
    color: '#595959',
    textAlign: 'left',
  },
  '@media (max-width: 780px)': {
    title: {
      fontSize: '24px',
    },
    main: {
      paddingTop: '50px',
    },
  },
}));

const PixExtract = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { loading, pixExtract } = useSelector((state) => state.pixExtract);

  let pix = pixExtract?.pixExtract;

  useEffect(() => {
    dispatch(fetchPixExtract());
  }, [dispatch]);

  function getExtractHistory(payments, plan) {
    if (!payments) return;
    //if(payments.length <= 1) return;
    return payments.map((pixPayment, index) => {
      if (payments.length === index + 1) return null;
      return <ExtractHistory extract={pixPayment} index={index + 1} plan={plan} />;
    });
  }

  return (
    <Loading isLoading={loading}>
      <div className={classes.main}>
        <div className={classes.pix}>
          <span className={classes.title}>Seu Extrato</span>
        </div>
      </div>
      <div>
        {pix?.pixData && (
          <PixContainer pix={pix.pixData} plan={pix?.plan} index={pix?.payments?.length} />
        )}
      </div>
      <div className={classes.history}>
        <span className={classes.titleHistory}>Minhas contas pagas</span>
      </div>
      {getExtractHistory(pix?.payments, pix?.plan)}
    </Loading>
  );
};
export default PixExtract;
