import { Grid, MenuItem, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import BannerRanking from '../../components/BannerRanking';
import Loading from '../../components/Loading';
import { fetchReferralRank, fetchReferrals } from '../../store/actions';
import { getTenantToken } from '../../store/services/auth';
import { alto, brancoBranco, cinzaMuitoEscuro, white } from '../../themes/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    boxShadow: `1px 3px 6px ${alto}`,
    minHeight: '100vh',
  },
  title: {
    color: cinzaMuitoEscuro,
    fontFamily: 'open sans',
    fontWeight: 'bold',
    fontSize: '2.7em',
    marginBottom: '1rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '3.7em',
    },
  },
  subtitle: {
    color: cinzaMuitoEscuro,
    fontSize: '1.7em',
    [theme.breakpoints.up('md')]: {
      fontSize: '2.7em',
    },
  },
  subtitleHighlight: {
    color: white,
    fontSize: '1em',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.5em',
    },
  },
  headerBox: {
    display: 'flex',
    padding: '4rem 0 0 1rem',
    background: 'linear-gradient(90deg, rgba(253,218,45,1) 40%, rgba(255,236,123,1) 100%)',
    [theme.breakpoints.up('md')]: {
      padding: '4rem 0 0 3.5rem',
    },
  },
  contentBox: {
    flex: 1.5,
    [theme.breakpoints.up('md')]: {
      flex: 1,
    },
  },
  imageBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    flex: 1,
    '& img': {
      position: 'relative',
      maxHeight: '150px',
      [theme.breakpoints.up('md')]: {
        maxHeight: '270px',
      },
    },
  },
  containerTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginBottom: '2rem',
  },
  titlePage: {
    color: cinzaMuitoEscuro,
    fontSize: '1.6em',
  },
  previousMonths: {
    width: '100px',
    margin: 0,
  },
  container: {
    marginTop: '1rem',
    padding: '2rem 2rem 4rem',
  },
  cardPodiumTop: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1rem',
    borderRadius: '5px',
    '& img': {
      maxWidth: '100%',
    },
    [theme.breakpoints.up('md')]: {
      padding: '1.5rem',
      justifyContent: 'center',
    },
  },
  cardPodium: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1rem',
    borderRadius: '5px',
    background: '#F9F9F9',
    width: '100%',
  },
  gold: {
    background: '#A01235',
  },
  silver: {
    background: '#ffaabf',
  },
  bronze: {
    background: '#e74c3c',
  },
  topPositions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& img': {
      marginRight: '1rem',
      width: '2.7rem',
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'column',
      '& img': {
        marginRight: '0',
      },
    },
  },
  topPosition: {
    display: 'none',
    margin: 0,
    [theme.breakpoints.up('md')]: {
      display: 'block',
      color: white,
      marginTop: '1rem',
      fontSize: '0.9rem',
    },
  },
  topUser: {
    color: brancoBranco,
    fontSize: '1rem',
    fontWeight: 'bold',
    margin: 0,
  },
  topAmount: {
    color: white,
    fontSize: '1.5rem',
    fontWeight: 'bold',
    margin: 0,
  },
  containerPositionNameTop: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'column',
    },
  },
  containerPositionName: {
    display: 'flex',
    alignItems: 'center',
  },
  badge: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#A01235',
    color: white,
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    marginRight: '1rem',
  },
  name: {
    fontSize: '0.9rem',
    margin: 0,
  },
  amount: {
    marginRight: '1rem',
    fontSize: '1rem',
    margin: 0,
  },
  emptyRank: {
    display: 'block',
    width: '100%',
    textAlign: 'center',
    padding: '1rem',
    background: '#F9F9F9',
    borderRadius: '5px',
  },
  infoRank: {
    marginTop: '2rem',
  },
}));

const Leaderboard = ({
  onFetchReferrals,
  onFetchReferralsRank,
  referralRank,
  loading,
  navigateTo,
}) => {
  const classes = useStyles();
  const urlImages = 'https://d2ivdm85owtv7v.cloudfront.net/images/customer-area';
  const [months, setMonths] = useState(null);
  const [monthSelected, setMonthSelected] = useState('');

  const getTrophyImage = (position) => {
    let trophy = '';
    if (position === 1) trophy = 'gold-trophy.png';
    else if (position === 2) trophy = 'silver-trophy.png';
    else trophy = 'bronze-trophy.png';

    return trophy;
  };

  const getPreviousMonths = (startDate, endDate) => {
    const start = startDate.split('-');
    const end = endDate.split('-');
    const startYear = parseInt(start[0]);
    const endYear = parseInt(end[0]);
    const dates = [];

    for (let i = startYear; i <= endYear; i++) {
      let endMonth = i !== endYear ? 11 : parseInt(end[1]) - 1;
      let startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
      for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
        let month = j + 1;
        let displayMonth = month < 10 ? '0' + month : month;
        dates.push({
          option: [displayMonth, i].join('/'),
          value: [i, displayMonth].join('-'),
        });
      }
    }
    setMonths(dates.reverse());
  };

  useEffect(() => {
    if (monthSelected) {
      onFetchReferralsRank(monthSelected);
    }
  }, [monthSelected, onFetchReferralsRank]);

  useEffect(() => {
    onFetchReferrals();
    onFetchReferralsRank(moment().format('yyyy-MM'));
  }, [onFetchReferrals, onFetchReferralsRank]);

  useEffect(() => {
    if (getTenantToken() !== 'uaubox') {
      navigateTo('/');
    }
  }, [navigateTo]);

  useEffect(() => {
    getPreviousMonths('2021-07-01', moment().format('yyyy-MM-DD'));
  }, []);

  return (
    <Loading isLoading={loading}>
      <div className={classes.root}>
        <BannerRanking />
        <div className={classes.container}>
          <form className={classes.containerTitle}>
            <Typography className={classes.titlePage}>#RankingUAU</Typography>
            {months ? (
              <TextField
                select
                className={classes.previousMonths}
                onChange={(e) => {
                  setMonthSelected(e.target.value);
                }}
                fullWidth
                label="mês/ano"
                margin="dense"
                variant="outlined"
                value={monthSelected}
              >
                {months?.map((month, index) => (
                  <MenuItem key={index} value={month.value}>
                    {month.option}
                  </MenuItem>
                ))}
              </TextField>
            ) : null}
          </form>
          <Grid container spacing={2}>
            {referralRank.map((item) =>
              item.position <= 3 ? (
                <Grid item xs={12} md={4} key={item.position}>
                  <div
                    className={`${classes.cardPodiumTop} ${classes.topPositions}
                    ${
                      item.position === 1
                        ? classes.gold
                        : item.position === 2
                        ? classes.silver
                        : classes.bronze
                    }`}
                  >
                    <div className={classes.containerPositionNameTop}>
                      <img
                        src={`${urlImages}/leaderboard/${getTrophyImage(item.position)}`}
                        alt="Icone do troféu de acordo com a posição"
                      />
                      <Typography className={classes.topPosition} gutterBottom>
                        {item.position}º
                      </Typography>
                      <Typography className={classes.topUser} gutterBottom>
                        {item.firstName} {item.highlight ? ' (você)' : ''}
                      </Typography>
                    </div>
                    <Typography className={classes.topAmount} gutterBottom>
                      {item.rankWeight}
                    </Typography>
                  </div>
                </Grid>
              ) : (
                <Grid item xs={12} md={12} key={item.position}>
                  <div className={classes.cardPodium}>
                    <div className={classes.containerPositionName}>
                      <span className={classes.badge}>{item.position}</span>
                      <span
                        className={`${classes.name} ${item.highlight ? classes.highlight : ''}`}
                      >
                        {item.highlight ? (
                          <strong>{item.firstName} &#40;você&#41;</strong>
                        ) : (
                          item.firstName
                        )}
                      </span>
                    </div>
                    <span className={classes.amount}>{item.amount}</span>
                  </div>
                </Grid>
              )
            )}
            {referralRank.length === 0 ? (
              <div className={classes.emptyRank}>Nenhum registro encontrado!</div>
            ) : null}
          </Grid>
          <div className={classes.infoRank}>* Rank atualizado todos os dias as 6h da manhã.</div>
        </div>
      </div>
    </Loading>
  );
};

const mapStateToProps = (state) => {
  const { referralRank, loading } = state.dashboard;
  return { referralRank, loading };
};

const mapDispatchToProps = (dispatch) => ({
  onFetchReferrals: () => dispatch(fetchReferrals()),
  onFetchReferralsRank: (month) => dispatch(fetchReferralRank(month)),
  navigateTo: (path) => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Leaderboard);
