// @flow
import * as fromTypes from '../types/dashboard';

export const fetchDashboard = (): fromTypes.DashboardAction => ({
  type: fromTypes.FETCH_DASHBOARD,
});

export const fetchDashboardSuccess = (payload: any): fromTypes.DashboardAction => ({
  type: fromTypes.FETCH_DASHBOARD_SUCCESS,
  payload,
});

export const fetchDashboardError = (payload: any): fromTypes.DashboardAction => ({
  type: fromTypes.FETCH_DASHBOARD_ERROR,
  payload,
});

export const fetchPix = (): fromTypes.DashboardAction => ({
  type: fromTypes.FETCH_PIX,
});

export const fetchPixSuccess = (payload: any): fromTypes.DashboardAction => ({
  type: fromTypes.FETCH_PIX_SUCCESS,
  payload,
});

export const fetchPixError = (payload: any): fromTypes.DashboardAction => ({
  type: fromTypes.FETCH_PIX_ERROR,
  payload,
});

export const fetchReferrals = (): fromTypes.DashboardAction => ({
  type: fromTypes.FETCH_REFERRALS,
});

export const fetchReferralsSuccess = (payload: any): fromTypes.DashboardAction => ({
  type: fromTypes.FETCH_REFERRALS_SUCCESS,
  payload,
});

export const fetchReferralsError = (payload: any): fromTypes.DashboardAction => ({
  type: fromTypes.FETCH_REFERRALS_ERROR,
  payload,
});

export const fetchReferralRank = (payload: any): fromTypes.DashboardAction => ({
  type: fromTypes.FETCH_REFERRAL_RANK,
  payload,
});

export const fetchReferralRankSuccess = (payload: any): fromTypes.DashboardAction => ({
  type: fromTypes.FETCH_REFERRAL_RANK_SUCCESS,
  payload,
});

export const fetchReferralRankError = (): fromTypes.DashboardAction => ({
  type: fromTypes.FETCH_REFERRAL_RANK_ERROR,
});

export const fetchBlogFeed = (): fromTypes.DashboardAction => ({
  type: fromTypes.FETCH_BLOG_FEED,
});

export const fetchBlogFeedSuccess = (payload: any): fromTypes.DashboardAction => ({
  type: fromTypes.FETCH_BLOG_FEED_SUCCESS,
  payload,
});

export const fetchBlogFeedError = (payload: any): fromTypes.DashboardAction => ({
  type: fromTypes.FETCH_BLOG_FEED_ERROR,
  payload,
});

export const fetchBlogSearch = (searchTerm: String): fromTypes.DashboardAction => ({
  type: fromTypes.FETCH_BLOG_SEARCH,
  searchTerm,
});

export const fetchBlogSearchSuccess = (payload: any): fromTypes.DashboardAction => ({
  type: fromTypes.FETCH_BLOG_SEARCH_SUCCESS,
  payload,
});

export const fetchBlogSearchError = (payload: any): fromTypes.DashboardAction => ({
  type: fromTypes.FETCH_BLOG_SEARCH_ERROR,
  payload,
});

export const fetchVideoFeed = (): fromTypes.DashboardAction => ({
  type: fromTypes.FETCH_VIDEO_FEED,
});

export const fetchVideoFeedSuccess = (payload: any): fromTypes.DashboardAction => ({
  type: fromTypes.FETCH_VIDEO_FEED_SUCCESS,
  payload,
});

export const fetchVideoFeedError = (payload: any): fromTypes.DashboardAction => ({
  type: fromTypes.FETCH_VIDEO_FEED_ERROR,
  payload,
});

export const fetchVideoSearch = (searchTerm: String): fromTypes.DashboardAction => ({
  type: fromTypes.FETCH_VIDEO_SEARCH,
  searchTerm,
});

export const fetchVideoSearchSuccess = (payload: any): fromTypes.DashboardAction => ({
  type: fromTypes.FETCH_VIDEO_SEARCH_SUCCESS,
  payload,
});

export const fetchVideoSearchError = (payload: any): fromTypes.DashboardAction => ({
  type: fromTypes.FETCH_VIDEO_SEARCH_ERROR,
  payload,
});

export const fetchVideoDetail = (id: String): fromTypes.DashboardAction => ({
  type: fromTypes.FETCH_VIDEO_DETAIL,
  id,
});

export const fetchVideoDetailSuccess = (payload: any): fromTypes.DashboardAction => ({
  type: fromTypes.FETCH_VIDEO_DETAIL_SUCCESS,
  payload,
});

export const fetchVideoDetailError = (payload: any): fromTypes.DashboardAction => ({
  type: fromTypes.FETCH_VIDEO_DETAIL_ERROR,
  payload,
});

export const createMediaTracking = (payload: any): fromTypes.DashboardAction => ({
  type: fromTypes.CREATE_MEDIA_TRACKING,
  payload,
});

export const updateTermsOfUse = (): fromTypes.DashboardAction => ({
  type: fromTypes.UPDATE_TERMS_OF_USE,
});
