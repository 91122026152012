// @flow

export const SIGN_IN = '[USER] SignIn';
export const SIGN_IN_SUCCESS = '[USER] SignIn success';
export const SIGN_IN_ERROR = '[USER] SignIn error';

export const CHECK_TOKEN_RECOVER_PASSWORD = '[USER] Check Token for Recover Password';
export const CHECK_TOKEN_RECOVER_PASSWORD_SUCCESS =
  '[USER] Check Token for Recover Password success';
export const CHECK_TOKEN_RECOVER_PASSWORD_ERROR = '[USER] Check Token for Recover Password error';

export const SIGN_OUT = '[USER] SignOut';
export const SIGN_OUT_SUCCESS = '[USER] SignOut success';
export const SIGN_OUT_ERROR = '[USER] SignOut error';

export const RESET_PASSWORD = '[USER] Reset password';

export const UPDATE_PASSWORD = '[USER] Update password';
export const UPDATE_PASSWORD_SUCCESS = '[USER] Update password success';
export const UPDATE_PASSWORD_ERROR = '[USER] Update password error';

export const CONFIRM_RESET_PASSWORD = '[USER] Confirm Reset Password';
export const CONFIRM_RESET_PASSWORD_SUCCESS = '[USER] Confirm Reset Password success';
export const CONFIRM_RESET_PASSWORD_ERROR = '[USER] Confirm Reset Password error';

export const SET_TENANT_NAME_WITH_TOKEN = '[USER] Set Tenant Name on Redux';
export const SET_TENANT_NAME_WITH_TOKEN_SUCCESS = '[USER] Set Tenant Name on Redux success';
export const SET_TENANT_NAME_WITH_TOKEN_ERROR = '[USER] Set Tenant Name on Redux error';

export const CONFIRM_EMAIL = '[USER] Confirm email';
export const CONFIRM_EMAIL_SUCCESS = '[USER] Confirm email success';
export const CONFIRM_EMAIL_ERROR = '[USER] Confirm email error';

type SignIn = {
  type: typeof SIGN_IN,
  tenant: string,
  payload: object,
};
type SignInSuccess = {
  type: typeof SIGN_IN_SUCCESS,
  payload: any,
};

type SignInError = {
  type: typeof SIGN_IN_ERROR,
  payload: any,
};

type CheckToken = {
  type: typeof CHECK_TOKEN_RECOVER_PASSWORD,
  token: string,
};
type CheckTokenSuccess = {
  type: typeof CHECK_TOKEN_RECOVER_PASSWORD_SUCCESS,
  payload: any,
};

type CheckTokenError = {
  type: typeof CHECK_TOKEN_RECOVER_PASSWORD_ERROR,
  payload: any,
};

type SignOut = {
  type: typeof SIGN_OUT,
};
type SignOutSuccess = {
  type: typeof SIGN_OUT_SUCCESS,
  payload: any,
};

type SignOutError = {
  type: typeof SIGN_OUT_ERROR,
  payload: any,
};

type ResetPassword = {
  type: typeof RESET_PASSWORD,
  email: string,
};

type UpdatePassword = {
  type: typeof UPDATE_PASSWORD,
  password: string,
  newPassword: string,
};
type UpdatePasswordSuccess = {
  type: typeof UPDATE_PASSWORD_SUCCESS,
  payload: any,
};
type UpdatePasswordError = {
  type: typeof UPDATE_PASSWORD_ERROR,
  payload: any,
};

type SetTenantNameWithToken = {
  type: typeof SET_TENANT_NAME_WITH_TOKEN,
  password: string,
  newPassword: string,
};

type SetTenantNameWithTokenSuccess = {
  type: typeof SET_TENANT_NAME_WITH_TOKEN_SUCCESS,
  payload: any,
};

type SetTenantNameWithTokenError = {
  type: typeof SET_TENANT_NAME_WITH_TOKEN_ERROR,
  payload: any,
};

type ConfirmEmail = {
  type: typeof CONFIRM_EMAIL,
  tenant: string,
  token: string,
};

type ConfirmEmailSuccess = {
  type: typeof CONFIRM_EMAIL_SUCCESS,
  payload: any,
};

type ConfirmEmailError = {
  type: typeof CONFIRM_EMAIL_ERROR,
  payload: any,
};

export type AuthAction =
  | SignIn
  | SignInSuccess
  | SignInError
  | CheckToken
  | CheckTokenSuccess
  | CheckTokenError
  | ResetPassword
  | UpdatePassword
  | UpdatePasswordSuccess
  | UpdatePasswordError
  | SignOut
  | SignOutSuccess
  | SignOutError
  | SetTenantNameWithToken
  | SetTenantNameWithTokenSuccess
  | SetTenantNameWithTokenError
  | ConfirmEmail
  | ConfirmEmailSuccess
  | ConfirmEmailError;
