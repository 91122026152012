import { Button, Dialog, Typography, Checkbox, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { animateScroll as scroll } from 'react-scroll';
import Loading from '../../components/Loading';
import RestrictedWallet from '../../components/popups/RestrictedWallet';
import RetentionOffer from '../../components/RetentionOffer/RetentionOffer';
import { fetchBlogFeed, fetchDashboard, fetchPix, setFeedbackSubmit } from '../../store/actions';
import { getRolesRestricted, getTenantToken } from '../../store/services/auth';
import Header from './sections/Header';
import News from './sections/News';
import SideBar from './sections/SideBar';
import { updateTermsOfUse } from '../../store/actions';
import PromoCards from '../../components/PromoCards/PromoCards';
import PixContainer from '../../components/PixContainer/PixContainer';

const {
  REACT_APP_REGISTRATION_WEBSITE_DEFAULT,
  REACT_APP_REGISTRATION_WEBSITE_SCARLET,
} = process.env;

const useStyles = makeStyles((theme) => ({
  blogContainer: {
    padding: '0 2.5rem',
    marginTop: '2.5rem',
  },
  buttonBox: {
    display: 'flex',
    justifyContent: 'center',
    padding: '1rem',
    marginTop: '1.5rem',
    '& button': {
      margin: theme.spacing(1),
    },
  },
  body: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  bodyContainer: {
    width: '100%',
  },
  termsOfUseBox: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    margin: '2rem 0',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      margin: '1rem 0',
    },
    '& img': {
      width: '14px',
      margin: '1rem 0',
      maxWidth: '20px',
    },
  },
  termsOfUseContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '40px',
    paddingTop: '60px',
    position: 'relative',
  },
  termsOfUseBtnBox: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '0.7rem',
    position: 'relative',
  },
  closeBtn: {
    position: 'absolute',
    top: '15px',
    right: '10px',
    padding: '10px',
    borderRadius: '10px',
    transition: '.3s',
    '&:hover': {
      cursor: 'pointer',
      filter: 'brightness(0.8)',
    },
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const [restrictedWallet, setRestrictedWallet] = useState(false);
  const highDevice = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const { feedbackSubmitted } = useSelector((state) => state.profile);
  const {
    customer,
    loading,
    blogFeed,
    retentionOffer,
    subscription,
    termsOfUse,
    pix,
  } = useSelector((state) => state.dashboard);

  const [termsOfUseDialogIsOpen, setTermsOfUseDialogIsOpen] = useState(true);
  const [isChecked, setIsChecked] = useState(false);

  function skipPopUpTermsOfUse() {
    setTermsOfUseDialogIsOpen(false);
  }

  function callUpdateTerms() {
    setTermsOfUseDialogIsOpen(false);
    dispatch(updateTermsOfUse());
  }

  const scrollTop = () => scroll.scrollToTop();

  useEffect(() => {
    scrollTop();
    dispatch(fetchPix());
    dispatch(fetchDashboard());
    dispatch(fetchBlogFeed());
  }, [dispatch]);

  useEffect(() => {
    if (feedbackSubmitted) {
      dispatch(fetchDashboard());
      dispatch(setFeedbackSubmit());
    }
  }, [feedbackSubmitted, dispatch]);

  useEffect(() => {
    if (highDevice) {
      setActiveTab(0);
    }
  }, [highDevice]);

  useEffect(() => {
    const rolesRestricted = getRolesRestricted();
    if (rolesRestricted?.some((role) => role.includes('WALLET'))) {
      setRestrictedWallet(true);
    }
  }, [setRestrictedWallet]);

  const getBlogItems = () => {
    const newsContainer = [];
    if (!loading) {
      for (let i = 0; i < blogFeed.length; i++) {
        newsContainer.push(
          <News item={blogFeed[i]} direction={(i + 1) % 2 !== 0 ? 'row' : 'row-reverse'} key={i} />
        );
      }
    } else {
      for (let i = 0; i < 5; i++) {
        newsContainer.push(
          <News item={null} direction={(i + 1) % 2 !== 0 ? 'row' : 'row-reverse'} key={i} />
        );
      }
    }
    return newsContainer;
  };

  return (
    <Loading isLoading={loading}>
      {restrictedWallet && getTenantToken() === 'uaubox' && <RestrictedWallet />}
      <div
        style={{ display: 'flex', justifContent: 'space-between', height: '100vh' }}
        className={classes.body}
      >
        <div className={classes.bodyContainer}>
          <Header customer={customer} />

          {termsOfUse === false ? (
            <div className={classes.termsOfUseBox}>
              <Dialog open={termsOfUseDialogIsOpen} style={{ zIndex: '9999' }}>
                <div className={classes.termsOfUseContent}>
                  <Typography align="center" gutterBottom>
                    {`Há uma nova versão dos termos de uso desde a última vez que você nos visitou.`}
                  </Typography>
                  <a
                    href={
                      getTenantToken() === 'uaubox'
                        ? `${REACT_APP_REGISTRATION_WEBSITE_DEFAULT}/termos-de-uso`
                        : `${REACT_APP_REGISTRATION_WEBSITE_SCARLET}/termos-de-uso`
                    }
                  >
                    Confira aqui.
                  </a>
                  <FormControlLabel
                    label="Li e concordo com os termos de uso."
                    control={<Checkbox onClick={() => setIsChecked(!isChecked)} />}
                  />
                  <img
                    onClick={() => skipPopUpTermsOfUse()}
                    className={classes.closeBtn}
                    alt="Fechar"
                    src="https://s3.amazonaws.com/static.uaubox.com.br/images/popups/close-button.png"
                  />
                  <div className={classes.termsOfUseBtnBox}>
                    <Button
                      type="button"
                      color="primary"
                      variant="text"
                      onClick={() => callUpdateTerms()}
                      disabled={!isChecked}
                    >
                      ACEITAR
                    </Button>
                  </div>
                </div>
              </Dialog>
            </div>
          ) : null}

          {!highDevice ? (
            <div className={classes.buttonBox}>
              <Button
                variant={activeTab === 0 ? 'contained' : 'text'}
                color={activeTab === 0 ? 'primary' : 'secondary'}
                onClick={() => setActiveTab(0)}
              >
                PRA VOCÊ
              </Button>

              <Button
                variant={activeTab === 1 ? 'contained' : 'text'}
                color={activeTab === 1 ? 'primary' : 'secondary'}
                onClick={() => setActiveTab(1)}
              >
                SUA BOX
              </Button>
            </div>
          ) : null}

          {activeTab === 0 ? (
            <>
              {!!subscription?.status && subscription?.status !== 'CANCELED' ? (
                <>
                  {/* <BannerShop /> */}
                  {pix?.vindiPixId && (
                    <PixContainer pix={pix} plan={subscription.plan} showExtract={true} />
                  )}
                  <PromoCards />
                </>
              ) : null}

              <div>
                <RetentionOffer offer={retentionOffer} />
              </div>

              <div className={classes.blogContainer}>{getBlogItems()}</div>
            </>
          ) : (
            ''
          )}
        </div>

        <SideBar />
      </div>
    </Loading>
  );
};

export default Dashboard;
