import { getEmailToken } from '../../store/services/auth';
import { getAuthCustomerId } from '../../store/services/auth';

const NODE_ENV = process.env.NODE_ENV;
const isProdEnv = NODE_ENV === 'production';

export const initCriteo = () => {
  const script = document.createElement('script');

  script.src = '//dynamic.criteo.com/js/ld/ld.js?a=73577';
  script.async = true;

  document.body.appendChild(script);
};

export const deviceType = () =>
  /iPad/.test(navigator.userAgent)
    ? 't'
    : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent)
    ? 'm'
    : 'd';

export const CheckoutCriteo = () => {
  if (isProdEnv) {
    const email = getEmailToken();
    if (typeof window !== 'undefined' && email) {
      window.criteo_q = window.criteo_q || [];
      window.criteo_q.push(
        { event: 'setAccount', account: 73577 },

        { event: 'setEmail', email: email },

        { event: 'setSiteType', type: deviceType() },

        {
          event: 'viewBasket',
          item: [
            { id: '1', quantity: 1 },
            { id: '2', quantity: 1 },
          ],
        }
      );
    }
  }
};

export const ConversionCriteo = () => {
  if (isProdEnv) {
    const email = getEmailToken();
    const transactionId = getAuthCustomerId();
    if (typeof window !== 'undefined' && email && transactionId) {
      window.criteo_q = window.criteo_q || [];
      window.criteo_q.push(
        { event: 'setAccount', account: 73577 },

        { event: 'setEmail', email: email },

        { event: 'setSiteType', type: deviceType() },

        {
          event: 'trackTransaction',
          id: transactionId,
          item: [
            { id: '1', quantity: 1 },
            { id: '2', quantity: 1 },
          ],
        }
      );
    }
  }
};
