import React from 'react';

const BoxIcon = ({ fill = '#fff', width = '100%', className = '', height = '100%' }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.029 2.53999C11.326 2.37503 11.6602 2.28847 12 2.28847C12.3398 2.28847 12.674 2.37503 12.971 2.53999L20.486 6.71399C20.6418 6.80063 20.7716 6.92735 20.862 7.08102C20.9524 7.23469 21 7.40972 21 7.58799V15.823C20.9999 16.1796 20.9045 16.5298 20.7235 16.8371C20.5426 17.1445 20.2828 17.3978 19.971 17.571L12.971 21.461C12.674 21.626 12.3398 21.7125 12 21.7125C11.6602 21.7125 11.326 21.626 11.029 21.461L4.029 17.571C3.71736 17.3979 3.45763 17.1447 3.27671 16.8376C3.0958 16.5304 3.00026 16.1805 3 15.824V7.58799C2.99999 7.40972 3.04764 7.23469 3.13802 7.08102C3.22839 6.92735 3.3582 6.80063 3.514 6.71399L11.03 2.53999H11.029Z"
      stroke="#717171"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3 7L12 12M12 12L21 7M12 12V22"
      stroke="#717171"
      stroke-width="2"
      stroke-linejoin="round"
    />
    <path
      d="M7.5 9.5L16.5 4.5M6 12.328L9 14"
      stroke="#717171"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default BoxIcon;
