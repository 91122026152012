import React from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'react-router-redux';
import { Avatar, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { white, black, alto } from '../../../../../themes/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    borderBottom: `1px solid ${alto}`,
    paddingBottom: '1.5rem',
  },
  avatar: {
    width: '7rem',
    height: '7rem',
    border: `2px solid ${white}`,
    margin: '1rem',
    background: alto,
  },
  title: {
    color: black,
    fontSize: '1.3em',
    fontWeight: 'bold',
  },
  button: {
    textTransform: 'capitalize',
  },
}));

const UserCard = ({ customer }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const getFullname = () => `${customer.firstName} ${customer.lastName}`;

  return (
    <div className={classes.root}>
      <Avatar
        alt="Avatar"
        src="https://d2ivdm85owtv7v.cloudfront.net/images/customer-area/people.png"
        className={classes.avatar}
      />

      {customer.firstName && (
        <Typography className={classes.title} align="center">
          {getFullname()}
        </Typography>
      )}
      <Button
        className={classes.button}
        variant="text"
        color="primary"
        onClick={() => dispatch(push('/perfil/dados-pessoais'))}
      >
        Editar dados
      </Button>
    </div>
  );
};

export default UserCard;
