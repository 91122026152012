import { makeStyles } from '@material-ui/core/styles';
import { cinzaClaro, redOrange, black, white, scarletColor } from '../../themes/colors';
import { isEqual } from 'lodash';
import { getTenantToken } from '../../store/services/auth';

const useStyles = makeStyles((theme) => ({
  checkoutPage: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  checkoutBanner: {
    height: '100px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: '#fff',
    width: '100%',
    justifyContent: 'center',
    background: scarletColor,
  },

  title: {
    fontFamily: 'open sans',
    fontStyle: 'normal',
    [theme.breakpoints.down(600)]: {
      fontSize: '16px',
    },
  },

  content: {
    width: '100%',
    padding: '50px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '48px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px',
    },
    [theme.breakpoints.down(600)]: {
      padding: '8px',
    },
  },

  contentLeft: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: '700px',
  },

  address: {
    width: '100%',
    fontFamily: 'open sans',
  },

  addressBody: {
    height: '105px',
    background: '#F6F6F6',
    borderRadius: '4px',
    padding: '18px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  addressBodyLeft: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },

  addressBodyRight: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },

  paymentMethods: {
    width: '100%',
    marginTop: '56px',
  },

  openSans: {
    fontFamily: 'open sans',
  },

  subtitleCheckout: {
    fontWeight: '500',
    fontSize: '20px',
    color: black,
  },

  selectedTab: {
    fontFamily: 'open sans',
    color: black,
    fontWeight: '600',
  },

  secondaryTab: {
    fontFamily: 'open sans',
    color: '#BDBDBD',
  },

  giftCode: {
    marginTop: '1rem',
  },

  contentRight: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '350px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      maxWidth: '700px',
      justifyContent: 'space-between',
    },
    [theme.breakpoints.down(600)]: {
      flexDirection: 'column',
    },
  },

  contentRightImage: {
    '& img': {
      maxHeight: '20rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '0 auto',
      [theme.breakpoints.up('sm')]: {
        maxWidth: '100%',
        maxHeight: '20rem',
      },
    },
  },

  orderSummary: {
    width: '100%',
    maxWidth: '300px',
    [theme.breakpoints.down(600)]: {
      maxWidth: '600px',
    },
  },

  btnAddress: {
    margin: 0,
    marginLeft: 'auto',
    borderRadius: '2em',
    fontSize: '0.7rem',
    fontFamily: 'open sans',
    color: isEqual(getTenantToken(), 'uaubox') ? redOrange : black,
  },

  radioGroup: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(1),
  },

  radio: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: theme.spacing(3),
    '& svg': {
      width: '2rem',
    },
    '& label': {
      marginRight: 0,
    },
  },

  subtotal: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '1em',
    marginBottom: '1em',
    color: black,
  },

  subtotalTitle: {
    color: cinzaClaro,
    fontSize: '1em',
    fontFamily: 'open sans',
    padding: theme.spacing(2),
  },

  subtotalPrice: {
    color: cinzaClaro,
    fontSize: '1em',
    fontFamily: 'open sans',
    padding: theme.spacing(2),
    textAlign: 'end',
  },

  subtotalLabel: {
    fontSize: '.7em',
    textAlign: 'end',
  },

  totalContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  totalName: {
    padding: '0px',
    fontWeight: 'bold',
    color: black,
    fontSize: '1.5em',
    fontFamily: 'open sans',
    [theme.breakpoints.up('md')]: {
      fontSize: '2em',
    },
  },

  totalNum: {
    padding: '0px',
    fontWeight: 'bold',
    color: black,
    fontFamily: 'open sans',
    fontSize: '1.5em',
    [theme.breakpoints.up('md')]: {
      fontSize: '2em',
    },
  },

  boxOnlyPrice: {
    display: 'flex',
    padding: theme.spacing(0, 1),
    '& span': {
      textDecoration: 'line-through',
      fontSize: '0.9em',
      marginRight: '0.2rem',
    },
  },

  totalObs: {
    fontFamily: 'open sans',
    fontSize: '1em',
    textAlign: 'justify',
    color: redOrange,
    fontWeight: 'bold',
    padding: theme.spacing(1),
  },

  btnSignature: {
    width: '100%',
    padding: '1rem 2rem',
    fontFamily: 'open sans',
    fontWeight: 'bold',
    marginBottom: '5rem',
    marginTop: '1rem',
    background: isEqual(getTenantToken(), 'uaubox') ? redOrange : black,
    '&:hover': {
      color: white,
      background: isEqual(getTenantToken(), 'uaubox') ? '#FE6F61' : '#3e3e3e',
    },
    '&:active': {
      color: white,
    },
  },
}));

export default useStyles;
