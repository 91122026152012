import { put, takeLatest, select } from 'redux-saga/effects';
import axios from 'axios';
import * as fromTypes from '../types';
import * as fromActions from '../actions';
import { toast } from 'react-toastify';
import { logException, logEvent } from '../../components/utils/GoogleAnalytics';
import { logGoogleAdsTransaction } from '../../components/utils/GoogleAds';
import {
  updateSelectedCoupon,
  parseCardPayload,
  parseSubscription,
  updateAdditionalItem,
} from '../../models/registrationBuilder';
import { setRegistrationDraft } from '../services/registration';
import { getVindiError } from './utils/registration';
import { initialState } from '../reducers/registration';
import { push } from 'react-router-redux';
import { isEmpty } from 'lodash';

import { ConversionCriteo } from '../../components/utils/Criteo';
import { regPurchaseTaboola } from '../../components/utils/Taboola';
import { getTenantToken } from '../services/auth';
import { logTikTokPurchase } from '../../components/utils/tiktok';
import dataLayerEvents from '../../components/utils/dataLayerEvents';

const getRegistrationState = (state) => state;

const BASE_API = `${process.env.REACT_APP_BASE_API_ADDRESS}/customer-area`;
const BASE_API_LEGACY = `${process.env.REACT_APP_BASE_API_ADDRESS}`;
const VINDI_KEY = process.env.REACT_APP_VINDI_KEY;
const VINDI_TOKEN_URL = process.env.REACT_APP_VINDI_TOKEN_URL;
const NODE_ENV = process.env.REACT_APP_NODE_ENV;

export function* fetchMainAddress() {
  try {
    const TENANT = yield select(getTenantToken);
    const response = yield axios.get(`${BASE_API}/${TENANT}/shipping-address/main-address`);

    if (response.status === 200) {
      yield put(fromActions.fetchMainAddressSuccess(response.data));
    }
  } catch (error) {
    console.error(error);
    logException(`fetchShippingAddress: ${error.message}`, false);
    yield put(fromActions.fetchMainAddressError(error));
  }
}

export function* fetchShippingCost({ shippingAddress }) {
  try {
    const TENANT = yield select(getTenantToken);
    const { id } = shippingAddress;

    const response = yield axios.get(`${BASE_API}/shipping/${TENANT}/shipping-cost/${id}`);
    if (response.status === 200) {
      const shippingCost = response.data;
      yield put(fromActions.fetchShippingCostSuccess(shippingCost));
    }
  } catch (error) {
    logException(`Shipping Cost error: ${error.message}`, true);
    yield put(fromActions.fetchShippingCostError(error));
    console.error(error);
  }
}

export function* fetchPlanList() {
  try {
    const TENANT = yield select(getTenantToken);
    const response = yield axios.get(`${BASE_API}/${TENANT}/plans`);

    if (response.status === 200) {
      yield put(fromActions.fetchPlanListSuccess(response.data));
    }
  } catch (error) {
    logException(`Fetch Plan error: ${error.message}`, true);
    yield put(fromActions.fetchPlanListError(error));
    console.error(error);
  }
}

export function* selectPlan({ plan }) {
  try {
    // let state = yield select(getRegistrationState);
    // if (
    //   !isEmpty(state) &&
    //   isEqual(state?.selectedCoupon?.name, 'UAU_35_ANNUAL') &&
    //   !isEqual(plan.recurrencePeriod, 'ANNUAL') &&
    //   !isEqual(plan.recurrencePeriod, 'ANNUAL_INSTALLMENT')
    // ) {
    //   state = updateSelectedCoupon(state, {});
    //   setRegistrationDraft(JSON.stringify(state));
    // }

    yield put(fromActions.selectPlanSuccess(plan));
  } catch (error) {
    console.error(error);
    toast.error('Houve um erro inesperado, tente novamente.');
  }
}

export function* fetchCoupon({ planId, coupon, cpf, email }) {
  try {
    const TENANT = yield select(getTenantToken);
    const state = yield select(getRegistrationState);

    const response = yield axios.get(
      `${BASE_API}/discount/${TENANT}/plans/${planId}/code/${coupon}?email=${window.encodeURIComponent(
        email
      )}&cpf=${cpf}`
    );

    if (response.status === 200) {
      setRegistrationDraft(JSON.stringify(updateSelectedCoupon(state, response.data)));
      yield put(fromActions.fetchCouponSuccess(response.data));
      if (coupon !== 'UAUPRIMEIRO1REAL') {
        toast.success('Cupom adicionado com sucesso.');
      }
    }
  } catch (error) {
    logException(`Fetch Coupon error: ${error.message}`, false);
    console.error(error);
    yield put(fromActions.fetchCouponError(error));

    if (error.message.includes('422')) {
      toast.info('Só é permitido cadastrar um cupom por cpf!');
    } else {
      toast.error('Cupom inválido!');
    }
  }
}

export function* fetchCouponPlanAvailable({ planId, coupon, cpf, email }) {
  try {
    const TENANT = yield select(getTenantToken);
    const state = yield select(getRegistrationState);
    const response = yield axios.get(
      `${BASE_API}/discount/${TENANT}/plans/${planId}/code/${coupon}?email=${window.encodeURIComponent(
        email
      )}&cpf=${cpf}`
    );

    if (response.status === 200) {
      setRegistrationDraft(JSON.stringify(updateSelectedCoupon(state, response.data)));
      yield put(fromActions.fetchCouponSuccess(response.data));
    }
  } catch (error) {
    logException(`Fetch Coupon plan validation error: ${error.message}`, false);
    console.error(error.message);
    if (coupon !== 'UAUPRIMEIRO1REAL') {
      toast.info('Cupom inválido para o plano selecionado.');
    }
  }
}

export function* clearCoupon() {
  try {
    const state = yield select(getRegistrationState);
    setRegistrationDraft(JSON.stringify(updateSelectedCoupon(state, {})));
    yield put(fromActions.fetchCouponSuccess({}));
  } catch (error) {
    console.error('error', error);
  }
}

export function* createStripeSubscription({ payload }) {
  const {
    subscription,
    cardDetails,
    plan,
    paymentMethod,
    coupon,
    couponDiscount,
    mediaTracking,
    specialBoxEdition,
    customer,
  } = payload;

  if (cardDetails?.giftCode) {
    try {
      yield axios.post(`${BASE_API}/gift-subscription-create-stripe/${cardDetails.giftCode}`);
      toast.success('Parabéns, assinatura gift card concluida com sucesso!');
      yield put(push('/dashboard'));
    } catch (error) {
      console.error(error);
      toast.error(
        'Ocorreu algum erro com sua assinatura gift! Por favor, Tente novamente mais tarde.'
      );
    }
    return;
  }

  try {
    yield axios.post(`${BASE_API}/subscriptions/create/stripe`, {
      planId: plan.id,
      paymentMethod,
      coupon,
      mediaTracking: {
        utmSource: mediaTracking?.utm_source,
        utmMedium: mediaTracking?.utm_medium,
        utmCampaign: mediaTracking?.utm_campaign,
      },
      specialBoxEdition,
    });

    try {
      dataLayerEvents.purchase(
        subscription.realPrice,
        subscription.realFreight,
        plan,
        customer,
        coupon,
        couponDiscount
      );
    } catch (error) {
      console.log(error);
    }

    sessionStorage.setItem('subscriptionCreated', true);

    yield put(push('/assinatura-confirmada'));
    toast.success('Parabéns, assinatura concluida com sucesso!');
  } catch (error) {
    console.error(error);
    toast.error(
      'Ocorreu algum erro na criação da sua assinatura. Por favor, Tente novamente mais tarde.'
    );
  }

  return;
}

export function* createVindiSubscription({ payload }) {
  try {
    const { cardDetails } = payload;

    if (cardDetails?.giftCode) {
      yield axios.post(
        `${BASE_API_LEGACY}/legacy/v1/gift-subscription-create/${cardDetails.giftCode}`
      );
      toast.success('Parabéns, assinatura concluida com sucesso!');
      yield put(push('/dashboard'));
    } else {
      const parseCardDetails = parseCardPayload(cardDetails);

      const response = yield axios.post(VINDI_TOKEN_URL, parseCardDetails, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Basic ${window.btoa(VINDI_KEY)}:`,
        },
      });

      if (response.status === 201) {
        const {
          payment_profile: { gateway_token: creditCardToken },
        } = response.data;
        ConversionCriteo();
        yield put(fromActions.createVindiSubscriptionSuccess(creditCardToken));
        yield put(
          fromActions.createSubscription(payload?.mediaTracking, payload?.specialBoxEdition)
        );
      }
    }
  } catch (error) {
    if (error.response.status === 404) {
      toast.error(error.response.message);
    }
    console.error('error', error);
    yield put(fromActions.createVindiSubscriptionError(error));
    logEvent('Checkout Failed', 'checkout.purchasedfailed', 'Purchase Failed');
    const messages = getVindiError(error.response.data);
    messages.map((i) => {
      logEvent('Checkout Failed Error', 'purchasedfailed.error', `Message failed ${i}`);
      return toast.error(i);
    });
  }
}

export function* createSubscription({ payload }) {
  try {
    const { mediaTracking } = payload;
    const { specialBoxEdition } = payload;

    const TENANT = yield select(getTenantToken);
    const state = yield select(getRegistrationState);

    const {
      subscription: { subscription },
    } = state;

    const parsedSubscription = parseSubscription({ ...state, mediaTracking, specialBoxEdition });

    const response = yield axios.post(
      `${BASE_API}/${TENANT}/subscription-create`,
      parsedSubscription
    );

    if (response.status === 200) {
      localStorage.removeItem('subscription-media-tracking');
      const realPrice = Number(state?.registration?.selectedPlan?.amount) / 100;
      const realFreight = Number(state?.registration?.shippingCost);
      const identifier = state?.registration?.selectedPlan?.identifier;
      const coupon = state?.registration?.selectedCoupon?.name;

      dataLayerEvents.purchase(realPrice, realFreight, identifier, coupon);
      logGoogleAdsTransaction(realPrice);
      logTikTokPurchase(realPrice, identifier);

      TENANT === 'uaubox' && sessionStorage.setItem('subscriptionCreated', true);

      if (!subscription) {
        const taboolaId = TENANT === 'uaubox' ? '1327929' : '1395918';

        regPurchaseTaboola(taboolaId);

        if (NODE_ENV === 'production') {
          const script = document.createElement('script');
          script.src = 'https://api.weclever.co/tag/662';
          script.async = true;
          document.body.appendChild(script);
        }
      }

      yield put(fromActions.clearCoupon());
      yield put(fromActions.createSubscriptionSuccess());
      setRegistrationDraft(JSON.stringify(initialState));
      logEvent('Checkout Purchase Success', 'purchasesuccess', 'Purchase Success');
      if (TENANT === 'uaubox') {
        yield put(push('/assinatura-confirmada'));
      } else {
        toast.success('Assinatura realizada com sucesso.');
        yield put(push('/dashboard'));
      }
    }
  } catch (error) {
    if (error.response.status === 406) {
      toast.error('Não foi possível fazer a verificação do seu cartão.');
    } else {
      toast.error(error.response.data);
    }

    yield put(fromActions.createSubscriptionError(error));
    logException(`Create subscription error: ${error}`, true);
    console.error('error', error);
    toast.error('Houve um erro inesperado, tente novamente.');
  }
}

export function* selectAdditionalItem({ isSelected }) {
  try {
    let state = yield select(getRegistrationState);

    if (!isEmpty(state)) {
      const registratationUpdated = updateAdditionalItem(state, isSelected);
      yield put(fromActions.updateRegistrationState(registratationUpdated.registration));
    }
  } catch (error) {
    console.error(error.message);
    toast.error('Houve um erro inesperado, tente novamente.');
  }
}

export function* fetchAdditionalItemAvailable() {
  try {
    const response = yield axios.get(
      `${BASE_API_LEGACY}/legacy/v1/box-orders/additional-product-amount`
    );

    if (response.status === 200) {
      yield put(fromActions.fetchAdditionalItemAvailableSuccess(response.data));
    }
  } catch (error) {
    console.error(error.message);
  }
}

export function* watchRegistration() {
  yield takeLatest(fromTypes.FETCH_PLAN_LIST, fetchPlanList);
  yield takeLatest(fromTypes.FETCH_MAIN_ADDRESS, fetchMainAddress);
  yield takeLatest(fromTypes.SELECT_PLAN, selectPlan);
  yield takeLatest(fromTypes.FETCH_COUPON, fetchCoupon);
  yield takeLatest(fromTypes.FETCH_COUPON_PLAN_AVAILABLE, fetchCouponPlanAvailable);
  yield takeLatest(fromTypes.CLEAR_COUPON, clearCoupon);
  yield takeLatest(fromTypes.FETCH_SHIPPING_COST, fetchShippingCost);
  yield takeLatest(fromTypes.CREATE_VINDI_SUBSCRIPTION, createVindiSubscription);
  yield takeLatest(fromTypes.CREATE_STRIPE_SUBSCRIPTION, createStripeSubscription);
  yield takeLatest(fromTypes.CREATE_SUBSCRIPTION, createSubscription);
  yield takeLatest(fromTypes.FETCH_ADDITIONAL_ITEM_AVAILABLE, fetchAdditionalItemAvailable);
  yield takeLatest(fromTypes.SELECT_ADDITIONAL_ITEM, selectAdditionalItem);
}
