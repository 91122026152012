// @flow
import * as fromTypes from '../types/dashboard';

type State = {
  loading: boolean,
  customer: Object,
  box: Object,
  preferences: Object,
  referrals: Object,
  referralRank: Array,
  blogFeed: Object,
  videoFeed: Object,
  videoDetail: Object,
  blogSearchResult: Object,
  subscription: Object,
  pix?: Object,
};

export const initialState = {
  loading: false,
  customer: {},
  box: {},
  preferences: {},
  referrals: {},
  referralRank: [],
  blogFeed: {},
  videoFeed: {},
  videoDetail: {},
  blogSearchResult: {},
  subscription: {},
  pix: {},
};

const updateState = (state, { payload }) => ({
  ...state,
  ...payload,
  loading: false,
});
const updateReferrals = (state, { payload }) => ({
  ...state,
  referrals: payload,
  loading: false,
});

const updateReferralRank = (state, { payload }) => ({
  ...state,
  referralRank: payload,
  loading: false,
});

const updateBlogFeed = (state, { payload }) => ({
  ...state,
  blogFeed: payload,
  loading: false,
});

const updateBlogSearchResult = (state, { payload }) => ({
  ...state,
  blogSearchResult: payload,
  loading: false,
});

const updateVideoFeed = (state, { payload }) => ({
  ...state,
  videoFeed: payload,
  loading: false,
});

const updateVideoDetail = (state, { payload }) => ({
  ...state,
  videoDetail: payload,
  loading: false,
});

const setLoading = (state, loading) => ({ ...state, loading });

const setMediaTracking = (state, { payload }) => ({
  ...state,
  mediaTracking: payload,
});

const UpdateTermsOfUse = (state) => ({
  ...state,
  loading: false,
});

const updatePix = (state, { payload }) => ({
  ...state,
  pix: payload,
  loading: false,
});

const reducer = (state: State = initialState, action: fromTypes.DashboardAction) => {
  switch (action.type) {
    case fromTypes.FETCH_DASHBOARD:
    case fromTypes.FETCH_REFERRALS:
    case fromTypes.FETCH_REFERRAL_RANK:
    case fromTypes.FETCH_BLOG_FEED:
    case fromTypes.FETCH_BLOG_SEARCH:
    case fromTypes.FETCH_VIDEO_FEED:
    case fromTypes.FETCH_VIDEO_SEARCH:
    case fromTypes.FETCH_VIDEO_DETAIL:
      return setLoading(state, true);
    // success
    case fromTypes.FETCH_DASHBOARD_SUCCESS:
      return updateState(state, action);
    case fromTypes.FETCH_REFERRALS_SUCCESS:
      return updateReferrals(state, action);
    case fromTypes.FETCH_REFERRAL_RANK_SUCCESS:
      return updateReferralRank(state, action);
    case fromTypes.FETCH_BLOG_FEED_SUCCESS:
      return updateBlogFeed(state, action);
    case fromTypes.FETCH_BLOG_SEARCH_SUCCESS:
      return updateBlogSearchResult(state, action);
    case fromTypes.FETCH_VIDEO_FEED_SUCCESS:
      return updateVideoFeed(state, action);
    case fromTypes.FETCH_VIDEO_SEARCH_SUCCESS:
      return updateVideoFeed(state, action);
    case fromTypes.FETCH_VIDEO_DETAIL_SUCCESS:
      return updateVideoDetail(state, action);
    case fromTypes.FETCH_PIX_SUCCESS:
      return updatePix(state, action);
    // error
    case fromTypes.FETCH_DASHBOARD_ERROR:
    case fromTypes.FETCH_REFERRALS_ERROR:
    case fromTypes.FETCH_REFERRAL_RANK_ERROR:
      return setLoading(state, false);
    case fromTypes.CREATE_MEDIA_TRACKING:
      return setMediaTracking(state, action);
    case fromTypes.UPDATE_TERMS_OF_USE:
      return UpdateTermsOfUse(state, action);
    default:
      return state;
  }
};

export default reducer;
