import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FacebookIcon from '@material-ui/icons/Facebook';
import TelegramIcon from '@material-ui/icons/Telegram';
import TwitterIcon from '@material-ui/icons/Twitter';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CopyToClipboard } from '../../components/material';
import { fetchReferrals } from '../../store/actions';
import { getTenantToken } from '../../store/services/auth';
import { redOrange } from '../../themes/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    background: '#f9f9f9',
    padding: '4rem 1rem',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      padding: '2rem',
    },
  },
  title: {
    background: redOrange,
    padding: '0.3rem 0.5rem',
    borderRadius: '4px',
    fontWeight: 700,
    fontSize: '1.2rem',
    color: '#FFF',
    [theme.breakpoints.up('sm')]: {},
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '1rem',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      width: '70%',
    },
  },
  leftAside: {
    width: '100%',
    '& p': {
      marginBottom: '1rem',
    },
    [theme.breakpoints.up('md')]: {
      marginRight: '120px',
      maxWidth: '55%',
    },
  },
  button: {
    background: redOrange,
    borderRadius: '4px',
    color: '#FFF',
    fontWeight: 700,
    padding: '1.1rem 3rem',
    margin: '1rem 0',
    textTransform: 'capitalize',
    position: 'relative',
  },
  svg: {
    position: 'absolute',
    bottom: 15,
    right: 15,
    width: 15,
  },
  rightAside: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    padding: '1rem',
  },
  image: {
    width: '100%',
    maxWidth: 300,
    [theme.breakpoints.down(500)]: {
      display: 'none',
    },
  },
  imageMobile: {
    width: '90%',
    [theme.breakpoints.up(500)]: {
      display: 'none',
    },
  },
  icons: {
    display: 'flex',
    justifyContent: 'center',
    '& a': {
      textDecoration: 'none',
      color: '#4E4E4E',
    },
  },
  iconsBox: {
    maxWidth: 200,
    '& p': {
      textAlign: 'center',
      fontSize: '0.7rem',
      width: '70%',
      margin: 'auto',
      marginBottom: '0.5rem',
    },
  },
  three: {
    width: 15,
  },
  table: {
    borderCollapse: 'collapse',
    width: '100%',
    '& th': {
      background: '#F0F1F4',
    },
    '& th, & td': {
      border: '1px solid #CCCCCC',
      padding: '8px',
      textAlign: 'left',
      verticalAlign: 'middle',
    },
  },
}));

const cloudFrontApi = process.env.REACT_APP_AWS_CLOUDFRONT;
const indicationURL = 'https://uaubox.com.br/?c=';

const BannerCouponLink = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const { referrals } = useSelector((state) => state.dashboard);

  useEffect(() => {
    dispatch(fetchReferrals());
  }, [dispatch]);
  const { tenantName } = useSelector((state) => state?.auth);

  function capitalizeFirstLetter(str) {
    return str?.charAt(0)?.toUpperCase() + str?.slice(1);
  }

  return (
    <div className={classes.root}>
      <Box className={classes.leftAside}>
        <Typography className={classes.title}>
          Convide suas amigas para fazerem parte da {capitalizeFirstLetter(tenantName)}
        </Typography>
        <Typography>
          Ganhe até <strong>100% de desconto</strong> na sua assinatura! Para cada nova assinante
          que você trouxer, você ganha 10% de desconto na sua assinatura cumulativo! Confira abaixo
          como ganhar 100%:
        </Typography>
        <table className={classes.table}>
          <thead className={classes.tableHeader}>
            <tr>
              <th>Indicações com seu cupom</th>
              <th>Desconto na sua próxima fatura</th>
            </tr>
          </thead>
          <tbody className={classes.tableBody}>
            <tr>
              <td>1</td>
              <td>10%</td>
            </tr>
            <tr>
              <td>2</td>
              <td>20%</td>
            </tr>
            <tr>
              <td>3</td>
              <td>30%</td>
            </tr>
            <tr>
              <td>4</td>
              <td>40%</td>
            </tr>
            <tr>
              <td>5</td>
              <td>50%</td>
            </tr>
            <tr>
              <td>6</td>
              <td>60%</td>
            </tr>
            <tr>
              <td>7</td>
              <td>70%</td>
            </tr>
            <tr>
              <td>8</td>
              <td>80%</td>
            </tr>
            <tr>
              <td>9</td>
              <td>90%</td>
            </tr>
            <tr>
              <td>10</td>
              <td>100%</td>
            </tr>
          </tbody>
        </table>
        <Typography style={{ marginTop: '16px' }}>
          E tem mais: todo mundo que você indicar também ganha! Através do uso do seu cupom
          exclusivo, quem assinar ganha 10% OFF na primeira mensalidade.
        </Typography>

        <Typography>Copie o código abaixo e compartilhe com todo mundo!</Typography>

        <Box className={classes.section}>
          <CopyToClipboard title="Link copiado!">
            {({ copy }) => (
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={() =>
                  copy(
                    getTenantToken() === 'uaubox'
                      ? `${indicationURL}${referrals.code}&utm_source=indicacao`
                      : `https://lp.scarletbox.com.br/?c=${referrals.code}&utm_source=indicacao`
                  )
                }
              >
                {referrals.code}
                <img
                  className={classes.svg}
                  src={`${cloudFrontApi}/images/customer-area/coupon-text-icon.svg`}
                  alt="Imagem de um ícone de copiar"
                />
              </Button>
            )}
          </CopyToClipboard>
          <Box className={classes.iconsBox}>
            <Typography>Compartilhe seu cupom nas redes sociais</Typography>
            <Box className={classes.icons}>
              <a
                href={`https://api.whatsapp.com/send?text=${window.encodeURIComponent(
                  `${indicationURL}${referrals.code}`
                )}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                <WhatsAppIcon fontSize="medium" />
              </a>
              <a
                href={`https://t.me/share/url?url=example.org&text=${window.encodeURIComponent(
                  `${indicationURL}${referrals.code}`
                )}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                <TelegramIcon fontSize="medium" />
              </a>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={`https://www.facebook.com/dialog/feed?
                       app_id=1130461460476284&quote=${indicationURL}${referrals.code}
                       &redirect_uri=https://uaubox.com.br&link=https://uaubox.com.br`}
              >
                <FacebookIcon fill="#585858" />
              </a>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={`https://twitter.com/intent/tweet?text=${window.encodeURIComponent(
                  `${indicationURL}${referrals.code}`
                )}`}
              >
                <TwitterIcon fill="#585858" />
              </a>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className={classes.rightAside}>
        <img
          className={classes.image}
          src={`https://d2ivdm85owtv7v.cloudfront.net/images/scarlet/dia_das_mulheres_scarlet.png`}
          alt=""
        />
        <img
          className={classes.imageMobile}
          src={`https://d2ivdm85owtv7v.cloudfront.net/images/scarlet/dia_das_mulheres_scarlet.png`}
          alt=""
        />
      </Box>
    </div>
  );
};

export default BannerCouponLink;
