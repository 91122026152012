import React from 'react';

const FlatBrushIcon = () => (
  <svg
    viewBox="0 0 29.381 29.347"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g transform="matrix(0.966, 0.259, -0.259, 0.966, 6.199, 0)">
      <path
        d="M23.588,21.548,14.643,12.6,10.224,6.681a.583.583,0,0,0-.09-.118l-.9-.9a.59.59,0,0,0-.267-.153.492.492,0,0,0-.03-.251L7.107.707A1.12,1.12,0,0,0,5.779.037L5.755.044c-.282.08-.537.157-.772.232h-.01A6.365,6.365,0,0,0,2.063,1.984L2.047,2l-.011.013-.181.179L1.847,2.2a7.821,7.821,0,0,0-.609.771A9.9,9.9,0,0,0,.015,5.777a1.125,1.125,0,0,0,.691,1.23L5.33,8.862a.5.5,0,0,0,.183.035h.042a.582.582,0,0,0,.157.284l.9.9a.594.594,0,0,0,.1.083l5.936,4.427L21.6,23.539a1.408,1.408,0,1,0,1.991-1.991ZM12.763,14.185l-5.4-4.03a.6.6,0,0,0,.087-.072L10.134,7.4a.607.607,0,0,0,.07-.086l4.03,5.4ZM5.473,8.5.852,6.643a.73.73,0,0,1-.45-.8,5.2,5.2,0,0,1,.311-.979l2.138.976a.2.2,0,1,0,.164-.358L.869,4.5a12.3,12.3,0,0,1,.7-1.331,7.358,7.358,0,0,1,.564-.711l.049-.049L3.823,4.053A.2.2,0,0,0,4.1,3.775L2.468,2.141A5.73,5.73,0,0,1,4.912.714L5.6,2.8a.2.2,0,0,0,.187.135.206.206,0,0,0,.061-.01.2.2,0,0,0,.125-.246L5.287.592c.178-.055.368-.111.574-.17l.02-.005a.727.727,0,0,1,.861.436L8.568,5.4a.1.1,0,0,1-.022.106L7.518,6.538,5.709,8.347h0l-.129.129a.1.1,0,0,1-.107.022Zm.514.405a.2.2,0,0,1,0-.278L7.333,7.279,8.674,5.939a.2.2,0,0,1,.278,0l.894.893.046.062a.2.2,0,0,1-.036.226L7.169,9.807a.2.2,0,0,1-.278,0h0ZM23.311,23.261a1.015,1.015,0,0,1-1.434,0L13.06,14.445l1.434-1.434,8.818,8.816a1.014,1.014,0,0,1,0,1.434Z"
        transform="translate(0 0)"
      />
    </g>
  </svg>
);

export default FlatBrushIcon;
