// @flow
import * as fromTypes from '../types/registration';

type State = {
  loading: boolean,
  planList: Array<Object>,
  selectedPlan: Object,
  shippingCost: Number,
  promotionalShippingCost: number,
  selectedCoupon: Object,
  billingAddress: Object,
  payment: Object,
  numAdditionalItemAvailable: number,
  selectedAdditionalItem: Boolean,
  additionalItem: boolean,
};

export const initialState = {
  loading: false,
  planList: [],
  selectedPlan: {},
  shippingCost: null,
  promotionalShippingCost: null,
  selectedCoupon: {},
  billingAddress: {},
  payment: {},
  numAdditionalItemAvailable: -1,
  selectedAdditionalItem: false,
  additionalItem: false,
};

const setLoading = (state, loading) => ({ ...state, loading });

const updatePaymentToken = (state, { payload }) => ({
  ...state,
  loading: true,
  payment: {
    paymentMethod: 'credit_card',
    cardToken: payload,
  },
});

const updatePlanList = (state, { payload }) => ({
  ...state,
  planList: payload.filter((i) => i.active),
});

const updateAddress = (state, { payload }) => ({
  ...state,
  billingAddress: {
    id: payload.id,
    zipcode: payload.postalCode,
    street: payload.street,
    number: payload.number,
    complement: payload.complement,
    city: payload.city,
    district: payload.district,
    state: payload.state,
    reference: payload.reference,
  },
});

const updateShippingCost = (state, { payload }) => ({
  ...state,
  shippingCost: payload.shippingCost / 100,
  promotionalShippingCost:
    payload.promotionalShippingCost === null ? null : payload.promotionalShippingCost / 100,
});

const updateSelectedCoupon = (state, { payload }) => ({
  ...state,
  selectedCoupon: payload,
});

const updateSelectedPlan = (state, { payload }) => ({
  ...state,
  selectedPlan: payload,
});

const updateNumAdditionalItemAvailable = (state, { payload }) => ({
  ...state,
  numAdditionalItemAvailable: payload,
});

const updateRegistrationState = (state, { payload }) => ({
  ...state,
  selectedAdditionalItem: payload.selectedAdditionalItem,
  additionalItem: payload.selectedAdditionalItem,
});

const reducer = (state: State = initialState, action: fromTypes.RegistrationAction) => {
  switch (action.type) {
    case fromTypes.CREATE_STRIPE_SUBSCRIPTION:
      return setLoading(state, true);
    case fromTypes.CREATE_VINDI_SUBSCRIPTION:
      return setLoading(state, true);
    case fromTypes.FETCH_MAIN_ADDRESS_SUCCESS:
      return updateAddress(state, action);
    case fromTypes.CREATE_VINDI_SUBSCRIPTION_ERROR:
    case fromTypes.CREATE_SUBSCRIPTION_ERROR:
      return setLoading(state, false);
    case fromTypes.CREATE_VINDI_SUBSCRIPTION_SUCCESS:
      return updatePaymentToken(state, action);
    case fromTypes.FETCH_PLAN_LIST_SUCCESS:
      return updatePlanList(state, action);
    case fromTypes.FETCH_SHIPPING_COST_SUCCESS:
      return updateShippingCost(state, action);
    case fromTypes.FETCH_COUPON_SUCCESS:
      return updateSelectedCoupon(state, action);
    case fromTypes.SELECT_PLAN_SUCCESS:
      return updateSelectedPlan(state, action);
    case fromTypes.FETCH_ADDITIONAL_ITEM_AVAILABLE_SUCCESS:
      return updateNumAdditionalItemAvailable(state, action);
    case fromTypes.UPDATE_REGISTRATION_STATE:
      return updateRegistrationState(state, action);
    case fromTypes.CREATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedPlan: {},
      };
    default:
      return state;
  }
};

export default reducer;
