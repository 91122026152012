import moment from 'moment';

export const toForm = ({ firstName, lastName, email, cpf, telephone, birthdate, instagram }) => ({
  firstName,
  lastName,
  email,
  cpf,
  birthdate: birthdate && moment(birthdate, 'YYYY-MM-DD').format('DD/MM/YYYY'),
  telephone,
  instagram,
});
