import * as fromTypes from '../types/face';

export const initialState = {
  data: {},
  image: '',
  feedbacks: [],
  finishedLoading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case fromTypes.FACE_GET_RESULT_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        image: action.payload.image,
      };
    case fromTypes.DELETE_LIKE_SUCCESS:
      return {
        ...state,
      };
    case fromTypes.GET_FEEDBACK_SUCCESS:
      return {
        ...state,
        feedbacks: action.payload.feedbacks,
      };
    case fromTypes.FINISHED_LOADING:
      return {
        ...state,
        finishedLoading: action.payload.finished,
      };
    default:
      return state;
  }
};

export default reducer;
