// @flow

export const FACE_SEND_IMAGE = '[FACE] Send image';
export const FACE_VERIFY_STATUS = '[FACE] Verify status';
export const FACE_GET_RESULT = '[FACE] Get result';
export const FACE_GET_RESULT_SUCCESS = '[FACE] Get result with success';
export const TOGGLE_LIKE = '[FACE] Toggle like';
export const DELETE_LIKE_REQUEST = '[FACE] Delete like';
export const DELETE_LIKE_SUCCESS = '[FACE] Delete like success';
export const GET_FEEDBACK = '[FACE] Get feedbacks';
export const GET_FEEDBACK_SUCCESS = '[FACE] Get feedbacks success';
export const FINISHED_LOADING = '[FACE] Finished loading';

type FaceSendImage = {
  type: typeof FACE_SEND_IMAGE,
};

type FaceVerifyStatus = {
  type: typeof FACE_VERIFY_STATUS,
};

type FaceGetResult = {
  type: typeof FACE_GET_RESULT,
};

type FaceGetResultSuccess = {
  type: typeof FACE_GET_RESULT_SUCCESS,
};

type ToggleLike = {
  type: typeof TOGGLE_LIKE,
};

type DeleteLikeRequest = {
  type: typeof DELETE_LIKE_REQUEST,
};

type DeleteLikeSuccess = {
  type: typeof DELETE_LIKE_SUCCESS,
};

type GetFeedback = {
  type: typeof GET_FEEDBACK,
};

type GetFeedbackSuccess = {
  type: typeof GET_FEEDBACK_SUCCESS,
};

type FinishedLoading = {
  type: typeof FINISHED_LOADING,
};

export type FaceAction =
  | FaceSendImage
  | FaceVerifyStatus
  | FaceGetResult
  | FaceGetResultSuccess
  | ToggleLike
  | DeleteLikeRequest
  | DeleteLikeSuccess
  | GetFeedback
  | GetFeedbackSuccess
  | FinishedLoading;
