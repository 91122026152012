export const parseAddressFromApi = (address) => ({
  postalCode: address.cep,
  street: address.logradouro,
  district: address.bairro,
  city: address.localidade,
  state: address.uf,
  validated: address.validated,
});

export const buildProfilePayload = ({ firstName, lastName, email, telephone, instagram }, id) => ({
  firstName,
  lastName,
  email,
  telephone: telephone.replace(/\D/g, ''),
  instagram,
  id,
});
