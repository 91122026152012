// @flow

export const FETCH_USER_NOTIFICATIONS = '[NOTIFICATION] Fetch user notifications';
export const FETCH_USER_NOTIFICATIONS_SUCCESS = '[NOTIFICATION] Fetch user notifications success';
export const FETCH_USER_NOTIFICATIONS_ERROR = '[NOTIFICATION] Fetch user notifications error';

export const FETCH_USER_NOTIFICATION = '[NOTIFICATION] Fetch user notification';
export const FETCH_USER_NOTIFICATION_SUCCESS = '[NOTIFICATION] Fetch user notification success';
export const FETCH_USER_NOTIFICATION_ERROR = '[NOTIFICATION] Fetch user notification error';

export const MARK_USER_NOTIFICATION_AS_READ = '[NOTIFICATION] Mark user notification as read';
export const MARK_USER_NOTIFICATION_AS_READ_SUCCESS =
  '[NOTIFICATION] Mark user notification as read success';
export const MARK_USER_NOTIFICATION_AS_READ_ERROR =
  '[NOTIFICATION] Mark user notification as read error';

type FetchUserNotifications = {
  type: typeof FETCH_USER_NOTIFICATIONS,
};

type FetchUserNotificationsSuccess = {
  type: typeof FETCH_USER_NOTIFICATIONS_SUCCESS,
  payload: any,
};

type FetchUserNotificationsError = {
  type: typeof FETCH_USER_NOTIFICATIONS_ERROR,
  payload: any,
};

type FetchUserNotification = {
  type: typeof FETCH_USER_NOTIFICATION,
  id: Number,
};

type FetchUserNotificationSuccess = {
  type: typeof FETCH_USER_NOTIFICATION_SUCCESS,
  payload: any,
};

type FetchUserNotificationError = {
  type: typeof FETCH_USER_NOTIFICATION_ERROR,
  payload: any,
};

type MarkUserNotificationAsRead = {
  type: typeof MARK_USER_NOTIFICATION_AS_READ,
  id: Number,
};

type MarkUserNotificationAsReadSuccess = {
  type: typeof MARK_USER_NOTIFICATION_AS_READ_SUCCESS,
  payload: any,
};

type MarkUserNotificationAsReadError = {
  type: typeof MARK_USER_NOTIFICATION_AS_READ_ERROR,
  payload: any,
};
export type NotificationAction =
  | FetchUserNotifications
  | FetchUserNotificationsSuccess
  | FetchUserNotificationsError
  | FetchUserNotification
  | FetchUserNotificationSuccess
  | FetchUserNotificationError
  | MarkUserNotificationAsRead
  | MarkUserNotificationAsReadSuccess
  | MarkUserNotificationAsReadError;
