import React, { useEffect, useState } from 'react';
import { Typography, Button, Grid, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { push } from 'react-router-redux';
import { Field, reduxForm, reset } from 'redux-form';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { RenderedTextField, RenderedSelect } from '../../../../components/wrapped';
import {
  fetchShippingAddress,
  fetchAddressByZipcode,
  createShippingAddress,
  updateShippingAddress,
} from '../../../../store/actions';
import stateList from '../../../../store/utils/stateList';
import { isNil } from 'lodash';
import { black, tundora, pippin, alto } from '../../../../themes/colors';
import Loading from '../../../../components/Loading';
import { required, validateCEP, cepNormalize } from '../../../../components/utils/validators';
import { scrollToFirstError } from '../../../../components/utils/errorScrolling';
import { BackButton, BackButtonMobile } from '../../../../components/material';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    boxShadow: `1px 3px 6px ${alto}`,
  },
  contentBox: {
    background: pippin,
    padding: '1.5rem 2rem',
  },
  titleBox: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '2rem',
  },
  title: {
    color: black,
    fontSize: '2em',
    padding: '0 2.5rem',
    [theme.breakpoints.up('lg')]: {
      padding: '0 1rem',
    },
  },
  subtitle: {
    color: tundora,
    fontSize: '1.2em',
  },
  formBox: {
    padding: '2rem',
  },
  buttonBox: {
    display: 'flex',
    flexDirection: 'column',
    margin: '2rem 0',
    '& button:first-child': {
      marginBottom: '1rem',
    },
    [theme.breakpoints.up('md')]: {
      margin: '1rem 0',
      flexDirection: 'row',
      '& button': {
        marginRight: theme.spacing(1),
        '&:first-child': {
          marginBottom: '0',
        },
      },
    },
  },
}));

const ShippingAddressForm = ({
  handleSubmit,
  onReset,
  onFetchShippingAddress,
  onFetchAddressByZipcode,
  onCreateShippingAddress,
  onUpdateShippingAddress,
  navigateTo,
  submitting,
  match,
  loading,
  addressInfo,
}) => {
  const classes = useStyles();
  const id = match?.params?.id;
  const showArrow = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const [firstLoadEdit, setFirstLoadEdit] = useState(false);

  const onSubmit = (shippingAddress) =>
    !!id ? onUpdateShippingAddress(shippingAddress) : onCreateShippingAddress(shippingAddress);

  const handleOnFetchAddress = (e) => {
    setFirstLoadEdit(false);
    onFetchAddressByZipcode(e.target.value);
  };

  useEffect(() => {
    if (!isNil(id)) {
      onFetchShippingAddress(id);
      setFirstLoadEdit(true);
    } else {
      onReset();
    }
  }, [id, onFetchShippingAddress, onReset, setFirstLoadEdit]);

  return (
    <Loading isLoading={loading}>
      <div className={classes.root}>
        <BackButtonMobile onClick={() => navigateTo('/perfil/enderecos')} visible={!showArrow} />
        <div className={classes.contentBox}>
          <div className={classes.titleBox}>
            <BackButton onClick={() => navigateTo('/perfil/enderecos')} visible={showArrow} />
            <Typography className={classes.title}>Endereço de entrega</Typography>
          </div>
          <Typography className={classes.subtitle} gutterBottom>
            Mantenha seu endereço sempre atualizado para receber sua caixa
          </Typography>
        </div>

        <div className={classes.formBox}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Field
                  name="label"
                  type="text"
                  component={RenderedTextField}
                  label="Nome do endereço"
                  placeholder="Dê um nome"
                  validate={[required]}
                />
              </Grid>

              <Grid item xs={12} sm={6}></Grid>

              <Grid item xs={12} sm={6}>
                <Field
                  name="postalCode"
                  type="text"
                  component={RenderedTextField}
                  label="CEP"
                  normalize={cepNormalize}
                  placeholder="Informe o CEP"
                  validate={[required, validateCEP]}
                  onBlur={handleOnFetchAddress}
                />
              </Grid>
              <Grid item xs={12} sm={6}></Grid>

              <Grid item xs={12}>
                <Field
                  name="street"
                  type="text"
                  component={RenderedTextField}
                  label="Endereço"
                  placeholder="Nome da rua, avenida"
                  validate={[required]}
                  disabled={(!addressInfo?.erro && !!addressInfo?.logradouro) || firstLoadEdit}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Field
                  name="number"
                  type="text"
                  component={RenderedTextField}
                  label="Número"
                  placeholder="Informe o número"
                  validate={[required]}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Field
                  name="complement"
                  type="text"
                  component={RenderedTextField}
                  label="Complemento"
                  placeholder="Informe o complemento"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Field
                  name="district"
                  type="text"
                  component={RenderedTextField}
                  label="Bairro"
                  placeholder="Nome do bairro"
                  disabled={(!addressInfo?.erro && !!addressInfo?.bairro) || firstLoadEdit}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <Field
                  name="state"
                  component={RenderedSelect}
                  label="Estado"
                  validate={[required]}
                  disabled={(!addressInfo?.erro && !!addressInfo?.uf) || firstLoadEdit}
                >
                  {stateList.map((i) => (
                    <MenuItem key={i.id} value={i.id}>
                      {i.value}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>

              <Grid item xs={12}>
                <Field
                  name="city"
                  type="text"
                  component={RenderedTextField}
                  label="Cidade"
                  placeholder="Cidade"
                  validate={[required]}
                  disabled={(!addressInfo?.erro && !!addressInfo?.localidade) || firstLoadEdit}
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  name="reference"
                  type="text"
                  component={RenderedTextField}
                  label="Referência"
                  placeholder="Referência"
                  validate={[required]}
                />
              </Grid>
            </Grid>
            <div className={classes.buttonBox}>
              <Button variant="contained" color="secondary" type="submit" disabled={submitting}>
                Salvar
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => navigateTo('/perfil/enderecos')}
              >
                Voltar
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Loading>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { loading, addressInfo } = state.profile;
  return { match: ownProps?.match, loading, addressInfo };
};

const mapDispatchToProps = (dispatch) => ({
  onFetchAddressByZipcode: (zipcode) => dispatch(fetchAddressByZipcode(zipcode)),
  onFetchShippingAddress: (id) => dispatch(fetchShippingAddress(id)),
  onCreateShippingAddress: (shippingAddress) => dispatch(createShippingAddress(shippingAddress)),
  onUpdateShippingAddress: (shippingAddress) => dispatch(updateShippingAddress(shippingAddress)),
  navigateTo: (path) => dispatch(push(path)),
  onReset: (path) => dispatch(reset('shippingAddressForm')),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'shippingAddressForm',
    shouldValidate: () => true,
    onSubmitFail: (errors) => scrollToFirstError(errors),
  })
)(ShippingAddressForm);
