import React from 'react';
import { MastercardIcon, VisaIcon, HipercardIcon, EloIcon } from '../icons';

const getCardIcon = (brand) => {
  switch (brand) {
    case 'MASTERCARD':
      return <MastercardIcon />;
    case 'VISA':
      return <VisaIcon />;
    case 'HIPERCARD':
      return <HipercardIcon />;
    case 'ELO':
      return <EloIcon />;
    default:
      return <span />;
  }
};

const brandList = [
  {
    id: 2,
    name: 'Visa',
  },
  {
    id: 1,
    name: 'MasterCard',
  },
  {
    id: 11,
    name: 'Elo',
  },
  {
    id: 12,
    name: 'Hipercard',
  },
];

const getCardIconByCode = (code) => {
  switch (code) {
    case 1:
      return <MastercardIcon />;
    case 2:
      return <VisaIcon />;
    case 12:
      return <HipercardIcon />;
    case 11:
      return <EloIcon />;
    default:
      return <span />;
  }
};

export { getCardIcon, brandList, getCardIconByCode };
