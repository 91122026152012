// @flow
import React from 'react';
import { FormHelperText } from '@material-ui/core';
import { isEqual } from 'lodash';
import { TextField } from '../material';

type FieldProps = {
  input: Object,
  label: string,
  meta: Object,
  meta: Object,
  custom: Object,
  noPaste: boolean,
  value: string,
  onChangeValue: Function,
};

const TextareaField = ({
  input,
  value,
  label,
  multiline,
  meta: { touched, error },
  noPaste,
  onChangeValue,
  ...custom
}: FieldProps) => (
  <div>
    <TextField
      title={label}
      margin="dense"
      fullWidth
      error={touched && !!error}
      variant="outlined"
      value={value}
      {...input}
      {...custom}
      onChange={(e) => {
        if (noPaste && isEqual(e.nativeEvent.inputType, 'insertFromPaste')) {
          e.target.value = '';
        } else {
          input.onChange(e);
        }
      }}
      multiline
      rows={7}
      max-rows={7}
    />
    {touched && !!error ? (
      <FormHelperText error={touched && !!error}>{error}</FormHelperText>
    ) : null}
  </div>
);

export default TextareaField;
