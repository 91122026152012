// color names mapped using
// http://chir.ag/projects/name-that-color

export const white = '#FFFFFF';
export const black = '#1d1d18';
export const redOrange = '#A01235';
export const redOrangeHover = '#ff6666';
export const redOrangeLight = '#a5060617';
export const bittersweet = '#FE6F61';
export const yellowOrange = '#FFC244';
export const tundora = '#4E4E4E';
export const pippin = '#FFE6E3';
export const oldLace = '#FDF1DF';
export const pirateGold = '#B57A00';
export const zumthor = '#E6EDFF';
export const geyser = '#DAE7E7';
export const sun = '#FAB11B';
export const denim = '#0C43D6';
export const doveGray = '#707070';
export const silverChalice = '#9F9F9F';
export const silver = '#C1C1C1';
export const alto = '#DEDEDE';
export const lightPink = '#ffb8b8';
export const wildSand = '#F5F5F5';
export const milanoRed = '#C10606';
export const japaneseLaurel = '#06A513';
export const japaneseLaurelLight = '#06A51317';
export const blackSqueeze = '#FAFCFD';
export const gallery = '#EAEAEA';
export const linkWater = '#ECEDFA';
export const stTropaz = '#244F9C';
export const alabaster = '#F8F8F8';
export const mercury = '#E2E2E2';
export const whiteIce = '#f6f6f6';
export const lilac = '#C9C5FF';
export const cinzaMaisClaro = '#f3f3f3';
export const cinzaClaro = '#afafaf';
export const cinzaClaroRGBA = 'rgba(175, 175, 175, 0.3)';
export const cinzaClaroRGBA2 = 'rgba(229, 229, 229, 0.8)';
export const cinzaClaroRGBA3 = 'rgba(180, 180, 180, 0.9)';
export const cinzaMedio = '#707070';
export const cinzaMedioRGBA = 'rgba(112, 112, 112, 0.1)';
export const cinzaMedioRGBA2 = 'rgba(112, 112, 112, 0.7)';
export const cinzaEscuro = '#4e4e4e';
export const cinzaMuitoEscuro = '#212121';
export const brancoBranco = '#fff';

export const uauPromoBlue = '#5D8DBD';
export const uauPromoDarkBlue = '#405A84';
export const uauPromoDarkPink = '#D38D8A';

export const scarletDefault = '#000000';
export const scarletColor = '#731f2e';

export const pinkPopupBackground = '#F16887';

export const lightGreyBackground = '#F0F0F0';
export const subtitleGray = '#7C7C7C';
export const lightPinkCard = '#D88181';
