import * as fromTypes from '../types/face';

export const handleSendImage = (image) => ({
  type: fromTypes.FACE_SEND_IMAGE,
  payload: { image },
});

export const handleVerifyStatus = () => ({
  type: fromTypes.FACE_VERIFY_STATUS,
});

export const handleGetResult = () => ({
  type: fromTypes.FACE_GET_RESULT,
});

export const handleGetResultSuccess = ({ data, image }) => ({
  type: fromTypes.FACE_GET_RESULT_SUCCESS,
  payload: { data, image },
});

export const handleToggleLike = ({ questionName, isCorrect }) => ({
  type: fromTypes.TOGGLE_LIKE,
  payload: { questionName, isCorrect },
});

export const handleDeleteLikeRequest = ({ questionName, isCorrect }) => ({
  type: fromTypes.DELETE_LIKE_REQUEST,
  payload: { questionName, isCorrect },
});

export const handleDeleteLikeSuccess = (questionName) => ({
  type: fromTypes.DELETE_LIKE_SUCCESS,
  payload: { questionName },
});

export const handleGetFeedback = () => ({
  type: fromTypes.GET_FEEDBACK,
});

export const handleGetFeedbackSuccess = (feedbacks) => ({
  type: fromTypes.GET_FEEDBACK_SUCCESS,
  payload: { feedbacks },
});

export const handleLoadingFinished = (finished) => ({
  type: fromTypes.FINISHED_LOADING,
  payload: { finished },
});
