import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100%',
  },
  grow: {
    height: '50vh',
    width: '100%',
    margin: 'auto',
  },
  loadingText: {
    color: 'black',
  },
  text: {
    marginTop: '1rem',
    color: '#A01235',
    fontFamily: 'open sans',
  },
  logoLoading: {
    width: '50%',
  },
  timeline: {
    marginBottom: '2rem',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    [theme.breakpoints.up('md')]: {
      width: '70%',
      marginTop: '0.5rem',
    },
  },
}));

export default useStyles;
