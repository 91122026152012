const NODE_ENV = process.env.NODE_ENV;
const isProdEnv = NODE_ENV === 'production';
const googleAdsId = process.env.REACT_APP_GA;
const oldGoogleAdsId = process.env.REACT_APP_GA_OLD;

export const initGoogleAds = () => {
  const script = document.createElement('script');

  script.src = `https://www.googletagmanager.com/gtag/js?id=${googleAdsId}`;
  script.async = true;

  document.body.appendChild(script);

  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  gtag('js', new Date());

  gtag('config', googleAdsId);

  initOldGoogleAds();
};

const initOldGoogleAds = () => {
  const script = document.createElement('script');

  script.src = `https://www.googletagmanager.com/gtag/js?id=${oldGoogleAdsId}`;
  script.async = true;

  document.body.appendChild(script);

  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }
  gtag('js', new Date());

  gtag('config', googleAdsId);
};

export const logGoogleAdsTransaction = (realPrice) => {
  if (isProdEnv) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'purchasesuccess',
      conversionValue: realPrice,
    });
  }
};
