import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import { toast } from 'react-toastify';
import { push } from 'react-router-redux';
import * as fromTypes from '../types';
import * as fromActions from '../actions';

const BASE_API = `${process.env.REACT_APP_BASE_API_ADDRESS}/customer-area`;
// const getWalletBankFromState = (state) => state?.wallet?.bank;

export function* fetchWalletPoints() {
  try {
    const response = yield axios.get(`${BASE_API}/wallet/accounts/CASH/balances`);

    let points = {
      ...response.data,
    };

    points.current = Math.floor(points.current / points.currency);
    points.incoming = Math.floor(points.incoming / points.currency);
    points.outgoing = Math.floor(points.outgoing / points.currency);

    if (response.status === 200) {
      yield put(fromActions.fetchWalletPointsSuccess({ points: points }));
    }
  } catch (error) {
    console.error(error);

    if (error.response.status === 404) {
      toast.info(
        'Desculpe, mas ainda não houve movimentação de pontos em sua conta, então não estamos mostrando seus pontos!'
      );
    } else {
      toast.error('Não foi possível carregar seus pontos, tente novamente mais tarde.');
    }
    yield put(fromActions.fetchWalletPointsError(error));
  }
}

export function* fetchBankStatement({ payload }) {
  try {
    const response = yield axios.get(
      `${BASE_API}/wallet/accounts/CASH/statements?size=10&page=${payload - 1}`
    );

    if (response.status === 200) {
      yield put(fromActions.fetchBankStatementSuccess({ statement: response.data }));
    }
  } catch (error) {
    console.error(error);

    if (error.response.status === 404) {
      toast.info(
        'Desculpe, mas ainda não houve movimentação em sua conta, então não estamos mostrando seu extrato!'
      );
    } else {
      toast.error('Não foi possível carregar seu extrato, tente novamente mais tarde.');
    }
    yield put(fromActions.fetchBankStatementError(error));
  }
}

export function* fetchBankData() {
  try {
    const response = yield axios.get(`${BASE_API}/wallet/account/bank`);

    if (response.status === 200) {
      yield put(fromActions.fetchBankDataSuccess({ bank: response.data }));
    }
  } catch (error) {
    console.error(error);

    if (error.response.status === 404) {
      toast.info(
        'Desculpe, mas não encontramos seus dados bancários, caso já tenha cadastrado, tente novamente mais tarde.'
      );
    } else {
      toast.error('Não foi possível carregar seus dados bancários, tente novamente mais tarde.');
    }
    yield put(fromActions.fetchBankDataError(error));
  }
}

export function* updateBankData({ payload }) {
  try {
    const type = payload.type;
    let pix;
    type === 'PHONE' ? (pix = '+55' + payload.pix) : (pix = payload.pix);

    const response = yield axios.post(`${BASE_API}/wallet/account/bank`, {
      type,
      pix,
    });

    if (response.status === 201) {
      yield put(fromActions.updateBankDataSuccess(response.data));
      toast.success('Dados bancários atualizados com sucesso!');
      yield put(push('/minha-carteira'));
    }
  } catch (error) {
    console.error(error);

    if (error.response.status === 404) {
      toast.info(
        'Desculpe, mas ainda não é possível salvar esses dados em sua conta. Tente novamente mais tarde.'
      );
    } else {
      toast.error('Não foi possível salvar seus dados bancários, tente novamente mais tarde.');
    }

    yield put(fromActions.updateBankDataError(error));
  }
}

export function* requestWithdrawal({ payload }) {
  try {
    const response = yield axios.post(
      `${BASE_API}/wallet/financial-transactions/withdraw`,
      payload
    );

    if (response.status === 200) {
      yield put(fromActions.requestWithdrawalSuccess());
      toast.success('Resgate solicitado com sucesso!');
      yield put(push('/minha-carteira'));
    }
  } catch (error) {
    console.error(error);
    toast.error('Não foi possível solicitar o resgate, tente novamente mais tarde.');
    yield put(fromActions.requestWithdrawalError(error));
  }
}

export function* watchWallet() {
  yield takeLatest(fromTypes.FETCH_WALLET_POINTS, fetchWalletPoints);
  yield takeLatest(fromTypes.FETCH_BANK_DATA, fetchBankData);
  yield takeLatest(fromTypes.FETCH_BANK_STATEMENT, fetchBankStatement);
  yield takeLatest(fromTypes.UPDATE_BANK_DATA, updateBankData);
  yield takeLatest(fromTypes.REQUEST_WITHDRAWAL, requestWithdrawal);
}
