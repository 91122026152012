import React, { Node } from 'react';
import { Route } from 'react-router-dom';
import SEO from '../../../components/SEO';
import { isAuthenticated } from '../../../store/services/auth';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';

type Props = {
  component: Node,
  location: any,
  title: text,
  metaDescription: text,
};

const NormalRoute = ({
  component: Component,
  title,
  metaDescription,
  checkAuth,
  ...rest
}: Props) => {
  const validAuth = isAuthenticated() && checkAuth;
  const redirectURL = '/dashboard' + rest.location.search;

  return (
    <>
      {!validAuth ? (
        <Route
          {...rest}
          render={(props) => {
            return (
              <div style={{ display: 'flex' }}>
                <SEO title={title} description={metaDescription} />
                <Component {...props} />
              </div>
            );
          }}
        />
      ) : (
        <Redirect to={redirectURL} />
      )}
    </>
  );
};

export default NormalRoute;
