// @flow
import decode from 'jwt-decode';
import axios from 'axios';

type Json = 'application/json';

export const APPLICATION_JSON: Json = 'application/json';

export const logout = () => {
  window.localStorage.removeItem('registration');
  window.localStorage.removeItem('token');
};

export const setToken = async (token: string) => localStorage.setItem('token', token);

export const getToken = (): string => {
  const token = localStorage.getItem('token');
  if (token && token === 'undefined') {
    localStorage.removeItem('token');
    return '';
  }

  return token || '';
};

export const getAuthCustomerId = () => {
  const { customer_id } = decode(getToken());

  return customer_id;
};

export const getTenantToken = () => {
  const { tenant } = decode(getToken());
  return tenant;
};

export const getRolesRestricted = () => {
  const { rolesRestricted } = decode(getToken());
  return rolesRestricted;
};

export const getEmailToken = () => {
  const { sub } = decode(getToken());

  return sub;
};

export const getAuthId = () => {
  const token = decode(getToken());
  return token.id;
};

export const isTokenExpired = (token: string) => {
  try {
    const decoded = decode(token);
    if (decoded.exp < Date.now() / 1000) {
      return true;
    }
    return false;
  } catch (err) {
    return false;
  }
};

export const isAuthenticated = (path = null) => {
  const token = getToken();
  if (token) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  } else {
    axios.defaults.headers.common['Authorization'] = null;
    const pathName = window.location.pathname;
    if (path && pathName && path === pathName) {
      window.location.search
        ? pathName && sessionStorage.setItem('pathRedirect', pathName + window.location.search)
        : path && sessionStorage.setItem('pathRedirect', path);
    }
  }
  return !!token && !isTokenExpired(token);
};

export const isPasswordExpired = () => {
  const token = decode(getToken());
  return token.expiredPassword;
};

export const getName = () => {
  const token = decode(getToken());
  return token?.name;
};
