// @flow
import React from 'react';
import { Button, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { cancelSingleProduct } from '../../store/actions';
import { white, black } from '../../themes/colors';

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    padding: '1rem',
  },
  title: {
    fontFamily: 'open sans',
    fontSize: '1.5em',
    fontWeight: 'bold',
  },
  text: {
    padding: theme.spacing(2, 0),
  },
  btnContainer: {
    paddingTop: '2em',
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column-reverse',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  closeBtn: {
    color: black,
    border: '1px solid black',
    fontFamily: 'open sans',
    fontWeight: 'bold',
    background: white,
    borderRadius: '2em',
    fontSize: '1.125em',
    width: '100%',
    '&:hover': {
      background: white,
    },
    [theme.breakpoints.up('md')]: {
      marginRight: '0.5em',
      width: 'auto',
    },
  },
  confirmBtn: {
    color: white,
    fontFamily: 'open sans',
    fontWeight: 'bold',
    background: black,
    borderRadius: '2em',
    fontSize: '1.125em',
    width: '100%',
    marginBottom: '0.5em',
    '&:hover': {
      background: black,
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: '0.5em',
      marginBottom: '0px',
      width: 'auto',
    },
  },
}));

const SingleProductCancelCard = ({ open, onClose, productDetail }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const tablet = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  const handleClose = (wasCanceled) => {
    onClose(wasCanceled);
  };

  const confirmCancel = () => {
    dispatch(cancelSingleProduct(productDetail.invoiceId));
    handleClose(true);
  };

  return (
    <Dialog
      maxWidth="sm"
      open={open}
      aria-labelledby="feedback-dialog-title"
      fullWidth={tablet}
      fullScreen={!tablet}
    >
      <div className={classes.dialogContainer}>
        <DialogTitle className={classes.title}>Cancelar pedido do produto extra</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Typography className={classes.text}>
            Você está cancelando a compra do produto extra <strong>{productDetail?.name}</strong>{' '}
            pelo valor de R${' '}
            {productDetail?.price?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}.
            <br />
            Deseja cancelar sua compra, Mig?
          </Typography>
          <div className={classes.btnContainer}>
            <Button className={classes.closeBtn} onClick={() => handleClose(false)}>
              NÃO QUERO CANCELAR
            </Button>
            <Button className={classes.confirmBtn} onClick={confirmCancel}>
              CANCELAR COMPRA
            </Button>
          </div>
        </DialogContent>
      </div>
    </Dialog>
  );
};

export default SingleProductCancelCard;
