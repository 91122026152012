import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signOut } from '../../../store/actions';
import { Link } from 'react-router-dom';

import {
  BoxIcon,
  CommunicationIcon,
  GiftIcon,
  LeaveIcon,
  PersonIcon,
  ShopIcon,
  ClockIcon,
} from '../../icons';
import useStylesMenuItems from './styles';
import { redOrange } from '../../../themes/colors';

const MenuItems = () => {
  const classes = useStylesMenuItems();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const MenuItem = ({ title, icon, active, href, isExternalLink }) => {
    return isExternalLink ? (
      <a href={href} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
        <div className={classNames(classes.menuItem, { [classes.active]: active })}>
          <div className={classes.menuItemBox}>
            {icon}
            <span>{title}</span>
          </div>
        </div>
      </a>
    ) : (
      <Link to={href} style={{ textDecoration: 'none' }}>
        <div className={classNames(classes.menuItem, { [classes.active]: active })}>
          <div className={classes.menuItemBox}>
            {icon}
            <span>{title}</span>
          </div>
        </div>
      </Link>
    );
  };

  const canShow = () => !window.location?.pathname.includes('/checkout');
  const { tenantName } = useSelector((state) => state?.auth);
  const isActive = (path) => window.location?.pathname?.includes(path);
  return (
    <>
      <div>
        {canShow() ? (
          <>
            <MenuItem
              title="Página inicial"
              icon={<ClockIcon />}
              href="/dashboard"
              active={isActive('/dashboard')}
            />
            <div className={classes.subtitle}>Perfil</div>
            <MenuItem
              title="Minha Box"
              icon={<BoxIcon />}
              href="/sua-box"
              active={isActive('/sua-box')}
            />
            <MenuItem
              title={tenantName === 'scarlet' ? 'Dados Pessoais' : 'Perfil de Beleza'}
              icon={<PersonIcon />}
              href="/perfil"
              active={isActive('/perfil')}
            />
            {/* <MenuItem
              title="Se descubra"
              icon={<AIIcon />}
              href="/se-descubra/tirar-foto"
              active={isActive('/se-descubra/tirar-foto')}
            /> */}
            <div className={classes.subtitle}>Comunidade</div>
            <MenuItem
              title="Indique e ganhe"
              icon={<GiftIcon />}
              href="/indicacoes"
              active={isActive('/indicacoes')}
            />
            <div className={classes.subtitle}>Veja Também</div>
            {/* <MenuItem
              title="Dúvidas Frequentes"
              icon={<QAIcon />}
              href={
                tenantName === 'scarlet'
                  ? 'https://scarletbox.com.br/duvidas'
                  : 'https://legacy.uaubox.com.br/duvidas'
              }
              isExternalLink={true}
            /> */}
            <MenuItem
              title="UAUStore"
              icon={<ShopIcon />}
              href="https://shop.uaubox.com.br"
              isExternalLink={true}
            />

            <MenuItem
              title="UAUBlog"
              icon={<CommunicationIcon />}
              href="https://blog.uaubox.com.br/"
              isExternalLink={true}
            />
          </>
        ) : null}
        <div className={classes.menuItem} onClick={() => setOpen(true)}>
          <div className={classes.menuItemBox}>
            <LeaveIcon fill="#A01235" />
            <span style={{ color: redOrange }}>Sair da conta</span>
          </div>
        </div>

        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{'Deseja sair?'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Ao encerrar sua sessão você será redirecionado para a tela de login.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} variant="outlined" color="secondary">
              Cancelar
            </Button>
            <Button
              onClick={() => dispatch(signOut())}
              variant="contained"
              color="secondary"
              autoFocus
            >
              Sair
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export default MenuItems;
