import { makeStyles } from '@material-ui/core';
import { redOrange, white } from '../../../../themes/colors';

const useStylesHeaderDashboard = makeStyles((theme) => ({
  root: {
    background: '#F0F0F0',
    padding: '1rem 0',
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      minHeight: 'initial',
    },
    [theme.breakpoints.up('md')]: {
      padding: '2rem',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  mainContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  mainContentLeft: {
    display: 'flex',
    flexDirection: 'column',

    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      marginRight: '1.5rem',
    },
  },
  mainContentRight: {
    padding: '1rem',
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      height: '100%',
      borderRadius: '8px',
      objectFit: 'contain',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  title: {
    flex: 1,
    color: redOrange,
    fontSize: '1.5em',
    alignSelf: 'center',
    '& h6': {
      paddingLeft: '3rem',
      [theme.breakpoints.up('lg')]: {
        marginLeft: 0,
        padding: 0,
      },
    },
  },
  avatar: {
    width: '3rem',
    height: '3rem',
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginRight: 0,
      marginBottom: theme.spacing(2),
      width: '8rem',
      height: '8rem',
    },
  },
  mainBox: {
    padding: '1rem',
    color: '#1D1D18',
    marginTop: '1rem',
    maxWidth: 800,
    [theme.breakpoints.up('md')]: {
      padding: 0,
    },
  },
  mainBoxTitle: {
    background: redOrange,
    fontWeight: 900,
    padding: 10,
    fontSize: '1.2rem',
    textAlign: 'left',
    color: white,
    borderRadius: '4px',
    lineHeight: '2rem',
    [theme.breakpoints.up('sm')]: {
      marginTop: '1.3rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.5rem',
      marginRight: '2.6rem',
      fontWeight: 900,
    },
  },
  imageTitle: {
    width: '13px',
    marginRight: '0.2rem',
    [theme.breakpoints.up('sm')]: {
      marginRight: '0.3rem',
    },
  },
  mainBoxContent: {
    width: '100%',
    marginTop: '1rem',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      width: '100%',
      marginTop: '1.6rem',
      textAlign: 'left',
    },
  },
  textField: {
    width: '100%',
    marginTop: '1rem',
    margin: theme.spacing(1, 0),
    background: white,
    borderRadius: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      width: '100%',
      marginTop: '2rem',
    },
  },
  image: {
    width: '13rem',
    marginTop: '1rem',
    margin: '0 auto',
    [theme.breakpoints.up('sm')]: {
      width: '15rem',
    },
    [theme.breakpoints.up('md')]: {
      marginTop: '0rem',
      width: '16rem',
    },
  },
  notificationBox: {
    '& svg': {
      fontSize: '1.5em',
    },
  },
}));

export default useStylesHeaderDashboard;
