import React from 'react';
import { Typography, Button, Divider, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { black, silverChalice } from '../../themes/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '1rem 0',
  },
  mainTitle: {
    color: silverChalice,
    fontSize: '1.5em',
    fontWeight: 'bold',
  },
  title: {
    color: silverChalice,
  },
  titleHighlight: {
    color: black,
    fontSize: '1.2em',
  },
  buttonBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: '2rem 0',
    '& button': {
      fontSize: '0.8em',
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      padding: '1rem 0',
      margin: 0,
      marginLeft: '25%', // grid size
      '& button': {
        marginRight: '0.5rem',
      },
    },
  },
}));

const ShippingAddress = ({ navigateTo, shippingAddress }) => {
  const classes = useStyles();
  const desktop = useMediaQuery((theme) => theme.breakpoints.up('md'));

  return (
    <div className={classes.root}>
      <Grid
        container
        alignItems={desktop ? 'center' : 'flex-start'}
        direction={desktop ? 'row' : 'column'}
        spacing={2}
      >
        <Grid item xs={12} sm={6} md={3}>
          <Typography className={classes.mainTitle} gutterBottom>
            Endereço de entrega
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Typography className={classes.title} gutterBottom>
            Endereço
          </Typography>
          <Typography className={classes.titleHighlight} gutterBottom>
            {shippingAddress?.street}
          </Typography>
          <Typography className={classes.title} gutterBottom>
            Número
          </Typography>
          <Typography className={classes.titleHighlight} gutterBottom>
            {shippingAddress?.number}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          {shippingAddress?.complement && (
            <div>
              <Typography className={classes.title} gutterBottom>
                Complemento
              </Typography>

              <Typography className={classes.titleHighlight} gutterBottom>
                {shippingAddress.complement}
              </Typography>
            </div>
          )}
          <Typography className={classes.title} gutterBottom>
            Bairro
          </Typography>
          <Typography className={classes.titleHighlight} gutterBottom>
            {shippingAddress?.district}
          </Typography>
          <Typography className={classes.title} gutterBottom>
            Referência
          </Typography>
          <Typography className={classes.titleHighlight} gutterBottom>
            {shippingAddress?.reference}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Typography className={classes.title} gutterBottom>
            Cidade/Estado
          </Typography>
          <Typography className={classes.titleHighlight} gutterBottom>
            {`${shippingAddress?.city} - ${shippingAddress?.state}`}
          </Typography>
          <Typography className={classes.title} gutterBottom>
            CEP
          </Typography>
          <Typography className={classes.titleHighlight} gutterBottom>
            {shippingAddress?.postalCode}
          </Typography>
        </Grid>
      </Grid>
      <div className={classes.buttonBox}>
        <Button variant="outlined" color="primary" onClick={() => navigateTo('/perfil/enderecos')}>
          EDITAR endereço
        </Button>
      </div>
      <Divider />
    </div>
  );
};

export default ShippingAddress;
