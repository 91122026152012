import React from 'react';
import MobileMenu from './MobileMenu';
import Menu from './Menu';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const WrappedMenu = () => {
  const desktop = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  return desktop ? <Menu /> : <MobileMenu />;
};
export default WrappedMenu;
