import { put, takeLatest, select } from 'redux-saga/effects';
import axios from 'axios';
import { toast } from 'react-toastify';
import { push } from 'react-router-redux';
import * as fromTypes from '../types';
import * as fromActions from '../actions';
import { getTenantToken } from '../services/auth';

const BASE_API = `${process.env.REACT_APP_BASE_API_ADDRESS}/customer-area`;

export function* fetchExtraProducts() {
  try {
    const response = yield axios.get(`${BASE_API}/campaign/offer-suggestions`);

    if (response.status === 200) {
      yield put(fromActions.fetchExtraProductsSuccess(response.data));
    }
  } catch (error) {
    console.error(error);
    yield put(fromActions.fetchExtraProductsError(error));
  }
}

export function* buyExtraProduct({ id, sku }) {
  try {
    const payload = { sku };
    const response = yield axios.put(`${BASE_API}/campaign/offer-suggestions/${id}`, payload);

    if (response.status === 200) {
      toast.success('Compra efetuada com sucesso!');
      yield put(fromActions.buyExtraProductSuccess());
      yield put(fromActions.fetchExtraProducts());
    }
  } catch (error) {
    console.error(error);
    toast.error(
      'Não foi possível completar a compra do produto extra, tente novamente mais tarde.'
    );
    yield put(fromActions.buyExtraProductError(error));
    yield put(fromActions.fetchExtraProducts());
  }
}

export function* cancelExtraProduct({ id }) {
  try {
    const response = yield axios.delete(`${BASE_API}/campaign/offer-suggestions/${id}`);
    if (response.status === 200) {
      toast.success('Compra cancelada com sucesso!');
      yield put(fromActions.cancelExtraProductSuccess());
      yield put(fromActions.fetchExtraProducts());
    }
  } catch (error) {
    console.error(error);
    toast.error('Não foi possível cancelar a compra do produto extra, tente novamente mais tarde.');
    yield put(fromActions.cancelExtraProductError(error));
    yield put(fromActions.fetchExtraProducts());
  }
}

export function* selectRetentionOffer({ id, offer }) {
  try {
    const TENANT = yield select(getTenantToken);
    const response = yield axios.put(`${BASE_API}/campaign/retention/${TENANT}/offer/${id}`, offer);
    if (response.status === 200) {
      toast.success('Oferta selecionada com sucesso!');
      yield put(fromActions.cancelExtraProductSuccess());
      yield put(fromActions.fetchDashboard());
    }
  } catch (error) {
    console.error(error);
    toast.error('Não foi possível selecionar a oferta, tente novamente mais tarde.');
    yield put(fromActions.cancelExtraProductError(error));
    yield put(fromActions.fetchDashboard());
  }
}

export function* buySingleProduct({ id }) {
  try {
    const TENANT = yield select(getTenantToken);
    const payload = { id };
    const response = yield axios.post(
      `${BASE_API}/campaign/retention/${TENANT}/single-product`,
      payload
    );

    if (response.status === 200) {
      toast.success('Compra efetuada com sucesso!');
      yield put(fromActions.buySingleProductSuccess());
      yield put(fromActions.fetchExtraProducts());
    }
  } catch (error) {
    console.error(error);
    toast.error(
      'Não foi possível completar a compra do produto extra, tente novamente mais tarde.'
    );
    yield put(fromActions.buySingleProductError(error));
    yield put(fromActions.fetchExtraProducts());
  }
}

export function* cancelSingleProduct({ invoiceId }) {
  try {
    const TENANT = yield select(getTenantToken);
    const response = yield axios.delete(
      `${BASE_API}/campaign/retention/${TENANT}/single-product/invoice/${invoiceId}`
    );

    if (response.status === 200) {
      toast.success(
        'Compra cancelada com sucesso! Pode levar alguns segundos para atualizar o estatus do seu pedido.'
      );
      yield put(fromActions.cancelSingleProductSuccess());
      yield put(push('/sua-box'));
    }
  } catch (error) {
    console.error(error);
    toast.error('Não foi possível cancelar a compra do produto extra, tente novamente mais tarde.');
    yield put(fromActions.cancelSingleProductError(error));
    yield put(fromActions.fetchExtraProducts());
  }
}

export function* watchPurchase() {
  yield takeLatest(fromTypes.FETCH_EXTRA_PRODUCTS, fetchExtraProducts);
  yield takeLatest(fromTypes.BUY_EXTRA_PRODUCT, buyExtraProduct);
  yield takeLatest(fromTypes.CANCEL_EXTRA_PRODUCT, cancelExtraProduct);
  yield takeLatest(fromTypes.SELECT_RETENTION_OFFER, selectRetentionOffer);
  yield takeLatest(fromTypes.BUY_SINGLE_PRODUCT, buySingleProduct);
  yield takeLatest(fromTypes.CANCEL_SINGLE_PRODUCT, cancelSingleProduct);
}
