import { Grid, IconButton, Popover, Typography } from '@material-ui/core';
import { Brightness1Rounded } from '@material-ui/icons';
import React from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { markUserNotificationAsRead } from '../../../../../store/actions';
import useStylesHeaderDashboardNotification from './styles';

function NotificationPopover({ notifications, anchorEl, handleClose, open }) {
  const dispatch = useDispatch();
  const classes = useStylesHeaderDashboardNotification();

  const handleOnClickUrl = ({ id, url }) => {
    if (!url) return;
    dispatch(markUserNotificationAsRead(id));
    window.location.href = url;
  };

  return (
    <Popover
      id="notification-popover"
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <div className={classes.notificationPopover}>
        <Typography className={classes.notificationMaintitle}>Notificações</Typography>

        {!!notifications?.length ? (
          notifications?.map((i) => {
            const hasImage = !isEmpty(i?.image);
            return (
              <Grid
                key={`index-${i.id}`}
                container
                spacing={2}
                className={classNames({ [classes.opacity]: i.read })}
              >
                {hasImage && (
                  <Grid item sm={3} style={{ textAlign: 'center' }}>
                    <img
                      className={classes.notificationImage}
                      src={i?.image}
                      alt="Imagem da notificação"
                    />
                  </Grid>
                )}

                <Grid item sm={hasImage ? 9 : 12}>
                  <div className={classes.notificationMessage}>
                    <div
                      className={classNames(classes.notificationLink, {
                        [classes.notificationLinkCursor]: i?.url,
                      })}
                      onClick={() => handleOnClickUrl(i)}
                    >
                      <Typography className={classes.notificationTitle}>{i.title}</Typography>
                      <Typography rows={2} className={classes.notificationBody}>
                        {i.body}
                      </Typography>
                    </div>
                    <div className={classes.notificationRead}>
                      {!i.read && (
                        <IconButton
                          aria-label="notification"
                          onClick={() => dispatch(markUserNotificationAsRead(i?.id))}
                        >
                          <Brightness1Rounded />
                        </IconButton>
                      )}
                    </div>
                  </div>
                </Grid>
              </Grid>
            );
          })
        ) : (
          <div className={classes.noNotification}>
            <Typography>Sem novas notificações.</Typography>
          </div>
        )}
      </div>
    </Popover>
  );
}

export default NotificationPopover;
