import React from 'react';
import { Typography, Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { push } from 'react-router-redux';
import { Field, reduxForm } from 'redux-form';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { RenderedTextField } from '../../../../components/wrapped';
import { updatePassword } from '../../../../store/actions';
import { black, tundora, pippin, alto } from '../../../../themes/colors';
import { required, minLength6 } from '../../../../components/utils/validators.js';
import { BackButton, BackButtonMobile } from '../../../../components/material';

const validate = (values) => {
  const errors = {};
  if (values.newPassword && values.newPassword !== values.confirmPassword) {
    errors.confirmPassword = 'Senha não confere';
  }
  return errors;
};

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    boxShadow: `1px 3px 6px ${alto}`,
  },
  contentBox: {
    background: pippin,
    padding: '1.5rem 2rem',
  },
  titleBox: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '2rem',
  },
  title: {
    color: black,
    fontSize: '2em',
    padding: '0 2.5rem',
    [theme.breakpoints.up('lg')]: {
      padding: '0 1rem',
    },
  },
  subtitle: {
    color: tundora,
    fontSize: '1.2em',
  },
  formBox: {
    padding: '2rem',
    maxWidth: '20rem',
  },
  buttonBox: {
    display: 'flex',
    flexDirection: 'column',
    margin: '1rem 0',
    [theme.breakpoints.up('md')]: {
      margin: '1rem 0',
      flexDirection: 'row',
      '& button': {
        marginRight: theme.spacing(1),
      },
    },
  },
}));

const ChangePasswordForm = ({ handleSubmit, onUpdatePassword, navigateTo, submitting }) => {
  const classes = useStyles();
  const showArrow = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  const onSubmit = ({ password, newPassword }) => onUpdatePassword(password, newPassword);

  return (
    <div className={classes.root}>
      <BackButtonMobile onClick={() => navigateTo('/perfil')} visible={!showArrow} />
      <div className={classes.contentBox}>
        <div className={classes.titleBox}>
          <BackButton onClick={() => navigateTo('/perfil')} visible={showArrow} />
          <Typography className={classes.title}>Alterar senha</Typography>
        </div>
        <Typography className={classes.subtitle} gutterBottom>
          Altere sua senha caso sinta necessidade
        </Typography>
      </div>

      <div className={classes.formBox}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Field
                name="password"
                type="password"
                component={RenderedTextField}
                label="Senha atual"
                placeholder="Informe sua senha atual"
                validate={[required, minLength6]}
              />
            </Grid>

            <Grid item xs={12}>
              <Field
                name="newPassword"
                type="password"
                component={RenderedTextField}
                label="Nova senha"
                placeholder="Mínimo 6 caracteres"
                validate={[required, minLength6]}
              />
            </Grid>

            <Grid item xs={12}>
              <Field
                name="confirmPassword"
                type="password"
                component={RenderedTextField}
                label="Confirme nova senha"
                placeholder="Mínimo 6 caracteres"
                validate={[required, minLength6]}
              />
            </Grid>
          </Grid>
          <div className={classes.buttonBox}>
            <Button variant="contained" color="secondary" type="submit">
              Salvar
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigateTo('/perfil')}
              disabled={submitting}
            >
              Voltar
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onUpdatePassword: (password, newPassword) => dispatch(updatePassword(password, newPassword)),
  navigateTo: (path) => dispatch(push(path)),
});

export default compose(
  connect(null, mapDispatchToProps),
  reduxForm({
    form: 'changePasswordForm',
    validate,
  })
)(ChangePasswordForm);
