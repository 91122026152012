import React, { useEffect, useCallback } from 'react';
import { Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  fetchProfileAnswer,
  updateBeautyProfile,
  fetchProfileAnswers,
} from '../../../../store/actions';
import { black, tundora, pippin, alto } from '../../../../themes/colors';
import BeautyCategoryCard from '../../../../components/BeautyCategoryCard';
import BeautyImageCard from '../../../../components/BeautyImageCard';
import BeautyTextCard from '../../../../components/BeautyTextCard';
import BeautyChoicesCard from '../../../../components/BeautyChoicesCard';
import Loading from '../../../../components/Loading';
import { BackButton, BackButtonMobile } from '../../../../components/material';
import { history } from '../../../../store';
import { canShowQuestion } from './BeautyProfile';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    boxShadow: `1px 3px 6px ${alto}`,
  },
  mainBox: {
    background: pippin,
    padding: '1.5rem 2rem',
  },
  titleBox: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '2rem',
  },
  title: {
    color: black,
    fontSize: '2em',
    padding: '0 2.5rem',
    [theme.breakpoints.up('lg')]: {
      padding: '0 1rem',
    },
  },
  subtitle: {
    color: tundora,
    fontSize: '1.2em',
  },
  contentBox: {
    padding: '2rem',
    minHeight: '100vh',
  },
  contentButtonBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '1.5rem 0',
    '& button': {
      margin: '0.5rem 0',
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start',
      flexDirection: 'row',
      '& button': {
        marginRight: '0.5rem',
      },
    },
  },
  button: {
    fontWeight: 'bold',
  },
}));

const getCardByType = (answer) => {
  switch (answer?.style) {
    case 'IMAGE_TITLE':
      return <BeautyImageCard answer={answer} />;
    case 'TITLE_SUBTITLE':
      return <BeautyTextCard answer={answer} />;
    case 'IMAGE_TITLE_CHOICES':
      return <BeautyChoicesCard answer={answer} />;
    default:
      return <div />;
  }
};

const BeautyProfileAnswer = ({
  onUpdateBeautyProfile,
  onFetchProfileAnswer,
  onFetchProfileAnswers,
  navigateTo,
  answer,
  answers,
  match,
  loading,
}) => {
  const classes = useStyles();
  const showArrow = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const id = match?.params?.id;
  useEffect(() => {
    onFetchProfileAnswers();
  }, [onFetchProfileAnswers]);

  useEffect(() => {
    onFetchProfileAnswer(id);
  }, [id, onFetchProfileAnswer]);

  const getNextQuestion = useCallback(() => {
    let nextQuestion = parseInt(id) + 1;

    for (let i = parseInt(id); i <= answers?.detailedAnswers?.length; i++) {
      if (!answers?.detailedAnswers[i]?.skip) {
        nextQuestion = i + 1;
        break;
      } else {
        const canShow = canShowQuestion(answers, answers?.detailedAnswers[i]);
        if (canShow) {
          nextQuestion = i + 1;
          break;
        }
      }
    }
    return nextQuestion;
  }, [answers, id]);

  const handleUpdateAndNextQuestion = useCallback(async () => {
    onUpdateBeautyProfile(true);
    const nextQuestion = getNextQuestion();
    history.push(`/perfil/perfil-de-beleza/${nextQuestion}`);
  }, [onUpdateBeautyProfile, getNextQuestion]);

  return (
    <Loading isLoading={loading}>
      <div className={classes.root}>
        <BackButtonMobile
          onClick={() => navigateTo('/perfil/perfil-de-beleza')}
          visible={!showArrow}
        />
        <div className={classes.mainBox}>
          <div className={classes.titleBox}>
            <BackButton
              onClick={() => navigateTo('/perfil/perfil-de-beleza')}
              visible={showArrow}
            />
            <Typography className={classes.title}>Perfil de beleza</Typography>
          </div>
          <Typography className={classes.subtitle} gutterBottom>
            Mantenha suas características e preferências atualizadas para prepararmos a sua caixa.
          </Typography>
        </div>

        <div className={classes.contentBox}>
          <BeautyCategoryCard />

          {getCardByType(answer)}

          <div className={classes.contentButtonBox}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigateTo(`/perfil/perfil-de-beleza`)}
            >
              voltar
            </Button>
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={() => onUpdateBeautyProfile()}
            >
              SALVAR
            </Button>
            {id < answers?.detailedAnswers?.length && (
              <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                onClick={() => handleUpdateAndNextQuestion()}
              >
                SALVAR E AVANÇAR
              </Button>
            )}
          </div>
        </div>
      </div>
    </Loading>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { answer, answers, loading } = state.profile;

  return { answer, match: ownProps?.match, loading, answers };
};

const mapDispatchToProps = (dispatch) => ({
  onFetchProfileAnswer: (id) => dispatch(fetchProfileAnswer(id)),
  onFetchProfileAnswers: () => dispatch(fetchProfileAnswers()),
  onUpdateBeautyProfile: (shouldGoNext) => dispatch(updateBeautyProfile(shouldGoNext)),
  navigateTo: (path) => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BeautyProfileAnswer);
