import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import TitleAndDescription from '../TitleAndDescription/TitleAndDescription';
import { black, redOrange } from '../../themes/colors';

type ExtractHistoryData = {
  extract: {
    amount: number,
    monthEdition: string,
    recurrence: number,
    status: string,
  },
  plan?: any,
};

const useStyles = makeStyles((theme) => ({
  mobileMain: {
    border: '1px solid #595959',
    borderRadius: '3px',
    padding: '14px 28px 14px 20px',
    margin: '14px 20px',
    position: 'relative',
  },
  arrow: {
    position: 'absolute',
    width: '0px',
    height: '0px',
    right: '5px',
    top: '6px',
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderTop: `5px solid ${redOrange}`,
  },
  content: {
    padding: '16x 10px 16px 0px ',
    marginTop: '14px',
  },
  mobileTitle: {
    color: '#595959',
    fontSize: '16px',
    fontWeight: 400,
  },
  mobileSubtitle: {
    color: black,
    fontWeight: 500,
  },
  computerMain: {
    display: 'flex',
    justifyContent: 'space-around',
    padding: '33px 0px 40px 0px',
  },
  subtitle: {
    color: '#595959',
    fontSize: '16px',
    fontWeight: 400,
  },
  hr: {
    width: '1px',
    backgroundColor: '#D9D9D9',
  },
}));

const ExtractHistory = ({ extract, plan }): ExtractHistoryData => {
  const classes = useStyles();
  const tablet = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const [isOpen, setIsOpen] = useState(false);

  function getMonthName(monthEdition) {
    switch (monthEdition) {
      case 1:
        return 'Janeiro';
      case 2:
        return 'Fevereiro';
      case 3:
        return 'Março';
      case 4:
        return 'Abril';
      case 5:
        return 'Maio';
      case 6:
        return 'Junho';
      case 7:
        return 'Julho';
      case 8:
        return 'Agosto';
      case 9:
        return 'Setembro';
      case 10:
        return 'Outubro';
      case 11:
        return 'Novembro';
      case 12:
        return 'Dezembro';
      default:
        return 'Janeiro';
    }
  }

  function getStatus(status) {
    switch (status) {
      case 'PENDING_PAYMENT':
        return 'Pendente';
      case 'PAID':
        return 'Paga';
      case 'CANCELED':
        return 'Cancelada';
      case 'REFUNDED':
        return 'Reembolsada';
      default:
        return 'PENDENTE';
    }
  }

  function getPaymentModel(plan) {
    let paymentModel = 'PIX';
    if (plan?.hasInstallments) {
      return (paymentModel = 'PIX Valor cheio');
    }
    switch (plan?.recurrencePeriod) {
      case 'ANNUAL':
        return (paymentModel += ' 12 vezes');
      case 'BIANNUAL':
        return (paymentModel += ' 6 vezes');
      case 'QUARTERLY':
        return (paymentModel += ' 3 vezes');
      case 'MONTH':
        return (paymentModel += ' Plano mensal');
      default:
        break;
    }
    return paymentModel;
  }

  return (
    <>
      {!tablet ? (
        <>
          <div className={classes.mobileMain} onClick={() => setIsOpen(!isOpen)}>
            <div className={classes.arrow} style={!isOpen ? {} : { transform: 'rotate(180deg)' }} />
            <span className={classes.mobileTitle}>Edição</span>
            <span className={classes.mobileSubtitle} style={{ marginLeft: '5px' }}>
              Setembro
            </span>
            <span className={classes.mobileTitle} style={{ float: 'right' }}>
              2023
            </span>

            {isOpen && (
              <div className={classes.content}>
                <div>
                  <TitleAndDescription
                    props={{
                      title: 'Status',
                      description: getStatus(extract?.status),
                      fontWeight: '500',
                      fontSize: '16px',
                    }}
                  />
                  <span className={classes.subtitle}>27/04/2023</span>
                </div>
                <TitleAndDescription
                  props={{
                    title: 'Valor',
                    description: `R$ ${extract?.amount?.toFixed(2)?.replace('.', ',')}`,
                    fontWeight: '500',
                    fontSize: '16px',
                  }}
                />
                {plan && (
                  <TitleAndDescription
                    props={{
                      title: 'Modelo de Pagamento',
                      description: getPaymentModel(plan),
                      fontWeight: '500',
                      fontSize: '16px',
                    }}
                  />
                )}
                <TitleAndDescription
                  props={{
                    title: 'Parcela',
                    description: `${extract?.recurrence}A`,
                    fontWeight: '500',
                    fontSize: '16px',
                  }}
                />
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <div className={classes.computerMain}>
            <div>
              <TitleAndDescription
                props={{
                  title: 'Edição',
                  description: getMonthName(extract?.monthEdition),
                  fontWeight: '500',
                  fontSize: '16px',
                }}
              />
              <span className={classes.subtitle}>2023</span>
            </div>
            <div>
              <TitleAndDescription
                props={{
                  title: 'Status',
                  description: getStatus(extract?.status),
                  fontWeight: '500',
                  fontSize: '16px',
                }}
              />
              <span className={classes.subtitle}>27/04/2023</span>
            </div>
            <TitleAndDescription
              props={{
                title: 'Valor',
                description: `R$ ${extract?.amount?.toFixed(2)?.replace('.', ',')}`,
                fontWeight: '500',
                fontSize: '16px',
              }}
            />
            <TitleAndDescription
              props={{
                title: 'Modelo de Pagamento',
                description: 'PIX 12 Vezes',
                fontWeight: '500',
                fontSize: '16px',
              }}
            />
            <TitleAndDescription
              props={{
                title: 'Parcela',
                description: `${extract?.recurrence}A`,
                fontWeight: '500',
                fontSize: '16px',
              }}
            />
          </div>

          <hr className="" />
        </>
      )}
    </>
  );
};
export default ExtractHistory;
