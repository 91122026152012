import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { routerReducer } from 'react-router-redux';
import * as fromTypes from '../types/auth';
import auth from './auth';
import dashboard from './dashboard';
import profile from './profile';
import registration from './registration';
import subscription from './subscription';
import face from './face';
import purchase from './purchase';
import notification from './notification';
import wallet from './wallet';
import pixExtract from './pixExtract';

const appReducer = combineReducers({
  routing: routerReducer,
  form: formReducer,
  auth,
  dashboard,
  profile,
  registration,
  subscription,
  face,
  purchase,
  notification,
  wallet,
  pixExtract,
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === fromTypes.SIGN_OUT) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
