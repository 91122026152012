// @flow
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { selectRetentionOffer } from '../../store/actions';
import { black, cinzaEscuro, white } from '../../themes/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 4),
    borderRadius: '1em',
    padding: theme.spacing(2),
    minHeight: '5rem',
    background:
      'linear-gradient(180deg, rgba(201, 197, 255, 0.88) 0%, rgba(201, 197, 255, 0.76) 100%)',
    [theme.breakpoints.up('md')]: {
      overflow: 'hidden',
    },
  },
  title: {
    fontFamily: 'open sans',
    fontWeight: 'bold',
    fontSize: '1.3em',
    color: black,
    padding: theme.spacing(0, 6),
  },
  subtitle: {
    fontFamily: 'open sans',
    fontSize: '1em',
    textAlign: 'justify',
    color: cinzaEscuro,
    padding: theme.spacing(2, 1),
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  btn: {
    color: white,
    fontFamily: 'open sans',
    fontWeight: 'bold',
    background: black,
    borderRadius: '2em',
    padding: theme.spacing(1, 3),
    marginBottom: theme.spacing(1),
    '&:hover': {
      background: black,
    },
  },
  mainCardInfo: {
    maxWidth: '60%',
  },
  mainCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  mainCardIcon: {
    fontSize: '6em',
    fontFamily: 'open sans',
    fontWeight: 'bold',
    marginRight: theme.spacing(2),
  },
  imageCard: {
    alignSelf: 'center',
    marginRight: theme.spacing(2),
    '& img': {
      width: '7rem',
    },
  },
  flowerImg: {
    position: 'absolute',
    width: '15%',
    maxWidth: '6em',
    [theme.breakpoints.up('md')]: {
      width: '5.5em',
    },
  },
  info: {
    fontStyle: 'italic',
  },
  highlight: {
    fontWeight: 600,
  },
}));

const RetentionOffer = ({ offer }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { tenantName } = useSelector((state) => state.auth);

  const onChooseOffer = (accepted) =>
    dispatch(selectRetentionOffer(offer?.id, { ...offer, accepted }));

  const formatAmount = (value) => (value / 100).toFixed(2);
  const formatPercent = (value) => value * 100;

  const AdditionalBoxCard = ({ data }) => {
    return (
      <div className={classes.mainCard}>
        <div className={classes.imageCard}>
          {tenantName === 'uaubox' ? (
            <img
              src="https://d2ivdm85owtv7v.cloudfront.net/images/customer-area/box_generica.jpg"
              alt="UAUBox Default Genérica"
            />
          ) : (
            <img
              src="https://d2ivdm85owtv7v.cloudfront.net/images/customer-area/box-generica-scarlet.png"
              alt="Scarlet by UAUBox Genérica"
            />
          )}
        </div>
        <div className={classes.mainCardInfo}>
          <Typography className={classes.subtitle} align="center">
            {`Ao realizar a renovação de seu plano e continuar com a gente nesta jornada de autoestima iremos enviar junto na sua primeira Box um item adicional que iremos selecionar especialmente para você.`}
          </Typography>
        </div>
      </div>
    );
  };

  const PercentageCard = ({ data }) => {
    return (
      <div className={classes.mainCard}>
        <div className={classes.mainCardIcon}>%</div>
        <div className={classes.mainCardInfo}>
          <Typography className={classes.subtitle} align="center">
            Preparamos um desconto especial para você! Ao realizar a renovação de seu plano e
            continuar com a gente nesta jornada de autoestima você terá um desconto de{' '}
            <span className={classes.highlight}>{formatPercent(data.percentage)}%</span> na fatura
            da sua Box.
            <br />
            <span className={classes.info}>
              {data.cycles > 1
                ? `* O desconto será aplicado nas proximas ${data.cycles} faturas.`
                : '* O desconto será aplicado apenas na proxima fatura.'}
            </span>
          </Typography>
        </div>
      </div>
    );
  };

  const AdditionalItemCard = ({ data }) => {
    return (
      <div className={classes.mainCard}>
        {data?.image ? (
          <div className={classes.imageCard}>
            <img className={classes.productImg} src={data?.image} alt={data?.name} />
          </div>
        ) : (
          <div className={classes.mainCardIcon}>?</div>
        )}
        <div className={classes.mainCardInfo}>
          <Typography className={classes.subtitle} align="center">
            Ao realizar a renovação de seu plano e continuar com a gente nesta jornada de autoestima
            iremos enviar junto na sua primeira Box o{' '}
            {data.name ? (
              <>
                <span className={classes.highlight}>{data.name}</span>
              </>
            ) : (
              'Ao realizar a renovação de seu plano e continuar com a gente nesta jornada de autoestima iremos enviar junto na sua primeira Box um item adicional que iremos selecionar especialmente para você.'
            )}
          </Typography>
        </div>
      </div>
    );
  };

  const FirstBoxCard = ({ data }) => {
    return (
      <div className={classes.mainCard}>
        <div className={classes.mainCardIcon}>%</div>
        <div className={classes.mainCardInfo}>
          <Typography className={classes.subtitle} align="center">
            Ao realizar a renovação de seu plano e continuar com a gente nesta jornada de autoestima
            você terá um desconto especial e a sua primeira Box sairá por{' '}
            <span className={classes.highlight}>R${formatAmount(data.price)}.</span>
            <br />
            <span className={classes.info}>
              * O valor promocional será válido apenas na primeira Box após renovação do plano.
            </span>
          </Typography>
        </div>
      </div>
    );
  };

  const buildCard = ({ offerType, additionalData }) => {
    switch (offerType) {
      case 'ADDITIONAL_BOX':
        return <AdditionalBoxCard data={additionalData} />;
      case 'DISCOUNT_PERCENTAGE':
        return <PercentageCard data={additionalData} />;
      case 'FIRST_BOX_EXACT_PRICE':
        return <FirstBoxCard data={additionalData} />;
      case 'ADDITIONAL_PRODUCT':
        return <AdditionalItemCard data={additionalData} />;
      default:
        return null;
    }
  };
  return offer ? (
    <div className={classes.root}>
      <img
        className={classes.flowerImg}
        src={`${process.env.REACT_APP_AWS_CLOUDFRONT}/images/customer-area/flores_produto.svg`}
        alt="Flores"
      />
      <Typography className={classes.title} align="center">
        Preparamos uma oferta especial para você!
      </Typography>

      {buildCard(offer)}

      <div className={classes.btnContainer}>
        <Button className={classes.btn} onClick={() => onChooseOffer(true)}>
          QUERO!
        </Button>
        <Button className={classes.btn} onClick={() => onChooseOffer(false)}>
          FICAREI DE FORA
        </Button>
      </div>
    </div>
  ) : null;
};

export default RetentionOffer;
