import React from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'react-router-redux';
import { Typography, Button, Divider, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { black, silverChalice, tundora } from '../../themes/colors';
import moment from 'moment';
import 'moment/locale/pt-br';
import { boxStatus } from '../utils/boxUtils';
import Feedback from '../../components/Feedback';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '1rem 0',
  },
  title: {
    color: silverChalice,
  },
  titleHighlight: {
    color: black,
    fontSize: '1.2em',
    textTransform: 'capitalize',
  },
  subtitle: {
    color: tundora,
    fontSize: '0.9em',
  },
  buttonBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: '2rem 0',
    '& button': {
      margin: '0.5rem 0',
    },
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
      margin: 0,
    },
  },
}));

const BoxTrackingCard = ({
  orderId,
  boxOrderId,
  edition,
  lastTimelineEvent,
  shippingAddress,
  feedback,
  timeline,
  kind,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const desktop = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const status = boxStatus.find((i) => i.status === lastTimelineEvent?.status);

  const boxCanceled = timeline.some((box) => box.status === 'CANCELED');

  return (
    <div className={classes.root}>
      <Grid
        container
        alignItems={desktop ? 'center' : 'flex-start'}
        direction={desktop ? 'row' : 'column'}
        spacing={2}
      >
        {/* <Grid item xd={12} md={2}>
          {`Imagem da Box`}
        </Grid> */}

        <Grid item xd={12} md={2}>
          <Typography className={classes.title} gutterBottom>
            Edição
          </Typography>
          <Typography className={classes.titleHighlight} gutterBottom>
            {kind === 'SINGLE_PURCHASE_BOX'
              ? 'Box adicional'
              : moment(edition?.date).format('MMMM')}
          </Typography>
          <Typography className={classes.subtitle} gutterBottom>
            {moment(edition?.date).format('YYYY')}
          </Typography>
        </Grid>

        <Grid item xd={12} md={2}>
          <Typography className={classes.title} gutterBottom>
            Status
          </Typography>
          <Typography className={classes.titleHighlight} gutterBottom>
            {boxCanceled ? 'Cancelada' : status?.title}
          </Typography>
          <Typography className={classes.subtitle} gutterBottom>
            {moment(lastTimelineEvent?.createdAt).format('DD/MM/YYYY - HH:mm')}
          </Typography>
        </Grid>

        <Grid item xd={12} md={4}>
          <Typography className={classes.title} gutterBottom>
            Endereço de entrega
          </Typography>
          <Typography className={classes.titleHighlight} gutterBottom>
            {`${shippingAddress?.street} ${shippingAddress?.number} ${
              shippingAddress?.complement ? '- ' + shippingAddress?.complement : ''
            } - ${shippingAddress?.postalCode} - ${shippingAddress?.district} - ${
              shippingAddress?.city
            } - ${shippingAddress?.state}`}
          </Typography>
        </Grid>

        <Grid item xd={12} md={2}>
          <div className={classes.buttonBox}>
            {!feedback && lastTimelineEvent?.status === 'DELIVERED' && (
              <Feedback
                page="box"
                buttonText="Dar Feedback"
                orderId={boxOrderId}
                edition={edition}
              />
            )}

            <Button
              color="primary"
              variant="text"
              onClick={() => dispatch(push(`/sua-box/detalhes/${orderId}`))}
            >
              Ver detalhes da box
            </Button>
          </div>
        </Grid>
      </Grid>

      <Divider />
    </div>
  );
};

export default BoxTrackingCard;
