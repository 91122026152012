import React from 'react';

const VisaIcon = ({ className = '' }) => (
  <svg
    className={className}
    viewBox="0 0 27 20"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <rect y="0.307678" width="26.25" height="19.6923" rx="4" fill="white" />
    <path
      d="M0 4C0 1.79086 1.79086 0 4 0H22.25C24.4591 0 26.25 1.79086 26.25 4V7.07692H0V4Z"
      fill="#1F3771"
    />
    <path
      d="M0 12.923H26.25V16C26.25 18.2091 24.4591 20 22.25 20H4C1.79086 20 0 18.2091 0 16V12.923Z"
      fill="#EEB63E"
    />
  </svg>
);

export default VisaIcon;
