// @flow
import * as fromTypes from '../types/subscription';

type State = {
  loading: boolean,
  submiting: boolean,
  subscription: Object,
  cancelReasons: Object,
  billingDate: Object,
  paymentHistory: Array<Object>,
  paymentProfiles: Array<Object>,
  paymentProfile: Object,
  paymentMethods: Array<Object>,
  upgradePlanOptions: Array<Object>,
};

export const initialState = {
  loading: false,
  submiting: false,
  subscription: {},
  cancelReasons: {},
  billingDate: {},
  paymentHistory: [],
  paymentProfiles: [],
  paymentProfile: {},
  paymentMethods: [],
  upgradePlanOptions: [],
};

const updateDetails = (state, { payload }) => ({
  ...state,
  subscription: payload,
  loading: false,
});

const updateCancellationReasons = (state, { payload }) => ({
  ...state,
  cancelReasons: payload,
  loading: false,
});

const updateBillingDate = (state, { payload }) => ({
  ...state,
  billingDate: payload,
  loading: false,
});

const updatePaymentMethods = (state, { payload }) => ({
  ...state,
  paymentMethods: payload,
  loading: false,
});

const updatePaymentProfiles = (state, { payload }) => ({
  ...state,
  paymentProfiles: payload,
  loading: false,
});

const updatePaymentHistory = (state, { payload }) => ({
  ...state,
  paymentHistory: payload,
  loading: false,
});

const updatePaymentProfile = (state, { payload }) => {
  const paymentProfiles = state.paymentProfiles.map((i) =>
    i?.id === payload.id ? payload : { ...i, default: !payload?.default }
  );

  return {
    ...state,
    paymentProfile: payload,
    paymentProfiles,
    loading: false,
  };
};

const updateUpgradePlanOptions = (state, { payload }) => {
  const updatedPlan = [...[state.subscription?.plan], ...payload];

  return { ...state, upgradePlanOptions: updatedPlan };
};

const setLoading = (state, loading) => ({ ...state, loading });

const setSubmiting = (state, submiting) => ({ ...state, submiting });

const reducer = (state: State = initialState, action: fromTypes.SubscriptionAction) => {
  switch (action.type) {
    case fromTypes.FETCH_SUBSCRIPTION_DETAILS:
    case fromTypes.FETCH_SUBSCRIPTION_CANCEL_REASON:
    case fromTypes.FETCH_SUBSCRIPTION_BILLING_DATE:
    case fromTypes.FETCH_PAYMENT_METHODS:
    case fromTypes.FETCH_PAYMENT_PROFILES:
    case fromTypes.FETCH_PAYMENT_HISTORY:
    case fromTypes.UPDATE_PAYMENT_PROFILE:
    case fromTypes.UPGRADE_SUBSCRIPTION_PLAN:
      return setLoading(state, true);
    case fromTypes.FETCH_SUBSCRIPTION_DETAILS_SUCCESS:
    case fromTypes.UPGRADE_SUBSCRIPTION_PLAN_SUCCESS:
      return updateDetails(state, action);
    case fromTypes.FETCH_SUBSCRIPTION_CANCEL_REASON_SUCCESS:
      return updateCancellationReasons(state, action);
    case fromTypes.FETCH_SUBSCRIPTION_BILLING_DATE_SUCCESS:
      return updateBillingDate(state, action);
    case fromTypes.FETCH_PAYMENT_METHODS_SUCCESS:
      return updatePaymentMethods(state, action);
    case fromTypes.FETCH_PAYMENT_PROFILES_SUCCESS:
      return updatePaymentProfiles(state, action);
    case fromTypes.FETCH_PAYMENT_HISTORY_SUCCESS:
      return updatePaymentHistory(state, action);
    case fromTypes.UPDATE_PAYMENT_PROFILE_SUCCESS:
      return updatePaymentProfile(state, action);
    case fromTypes.CANCEL_SUBSCRIPTION:
      return setSubmiting(state, true);
    case fromTypes.CANCEL_SUBSCRIPTION_SUCCESS:
      return initialState;
    case fromTypes.CANCEL_SUBSCRIPTION_ERROR:
      return setSubmiting(state, false);
    case fromTypes.FETCH_UPGRADE_PLAN_OPTION_SUCCESS:
      return updateUpgradePlanOptions(state, action);
    case fromTypes.FETCH_SUBSCRIPTION_DETAILS_ERROR:
    case fromTypes.FETCH_SUBSCRIPTION_CANCEL_REASON_ERROR:
    case fromTypes.FETCH_SUBSCRIPTION_BILLING_DATE_ERROR:
    case fromTypes.FETCH_PAYMENT_METHODS_ERROR:
    case fromTypes.FETCH_PAYMENT_PROFILES_ERROR:
    case fromTypes.FETCH_PAYMENT_HISTORY_ERROR:
    case fromTypes.UPDATE_PAYMENT_PROFILE_ERROR:
    case fromTypes.UPGRADE_SUBSCRIPTION_PLAN_ERROR:
      return setLoading(state, false);
    default:
      return state;
  }
};

export default reducer;
