// @flow
import * as fromTypes from '../types/auth';

export const signIn = (tenant: string, payload: object): fromTypes.AuthAction => ({
  type: fromTypes.SIGN_IN,
  tenant,
  payload,
});

export const signInSuccess = (payload: any, tenant: string): fromTypes.AuthAction => ({
  type: fromTypes.SIGN_IN_SUCCESS,
  payload,
  tenant,
});
export const signInError = (payload: any): fromTypes.AuthAction => ({
  type: fromTypes.SIGN_IN_ERROR,
  payload,
});

export const confirmEmail = (tenant: string, token: string): fromTypes.AuthAction => ({
  type: fromTypes.CONFIRM_EMAIL,
  tenant,
  token,
});

export const confirmEmailSuccess = (payload: any): fromTypes.AuthAction => ({
  type: fromTypes.CONFIRM_EMAIL_SUCCESS,
  payload,
});

export const confirmEmailError = (payload: any): fromTypes.AuthAction => ({
  type: fromTypes.CONFIRM_EMAIL_ERROR,
  payload,
});

export const checkToken = (token: string): fromTypes.AuthAction => ({
  type: fromTypes.CHECK_TOKEN_RECOVER_PASSWORD,
  token,
});

export const checkTokenSuccess = (payload: any): fromTypes.AuthAction => ({
  type: fromTypes.CHECK_TOKEN_RECOVER_PASSWORD_SUCCESS,
  payload,
});

export const checkTokenError = (payload: any): fromTypes.AuthAction => ({
  type: fromTypes.CHECK_TOKEN_RECOVER_PASSWORD_ERROR,
  payload,
});

export const signOut = (): fromTypes.AuthAction => ({
  type: fromTypes.SIGN_OUT,
});

export const signOutSuccess = (payload: any): fromTypes.AuthAction => ({
  type: fromTypes.SIGN_OUT_SUCCESS,
  payload,
});

export const signOutError = (payload: any): fromTypes.AuthAction => ({
  type: fromTypes.SIGN_OUT_ERROR,
  payload,
});

export const resetPassword = (tenant: string, email: string): fromTypes.AuthAction => ({
  type: fromTypes.RESET_PASSWORD,
  tenant,
  email,
});

export const updatePassword = (password: string, newPassword: string): fromTypes.AuthAction => ({
  type: fromTypes.UPDATE_PASSWORD,
  password,
  newPassword,
});

export const updatePasswordSuccess = (payload: any): fromTypes.AuthAction => ({
  type: fromTypes.UPDATE_PASSWORD_SUCCESS,
  payload,
});

export const updatePasswordError = (payload: any): fromTypes.AuthAction => ({
  type: fromTypes.UPDATE_PASSWORD_ERROR,
  payload,
});

export const confirmResetPassword = (
  newPassword: string,
  token: string,
  tenant: string
): fromTypes.AuthAction => ({
  type: fromTypes.CONFIRM_RESET_PASSWORD,
  newPassword,
  token,
  tenant,
});

export const confirmResetPasswordSuccess = (payload: any): fromTypes.AuthAction => ({
  type: fromTypes.CONFIRM_RESET_PASSWORD_SUCCESS,
  payload,
});

export const confirmResetPasswordError = (payload: any): fromTypes.AuthAction => ({
  type: fromTypes.CONFIRM_RESET_PASSWORD_ERROR,
  payload,
});

export const setTenantNameWithToken = (tenant: string): fromTypes.AuthAction => ({
  type: fromTypes.SET_TENANT_NAME_WITH_TOKEN,
  tenant,
});
