import React from 'react';

const CardIcon = ({ fill = '#fff', width = '100%', className = '', height = '100%' }) => (
  <svg
    className={className}
    viewBox="0 0 29.394 29.394"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g transform="translate(30.561 58.697)">
      <g transform="matrix(0.966, -0.259, 0.259, 0.966, -30.692, -52.45)">
        <rect fill={fill} opacity={0} width="24" height="24" transform="translate(0.136 0)" />
        <path
          fill={fill}
          d="M19,5H5A3,3,0,0,0,2,8v8a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V8A3,3,0,0,0,19,5ZM4,8A1,1,0,0,1,5,7H19a1,1,0,0,1,1,1V9H4Zm16,8a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V11H20Z"
          transform="translate(0 0.037)"
        />
        <path
          fill={fill}
          d="M7,15h4a1,1,0,0,0,0-2H7a1,1,0,0,0,0,2Z"
          transform="translate(0 0.037)"
        />
        <path
          fill={fill}
          d="M15,15h2a1,1,0,0,0,0-2H15a1,1,0,0,0,0,2Z"
          transform="translate(0 0.037)"
        />
      </g>
    </g>
  </svg>
);

export default CardIcon;
