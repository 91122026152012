import { put, takeLatest, select } from 'redux-saga/effects';
import axios from 'axios';
import * as fromTypes from '../types';
import * as fromActions from '../actions';
import { toast } from 'react-toastify';
import { push } from 'react-router-redux';

import { getTenantToken } from '../services/auth';

import { logException, logEvent } from '../../components/utils/GoogleAnalytics';

const BASE_API = `${process.env.REACT_APP_BASE_API_ADDRESS}/customer-area`;

const getSubscriptionIdFromState = (state) => state?.subscription?.subscription;

export function* fetchSubscriptionCancelReason(id) {
  try {
    const TENANT = yield select(getTenantToken);
    const response = yield axios.get(`${BASE_API}/${TENANT}/subscription/cancel-reasons`);

    if (response.status === 200) {
      yield put(fromActions.fetchSubscriptionCancelReasonSuccess(response.data));
    }
  } catch (error) {
    console.error(error);
    toast.error('Houve um erro inesperado, tente novamente.');
    logException(`fetchSubscriptionCancelReason: ${error.message}`, false);
    yield put(fromActions.fetchSubscriptionCancelReasonError(error));
  }
}

export function* fetchSubscriptionBillingDate() {
  try {
    const TENANT = yield select(getTenantToken);
    const response = yield axios.get(`${BASE_API}/${TENANT}/subscription/billing-date`);

    if (response.status === 200) {
      yield put(fromActions.fetchSubscriptionBillingDateSuccess(response.data));
    }
  } catch (error) {
    console.error(error);
    toast.error('Houve um erro inesperado, tente novamente.');
    logException(`fetchSubscriptionBillingDate: ${error.message}`, false);
    yield put(fromActions.fetchSubscriptionBillingDateError(error));
  }
}

export function* updateSubscriptionBillingDate({ dayOfMonth }) {
  try {
    const TENANT = yield select(getTenantToken);
    const response = yield axios.put(`${BASE_API}/${TENANT}/subscription/billing-date`, {
      dayOfMonth,
    });

    if (response.status === 200) {
      logEvent(
        'Update Billing Date',
        'dashboard.billing.updatesucess',
        'User update billing date sucessfully'
      );
      yield put(fromActions.updateSubscriptionBillingDateSuccess(response.data));
      toast.success('Data de cobrança atualizada com sucesso.');
    }
  } catch (error) {
    console.error(error);
    toast.error(error.message);
    logException(`updateSubscriptionBillingDate: ${error.message}`, false);
    yield put(fromActions.updateSubscriptionBillingDateError(error));
  }
}

export function* fetchSubscriptionDetails() {
  try {
    const TENANT = yield select(getTenantToken);
    const response = yield axios.get(`${BASE_API}/${TENANT}/subscription/details`);

    if (response.status === 200) {
      yield put(fromActions.fetchSubscriptionDetailsSuccess(response.data));
    }
  } catch (error) {
    console.error(error);
    toast.error('Houve um erro inesperado, tente novamente.');
    logException(`fetchSubscriptionDetails: ${error.message}`, false);
    yield put(fromActions.fetchSubscriptionDetailsError(error));
  }
}

export function* fetchPaymentMethods() {
  try {
    const TENANT = yield select(getTenantToken);
    const response = yield axios.get(`${BASE_API}/${TENANT}/payment/methods`);

    if (response.status === 200) {
      yield put(fromActions.fetchPaymentMethodsSuccess(response.data));
    }
  } catch (error) {
    console.error(error);
    logException(`fetchPaymentMethods: ${error.message}`, true);
    yield put(fromActions.fetchPaymentMethodsError(error));
  }
}

export function* fetchPaymentProfiles() {
  try {
    const TENANT = yield select(getTenantToken);
    const response = yield axios.get(`${BASE_API}/${TENANT}/payment/profiles`);

    if (response.status === 200) {
      yield put(fromActions.fetchPaymentProfilesSuccess(response.data));
    }
  } catch (error) {
    console.error(error);
    toast.error('Houve um erro inesperado, tente novamente.');
    logException(`fetchPaymentProfiles: ${error.message}`, false);
    yield put(fromActions.fetchPaymentProfilesError(error));
  }
}

export function* deletePaymentProfile({ id }) {
  try {
    const TENANT = yield select(getTenantToken);
    const response = yield axios.delete(`${BASE_API}/${TENANT}/payment/profile/${id}`);

    if (response.status === 200) {
      logEvent(
        'Delete Payment Profile Success',
        'dashboard.payment.deletesucess',
        'User delete paymenbt profilesucessfully'
      );
      yield put(fromActions.deletePaymentProfileSuccess(response.data));
      toast.success('Cartão de crédito excluido com sucesso.');
    }
  } catch (error) {
    console.error(error);
    toast.error(error.message);
    logException(`deletePaymentProfile ${id}: ${error.message}`, false);
    yield put(fromActions.deletePaymentProfileError(error));
  }
}

export function* cancelSubscription({ cancel }) {
  try {
    const { id } = yield select(getSubscriptionIdFromState);
    const cancelData = {
      cancelReason: cancel.cancelReason,
      cancelReasonComment: cancel.cancelReasonComment,
    };
    const response = yield axios.delete(`${BASE_API}/subscriptions/${id}`, { data: cancelData });

    if (response.status === 200) {
      yield put(push('/dashboard'));
      toast.success('Sua assinatura foi cancelada, vamos sentir saudades de você :(');
      logEvent(
        'Plan cancellation Success',
        'dashboard.cancellation.plan.success',
        'Cancellation plan sucessfully'
      );
      yield put(fromActions.cancelSubscriptionSuccess(cancel));
    }
  } catch (error) {
    console.error(error);
    toast.error('Houve um erro com o cancelamento. Tente novamente mais tarde.');
    logException(`cancellationPlan: ${error.message}`, false);
    yield put(fromActions.cancelSubscriptionError(error));
  }
}

export function* updatePaymentProfile({ profile }) {
  try {
    const TENANT = yield select(getTenantToken);
    const response = yield axios.put(
      `${BASE_API}/${TENANT}/subscriptions/update-credit-card`,
      profile
    );

    if (response.status === 200) {
      logEvent(
        'Update Payment Profile Success',
        'dashboard.payment.updatesuccess',
        'User update payment profilesucessfully'
      );
      yield put(fromActions.updatePaymentProfileSuccess(response.data));
      toast.success('Cartão de crédito atualizado com sucesso.');
      yield put(push('/perfil/seus-pagamentos'));
    }
  } catch (error) {
    console.error(error);
    toast.error('Houve um erro na alteração do seu cartão de crédito.');
    logException(`updatePaymentProfile ${profile}: ${error.message}`, false);
    yield put(fromActions.updatePaymentProfileError(error));
  }
}

export function* createPaymentProfile({ profile }) {
  try {
    const TENANT = yield select(getTenantToken);
    const response = yield axios.post(
      `${BASE_API}/${TENANT}/subscription/payment-profile`,
      profile
    );

    if (response.status === 200) {
      logEvent(
        'Create Payment Profile Success',
        'dashboard.payment.createsuccess',
        'User create payment profilesucessfully'
      );
      yield put(fromActions.createPaymentProfileSuccess(response.data));
      toast.success('Cartão de crédito cadastrado com sucesso.');
      yield put(push('/perfil/seus-pagamentos'));
    }
  } catch (error) {
    console.error(error);
    toast.error(error.message);
    logException(`createPaymentProfile ${profile}: ${error.message}`, false);
    yield put(fromActions.createPaymentProfileError(error));
  }
}

export function* fetchPaymentHistory() {
  try {
    const response = yield axios.get(`${BASE_API}/payment-history`);

    if (response.status === 200) {
      yield put(fromActions.fetchPaymentHistorySuccess(response.data));
    }
  } catch (error) {
    console.error(error);
    toast.error('Houve um erro inesperado, tente novamente.');
    logException(`fetchPaymentHistory: ${error.message}`, false);
    yield put(fromActions.fetchPaymentHistoryError(error));
  }
}

export function* fetchUpgradePlanOption() {
  try {
    const TENANT = yield select(getTenantToken);
    const response = yield axios.get(`${BASE_API}/${TENANT}/subscriptions/upgrade/plans`);

    if (response.status === 200) {
      yield put(fromActions.fetchUpgradePlanOptionSuccess(response.data));
    }
  } catch (error) {
    console.error(error);
    logException(`fetchUpgradePlanOption: ${error.message}`, false);
    yield put(fromActions.fetchUpgradePlanOptionError(error));
  }
}

export function* upgradeSubscriptionPlan({ subscriptionId, upgradePayload, profileType }) {
  try {
    const URL =
      profileType === 'STRIPE'
        ? `${BASE_API}/subscriptions/upgrade-stripe`
        : `${BASE_API}/subscriptions/${subscriptionId}/upgrade`;

    const response = yield axios.post(URL, upgradePayload);

    if (response.status === 200) {
      yield put(fromActions.upgradeSubscriptionPlanSuccess(response.data));
    }
  } catch (error) {
    console.error(error);
    logException(`upgradeSubscriptionPlan: ${error.message}`, false);
    yield put(fromActions.upgradeSubscriptionPlanError(error));
  }
}

export function* watchSubscription() {
  yield takeLatest(fromTypes.FETCH_SUBSCRIPTION_CANCEL_REASON, fetchSubscriptionCancelReason);
  yield takeLatest(fromTypes.FETCH_SUBSCRIPTION_BILLING_DATE, fetchSubscriptionBillingDate);
  yield takeLatest(fromTypes.UPDATE_SUBSCRIPTION_BILLING_DATE, updateSubscriptionBillingDate);
  yield takeLatest(fromTypes.FETCH_SUBSCRIPTION_DETAILS, fetchSubscriptionDetails);
  yield takeLatest(fromTypes.FETCH_PAYMENT_METHODS, fetchPaymentMethods);
  yield takeLatest(fromTypes.FETCH_PAYMENT_PROFILES, fetchPaymentProfiles);
  yield takeLatest(fromTypes.DELETE_PAYMENT_PROFILE, deletePaymentProfile);
  yield takeLatest(fromTypes.UPDATE_PAYMENT_PROFILE, updatePaymentProfile);
  yield takeLatest(fromTypes.CREATE_PAYMENT_PROFILE, createPaymentProfile);
  yield takeLatest(fromTypes.FETCH_PAYMENT_HISTORY, fetchPaymentHistory);
  yield takeLatest(fromTypes.CANCEL_SUBSCRIPTION, cancelSubscription);
  yield takeLatest(fromTypes.FETCH_UPGRADE_PLAN_OPTION, fetchUpgradePlanOption);
  yield takeLatest(fromTypes.UPGRADE_SUBSCRIPTION_PLAN, upgradeSubscriptionPlan);
}
