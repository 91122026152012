import { Button } from '@material-ui/core';
import useStyles from './promocard.style';
import React from 'react';
import { black, redOrange, lightPinkCard } from '../../themes/colors';
import { getTenantToken } from '../../store/services/auth';

export default function PromoCards() {
  const classes = useStyles();
  const tenant = getTenantToken();

  return (
    <div className={classes.root}>
      <div className={classes.cardPromo}>
        <img src="/images/uaublog.svg" alt="UAUBLOG" />
        <h3 className={classes.title}>UAUBlog</h3>
        <p className={classes.description}>Confira todos os artigos do nosso blog</p>
        <a href="https://blog.uaubox.com.br/" className={classes.link}>
          <Button className={classes.button} style={{ color: redOrange }}>
            ACESSAR
          </Button>
        </a>
      </div>

      <div className={classes.cardPromo} style={{ background: black }}>
        <img
          src={tenant === 'scarlet' ? '/images/store.svg' : '/images/scarletlogo.svg'}
          alt="ScarletBox"
        />
        <h3 className={classes.title} style={{ marginTop: tenant === 'scarlet' ? '0rem' : '1rem' }}>
          {tenant === 'scarlet' ? 'UAUStore' : 'UAUBox Scarlet'}
        </h3>
        <p className={classes.description}>
          {tenant === 'scarlet'
            ? 'Compre produtos com até 70% de desconto em toda a loja!'
            : 'Garanta agora a experiência premium da UAUBox'}
        </p>
        <a
          href={
            tenant === 'scarlet' ? 'https://store.uaubox.com.br' : 'https://lp.scarletbox.com.br/'
          }
          className={classes.link}
        >
          <Button className={classes.button} style={{ color: black }}>
            {tenant === 'scarlet' ? 'COMPRAR AGORA' : 'ASSINAR AGORA'}
          </Button>
        </a>
      </div>

      <div className={classes.cardPromo} style={{ background: lightPinkCard }}>
        <img src="/images/giftbox.svg" alt="ScarletBox" />
        <h3 className={classes.title}>Indique e Ganhe</h3>
        <p className={classes.description}>Convide todo mundo para a experiência de autocuidado</p>
        <a href="/indicacoes" className={classes.link}>
          <Button className={classes.button} style={{ color: lightPinkCard }}>
            ACESSAR
          </Button>
        </a>
      </div>
    </div>
  );
}
