import React from 'react';
import { Typography, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { black, wildSand } from '../../themes/colors';
import { FlatBrushIcon, SkinProductIcon, CombIcon } from '../icons';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    color: black,
    fontSize: '1em',
    minHeight: theme.spacing(4),
    padding: theme.spacing(0, 1),
    marginBottom: theme.spacing(1),
    '& svg': {
      width: '2em',
      marginRight: theme.spacing(1),
    },
  },
  divider: {
    height: theme.spacing(1),
    borderRadius: theme.spacing(2.25),
    background: wildSand,
  },
  active: {
    background: theme.palette.primary.main,
  },
}));

const BeautyCategoryCard = () => {
  const classes = useStyles();

  const Item = ({ title, icon, active }) => (
    <div>
      <div className={classes.container}>
        {icon}
        {active ? <Typography>{title}</Typography> : null}
      </div>
      <Divider className={classNames(classes.divider, { [classes.active]: active })} />
    </div>
  );

  return (
    <div className={classes.root}>
      <Item title="Maquiagem" icon={<FlatBrushIcon />} active />
      <Item title="Pele" icon={<SkinProductIcon />} />
      <Item title="Cabelo" icon={<CombIcon />} />
    </div>
  );
};

export default BeautyCategoryCard;
