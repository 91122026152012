export const FETCH_PIX_EXTRACT = '[PIX_EXTRACT] Fetch pix extract';
export const FETCH_PIX_EXTRACT_SUCCESS = '[PIX_EXTRACT] Fetch pix extract success';
export const FETCH_PIX_EXTRACT_ERROR = '[PIX_EXTRACT] Fetch pix extract error';

type FetchPixExtract = {
  type: typeof FETCH_PIX_EXTRACT,
};

type FetchPixExtractSuccess = {
  type: typeof FETCH_PIX_EXTRACT_SUCCESS,
  payload: any,
};

type FetchPixExtractError = {
  type: typeof FETCH_PIX_EXTRACT_ERROR,
  payload: any,
};

export type PixExtractAction = FetchPixExtract | FetchPixExtractSuccess | FetchPixExtractError;
