import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

type Props = {
  title: string,
  description: string,
  fontWeight: string,
  fontSize: string,
};

const useStyles = makeStyles((theme) => ({
  mainDiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    font: 'Inter',
    '@media (max-width: 780px)': {
      flexDirection: 'center',
    },
  },
  spanTitle: {
    fontSize: '14px',
    color: '#595959',
    fontWeight: 400,
  },
  spanDescription: {
    color: '#1D1D18',
  },
  '@media (max-width: 780px)': {
    mainDiv: {
      marginTop: '10px',
    },
  },
}));

const TitleAndDescription = ({ props }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.mainDiv}>
      <span className={classes.spanTitle}>{props.title}</span>
      <span
        className={classes.spanDescription}
        style={{ fontSize: props.fontSize, fontWeight: props.fontWeight }}
      >
        {props.description}
      </span>
    </div>
  );
};

export default TitleAndDescription;
