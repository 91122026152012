import React, { useState } from 'react';
import { Typography, Box, Divider, FormControlLabel, Radio } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { tundora, doveGray } from '../../themes/colors';
import { Field, clearFields } from 'redux-form';
import { useDispatch } from 'react-redux';
import { RenderedTextField, RenderedRadioGroup, RenderedRating } from '../wrapped';
import { toString } from 'lodash';
import { required } from '../utils/validators.js';

const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: '1rem',
  },
  title: {
    color: doveGray,
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  box: {
    marginBottom: '2rem',
  },
  cardTitle: {
    color: tundora,
    fontSize: '1.2em',
    marginBottom: '1rem',
  },
  formControlLabel: {
    margin: theme.spacing(0.5, 0),
  },
}));

const ManufacturedQuestion = ({ questions = [], activeStep }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [questionSelected, setQuestionSelected] = useState([]);

  const getValue = (newValue, sku, field, name) => {
    if (field !== 2) return;

    if (newValue <= 2) {
      setQuestionSelected([...questionSelected, sku]);
    } else {
      setQuestionSelected(questionSelected.filter((skuArray) => skuArray !== sku));
      dispatch(clearFields('feedbackForm', false, false, name));
    }
  };

  return questions?.map((item, index) =>
    activeStep === index ? (
      <div key={index}>
        <div className={classes.header}>
          <Typography className={classes.title} align="center" gutterBottom>
            {item?.name}
          </Typography>
          <Divider />
        </div>
        {item?.questions?.map(({ id, text, identifier, componentType, options }, index) => {
          switch (componentType) {
            case 'INPUT_RADIO':
              return (
                (identifier !== 'o_que_nao_te_agradou_no_produto' ||
                  (identifier === 'o_que_nao_te_agradou_no_produto' &&
                    questionSelected.includes(item?.sku))) && (
                  <Box key={index} className={classes.box}>
                    <Typography className={classes.cardTitle} align="left" gutterBottom>
                      {text}
                    </Typography>
                    <Field
                      name={`${item?.sku}-${toString(id)}-optionId`}
                      component={RenderedRadioGroup}
                    >
                      {options?.map((choice) => (
                        <FormControlLabel
                          key={choice.id}
                          className={classes.formControlLabel}
                          value={toString(choice.id)}
                          control={<Radio color="primary" />}
                          label={choice.text}
                          validate={[required]}
                        />
                      ))}
                    </Field>
                  </Box>
                )
              );
            case 'TEXT_AREA':
              return (
                <Box key={index} className={classes.box}>
                  <Typography className={classes.cardTitle} align="left" gutterBottom>
                    {text}
                  </Typography>
                  <Field
                    name={`${item?.sku}-${toString(id)}-text`}
                    type="text"
                    component={RenderedTextField}
                    placeholder="Deixe aqui seu comentário (opcional)"
                    multiline
                    rowsMax={3}
                    rows={3}
                  />
                </Box>
              );
            case 'STAR_RATING':
              return (
                <Box key={index} className={classes.box}>
                  <Typography className={classes.title} align="left" gutterBottom>
                    {text}
                  </Typography>
                  <Field
                    name={`${item?.sku}-${toString(id)}-optionId`}
                    component={RenderedRating}
                    onChange={(e, newValue, oldValue, name) =>
                      getValue(newValue, item?.sku, id, name.replace('-2-', '-17-'))
                    }
                    subtitle="Escolha de 1 a 5 para classificar."
                    validate={[required]}
                  />
                </Box>
              );
            default:
              return null;
          }
        })}
      </div>
    ) : null
  );
};

export default ManufacturedQuestion;
