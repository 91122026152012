// @flow

export const FETCH_DASHBOARD = '[DASHBOARD] Fetch dashboard';
export const FETCH_DASHBOARD_SUCCESS = '[DASHBOARD] Fetch dashboard success';
export const FETCH_DASHBOARD_ERROR = '[DASHBOARD] Fetch dashboard error';

export const FETCH_REFERRALS = '[DASHBOARD] Fetch referrals';
export const FETCH_REFERRALS_SUCCESS = '[DASHBOARD] Fetch referrals success';
export const FETCH_REFERRALS_ERROR = '[DASHBOARD] Fetch referrals error';

export const FETCH_REFERRAL_RANK = '[REFERRAL] Fetch referral rank';
export const FETCH_REFERRAL_RANK_SUCCESS = '[REFERRAL] Fetch referral rank success';
export const FETCH_REFERRAL_RANK_ERROR = '[REFERRAL] Fetch referral rank error';

export const FETCH_BLOG_FEED = '[DASHBOARD] Fetch blog feed';
export const FETCH_BLOG_FEED_SUCCESS = '[DASHBOARD] Fetch blog feed success';
export const FETCH_BLOG_FEED_ERROR = '[DASHBOARD] Fetch blog feed error';

export const FETCH_BLOG_SEARCH = '[DASHBOARD] Fetch blog search';
export const FETCH_BLOG_SEARCH_SUCCESS = '[DASHBOARD] Fetch blog search success';
export const FETCH_BLOG_SEARCH_ERROR = '[DASHBOARD] Fetch blog search error';

export const FETCH_VIDEO_FEED = '[DASHBOARD] Fetch video feed';
export const FETCH_VIDEO_FEED_SUCCESS = '[DASHBOARD] Fetch video feed success';
export const FETCH_VIDEO_FEED_ERROR = '[DASHBOARD] Fetch video feed error';

export const FETCH_VIDEO_SEARCH = '[DASHBOARD] Fetch video search';
export const FETCH_VIDEO_SEARCH_SUCCESS = '[DASHBOARD] Fetch video search success';
export const FETCH_VIDEO_SEARCH_ERROR = '[DASHBOARD] Fetch video search error';

export const FETCH_VIDEO_DETAIL = '[DASHBOARD] Fetch video detail';
export const FETCH_VIDEO_DETAIL_SUCCESS = '[DASHBOARD] Fetch video detail success';
export const FETCH_VIDEO_DETAIL_ERROR = '[DASHBOARD] Fetch video detail error';

export const CREATE_MEDIA_TRACKING = '[DASHBOARD] Save media tracking';
export const UPDATE_TERMS_OF_USE = '[DASHBOARD] Updating user terms of use.';

export const FETCH_PIX = '[DASHBOARD] Get pix information.';
export const FETCH_PIX_SUCCESS = '[DASHBOARD] Get pix information success!';
export const FETCH_PIX_ERROR = '[DASHBOARD] Get pix information error, probably is not a pix user.';

type FetchDashboard = {
  type: typeof FETCH_DASHBOARD,
};

type FetchDashboardSuccess = {
  type: typeof FETCH_DASHBOARD_SUCCESS,
  payload: any,
};

type FetchDashboardError = {
  type: typeof FETCH_DASHBOARD_ERROR,
  payload: any,
};

type FetchReferrals = {
  type: typeof FETCH_REFERRALS,
};

type FetchReferralsSuccess = {
  type: typeof FETCH_REFERRALS_SUCCESS,
  payload: any,
};

type FetchReferralsError = {
  type: typeof FETCH_REFERRALS_ERROR,
  payload: any,
};

type FetchReferralRank = {
  type: typeof FETCH_REFERRAL_RANK,
  payload: any,
};

type FetchReferralRankSuccess = {
  type: typeof FETCH_REFERRAL_RANK_SUCCESS,
  payload: any,
};

type FetchReferralRankError = {
  type: typeof FETCH_REFERRAL_RANK_ERROR,
};

type FetchBlogFeed = {
  type: typeof FETCH_BLOG_FEED,
};

type FetchBlogFeedSuccess = {
  type: typeof FETCH_BLOG_FEED_SUCCESS,
  payload: any,
};

type FetchBlogFeedError = {
  type: typeof FETCH_BLOG_FEED_ERROR,
  payload: any,
};

type FetchBlogSearch = {
  type: typeof FETCH_BLOG_FEED,
};

type FetchBlogSearchSuccess = {
  type: typeof FETCH_BLOG_FEED_SUCCESS,
  payload: any,
};

type FetchBlogSearchError = {
  type: typeof FETCH_BLOG_FEED_ERROR,
  payload: any,
};

type FetchVideoFeed = {
  type: typeof FETCH_VIDEO_FEED,
};

type FetchVideoFeedSuccess = {
  type: typeof FETCH_VIDEO_FEED_SUCCESS,
  payload: any,
};

type FetchVideoFeedError = {
  type: typeof FETCH_VIDEO_FEED_ERROR,
  payload: any,
};

type FetchVideoSearch = {
  type: typeof FETCH_VIDEO_SEARCH,
  searchTerm: any,
};

type FetchVideoSearchSuccess = {
  type: typeof FETCH_VIDEO_SEARCH_SUCCESS,
  payload: any,
};

type FetchVideoSearchError = {
  type: typeof FETCH_VIDEO_SEARCH_ERROR,
  payload: any,
};

type FetchVideoDetail = {
  type: typeof FETCH_VIDEO_DETAIL,
  id: String,
};

type FetchVideoDetailSuccess = {
  type: typeof FETCH_VIDEO_DETAIL_SUCCESS,
  payload: any,
};

type FetchVideoDetailError = {
  type: typeof FETCH_VIDEO_DETAIL_ERROR,
  payload: any,
};

type CreateMediaTracking = {
  type: typeof CREATE_MEDIA_TRACKING,
  payload: any,
};

type UpdateTermsOfUse = {
  type: typeof UPDATE_TERMS_OF_USE,
};

type FetchPix = {
  type: typeof FETCH_PIX,
};

type FetchPixSuccess = {
  type: typeof FETCH_PIX_SUCCESS,
  payload?: any,
};

type FetchPixError = {
  type: typeof FETCH_PIX_ERROR,
  payload?: any,
};

export type DashboardAction =
  | FetchDashboard
  | FetchDashboardSuccess
  | FetchDashboardError
  | FetchReferrals
  | FetchReferralsSuccess
  | FetchReferralsError
  | FetchReferralRank
  | FetchReferralRankSuccess
  | FetchReferralRankError
  | FetchBlogFeed
  | FetchBlogFeedSuccess
  | FetchBlogFeedError
  | FetchBlogSearch
  | FetchBlogSearchSuccess
  | FetchBlogSearchError
  | FetchVideoFeed
  | FetchVideoFeedSuccess
  | FetchVideoFeedError
  | FetchVideoSearch
  | FetchVideoSearchSuccess
  | FetchVideoSearchError
  | FetchVideoDetail
  | FetchVideoDetailSuccess
  | FetchVideoDetailError
  | FetchPix
  | FetchPixSuccess
  | FetchPixError
  | CreateMediaTracking
  | UpdateTermsOfUse;
