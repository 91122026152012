import { Badge, IconButton, InputAdornment, TextField, Typography } from '@material-ui/core';
import { Notifications, Search } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBlogSearch, fetchUserNotifications } from '../../../../store/actions';
import NotificationPopover from './NotificationPopover/NotificationPopover';
import useStylesHeaderDashboard from './styles';

const Header = ({ customer }) => {
  const classes = useStylesHeaderDashboard();
  const dispatch = useDispatch();
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [searched, setSearched] = useState(true);
  const [term, seTerm] = useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { notifications, notificationUnread } = useSelector((state) => state.notification);
  const cloudFrontApi = process.env.REACT_APP_AWS_CLOUDFRONT;
  const { tenantName } = useSelector((state) => state?.auth);

  const open = Boolean(anchorEl);

  const setTermSearch = (e) => {
    seTerm(e.target.value);
    setSearched(false);
  };

  useEffect(() => {
    dispatch(fetchUserNotifications());

    if (!searched) {
      setSearched(true);
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
      setTypingTimeout(
        setTimeout(() => {
          dispatch(fetchBlogSearch(term));
        }, 1000)
      );
    }
  }, [term, searched, typingTimeout, dispatch]);

  const handleClose = () => setAnchorEl(null);

  const handleOnClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <section className={classes.root}>
      <div className={classes.content}>
        <div className={classes.header}>
          <div className={classes.title}>
            <Typography variant="h6" align="left">
              Descobrir
            </Typography>
          </div>
          <div className={classes.notificationBox}>
            <IconButton aria-label="notification" onClick={handleOnClick}>
              <Badge badgeContent={notificationUnread} color="primary">
                <Notifications />
              </Badge>
            </IconButton>
          </div>
        </div>
        <div className={classes.mainContent}>
          <div className={classes.mainContentLeft}>
            <div className={classes.mainBox}>
              <Typography className={classes.mainBoxTitle} variant="h6" align="left" gutterBottom>
                Olá,{' '}
                <span style={{ textTransform: 'capitalize' }}>
                  {customer?.firstName && ` ${customer.firstName}. `}
                </span>
                Tudo bem?
              </Typography>
              <Typography
                className={classes.mainBoxContent}
                variant="body1"
                align="left"
                gutterBottom
              >
                Fique por dentro das suas próximas entregas, prêmios e conteúdos exclusivos!
              </Typography>
              <TextField
                placeholder="Busque seu conteúdo"
                margin="dense"
                fullWidth
                variant="outlined"
                className={classes.textField}
                onChange={setTermSearch}
                value={term}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
          <div className={classes.mainContentRight}>
            <div className={classes.imageContainer}>
              <img
                src={
                  tenantName === 'scarlet'
                    ? `${cloudFrontApi}/images/customer-area/home_banner2.png`
                    : `${cloudFrontApi}/images/customer-area/home-banner.png`
                }
                alt=""
                className={classes.image}
              />
            </div>
          </div>
        </div>
      </div>
      <NotificationPopover
        notifications={notifications}
        anchorEl={anchorEl}
        handleClose={handleClose}
        open={open}
      />
    </section>
  );
};

export default Header;
