import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'react-router-redux';
import { Typography, Grid, Button, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { tundora } from '../../themes/colors';
import { RenderedTextField, RenderedSelect } from '../../components/wrapped';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import { signIn } from '../../store/actions';
import { required, validateEmail } from '../../components/utils/validators';
import { isNil } from 'lodash';
import Loading from '../../components/Loading';
import { trim } from '../../components/utils/validators';
import queryString from 'query-string';
import planList from '../../components/utils/planList';

const REGISTRATION_WEBSITE_DEFAULT = process.env.REACT_APP_REGISTRATION_WEBSITE_DEFAULT;
const REGISTRATION_WEBSITE_SCARLET = process.env.REACT_APP_REGISTRATION_WEBSITE_SCARLET;

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    height: '100vh',
    '& h1': {
      fontSize: '2em',
      margin: '2rem 0 1rem',
    },
  },
  mainBox: {
    maxWidth: '25rem',
    margin: 'auto',
  },
  logoUau: {
    display: 'flex',
    justifyContent: 'center',
    margin: '3rem',
    height: '110px',
    position: 'relative',
    '& img': {
      position: 'absolute',
      visibility: 'hidden',
      opacity: '0',
      transition: 'all 700ms',
    },
    '& svg': {
      width: '6rem',
    },
  },
  imgLogin: {
    position: 'relative',
    display: 'flex',
    height: '768px',
    overflow: 'hidden',
    '& img': {
      position: 'absolute',
      visibility: 'hidden',
      opacity: '0',
      transition: 'all 700ms',
      maxHeight: '768px',
      left: 'calc(50% - 341px)',
    },
  },
  imgActive: {
    opacity: '1 !important',
    visibility: 'visible !important',
  },
  formBox: {
    padding: '0 1.5rem',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '1rem',
    '& button': {
      marginTop: theme.spacing(1),
      padding: '1rem',
      fontSize: '1.3em',
      fontWeight: 'bold',
    },
  },
  infoBox: {
    display: 'flex',
    justifyContent: 'center',
    '& div': {
      display: 'flex',
      flexDirection: 'column',
      width: 'fit-content',
    },
    '& button': {
      margin: theme.spacing(1, 0),
    },
    '& p': {
      margin: theme.spacing(1, 0),
      color: tundora,
    },
    '& a': {
      textDecoration: 'none',
      justifyContent: 'center',
      display: 'flex',
    },
  },
  grid: {
    height: '100%',
    overflowX: 'hidden',
  },
  subscribeBtn: {
    padding: '0.8rem 1rem',
    fontSize: '1.2em',
  },
}));

const SignIn = ({ handleSubmit, submitting, location }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const selector = formValueSelector('signInForm');
  const tenantSelected = selector(state, 'tenant');
  const { loading } = state.auth;
  const highDevice = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const [tenantAlreadySelected, setTenantAlreadySelected] = useState(false);
  const tenantList = [
    { tenant: 'uaubox', name: 'UAUBox' },
    { tenant: 'scarlet', name: 'Scarlet by UAUBox' },
  ];

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    const utm_source = params.get('utm_source');
    const utm_campaign = params.get('utm_campaign');
    const utm_medium = params.get('utm_medium');

    if (!utm_source) return;

    const data = {
      utm_source,
      utm_campaign,
      utm_medium,
    };

    localStorage.setItem('subscription-media-tracking', JSON.stringify(data));
  }, [location.search]);

  useEffect(() => {
    if (!tenantAlreadySelected) {
      setTenantAlreadySelected(true);
      const tenantLogout = sessionStorage.getItem('tenantLogout');
      if (isNil(tenantLogout)) {
        const registrationTenant = queryString.parse(window.location.search);
        if (registrationTenant.type) {
          dispatch(change('signInForm', 'tenant', registrationTenant.type));
        } else if (registrationTenant.plan) {
          const planIndex = planList.findIndex((plan) =>
            plan.ids.includes(registrationTenant.plan)
          );
          if (planIndex !== -1) {
            dispatch(change('signInForm', 'tenant', planList[planIndex].tenant));
          }
        } else {
          dispatch(change('signInForm', 'tenant', 'uaubox'));
        }
      } else {
        dispatch(change('signInForm', 'tenant', tenantLogout));
      }
    }
  }, [dispatch, tenantSelected, tenantAlreadySelected]);

  const onSubmit = ({ tenant, email, password }) => {
    dispatch(signIn(tenant, { email, password, grantType: 'password' }));
  };

  return (
    <Loading isLoading={loading}>
      <div className={classes.root}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
          <Grid container justify="center" alignItems="center" className={classes.grid}>
            <Grid item xs={12} md={6}>
              <div className={classes.mainBox}>
                <div className={classes.logoUau}>
                  <img
                    className={tenantSelected === 'uaubox' ? classes.imgActive : ''}
                    src="https://d2ivdm85owtv7v.cloudfront.net/images/uaubox/logo_uau_coral.png"
                    alt="UAUBox Default"
                  />
                  <img
                    className={tenantSelected === 'scarlet' ? classes.imgActive : ''}
                    src="https://d2ivdm85owtv7v.cloudfront.net/images/scarlet/logo-scarlet.png"
                    alt="UAUBox Default"
                  />
                </div>
                <Typography variant="h1" align="center">
                  Login
                </Typography>

                <div className={classes.formBox}>
                  <Field
                    name="tenant"
                    type="select"
                    component={RenderedSelect}
                    label="Tipo de assinatura"
                    validate={[required]}
                  >
                    {tenantList.map((i) => (
                      <MenuItem key={i.tenant} value={i.tenant}>
                        {i.name}
                      </MenuItem>
                    ))}
                  </Field>
                  <Field
                    name="email"
                    type="text"
                    component={RenderedTextField}
                    placeholder="E-mail"
                    normalize={trim}
                    validate={[required, validateEmail]}
                  />
                  <Field
                    name="password"
                    type="password"
                    component={RenderedTextField}
                    placeholder="Senha"
                    validate={[required]}
                  />
                  <Button
                    variant="contained"
                    className={classes.loginBtn}
                    color="secondary"
                    type="submit"
                    disabled={submitting}
                  >
                    Entrar
                  </Button>
                </div>

                <div className={classes.infoBox}>
                  <div>
                    <Button
                      variant="text"
                      color={tenantSelected === 'scarlet' ? 'secondary' : 'primary'}
                      onClick={() => dispatch(push('/recuperar-senha'))}
                    >
                      Esqueci minha senha
                    </Button>
                    <Typography align="center">Ainda não é assinante?</Typography>
                    <a
                      href={`${
                        tenantSelected === 'uaubox'
                          ? REGISTRATION_WEBSITE_DEFAULT
                          : REGISTRATION_WEBSITE_SCARLET
                      }`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button className={classes.subscribeBtn} variant="outlined" color="secondary">
                        Assine agora
                      </Button>
                    </a>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={6} style={{ position: 'relative' }}>
              {highDevice ? (
                <div className={classes.imgLogin}>
                  <img
                    className={`${tenantSelected === 'uaubox' ? classes.imgActive : null}`}
                    src="https://d2ivdm85owtv7v.cloudfront.net/images/customer-area/sign_in_image.png"
                    alt="UAUBox Default"
                  />
                  <img
                    className={`${tenantSelected === 'scarlet' ? classes.imgActive : null}`}
                    src="https://d2ivdm85owtv7v.cloudfront.net/images/customer-area/sign_in_image_scarlet.jpg"
                    alt="Scarlet by UAUBox"
                  />
                </div>
              ) : null}
            </Grid>
          </Grid>
        </form>
      </div>
    </Loading>
  );
};

export default reduxForm({
  form: 'signInForm',
  enableReinitialize: true,
})(SignIn);
