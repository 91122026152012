import React, { useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { fetchPaymentHistory } from '../../../../store/actions';
import { black, tundora, pippin, alto } from '../../../../themes/colors';
import BoxPaymentCard from '../../../../components/BoxPaymentCard';
import Loading from '../../../../components/Loading';
import { BackButton, BackButtonMobile } from '../../../../components/material';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    boxShadow: `1px 3px 6px ${alto}`,
    minHeight: '100vh',
  },
  mainBox: {
    background: pippin,
    padding: '1.5rem 2rem',
  },
  titleBox: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '2rem',
  },
  title: {
    color: black,
    fontSize: '2em',
    padding: '0 2.5rem',
    [theme.breakpoints.up('lg')]: {
      padding: '0 1rem',
    },
  },
  subtitle: {
    color: tundora,
    fontSize: '1.2em',
  },
  contentBox: {
    padding: '2rem',
  },
  previousBoxsubtitle: {
    color: tundora,
    fontWeight: 'bold',
    fontSize: '2em',
  },
}));

const PaymentHistory = ({
  onFetchPaymentHistory,
  navigateTo,
  paymentHistory,
  loading,
  tenantName,
}) => {
  const classes = useStyles();
  const showArrow = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  useEffect(() => {
    onFetchPaymentHistory();
  }, [onFetchPaymentHistory]);

  return (
    <Loading isLoading={loading}>
      <div className={classes.root}>
        <BackButtonMobile onClick={() => navigateTo('/perfil')} visible={!showArrow} />
        <div className={classes.mainBox}>
          <div className={classes.titleBox}>
            <BackButton onClick={() => navigateTo('/perfil')} visible={showArrow} />
            <Typography className={classes.title}>Histórico de pagamentos</Typography>
          </div>
          <Typography className={classes.subtitle} gutterBottom>
            Mantenha seus dados sempre atualizados, para receber sua caixa
          </Typography>
        </div>

        <div className={classes.contentBox}>
          {paymentHistory.length > 0 ? (
            paymentHistory?.map((i) => (
              <BoxPaymentCard key={i.orderId} payment={i} tenantName={tenantName} />
            ))
          ) : (
            <Typography className={classes.previousBoxsubtitle} gutterBottom>
              Você ainda não possui pagamentos
            </Typography>
          )}
        </div>
      </div>
    </Loading>
  );
};

const mapStateToProps = (state) => {
  const {
    subscription: { paymentHistory, loading },
    auth: { tenantName },
  } = state;
  return { paymentHistory, loading, tenantName };
};

const mapDispatchToProps = (dispatch) => ({
  onFetchPaymentHistory: () => dispatch(fetchPaymentHistory()),
  navigateTo: (path) => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentHistory);
