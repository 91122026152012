import React from 'react';

const TruckIcon = ({ fill = '#fff', width = '100%', className = '', height = '100%' }) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <rect
      id="Rectangle_134"
      data-name="Rectangle 134"
      width="24"
      height="24"
      fill="#fff"
      opacity="0"
    />
    <path
      d="M21.6,11.22,17,7.52V5a1.91,1.91,0,0,0-1.81-2H3.79A1.91,1.91,0,0,0,2,5V15a2,2,0,0,0,1.2,1.88A3,3,0,1,0,8.8,17h6.36a3,3,0,1,0,5.64,0H21a1,1,0,0,0,1-1V12a1,1,0,0,0-.4-.78ZM20,12.48V15H17V10.08ZM7,18a1,1,0,1,1-1-1A1,1,0,0,1,7,18Zm5-3H4V5H15V15Zm7,3a1,1,0,1,1-1-1A1,1,0,0,1,19,18Z"
      transform="translate(-0.136)"
      fill="#fff"
    />
  </svg>
);

export default TruckIcon;
