import { Button, Dialog, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { isEqual } from 'lodash';
import moment from 'moment';
import 'moment/locale/pt-br';
import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'react-router-redux';
import BoxTrackingDetail from '../../../../../components/BoxTrackingDetail';
import Feedback from '../../../../../components/Feedback';
import useStyles from './Styles.jsx';
import ToCheckout from './ToCheckout';

const Box = ({ box, subscription, referrals, loading }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { mostRecentEdition = {}, suggestedFeedback = {} } = box;

  const [feedbackDialogIsOpen, setFeedbackDialogIsOpen] = useState(true);

  const getMonth = (date) => moment(date).locale('pt').format('MMMM');

  function closePopup() {
    setFeedbackDialogIsOpen(false);
  }

  const goToTracking = () => {
    window.open(box?.mostRecentEdition?.shipping?.trackingUrl, '_blank');
  };

  return (
    <div className={classes.root}>
      <div>
        {!loading ? (
          <Fragment>
            {isEqual(subscription?.status, 'CANCELED') || !subscription ? (
              <ToCheckout />
            ) : (
              <div>
                {mostRecentEdition?.timeline && (
                  <Fragment>
                    <Typography className={classes.title} align="left" gutterBottom>
                      Sua Box
                    </Typography>
                    <Typography className={classes.subtitle} align="left" gutterBottom>
                      {mostRecentEdition?.edition?.date
                        ? ` Olha que demais! Em breve sua edição de ${getMonth(
                            mostRecentEdition?.edition?.date
                          )} estará na sua casa.`
                        : 'A box estará em breve na sua casa!'}
                    </Typography>
                    {/* <div className={classes.warning}>
                      <Typography>
                        Devido ao alto volume de pedidos, pode ser que sua box tenha um atraso de
                        até 7 dias úteis. Estamos empenhados em reduzir ao máximo esse prazo para
                        chegar o quanto antes na sua casa!
                      </Typography>
                    </div> */}

                    <BoxTrackingDetail
                      orientation="vertical"
                      timeline={mostRecentEdition.timeline}
                    />
                  </Fragment>
                )}

                {mostRecentEdition?.orderId && (
                  <>
                    {box?.mostRecentEdition?.shipping?.trackingUrl && (
                      <button className={classes.trackingButton} onClick={goToTracking}>
                        RASTREAR SUA BOX
                      </button>
                    )}

                    <div className={classes.moreInfoBox}>
                      <Button
                        variant="text"
                        color="primary"
                        onClick={() =>
                          dispatch(push(`/sua-box/detalhes/${mostRecentEdition?.orderId}`))
                        }
                      >
                        Ver detalhes da Box
                      </Button>
                    </div>
                  </>
                )}
                {suggestedFeedback && suggestedFeedback?.boxOrderId ? (
                  <div className={classes.feedbackBox}>
                    <div className={classes.feedbackBoxContent}>
                      <img
                        onClick={() => closePopup()}
                        className={classes.closeBtn}
                        alt="Fechar"
                        src="https://s3.amazonaws.com/static.uaubox.com.br/images/popups/close-button.png"
                      />
                      <Typography align="center" gutterBottom>
                        {`Conta pra gente… O que você achou da edição da UAUBox do mês de ${getMonth(
                          suggestedFeedback?.edition?.date
                        )}?`}
                      </Typography>

                      <div className={classes.feedbackBtnBox}>
                        <Feedback
                          buttonText="Dar Feedback"
                          orderId={suggestedFeedback?.boxOrderId}
                          edition={suggestedFeedback?.edition}
                        />
                      </div>
                    </div>
                    <Dialog open={feedbackDialogIsOpen}>
                      <div className={classes.feedbackBoxContent}>
                        <img
                          onClick={() => closePopup()}
                          className={classes.closeBtn}
                          alt="Fechar"
                          src="https://s3.amazonaws.com/static.uaubox.com.br/images/popups/close-button.png"
                        />
                        <Typography align="center" gutterBottom>
                          {`Conta pra gente… O que você achou da edição da UAUBox do mês de ${getMonth(
                            suggestedFeedback?.edition?.date
                          )}?`}
                        </Typography>

                        <div className={classes.feedbackBtnBox}>
                          <Feedback
                            buttonText="Dar Feedback"
                            orderId={suggestedFeedback?.boxOrderId}
                            edition={suggestedFeedback?.edition}
                          />
                        </div>
                      </div>
                    </Dialog>
                  </div>
                ) : null}
              </div>
            )}
          </Fragment>
        ) : (
          <div className={classes.previousBox}>
            <div className={classes.skeletonText}>
              <Skeleton variant="text" height={25} />
              <Skeleton variant="text" height={25} />
              <Skeleton variant="text" height={25} />
              <Skeleton variant="text" height={25} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Box;
