import { makeStyles } from '@material-ui/core/styles';
import {
  black,
  lightPink,
  redOrange,
  redOrangeHover,
  silverChalice,
  white,
} from '../../../../../themes/colors';

export default makeStyles((theme) => ({
  root: {
    margin: '1rem 0',
  },

  contentRight: {
    width: '100%',
    height: '100vh',
  },

  title: {
    color: black,
    fontSize: '1.3em',
    fontWeight: 'bold',
  },

  warning: {
    background: '#FDF7D9',
    padding: '16px',
    color: '#A01235',
    borderRadius: '4px',
  },

  subtitle: {
    color: silverChalice,
    fontSize: '1.3em',
  },
  moreInfoBox: {
    display: 'flex',
    padding: '1rem',
    justifyContent: 'center',
  },
  feedbackBox: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    margin: '1rem 0',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      margin: '1rem 0',
    },
    '& img': {
      width: '14px',
      margin: '1rem 0',
      maxWidth: '20px',
    },
  },
  feedbackBoxContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem',
    paddingTop: '60px',
    position: 'relative',
  },
  feedbackBtnBox: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '0.7rem',
    position: 'relative',
  },
  closeBtn: {
    position: 'absolute',
    top: '15px',
    right: '10px',
    padding: '10px',
    borderRadius: '10px',
    transition: '.3s',
    '&:hover': {
      cursor: 'pointer',
      filter: 'brightness(0.8)',
    },
  },
  couponBox: {
    backgroundSize: 'contain',
    minHeight: '20rem',
    backgroundColor: redOrange,
    display: 'flex',
    borderRadius: theme.spacing(1),
    justifyContent: 'center',
  },
  couponBoxContent: {
    color: white,
    padding: '2rem',
    display: 'flex',
    justifyContent: 'space-evenly',
    flexDirection: 'column',
  },
  couponBtnBox: {
    display: 'flex',
    justifyContent: 'center',
    '& button': {
      fontSize: '1rem',
      fontWeight: 'bold',
      color: lightPink,
      border: '1px solid #ffb8b8',
    },
  },
  couponBoxTitleHighlight: {
    fontSize: '1.5em',
    fontWeight: 'bold',
  },
  cancelledAccount: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    marginBottom: '2rem',
    '& p': {
      textAlign: 'center',
      margin: '1rem 0',
    },
  },
  imageBanner: {
    display: 'block',
    width: '100%',
    maxWidth: '250px',
    margin: '0 auto 2rem',
    [theme.breakpoints.up('md')]: {
      margin: '0 auto 1rem',
    },
  },
  skeletonText: {
    width: '300px',
  },

  subtotal: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '1em',
    marginBottom: '1em',
    color: black,
  },

  contentRightImage: {
    '& img': {
      maxHeight: '265px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '0 auto',
      [theme.breakpoints.up('sm')]: {
        maxWidth: '100%',
        maxHeight: '265px',
      },
    },
  },

  totalNum: {
    padding: '0px',
    fontWeight: 'bold',
    color: black,
    fontFamily: 'open sans',
    fontSize: '1.5em',
    [theme.breakpoints.up('md')]: {
      fontSize: '2em',
    },
  },

  totalContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '24px',
  },

  totalName: {
    padding: '0px',
    fontWeight: 'bold',
    color: black,
    fontSize: '1.5em',
    fontFamily: 'open sans',
    [theme.breakpoints.up('md')]: {
      fontSize: '2em',
    },
  },

  btnSignature: {
    width: '100%',
    marginTop: '24px',
    height: '60px',
    color: 'white',
    background: '#67CE67',
    borderRadius: '8px',
    fontSize: '18px',

    '&:hover': {
      background: '#6FDA6F',
    },
  },

  trackingButton: {
    background: redOrange,
    width: '100%',
    borderRadius: '0.25rem',
    outline: 'none',
    border: 'none',
    fontWeight: '700',
    color: 'white',
    lineHeight: '19px',
    textAlign: 'center',
    height: '3.125rem',
    '&:hover': {
      cursor: 'pointer',
      background: redOrangeHover,
    },
  },
}));
