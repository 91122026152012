import axios from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { toast } from 'react-toastify';
import { getEmailToken } from '../services/auth';

import * as fromTypes from '../types';
import * as fromActions from '../actions';

const BASE_API = `${process.env.REACT_APP_BASE_API_ADDRESS}/face`;

const hair_possibilities = ['wavy hair', 'straight hair'];
const beta_tags = ['blackhead', 'pores_jaw', 'forehead_Wrinkle', 'eye_pouch', 'acne'];

export function* sendImage({ payload }) {
  try {
    const { image } = payload;

    const response = yield axios.post(`${BASE_API}/start`, {
      img: image,
    });

    localStorage.removeItem('UAUBox:id');
    localStorage.setItem('UAUBox:FACE_UUID', response.data['user_uuid']);
    localStorage.setItem('UAUBox:VERIFY_STATUS', response.data['status']);
    localStorage.setItem('UAUBox:RESULT', response.data['result']);

    yield put(push('/se-descubra/carregando'));
  } catch (error) {
    toast.error('Falha ao comprimir a imagem, tente utilizar outra imagem.');
  }
}

export function* verifyStatus() {
  try {
    const statusURL = localStorage.getItem('UAUBox:VERIFY_STATUS');

    if (!statusURL)
      return {
        success: false,
        continue: false,
        message: 'Usuário não encontrado',
      };

    const response = yield axios.get(`${BASE_API}${statusURL}`);

    if (response.data.progress < 100) {
      return;
    }

    yield put(fromActions.handleLoadingFinished(true));
    yield put(push('/se-descubra/resultados'));
  } catch (error) {
    toast.error('Falha ao utilizar essa imagem.');
    yield put(push('/se-descubra/tirar-foto'));
  }
}

export function* getResult() {
  try {
    const uuid = localStorage.getItem('UAUBox:FACE_UUID');
    const email = getEmailToken();

    const response = yield axios.get(`${BASE_API}/${uuid}?email=${email}`);

    if (response.data.progress < 100) {
      yield put(fromActions.handleGetResult());
    }

    const result_tags = response.data.result.faceData.media.faces[0].tags;

    const hair_types = result_tags.filter((tag) =>
      hair_possibilities.includes(tag.name.toLowerCase())
    );

    const hair = hair_types?.find(
      (hair) =>
        hair.value === 'yes' ||
        hair_types.reduce((prev, current) => {
          return prev.confidence < current.confidence ? prev : current;
        }, {}).name === hair.name
    );

    const beta_data = response.data.result.skinData.result;

    const skinType = beta_data.skin_type.skin_type;

    const skin = {
      name:
        skinType === 0
          ? 'Pele oleosa'
          : skinType === 1
          ? 'Pele seca'
          : skinType === 2
          ? 'Pele normal'
          : skinType === 3
          ? 'Pele mista'
          : '',
      ...beta_data.skin_type.details[beta_data.skin_type.skin_type],
    };

    const result_parsed = [
      ...Object.keys(beta_data)
        .map((item) => ({
          name: item,
          confidence: beta_data[item].confidence,
          value: beta_data[item].value,
        }))
        .filter((beta) => beta_tags.includes(beta.name)),
      hair,
      skin,
    ];

    const image = response.data.result.image;

    if (image) {
      localStorage.setItem('UAUBox:image', `data:image/jpeg;base64,${response.data.result.image}`);
    } else {
      localStorage.removeItem('UAUBox:image');
    }

    if (response.data.result.id) {
      localStorage.setItem('UAUBox:id', response.data.result.id);
    } else {
      localStorage.removeItem('UAUBox:id');
    }

    localStorage.setItem('UAUBox:result', JSON.stringify(result_parsed));

    yield put(
      fromActions.handleGetResultSuccess({
        data: result_parsed,
        image: image ? `data:image/jpeg;base64,${response.data.result.image}` : null,
      })
    );
  } catch (err) {
    toast.error('Houve uma falha ao recuperar os dados, tente novamente!');
  }
}

export function* toggleLike({ payload }) {
  try {
    const { questionName, isCorrect } = payload;

    const id = localStorage.getItem('UAUBox:id');

    if (id) {
      yield axios.post(`${BASE_API}/feedback`, {
        id,
        question_name: questionName,
        correct: isCorrect,
        face_requests_id: id,
      });

      toast.success('Agradecemos o seu feedback!');
      yield put(fromActions.handleGetFeedback());
    }
  } catch (err) {
    toast.error('Falha ao alterar status da resposta.');
  }
}

export function* deleteLike({ payload }) {
  try {
    const { questionName } = payload;
    const id = localStorage.getItem('UAUBox:id');

    if (id) {
      yield axios.delete(`${BASE_API}/feedback/id/${id}/question/${questionName}`);

      toast.success('Apagamos seu feedback!');
      yield put(fromActions.handleDeleteLikeSuccess(questionName));
      yield put(fromActions.handleGetFeedback());
    }
  } catch (err) {
    toast.error('Houve uma falha ao deletar seu feedback');
  }
}

export function* getFeedbacks() {
  try {
    const id = localStorage.getItem('UAUBox:id');

    if (id) {
      const response = yield axios.get(`${BASE_API}/feedback/id/${id}`);

      const feedbacksData = response.data?.map((item) => item.question_name);

      localStorage.setItem('UAUBox:feedbacks', JSON.stringify(feedbacksData));

      yield put(fromActions.handleGetFeedbackSuccess(feedbacksData));
    }
  } catch (err) {
    return;
  }
}

export function* watchFace() {
  yield takeLatest(fromTypes.FACE_SEND_IMAGE, sendImage);
  yield takeLatest(fromTypes.FACE_VERIFY_STATUS, verifyStatus);
  yield takeLatest(fromTypes.FACE_GET_RESULT, getResult);
  yield takeLatest(fromTypes.TOGGLE_LIKE, toggleLike);
  yield takeLatest(fromTypes.DELETE_LIKE_REQUEST, deleteLike);
  yield takeLatest(fromTypes.GET_FEEDBACK, getFeedbacks);
}
