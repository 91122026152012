import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import imageCompression from 'browser-image-compression';
import blobToBase64 from 'blob-to-base64';
import { useDispatch } from 'react-redux';

import Popup from '../components/Popup';
import { Box, Typography } from '@material-ui/core';

import useStyles from '../styles/TakePicture';

import { handleSendImage } from '../../../store/actions';
import { Banner } from './Banner';

const TakePicture = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  function convert(compressedFile) {
    return new Promise((resolve, reject) => {
      blobToBase64(compressedFile, (err, b64) => {
        if (err) reject(err);
        else resolve(b64.split(';base64,')[1]);
      });
    });
  }

  async function handleImageUpload(evt) {
    try {
      if (typeof evt.target.files[0] == 'undefined') return;

      const imageFile = evt.target.files[0];

      localStorage.removeItem('UAUBox:FACE_UUID');
      localStorage.removeItem('UAUBox:VERIFY_STATUS');
      localStorage.removeItem('UAUBox:RESULT');

      const options = {
        maxSizeMB: 5,
        maxWidthOrHeight: 720,
        useWebWorker: true,
      };

      const compressedFile = await imageCompression(imageFile, options);

      const compressedImage = await convert(compressedFile);

      dispatch(handleSendImage(compressedImage));
    } catch (err) {
      toast.error('Houve uma falha na requisição, tenta novamente!');
    }
  }

  return (
    <Box className={classes.container}>
      <Banner />
      <img
        className={classes.avatar}
        src="https://d2ivdm85owtv7v.cloudfront.net/face/take-picure.png"
        alt=""
      />
      <Typography className={classes.title}>
        Mantenha a expressão neutra e use uma iluminação suave e natural
      </Typography>
      {!isVisible && (
        <Box className={classes.options}>
          <Box className={classes.group}>
            <label className={classes.label} htmlFor="upload-photo">
              Escolha uma foto
            </label>
            <input
              className={classes.inputFile}
              type="file"
              id="upload-photo"
              accept="image/*"
              onChange={(event) => handleImageUpload(event)}
            />
          </Box>
        </Box>
      )}
      {isVisible && (
        <Box className={classes.popupSpan}>
          <Popup setIsVisible={setIsVisible} />
        </Box>
      )}
      <Box className={classes.footerText}>
        Para obter resultados consistentes, nós recomendamos que a selfie seja tirada através de
        celular com câmera de alta definição.
      </Box>
    </Box>
  );
};

export default TakePicture;
