// @flow
export const FETCH_ADDRESS_BY_ZIPCODE = '[REGISTRATION] Fetch address by zipcode';
export const FETCH_ADDRESS_BY_ZIPCODE_SUCCESS = '[REGISTRATION] Fetch adress by zipcode success';
export const FETCH_ADDRESS_BY_ZIPCODE_ERROR = '[REGISTRATION] Fetch adress by zipcode error';

export const FETCH_PROFILE = '[PROFILE] Fetch profile';
export const FETCH_PROFILE_SUCCESS = '[PROFILE] Fetch profile success';
export const FETCH_PROFILE_ERROR = '[PROFILE] Fetch profile error';

export const UPDATE_PROFILE = '[PROFILE] Update profile';
export const UPDATE_PROFILE_SUCCESS = '[PROFILE] Update profile success';
export const UPDATE_PROFILE_ERROR = '[PROFILE] Update profile error';

export const FETCH_PROFILE_ANSWERS = '[PROFILE] Fetch profile answers';
export const FETCH_PROFILE_ANSWERS_SUCCESS = '[PROFILE] Fetch profile answers success';
export const FETCH_PROFILE_ANSWERS_ERROR = '[PROFILE] Fetch profile answers error';

export const FETCH_PROFILE_ANSWER = '[PROFILE] Fetch profile answer';
export const FETCH_PROFILE_ANSWER_SUCCESS = '[PROFILE] Fetch profile answer success';
export const FETCH_PROFILE_ANSWER_ERROR = '[PROFILE] Fetch profile answer error';

export const FETCH_PROFILE_DETAILS = '[PROFILE] Fetch profile details';
export const FETCH_PROFILE_DETAILS_SUCCESS = '[PROFILE] Fetch profile details success';
export const FETCH_PROFILE_DETAILS_ERROR = '[PROFILE] Fetch profile details error';

export const FETCH_SHIPPING_ADDRESSES = '[PROFILE] Fetch shipping addresses';
export const FETCH_SHIPPING_ADDRESSES_SUCCESS = '[PROFILE] Fetch shipping addresses success';
export const FETCH_SHIPPING_ADDRESSES_ERROR = '[PROFILE] Fetch shipping addresses error';

export const FETCH_SHIPPING_ADDRESS = '[PROFILE] Fetch shipping address';
export const FETCH_SHIPPING_ADDRESS_SUCCESS = '[PROFILE] Fetch shipping address success';
export const FETCH_SHIPPING_ADDRESS_ERROR = '[PROFILE] Fetch shipping address error';

export const CREATE_SHIPPING_ADDRESS = '[PROFILE] Create shipping address';
export const CREATE_SHIPPING_ADDRESS_SUCCESS = '[PROFILE] Create shipping address success';
export const CREATE_SHIPPING_ADDRESS_ERROR = '[PROFILE] Create shipping address error';

export const UPDATE_SHIPPING_ADDRESS = '[PROFILE] Update shipping address';
export const UPDATE_SHIPPING_ADDRESS_SUCCESS = '[PROFILE] Update shipping address success';
export const UPDATE_SHIPPING_ADDRESS_ERROR = '[PROFILE] Update shipping address error';

export const DELETE_SHIPPING_ADDRESS = '[PROFILE] Delete shipping address';
export const DELETE_SHIPPING_ADDRESS_SUCCESS = '[PROFILE] Delete shipping address success';
export const DELETE_SHIPPING_ADDRESS_ERROR = '[PROFILE] Delete shipping address error';

export const FETCH_BOX_ORDERS = '[PROFILE] Fetch box orders';
export const FETCH_BOX_ORDERS_SUCCESS = '[PROFILE] Fetch box orders success';
export const FETCH_BOX_ORDERS_ERROR = '[PROFILE] Fetch box orders error';

export const FETCH_BOX_ORDER = '[PROFILE] Fetch box order';
export const FETCH_BOX_ORDER_SUCCESS = '[PROFILE] Fetch box order success';
export const FETCH_BOX_ORDER_ERROR = '[PROFILE] Fetch box order error';

export const FETCH_FEEDBACK_QUESTIONS_BY_ORDER_ID = '[PROFILE] Fetch feedback questions by orderId';
export const FETCH_FEEDBACK_QUESTIONS_BY_ORDER_ID_SUCCESS =
  '[PROFILE] Fetch feedback questions by orderId success';
export const FETCH_FEEDBACK_QUESTIONS_BY_ORDER_ID_ERROR =
  '[PROFILE] Fetch feedback questions by orderId error';

export const CREATE_FEEDBACK = '[PROFILE] Create feedback';
export const CREATE_FEEDBACK_SUCCESS = '[PROFILE] Create feedback success';
export const CREATE_FEEDBACK_ERROR = '[PROFILE] Create feedback error';

export const SET_FEEDBACK_SUBMIT = '[PROFILE] Set feedback submit';
export const SET_BEAUTY_PROFILE_ANSWER = '[BEAUTY PROFILE] Set Beauty profile answer';
export const UPDATE_BEAUTY_PROFILE = '[BEAUTY PROFILE] Update Beauty profile';

export const SET_DELIVERY_FEEDBACK = '[PROFILE] Set delivery feedback';
export const SET_DELIVERY_FEEDBACK_SUCCESS = '[PROFILE] Set delivery feedback success';
export const SET_DELIVERY_FEEDBACK_ERROR = '[PROFILE] Set delivery feedback error';

export const FETCH_ORDER_ID = '[PROFILE] Fetch order id';
export const FETCH_ORDER_ID_SUCCESS = '[PROFILE] Fetch order id success';
export const FETCH_ORDER_ID_ERROR = '[PROFILE] Fetch order id error';

type FetchAddressByZipcode = {
  type: typeof FETCH_ADDRESS_BY_ZIPCODE,
  zipcode: string,
};

type FetchAddressByZipcodeSuccess = {
  type: typeof FETCH_ADDRESS_BY_ZIPCODE_SUCCESS,
  payload: any,
};

type FetchAddressByZipcodeError = {
  type: typeof FETCH_ADDRESS_BY_ZIPCODE_ERROR,
};

type FetchProfile = {
  type: typeof FETCH_PROFILE,
};
type FetchProfileSuccess = {
  type: typeof FETCH_PROFILE_SUCCESS,
  payload: any,
};

type FetchProfileError = {
  type: typeof FETCH_PROFILE_ERROR,
  payload: any,
};

type UpdateProfile = {
  type: typeof UPDATE_PROFILE,
  profile: any,
};
type UpdateProfileSuccess = {
  type: typeof UPDATE_PROFILE_SUCCESS,
  payload: any,
};

type UpdateProfileError = {
  type: typeof UPDATE_PROFILE_ERROR,
  payload: any,
};

type FetchProfileAnswers = {
  type: typeof FETCH_PROFILE_ANSWERS,
};
type FetchProfileAnswersSuccess = {
  type: typeof FETCH_PROFILE_ANSWERS_SUCCESS,
  payload: any,
};

type FetchProfileAnswersError = {
  type: typeof FETCH_PROFILE_ANSWERS_ERROR,
  payload: any,
};

type FetchProfileAnswer = {
  type: typeof FETCH_PROFILE_ANSWER,
  id: number,
};
type FetchProfileAnswerSuccess = {
  type: typeof FETCH_PROFILE_ANSWER_SUCCESS,
  payload: any,
};

type FetchProfileAnswerError = {
  type: typeof FETCH_PROFILE_ANSWER_ERROR,
  payload: any,
};

type FetchProfileDetails = {
  type: typeof FETCH_PROFILE_DETAILS,
};
type FetchProfileDetailsSuccess = {
  type: typeof FETCH_PROFILE_DETAILS_SUCCESS,
  payload: any,
};

type FetchProfileDetailsError = {
  type: typeof FETCH_PROFILE_DETAILS_ERROR,
  payload: any,
};

type FetchShippingAddresses = {
  type: typeof FETCH_SHIPPING_ADDRESSES,
};
type FetchShippingAddressesSuccess = {
  type: typeof FETCH_SHIPPING_ADDRESSES_SUCCESS,
  payload: any,
};

type FetchShippingAddressesError = {
  type: typeof FETCH_SHIPPING_ADDRESSES_ERROR,
  payload: any,
};

type FetchShippingAddress = {
  type: typeof FETCH_SHIPPING_ADDRESS,
  id: number,
};
type FetchShippingAddressSuccess = {
  type: typeof FETCH_SHIPPING_ADDRESS_SUCCESS,
  payload: any,
};

type FetchShippingAddressError = {
  type: typeof FETCH_SHIPPING_ADDRESS_ERROR,
  payload: any,
};

type CreateShippingAddress = {
  type: typeof CREATE_SHIPPING_ADDRESS,
  shippingAddress: Object,
};
type CreateShippingAddressSuccess = {
  type: typeof CREATE_SHIPPING_ADDRESS_SUCCESS,
  payload: any,
};

type CreateShippingAddressError = {
  type: typeof CREATE_SHIPPING_ADDRESS_ERROR,
  payload: any,
};

type UpdateShippingAddress = {
  type: typeof UPDATE_SHIPPING_ADDRESS,
  shippingAddress: Object,
};
type UpdateShippingAddressSuccess = {
  type: typeof UPDATE_SHIPPING_ADDRESS_SUCCESS,
  payload: any,
};

type UpdateShippingAddressError = {
  type: typeof UPDATE_SHIPPING_ADDRESS_ERROR,
  payload: any,
};

type DeleteShippingAddress = {
  type: typeof DELETE_SHIPPING_ADDRESS,
  id: number,
};
type DeleteShippingAddressSuccess = {
  type: typeof DELETE_SHIPPING_ADDRESS_SUCCESS,
  payload: any,
};

type DeleteShippingAddressError = {
  type: typeof DELETE_SHIPPING_ADDRESS_ERROR,
  payload: any,
};

type FetchBoxOrders = {
  type: typeof FETCH_BOX_ORDERS,
};

type FetchBoxOrdersSuccess = {
  type: typeof FETCH_BOX_ORDERS_SUCCESS,
  payload: any,
};

type FetchBoxOrdersError = {
  type: typeof FETCH_BOX_ORDERS_ERROR,
  payload: any,
};

type FetchBoxOrder = {
  type: typeof FETCH_BOX_ORDER,
  id: number,
};

type FetchBoxOrderSuccess = {
  type: typeof FETCH_BOX_ORDER_SUCCESS,
  payload: any,
};

type FetchBoxOrderError = {
  type: typeof FETCH_BOX_ORDER_ERROR,
  payload: any,
};

type FetchFeedbackQuestionsByOrderId = {
  type: typeof FETCH_FEEDBACK_QUESTIONS_BY_ORDER_ID,
  orderId: number,
};

type FetchFeedbackQuestionsByOrderIdSuccess = {
  type: typeof FETCH_FEEDBACK_QUESTIONS_BY_ORDER_ID_SUCCESS,
  payload: any,
};
type FetchFeedbackQuestionsByOrderIdError = {
  type: typeof FETCH_FEEDBACK_QUESTIONS_BY_ORDER_ID_ERROR,
  payload: any,
};

type CreateFeedback = {
  type: typeof CREATE_FEEDBACK,
  orderId: number,
  feedback: Object,
};

type CreateFeedbackSuccess = {
  type: typeof CREATE_FEEDBACK_SUCCESS,
  payload: any,
};
type CreateFeedbackError = {
  type: typeof CREATE_FEEDBACK_ERROR,
  payload: any,
};

type SetFeedbackSubmit = {
  type: typeof SET_FEEDBACK_SUBMIT,
  feedbackSubmitted: boolean,
};

type SetBeautyProfileAnswer = {
  type: typeof SET_BEAUTY_PROFILE_ANSWER,
  payload: any,
};

type UpdateBeautyProfile = {
  type: typeof UPDATE_BEAUTY_PROFILE,
};

type SetDeliveryFeedback = {
  type: typeof SET_DELIVERY_FEEDBACK,
  payload: any,
};

type SetDeliveryFeedbackSuccess = {
  type: typeof SET_DELIVERY_FEEDBACK_SUCCESS,
  payload: any,
};

type SetDeliveryFeedbackError = {
  type: typeof SET_DELIVERY_FEEDBACK_ERROR,
  payload: any,
};

type FetchOrderId = {
  type: typeof FETCH_ORDER_ID,
};

type FetchOrderIdSuccess = {
  type: typeof FETCH_ORDER_ID_SUCCESS,
  payload: any,
};

type FetchOrderIdError = {
  type: typeof FETCH_ORDER_ID_ERROR,
  payload: any,
};

export type ProfileAction =
  | FetchAddressByZipcode
  | FetchAddressByZipcodeSuccess
  | FetchAddressByZipcodeError
  | FetchProfile
  | FetchProfileSuccess
  | FetchProfileError
  | UpdateProfile
  | UpdateProfileSuccess
  | UpdateProfileError
  | FetchProfileAnswers
  | FetchProfileAnswersSuccess
  | FetchProfileAnswersError
  | FetchProfileAnswer
  | FetchProfileAnswerSuccess
  | FetchProfileAnswerError
  | FetchProfileDetails
  | FetchProfileDetailsSuccess
  | FetchProfileDetailsError
  | FetchShippingAddresses
  | FetchShippingAddressesSuccess
  | FetchShippingAddressesError
  | FetchShippingAddress
  | FetchShippingAddressSuccess
  | FetchShippingAddressError
  | CreateShippingAddress
  | CreateShippingAddressSuccess
  | CreateShippingAddressError
  | UpdateShippingAddress
  | UpdateShippingAddressSuccess
  | UpdateShippingAddressError
  | DeleteShippingAddress
  | DeleteShippingAddressSuccess
  | DeleteShippingAddressError
  | FetchBoxOrders
  | FetchBoxOrdersSuccess
  | FetchBoxOrdersError
  | FetchBoxOrder
  | FetchBoxOrderSuccess
  | FetchBoxOrderError
  | FetchFeedbackQuestionsByOrderId
  | FetchFeedbackQuestionsByOrderIdSuccess
  | FetchFeedbackQuestionsByOrderIdError
  | CreateFeedback
  | CreateFeedbackSuccess
  | CreateFeedbackError
  | SetFeedbackSubmit
  | SetBeautyProfileAnswer
  | UpdateBeautyProfile
  | SetDeliveryFeedback
  | SetDeliveryFeedbackSuccess
  | SetDeliveryFeedbackError
  | FetchOrderId
  | FetchOrderIdSuccess
  | FetchOrderIdError;
