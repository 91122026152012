import {
  // Button,
  // FormControl,
  // FormControlLabel,
  // Grid,
  // Radio,
  // RadioGroup,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import React from 'react';
import useStyles from '../checkout.style';
// import { useState } from 'react';
// import GiftPopup from '../../../components/popups/GiftPopup';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { change } from 'redux-form';

export default function Promotional({
  promotionalShippingCost,
  // customer,
  setTabSelected,
  location,
}) {
  //const [isAvailable, setIsAvailable] = useState();
  // const [giftPopupIsOpen, setGiftPopupIsOpen] = useState(false);
  // const [kit, setKit] = useState('');

  const classes = useStyles();
  const ToolipUAUBox = withStyles({
    tooltip: {
      fontFamily: 'open sans',
      padding: '1rem',
    },
  })(Tooltip);

  const dispatch = useDispatch();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const utm_source = params.get('utm_source');
    const utm_campaign = params.get('utm_campaign');
    const utm_medium = params.get('utm_medium');
    const gift = params.get('gift');
    if (gift) {
      setTabSelected(1);
      dispatch(change('CheckoutForm', 'giftCode', gift));
    }
    if (!utm_source) return;
    const data = {
      utm_source,
      utm_campaign,
      utm_medium,
    };
    localStorage.setItem('subscription-media-tracking', JSON.stringify(data));
    // setIsAvailable({});
  }, [location.search, dispatch, setTabSelected]);

  return (
    <>
      {promotionalShippingCost === 0 && (
        <Typography className={classes.promotionTitle}>
          Frete grátis para sua cidade por tempo limitado!
          <ToolipUAUBox
            placement="top"
            title={
              <Typography className={classes.descriptionTooltip}>
                Promoção não acumulativa para cupons com desconto acima de 10%.
              </Typography>
            }
          >
            <InfoOutlinedIcon className={classes.iconInfoPromotion} />
          </ToolipUAUBox>
        </Typography>
      )}
      {/* {isAvailable && (
        <>
          <Typography className={classes.titleAddress} variant="subtitle1" gutterBottom>
            Presente Dia dos Namorados
          </Typography>
          <div className={classes.giftContainer}>
            <GiftPopup isOpen={giftPopupIsOpen} setIsOpen={setGiftPopupIsOpen} kit={kit} />
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={9} lg={9}>
                <Typography className={classes.addressText} variant="subtitle1" gutterBottom>
                  <div className={classes.valentineText}>
                    Olá {customer.firstName}, com mais R$109,90 reais você consegue levar uma box
                    Scarlet recheada de produtos para seu cuidado diario!
                  </div>
                  <br />
                  <FormControl>
                    <RadioGroup
                      name="kit-form"
                      value={kit}
                      defaultValue={'kit0'}
                      onChange={(e) => setKit(e.target.value)}
                      className={classes.formKit}
                    >
                      <FormControlLabel
                        value="BOX_ADICIONAL_SCARLET_01"
                        control={<Radio color="primary" />}
                        label="Scarlet Kit 1"
                        className={classes.kit1}
                        disabled={!isAvailable.specialBoxEdition01}
                        classes={{ disabled: classes.disabledButton }}
                      />
                      <FormControlLabel
                        value="BOX_ADICIONAL_SCARLET_02"
                        control={<Radio color="primary" />}
                        label="Scarlet Kit 2"
                        className={classes.kit2}
                        disabled={!isAvailable.specialBoxEdition02}
                        classes={{ disabled: classes.disabledButton }}
                      />
                      <FormControlLabel
                        value=""
                        control={<Radio color="success" />}
                        label="Nenhum"
                        className={classes.kit0}
                      />
                    </RadioGroup>
                  </FormControl>
                </Typography>
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                <Button
                  variant="text"
                  className={classes.btnAddress}
                  color="primary"
                  onClick={() => setGiftPopupIsOpen(!giftPopupIsOpen)}
                  disabled={kit === ''}
                >
                  Ver mais
                </Button>
              </Grid>
            </Grid>
          </div>
        </>
      )} */}
    </>
  );
}
