import { tundora, cinzaClaroRGBA2 } from '../../../themes/colors';
import { makeStyles } from '@material-ui/styles';

const useStylesMenuItems = makeStyles((theme) => ({
  menuItem: {
    height: '2.5rem',
    padding: theme.spacing(0.5, 1.5),
    margin: theme.spacing(1.5, 0),
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '& span': {
      color: tundora,
      fontSize: '0.9375rem',
      marginLeft: '0.5rem',
    },
    '&:hover': {
      background: cinzaClaroRGBA2,
    },
  },
  subtitle: {
    fontSize: '0.9375rem',
    color: tundora,
    fontWeight: 600,
    marginTop: '3rem',
    marginBottom: '1.25rem',
  },
  menuItemBox: {
    display: 'flex',
    alignItems: 'center',
  },
  active: {
    background: cinzaClaroRGBA2,
  },
  logoUau: {
    margin: '2rem auto',
    padding: theme.spacing(2),
    width: 'fit-content',
    '& img': {
      width: '100%',
    },
  },
}));

export default useStylesMenuItems;
