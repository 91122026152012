import React from 'react';
import { Typography, Divider, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import moment from 'moment';
import 'moment/locale/pt-br';
import {
  black,
  silverChalice,
  redOrange,
  redOrangeLight,
  denim,
  zumthor,
  japaneseLaurel,
  japaneseLaurelLight,
} from '../../themes/colors';
import { getCardIcon } from '../utils/cardUtils';
import { toMoney } from '../../models/subscription';

import { isEqual } from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '1rem 0',
  },
  title: {
    color: silverChalice,
  },
  titleHighlight: {
    color: black,
    fontSize: '1.2em',
    textTransform: 'capitalize',
  },
  imageBox: {
    '& img': {
      width: '8rem',
    },
  },
  cardInfo: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(1, 0),
    color: black,
    fontSize: '1.2em',
    '& svg': {
      height: '1.5em',
      marginRight: theme.spacing(1),
    },
  },
  status: {
    borderRadius: theme.spacing(2.25),
    textTransform: 'lowercase',
    padding: '0.2rem 0.7rem',
    margin: '0.5rem 0 2rem',
  },
  waiting: {
    borderColor: denim,
    color: denim,
    background: zumthor,
  },
  success: {
    borderColor: japaneseLaurel,
    color: japaneseLaurel,
    background: japaneseLaurelLight,
  },
  warning: {
    borderColor: redOrange,
    color: redOrange,
    background: redOrangeLight,
  },
}));

const BoxReferralCard = ({ edition, payment, tenantName }) => {
  const classes = useStyles();
  const desktop = useMediaQuery((theme) => theme.breakpoints.up('md'));
  return (
    <div className={classes.root}>
      <Grid
        container
        alignItems={desktop ? 'center' : 'flex-start'}
        direction={desktop ? 'row' : 'column'}
        spacing={2}
      >
        <Grid item xd={12} md={2}>
          {/* <img src={edition?.image} alt={edition?.title} /> */}
          <div className={classes.imageBox}>
            {isEqual(tenantName, 'uaubox') ? (
              <img
                src="https://d2ivdm85owtv7v.cloudfront.net/images/customer-area/box_generica.jpg"
                alt="UAUBox Default Genérica"
              />
            ) : (
              <img
                src="https://d2ivdm85owtv7v.cloudfront.net/images/customer-area/box-generica-scarlet.png"
                alt="Scarlet by UAUBox"
              />
            )}
          </div>
        </Grid>

        <Grid item xd={12} md={2}>
          <Typography className={classes.title} gutterBottom>
            Edição
          </Typography>
          <Typography className={classes.titleHighlight} gutterBottom>
            {moment(payment?.boxEdition?.identifier).locale('pt').format('MMM YYYY')}
          </Typography>
        </Grid>

        <Grid item xd={12} md={2}>
          <Typography className={classes.title} gutterBottom>
            Data
          </Typography>
          <Typography className={classes.titleHighlight} gutterBottom>
            {payment?.createdAt}
          </Typography>
        </Grid>

        <Grid item xd={12} md={2}>
          <Typography className={classes.title} gutterBottom>
            Pagamento
          </Typography>
          <div className={classes.cardInfo}>
            {getCardIcon(payment?.cardScheme)}
            <span>{`final ${payment?.lastFourNumber}`}</span>
          </div>
        </Grid>

        <Grid item xd={12} md={2}>
          <Typography className={classes.title} gutterBottom>
            Valor
          </Typography>
          <Typography className={classes.titleHighlight} gutterBottom>
            {`R$ ${toMoney(payment?.value)}`}
          </Typography>
        </Grid>

        <Grid item xd={12} md={2}>
          <Typography className={classes.title} gutterBottom>
            Status da transação
          </Typography>
          <Typography
            className={classNames(
              classes.titleHighlight,
              classes.status,
              {
                [classes.waiting]:
                  payment.status !== 'PAID' &&
                  payment.status !== 'REFUNDED' &&
                  payment.status !== 'UNPAID',
              },
              { [classes.warning]: payment.status === 'UNPAID' },
              { [classes.success]: payment.status === 'PAID' || payment.status === 'REFUNDED' }
            )}
            variant="outlined"
          >
            {payment.status === 'UNPAID'
              ? 'cancelado'
              : payment.status === 'PAID'
              ? 'pago'
              : payment.status === 'REFUNDED'
              ? 'reembolsado'
              : 'aguardando'}
          </Typography>
        </Grid>
      </Grid>

      <Divider />
    </div>
  );
};

export default BoxReferralCard;
