import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@material-ui/core';
import { cinzaMedioRGBA2, white, redOrange, cinzaEscuro } from '../../themes/colors';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    fontFamily: 'open sans',
    left: 0,
  },
  popShadow: {
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    zIndex: '999999',
    top: 0,
    left: 0,
    background: cinzaMedioRGBA2,
  },
  popupHolder: {
    position: 'relative',
    width: '100vw',
    height: '100vh',
  },
  popup: {
    position: 'absolute',
    top: '20%',
    left: '50%',
    transform: 'translate(-50%, -20%)',
    width: '95%',
    [theme.breakpoints.up('md')]: {
      maxHeight: '600px',
      width: '510px',
    },
  },
  popCard: {
    padding: '1em',
    background: white,
    borderRadius: '1em',
  },
  popTitle: {
    background: redOrange,
    fontFamily: 'open sans',
    fontWeight: 'bold',
    fontSize: '1.5em',
    color: white,
    padding: theme.spacing(2),
    textAlign: 'center',
    borderRadius: '0.5em 0.5em 0 0',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.7em',
    },
  },
  popSub: {
    fontFamily: 'open sans',
    color: cinzaEscuro,
    padding: theme.spacing(2),
    fontSize: '1.2em',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.4em',
    },
    '& span': {
      color: cinzaEscuro,
      fontWeight: 'bold',
    },
    '& p': {
      padding: '0',
      margin: '0.6rem 0',
    },
    '& img': {
      margin: '0 0.5rem',
      height: '20px',
    },
  },
  popSub_italic: {
    fontFamily: 'open sans',
    color: cinzaEscuro,
    padding: theme.spacing(2),
    fontSize: '1.1em',
    fontStyle: 'italic',
    '& span': {
      color: cinzaEscuro,
      fontWeight: 'bold',
    },
    '& p': {
      padding: '0',
      margin: '0.6rem 0',
    },
    '& img': {
      margin: '0 0.5rem',
      height: '20px',
    },
  },
  popBtn: {
    margin: '1em auto 0.5em',
    background: redOrange,
    color: white,
    fontFamily: 'open sans',
    fontWeight: 'bold',
    fontSize: '1em',
    display: 'block',
    minWidth: '50%',
    padding: theme.spacing(1.5, 3),
    borderRadius: '2rem',
    '&:hover': {
      background: redOrange,
      color: white,
    },
  },
  closeIcon: {
    position: 'absolute',
    color: white,
    background: '#101010',
    borderRadius: '0 0.3em 0 0.5em',
    padding: '0.6rem',
    fontSize: '2.3rem',
    right: '10px',
    top: '10px',
    cursor: 'pointer',
    zIndex: 999,
    transition: '0.5s',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  },
}));

const Popup = ({ showModal, setShowModal, onUpgrade }) => {
  const classes = useStyles();
  const [isUpgraded, setIsUpgraded] = useState(false);

  const closeModal = () => {
    setIsUpgraded(false);
    setShowModal(false);
  };

  const onConfirm = () => {
    onUpgrade();
    setIsUpgraded(true);
    setTimeout(() => {
      closeModal();
    }, 5000);
  };

  return !isUpgraded ? (
    <div className={classes.root} style={{ display: showModal ? 'block' : 'none' }}>
      <div className={classes.popShadow}>
        <CloseIcon className={classes.closeIcon} onClick={() => closeModal()} />
        <div className={classes.popupHolder}>
          <div className={classes.popup}>
            <div className={classes.popCard}>
              <Typography className={classes.popTitle}>
                Tem certeza que deseja alterar o seu plano?
              </Typography>
              <Typography className={classes.popSub_italic}>
                Uma vez a alteração realizada não é possível reverter (downgrade) e o valor da
                assinatura será de acordo com o plano atualizado!
              </Typography>
              <Button
                variant="contained"
                color="primary"
                className={classes.popBtn}
                onClick={() => onConfirm()}
              >
                Confirmar
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className={classes.root} style={{ display: showModal ? 'block' : 'none' }}>
      <div className={classes.popShadow}>
        <CloseIcon className={classes.closeIcon} onClick={() => closeModal()} />
        <div className={classes.popupHolder}>
          <div className={classes.popup}>
            <div className={classes.popCard}>
              <Typography className={classes.popTitle}>Parabéns!</Typography>
              <Typography className={classes.popSub}>
                O seu novo plano será válido a partir do próximo mês{' '}
                <span role="img" aria-label="heart_eyes">
                  😍
                </span>
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup;
