import React from 'react';
import { Typography, Button, Divider, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { black, silverChalice } from '../../themes/colors';
import { getCardIcon } from '../../components/utils/cardUtils';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '1rem 0',
  },
  mainTitle: {
    color: silverChalice,
    fontSize: '1.5em',
    fontWeight: 'bold',
  },
  title: {
    color: silverChalice,
  },
  titleHighlight: {
    color: black,
    fontSize: '1.2em',
    '& svg': {
      width: '1.7rem',
      marginRight: '0.5rem',
    },
  },
  buttonBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: '2rem 0',
    '& button': {
      margin: '0.5rem 0',
      fontSize: '0.8em',
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      padding: '1rem 0',
      margin: 0,
      marginLeft: '25%', // grid size
      '& button': {
        marginRight: '0.5rem',
      },
    },
  },
}));

const Payment = ({ navigateTo, paymentProfile, customer }) => {
  const classes = useStyles();
  const desktop = useMediaQuery((theme) => theme.breakpoints.up('md'));

  return (
    <div className={classes.root}>
      <Grid
        container
        alignItems={desktop ? 'center' : 'flex-start'}
        direction={desktop ? 'row' : 'column'}
        spacing={2}
      >
        <Grid item xs={12} sm={6} md={3}>
          <Typography className={classes.mainTitle} gutterBottom>
            Pagamento
          </Typography>
        </Grid>

        {paymentProfile?.card?.lastFour ? (
          <>
            <Grid item xs={12} sm={6} md={3}>
              <Typography className={classes.title} gutterBottom>
                CPF do titular
              </Typography>
              <Typography className={classes.titleHighlight} gutterBottom>
                {customer?.cpf}
              </Typography>
              <Typography className={classes.title} gutterBottom>
                Cartão
              </Typography>
              <Typography className={classes.titleHighlight} gutterBottom>
                {getCardIcon(paymentProfile?.card?.brand)}
                {`**** **** **** ${paymentProfile?.card?.lastFour}`}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography className={classes.title} gutterBottom>
                Data de Validade
              </Typography>
              <Typography className={classes.titleHighlight} gutterBottom>
                {`${paymentProfile?.card?.expiresAt}`}
              </Typography>
            </Grid>
          </>
        ) : (
          <>
            <Typography className={classes.titleHighlight} gutterBottom>
              Você não possui um cartão de crédito registrado.
            </Typography>
          </>
        )}
      </Grid>
      <div className={classes.buttonBox}>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => navigateTo('/perfil/seus-pagamentos')}
        >
          ADICIONAR PAGAMENTO
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => navigateTo('/perfil/seus-pagamentos/historico')}
        >
          ver histórico de pagamentos
        </Button>
      </div>
      <Divider />
    </div>
  );
};

export default Payment;
