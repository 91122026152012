import React, { useState, useEffect, Fragment } from 'react';
import {
  Typography,
  Button,
  FormControlLabel,
  Radio,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Divider,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  fetchShippingAddresses,
  deleteShippingAddress,
  updateShippingAddress,
} from '../../../../store/actions';
import { black, tundora, pippin, alto } from '../../../../themes/colors';
import Loading from '../../../../components/Loading';
import { BackButton, BackButtonMobile } from '../../../../components/material';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    boxShadow: `1px 3px 6px ${alto}`,
    minHeight: '100vh',
  },
  mainBox: {
    background: pippin,
    padding: '1.5rem 2rem',
  },
  titleBox: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '2rem',
  },
  title: {
    color: black,
    fontSize: '2em',
    padding: '0 2.5rem',
    [theme.breakpoints.up('lg')]: {
      padding: '0 1rem',
    },
  },
  subtitle: {
    color: tundora,
    fontSize: '1.2em',
  },
  contentBox: {
    padding: '2rem',
    minHeight: '100vh',
  },
  contentButtonBox: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '1.5rem',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start',
    },
  },
  cardTitle: {
    color: tundora,
    fontSize: '1.3em',
  },
  cardHighlight: {
    color: black,
    fontWeight: 'bold',
  },
  cardSubtitle: {
    color: black,
    fontSize: '1.4em',
  },
  card: {
    padding: '1rem 0',
    color: black,
    '& label': {
      margin: 0,
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  buttonBox: {
    '& button': {
      marginRight: theme.spacing(1),
    },
  },
}));

const ShippingAddresses = ({
  onFetchShippingAddresses,
  onDeleteShippingAddress,
  onUpdateShippingAddress,
  navigateTo,
  shippingAddresses,
  loading,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(false);
  const [isMainAddress, setIsMainAddress] = useState(false);
  const showArrow = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const addressesLength = shippingAddresses?.length > 1;

  useEffect(() => {
    onFetchShippingAddresses();
  }, [onFetchShippingAddresses]);

  const handleDelete = () => {
    setOpen(false);
    !isMainAddress
      ? onDeleteShippingAddress(id)
      : toast.error('Endereço principal não pode ser apagado!');
  };

  const handleOpen = (isOpen, id, mainAddress) => {
    setOpen(isOpen);
    setId(id);
    setIsMainAddress(mainAddress);
  };

  const AddressCard = (address) => {
    return (
      <div className={classes.card}>
        <div style={{ maxWidth: '80%' }}>
          <Typography className={classes.cardTitle} gutterBottom>
            {address.label}
          </Typography>
          <Typography className={classes.cardHighlight} gutterBottom>
            {address.recipientName}
          </Typography>
          <Typography className={classes.cardSubtitle} gutterBottom>
            {address.street}, {address.number}{' '}
            {address.complement ? `(${address.complement})` : null}
          </Typography>
          <Typography className={classes.cardSubtitle} gutterBottom>
            {address.reference}
          </Typography>
          <Typography className={classes.cardSubtitle} gutterBottom>
            {address.city} - {address.state}
          </Typography>
          <FormControlLabel
            value={address.mainAddress}
            control={<Radio color="primary" />}
            label="Endereço principal"
            checked={address.mainAddress}
            onChange={() => onUpdateShippingAddress({ ...address, mainAddress: true })}
          />
        </div>
        <div className={classes.buttonBox}>
          {addressesLength ? (
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => handleOpen(true, address.id, address.mainAddress)}
            >
              EXCLUIR
            </Button>
          ) : null}

          <Button
            variant="outlined"
            color="primary"
            onClick={() => navigateTo(`/perfil/enderecos/${address.id}`)}
          >
            EDITAR
          </Button>
        </div>
        <Dialog
          open={open}
          onClose={() => handleOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{'Deseja excluir este endereço?'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Ao excluir o endereço, você não conseguirá utilizá-lo para o envio das próximas
              caixas.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleOpen(false)} variant="outlined" color="secondary">
              Cancelar
            </Button>
            <Button onClick={handleDelete} variant="contained" color="secondary" autoFocus>
              Sim, excluir endereço
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  return (
    <Loading isLoading={loading}>
      <div className={classes.root}>
        <BackButtonMobile onClick={() => navigateTo('/perfil')} visible={!showArrow} />
        <div className={classes.mainBox}>
          <div className={classes.titleBox}>
            <BackButton onClick={() => navigateTo('/perfil')} visible={showArrow} />
            <Typography className={classes.title}>Endereço de entrega</Typography>
          </div>
          <Typography className={classes.subtitle} gutterBottom>
            Mantenha seu endereço sempre atualizado para receber sua caixa
          </Typography>
        </div>

        <div className={classes.contentBox}>
          <div className={classes.contentButtonBox}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigateTo(`/perfil/enderecos/cadastro`)}
            >
              Adicionar novo endereço
            </Button>
          </div>

          {shippingAddresses?.map((i) => (
            <Fragment key={i.id}>
              <AddressCard {...i} />
              {addressesLength ? <Divider /> : null}
            </Fragment>
          ))}
        </div>
      </div>
    </Loading>
  );
};

const mapStateToProps = (state) => {
  const { shippingAddresses, loading } = state.profile;
  return { shippingAddresses, loading };
};

const mapDispatchToProps = (dispatch) => ({
  onFetchShippingAddresses: () => dispatch(fetchShippingAddresses()),
  onDeleteShippingAddress: (id) => dispatch(deleteShippingAddress(id)),
  onUpdateShippingAddress: (shippingAddress) => dispatch(updateShippingAddress(shippingAddress)),
  navigateTo: (path) => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShippingAddresses);
