// @flow
import React from 'react';
import {
  InProductionBoxIcon,
  ReadyToPostBoxIcon,
  TruckIcon,
  CardIcon,
  GiftIcon,
  CanceledBox,
} from '../icons';

const boxStatus = [
  {
    status: 'CANCELED',
    icon: <CanceledBox />,
    title: 'Cancelado',
    last: true,
  },
  {
    status: 'NEW',
    icon: <CardIcon />,
    title: 'Pagamento confirmado',
    last: false,
  },
  {
    status: 'PAYMENT_APPROVED',
    icon: <CardIcon />,
    title: 'Pagamento aprovado',
    last: false,
  },
  {
    status: 'PAYMENT_NOT_APPROVED',
    icon: <CardIcon />,
    title: 'Pagamento nao aprovado',
    last: false,
  },
  {
    status: 'PRODUCTS_SELECTION',
    icon: <InProductionBoxIcon />,
    title: 'Selecionando os produtos',
    last: false,
  },
  {
    status: 'IN_PRODUCTION',
    icon: <InProductionBoxIcon />,
    title: 'Box em produção',
    last: false,
  },
  {
    status: 'READY_TO_POST',
    icon: <ReadyToPostBoxIcon />,
    title: 'Pronta para coleta',
    last: false,
  },
  {
    status: 'ASSIGNED_LOGISTICS_PLATFORM',
    icon: <ReadyToPostBoxIcon />,
    title: 'Pagamento confirmado',
    last: false,
  },
  {
    status: 'IN_TRANSIT',
    icon: <TruckIcon />,
    title: 'Em trânsito',
    last: false,
  },
  {
    status: 'DELIVERED',
    icon: <GiftIcon />,
    title: 'Entregue',
    last: true,
  },
];

export { boxStatus };
