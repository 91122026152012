import React from 'react';

const PersonIcon = ({ fill = '#fff', width = '100%', className = '', height = '100%' }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 12C8.71667 12 8.47933 11.904 8.288 11.712C8.096 11.5207 8 11.2833 8 11C8 10.7167 8.096 10.479 8.288 10.287C8.47933 10.0957 8.71667 10 9 10H15C15.2833 10 15.521 10.0957 15.713 10.287C15.9043 10.479 16 10.7167 16 11C16 11.2833 15.9043 11.5207 15.713 11.712C15.521 11.904 15.2833 12 15 12H9ZM9 8C8.71667 8 8.47933 7.90433 8.288 7.713C8.096 7.521 8 7.28333 8 7C8 6.71667 8.096 6.479 8.288 6.287C8.47933 6.09567 8.71667 6 9 6H15C15.2833 6 15.521 6.09567 15.713 6.287C15.9043 6.479 16 6.71667 16 7C16 7.28333 15.9043 7.521 15.713 7.713C15.521 7.90433 15.2833 8 15 8H9ZM6 14H13.5C13.9833 14 14.4333 14.104 14.85 14.312C15.2667 14.5207 15.6167 14.8167 15.9 15.2L18 17.95V4H6V14ZM6 20H17.05L14.325 16.425C14.225 16.2917 14.1043 16.1877 13.963 16.113C13.821 16.0377 13.6667 16 13.5 16H6V20ZM18 22H6C5.45 22 4.97933 21.8043 4.588 21.413C4.196 21.021 4 20.55 4 20V4C4 3.45 4.196 2.979 4.588 2.587C4.97933 2.19567 5.45 2 6 2H18C18.55 2 19.021 2.19567 19.413 2.587C19.8043 2.979 20 3.45 20 4V20C20 20.55 19.8043 21.021 19.413 21.413C19.021 21.8043 18.55 22 18 22Z"
      fill="#717171"
    />
  </svg>
);

export default PersonIcon;
