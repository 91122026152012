import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    color: '#A01235',
    fontWeight: 500,
    fontSize: '2em',
    lineHeight: 1.2,
    fontFamily: 'open sans',
  },
  subtitle: {
    color: '#707070',
    fontWeight: 500,
    fontSize: '1.2rem',
    fontFamily: 'open sans',
  },
  trackingBox: {
    display: 'flex',
    alignItems: 'center',
    background: '#FDE6E3',
    padding: '3.5rem',
    position: 'relative',
    '& button': {
      padding: '0.5rem 0',
    },
  },
  imageBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& img': {
      position: 'relative',
      right: '1rem',
      bottom: '-1rem',
      [theme.breakpoints.up('md')]: {
        width: ' 17rem',
        right: '3rem',
      },
    },
  },
}));

export function Banner({ title, description }) {
  const classes = useStyles();

  return (
    <Grid className={classes.trackingBox} container>
      <Grid item xs={12} sm={6}>
        <Typography className={classes.title} gutterBottom>
          {title ?? 'PRONTA PARA COMEÇAR?'}
        </Typography>
        <Typography className={classes.subtitle} gutterBottom>
          {description ?? 'Vamos juntas nessa jornada de autoconhecimento?'}
        </Typography>
      </Grid>

      {/* <Grid className={classes.imageBox} item xs={12} sm={6}>
        <img
          src="https://d2ivdm85owtv7v.cloudfront.net/images/customer-area/carrying_box.svg"
          alt=""
          className={classes.image}
        />
      </Grid> */}
    </Grid>
  );
}
