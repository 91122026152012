import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Box,
  MobileStepper,
  IconButton,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, FormSection } from 'redux-form';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import {
  fetchFeedbackQuestionsByOrderId,
  createFeedback,
  setFeedbackSubmit,
} from '../../store/actions';
import { alabaster, black, tundora, white, redOrange, milanoRed } from '../../themes/colors';
import moment from 'moment';
import 'moment/locale/pt-br';
import { camelCase, split, isEmpty } from 'lodash';
import TenantQuestion from '../TenantQuestion';
import ManufacturedQuestion from '../ManufacturedQuestion';
import FeedbackSubmitCard from '../FeedbackSubmitCard';
import Loading from '../Loading';

const useStyles = makeStyles((theme) => ({
  formFeedback: {
    textAlign: 'center',
  },
  title: {
    color: black,
    fontSize: '1.8em',
  },
  subtitle: {
    color: tundora,
    fontSize: '1.2em',
    '& span': {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
    },
  },
  boxHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1rem',
  },
  dialogContent: {
    minHeight: '15rem',
    maxHeight: '35rem',
  },
  dialogActions: {
    background: alabaster,
  },
  mobileStepper: {
    background: alabaster,
    width: '100%',
  },
  btn: {
    background: redOrange,
    color: white,
    borderRadius: '20px',
    boxShadow: 'none',
    fontWeight: 'bold',
    '&:hover': {
      boxShadow: 'none',
      background: milanoRed,
    },
  },
}));

const Feedback = ({
  buttonText,
  handleSubmit,
  feedbackQuestions,
  onSetFeedbackSubmit,
  onFetchFeedbackQuestionsByOrderId,
  onCreateFeedback,
  orderId,
  page,
  edition,
  submitting,
  feedbackSubmitted,
  loading,
  loadingQuestions,
  loadedQuestions,
  feedbackForm,
}) => {
  const classes = useStyles();
  const [openClicked, setOpenClicked] = useState(false);
  const [orderIdSelected, setOrderIdSelected] = useState(null);
  const [open, setOpen] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [dialogContent, setDialogContent] = useState(null);
  const tablet = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const manufacturerLength = feedbackQuestions?.manufacturerQuestions?.length;
  const maxSteps = manufacturerLength + 1; // tenant e manufactored estao em 2 objetos separados. Tenant sera o ultimo index
  const lastStep = activeStep === maxSteps - 1;

  useEffect(() => {
    if (loadedQuestions && openClicked) {
      handleClickOpen();
    }
  }, [loadedQuestions, openClicked]);

  useEffect(() => {
    if (feedbackSubmitted && open) {
      setOpen(false);
    }
  }, [feedbackSubmitted, open]);

  const onGetQuestions = () => {
    setOrderIdSelected(orderId);
    onFetchFeedbackQuestionsByOrderId(orderId);
    setOpenClicked(true);
  };

  const handleClickOpen = () => {
    setActiveStep(0);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOrderIdSelected(null);
  };

  const handleNext = () => {
    const hasError = isEmpty(feedbackForm.syncErrors);
    if (hasError) {
      dialogContent.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      toast.error('Responda as perguntas obrigatórias!');
    }
  };

  const handleBack = () => {
    dialogContent.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getManufacturerObject = (key, value) => {
    const parts = split(key, '-');
    return {
      orderItemSku: parts[0],
      questionId: Number(parts[1]),
      [parts[2]]: parts[2] === 'text' ? value : Number(value),
    };
  };

  const getTenantObject = (key, value) => {
    const parts = split(key, '-');
    return {
      questionId: Number(parts[0]),
      [parts[1]]: parts[1] === 'text' ? value : Number(value),
    };
  };

  const onSubmit = (feedback) => {
    const manufacturerAnswers = [
      ...Object.entries(feedback?.manufacturerAnswers || {}).map(([key, value]) =>
        getManufacturerObject(key, value)
      ),
    ];
    const tenantAnswers = Object.entries(feedback?.tenantAnswers || {}).map(([key, value]) =>
      getTenantObject(key, value)
    );

    onCreateFeedback(orderId, { tenantAnswers, manufacturerAnswers }, page);
  };

  return feedbackSubmitted ? (
    <FeedbackSubmitCard onClose={onSetFeedbackSubmit} />
  ) : (
    <Loading isLoading={loadingQuestions}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={`${page === 'box' ? classes.formFeedback : null}`}
      >
        <Button
          variant={page ? 'contained' : 'outlined'}
          className={classes.btn}
          onClick={onGetQuestions}
        >
          {buttonText}
        </Button>

        <Dialog
          maxWidth="sm"
          open={open && orderId === orderIdSelected}
          aria-labelledby="feedback-dialog-title"
          fullWidth={tablet}
          fullScreen={!tablet}
        >
          <Loading isLoading={loadingQuestions}>
            <div className={classes.boxHeader}>
              <Box m={1}>
                <Typography className={classes.title} align="left" gutterBottom>
                  O que achou da sua UAUBox?
                </Typography>
                <Typography className={classes.subtitle} align="left" gutterBottom>
                  <span>{`#${camelCase(edition?.title)} | `}</span>
                  {moment(edition?.date).locale('pt').format('MMMM YYYY')}
                </Typography>
              </Box>
              <IconButton style={{ alignSelf: 'flex-start' }} color="primary" onClick={handleClose}>
                <Close />
              </IconButton>
            </div>

            <DialogContent className={classes.dialogContent}>
              <div
                ref={(node) => {
                  setDialogContent(node);
                }}
              >
                <FormSection name="manufacturerAnswers">
                  <ManufacturedQuestion
                    activeStep={activeStep}
                    questions={feedbackQuestions?.manufacturerQuestions}
                  />
                </FormSection>

                <FormSection name="tenantAnswers">
                  <TenantQuestion
                    activeStep={activeStep}
                    questions={feedbackQuestions?.tenantQuestions}
                    lastStep={lastStep}
                  />
                </FormSection>
              </div>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <MobileStepper
                className={classes.mobileStepper}
                steps={maxSteps}
                position="static"
                variant="text"
                activeStep={activeStep}
                nextButton={
                  lastStep ? (
                    <Button color="secondary" variant="contained" onClick={handleSubmit(onSubmit)}>
                      Enviar
                    </Button>
                  ) : (
                    <Button color="secondary" variant="contained" onClick={handleNext}>
                      próximo
                    </Button>
                  )
                }
                backButton={
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={handleBack}
                    disabled={activeStep === 0}
                  >
                    Voltar
                  </Button>
                }
              />
            </DialogActions>
          </Loading>
        </Dialog>
      </form>
    </Loading>
  );
};

const mapStateToProps = (state) => {
  const { feedbackForm } = state.form;
  const {
    feedbackQuestions,
    feedbackSubmitted,
    loading,
    loadingQuestions,
    loadedQuestions,
  } = state.profile;
  return {
    feedbackQuestions,
    feedbackSubmitted,
    loading,
    loadingQuestions,
    loadedQuestions,
    feedbackForm,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onFetchFeedbackQuestionsByOrderId: (orderId) =>
    dispatch(fetchFeedbackQuestionsByOrderId(orderId)),
  onCreateFeedback: (orderId, feedback, page) => dispatch(createFeedback(orderId, feedback, page)),
  onSetFeedbackSubmit: (feedbackSubmitted) => dispatch(setFeedbackSubmit(feedbackSubmitted)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'feedbackForm',
  })
)(Feedback);
