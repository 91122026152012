import React from 'react';
import useStyles from '../checkout.style';
import { Button } from '@material-ui/core';

export default function AddressCheckout({ billingAddress, navigateTo }) {
  const classes = useStyles();
  return (
    <div className={classes.address}>
      <h2 className={classes.subtitleCheckout}>Endereço de entrega</h2>
      <div className={classes.addressBody}>
        <div className={classes.addressBodyLeft}>
          {billingAddress?.street}
          <br />
          Nº {billingAddress?.number}, {billingAddress?.district}
          <br />
          CEP {billingAddress?.zipcode} - {billingAddress?.city}/{billingAddress?.state}
        </div>
        <div className={classes.addressBodyRight}>
          <Button
            variant="text"
            className={classes.btnAddress}
            color="primary"
            onClick={() => navigateTo('/perfil/enderecos')}
          >
            Editar endereço
          </Button>
        </div>
      </div>
    </div>
  );
}
