import React from 'react';

const SkinProductIcon = () => (
  <svg
    viewBox="0 0 23.314 29.35"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill="#9f9f9f"
      d="M1.72,24.1v3.079a.344.344,0,0,0,.344.344h6.9a.344.344,0,0,0,.344-.344V24.126L11.03,2.408V.344A.344.344,0,0,0,10.686,0H.344A.344.344,0,0,0,0,.344V2.435Zm7.653-9.481a10.693,10.693,0,0,1-3.721-.667,11.492,11.492,0,0,0-4.1-.7L.713,2.731h9.6ZM4.433,26.834a1.114,1.114,0,0,1,2.165,0Zm2.862,0a1.8,1.8,0,0,0-3.559,0H2.408V24.4H8.621v2.437Zm1.358-3.125H2.378L1.6,13.935a10.751,10.751,0,0,1,3.832.668,11.393,11.393,0,0,0,3.884.7ZM10.342.688V2.043H.688V.688Z"
      transform="translate(0 0)"
    />
  </svg>
);

export default SkinProductIcon;
