const planList = [
  {
    tenant: 'scarlet',
    ids: ['58', '59', '60', '61'],
  },
  {
    tenant: 'uaubox',
    ids: ['62', '63', '64', '65'],
  },
];

export default planList;
