import React, { useEffect, Node } from 'react';
import { Route } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import Menu from '../../../components/Menu';
import SEO from '../../../components/SEO';
import { connect } from 'react-redux';
import { isAuthenticated, getTenantToken } from '../../../store/services/auth';
import { setTenantNameWithToken } from '../../../store/actions';
import { signIn } from '../../../store/actions/auth';

type Props = {
  component: Node,
  location: any,
  title: text,
  metaDescription: text,
  menu: boolean,
  token: string,
  tenantName: string,
};
const ProtectedRoute = ({
  token,
  tenantName,
  component: Component,
  menu,
  title,
  metaDescription,
  onSetTenantNameWithToken,
  onSignIn,
  ...rest
}: Props) => {
  const loginRedirectLocation = '/login' + rest.location.search;
  const hasPermission = () => {
    let permission = true;
    if (rest.path === '/assinatura-confirmada') {
      permission = !!sessionStorage.getItem('subscriptionCreated');
    }

    return permission;
  };

  useEffect(() => {
    if (!token && !tenantName && isAuthenticated()) {
      const tenant = getTenantToken();
      onSetTenantNameWithToken(tenant);
    }
  }, [token, tenantName, onSetTenantNameWithToken]);

  return (
    <Route
      {...rest}
      render={(props) => {
        return isAuthenticated(rest.path) ? (
          hasPermission() ? (
            <div style={{ display: 'flex' }}>
              <SEO title={title} description={metaDescription} />
              {menu && <Menu />}
              <Component {...props} />
            </div>
          ) : (
            <Redirect to="/dashboard" />
          )
        ) : (
          <Redirect to={loginRedirectLocation} />
        );
      }}
    />
  );
};

const mapStateToProps = (state) => {
  const { token, tenantName } = state?.auth;

  return { token, tenantName };
};

const mapDispatchToProps = (dispatch) => ({
  onSignIn: (tenant, payload) => dispatch(signIn(tenant, payload)),
  onSetTenantNameWithToken: (token) => dispatch(setTenantNameWithToken(token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute);
