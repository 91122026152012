import React from 'react';
import { Typography, Button } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { black, silverChalice, gallery, tundora } from '../../../../themes/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: '1.5rem',
    [theme.breakpoints.up('sm')]: {},
  },
  button: {
    color: black,
    fontSize: '1.2em',
  },
  moreInfoBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    margin: '1rem 0',
    '& button': {
      fontSize: '0.8em',
    },
  },
  previewContainer: {
    padding: theme.spacing(0, 2),
  },
  title: {
    color: black,
    fontSize: '1.7em',
    marginTop: '1rem',
  },
  subTitle: {
    color: silverChalice,
    fontSize: '1.2em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 5,
    '-webkit-box-orient': 'vertical',
  },
  image: {
    width: '100%',
    display: 'flex',
    margin: 'auto',
  },
  feedbackBox: {
    borderTop: `1px solid ${gallery}`,
    padding: theme.spacing(1),
    color: tundora,
    '& svg': {
      fontSize: '1em',
      margin: theme.spacing(0, 0.5),
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: '1rem',
      display: 'flex',
      alignItems: 'center',
      '& p ': {
        marginRight: '2rem',
      },
    },
  },
  feedSkeleton: {
    width: '100%',
  },
  skeletonBox: {
    width: '100%',
    display: 'flex',
    margin: 'auto',
  },
  skeletonText: {
    marginTop: '2em',
    marginBottom: '2em',
  },
}));

const News = ({ item }) => {
  const classes = useStyles();

  const navigateTo = (url) => window.open(url, '_blank');

  return item ? (
    <section className={classes.root}>
      <img
        src={item?.node?.featuredImage?.node?.sourceUrl}
        alt={item?.node?.title}
        className={classes.image}
      />

      <div className={classes.previewContainer}>
        <Typography className={classes.title} align="left" gutterBottom>
          {item?.node?.title}
        </Typography>

        <Typography
          className={classes.subTitle}
          dangerouslySetInnerHTML={{ __html: item?.node?.excerpt }}
        ></Typography>

        <div className={classes.moreInfoBox}>
          <Button variant="outlined" color="primary" onClick={() => navigateTo(item?.node?.link)}>
            ler mais
          </Button>
        </div>
      </div>
    </section>
  ) : (
    <section className={classes.root}>
      <Skeleton className={classes.skeletonBox} variant="rect" height={200} />
      <Skeleton variant="text" height={50} />
      <div className={classes.skeletonText}>
        <Skeleton variant="text" height={25} />
        <Skeleton variant="text" height={25} />
        <Skeleton variant="text" height={25} />
      </div>
      <Skeleton variant="text" width={75} height={30} />
    </section>
  );
};

export default News;
