import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import { toast } from 'react-toastify';
import * as fromTypes from '../types';
import * as fromActions from '../actions';

const BASE_API = `${process.env.REACT_APP_BASE_API_ADDRESS}/customer-area`;

export function* fetchPixExtract() {
  try {
    const response = yield axios.get(`${BASE_API}/pix/payments`);

    if (response.status === 200) {
      yield put(fromActions.fetchPixExtractSuccess({ pixExtract: response.data }));
    }
  } catch (error) {
    console.error(error);

    if (error.response.status === 404) {
      toast.info('Desculpe, mas não encontramos seus dados do PIX, tente novamente mais tarde.');
    } else {
      toast.error('Não foi possível carregar seus dados do PIX, tente novamente mais tarde.');
    }
    yield put(fromActions.fetchPixExtractError(error));
  }
}

export function* watchPixExtract() {
  yield takeLatest(fromTypes.FETCH_PIX_EXTRACT, fetchPixExtract);
}
