import { Button, Divider, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import classNames from 'classnames';
import moment from 'moment';
import 'moment/locale/pt-br';
import React from 'react';
import {
  black,
  denim,
  japaneseLaurel,
  japaneseLaurelLight,
  redOrange,
  silverChalice,
  tundora,
  zumthor,
} from '../../themes/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '1rem 0',
  },
  title: {
    color: silverChalice,
  },
  titleHighlight: {
    color: black,
    fontSize: '1.2em',
  },
  subtitle: {
    color: tundora,
    fontSize: '0.9em',
  },
  button: {
    borderRadius: '4px',
    textTransform: 'lowercase',
    padding: '0 0.5rem',
    margin: '0.5rem 0 2rem',
  },
  waiting: {
    borderColor: denim,
    color: denim,
    background: zumthor,
  },
  success: {
    borderColor: japaneseLaurel,
    color: japaneseLaurel,
    background: japaneseLaurelLight,
  },
  warning: {
    color: redOrange,
  },
}));

const BoxReferralCard = ({
  firstName,
  lastName,
  subscribedAt,
  discount,
  status,
  paidAt,
  email,
}) => {
  const classes = useStyles();
  const desktop = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const paid = status === 'FINISHED';

  return (
    <div className={classes.root}>
      <Grid
        container
        alignItems={desktop ? 'center' : 'flex-start'}
        direction={desktop ? 'row' : 'column'}
        spacing={2}
        coluns={16}
      >
        <Grid item xs>
          <Typography className={classes.title} gutterBottom>
            Quem assinou
          </Typography>
          <Typography className={classes.titleHighlight} gutterBottom>
            {`${firstName} ${lastName}`}
          </Typography>
        </Grid>

        <Grid item xs>
          <Typography className={classes.title} gutterBottom>
            Data da assinatura
          </Typography>
          <Typography className={classes.titleHighlight} gutterBottom>
            {moment(subscribedAt).locale('pt').format('DD MMMM YYYY')}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography className={classNames(classes.title, classes.warning)} gutterBottom>
            Desconto Ganho
          </Typography>
          <Typography className={classes.title} gutterBottom>
            {'10% na próxima fatura'}
          </Typography>
        </Grid>

        <Grid item xs>
          <Typography className={classes.title} gutterBottom>
            Status
          </Typography>
          <Button
            className={classNames(
              classes.button,
              { [classes.waiting]: !paid },
              { [classes.success]: paid }
            )}
            variant="outlined"
          >
            {paid ? 'pago' : 'aguardando'}
          </Button>
        </Grid>
      </Grid>

      <Divider />
    </div>
  );
};

export default BoxReferralCard;
