import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Typography, Paper, Divider, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { black, redOrange, tundora, wildSand } from '../../themes/colors';
import { setBeautyProfileAnswer } from '../../store/actions';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '2rem 0',
  },
  title: {
    color: black,
    fontSize: '1.5em',
    fontWeight: 'bold',
  },
  subtitle: {
    color: tundora,
  },
  grid: {
    marginTop: '2rem',
  },
  paper: {
    width: 'fit-content',
    margin: 'auto',
    background: wildSand,
    color: tundora,
    fontSize: '1em',
    padding: theme.spacing(0.5),
    boxShadow: 'none',
    cursor: 'pointer',
    '& img': {
      width: '12rem',
    },
  },
  divider: {
    height: theme.spacing(1),
    background: wildSand,
  },
  active: {
    background: redOrange,
  },
}));

const cloudFrontApi = process.env.REACT_APP_AWS_CLOUDFRONT;

const BeautyImageCard = ({ onSetBeautyProfileAnswer, answer }) => {
  const classes = useStyles();
  const [state, setState] = useState({});
  const [selecteds, setSelecteds] = useState({});

  useEffect(() => {
    setState(answer);
  }, [answer]);

  useEffect(() => {
    onSetBeautyProfileAnswer(selecteds);
  }, [selecteds, onSetBeautyProfileAnswer]);

  useEffect(() => {
    if (state && state.options) {
      const newSelecteds = [];
      state.options.map((opt) => (opt.checked ? newSelecteds.push(opt.value) : null));
      const obj = { [answer.slug]: newSelecteds };
      setSelecteds(obj);
    }
  }, [state, answer]);

  const handleChange = (optionId) => {
    const options = state?.options?.map((i) => {
      return i.value === optionId
        ? { ...i, checked: !i.checked }
        : answer.type === 'SINGLE_CHOICE'
        ? { ...i, checked: false }
        : i;
    });

    setState({
      ...state,
      options,
    });
  };
  return (
    <div className={classes.root}>
      <Typography className={classes.title} gutterBottom>
        {state?.title}
      </Typography>
      <Typography className={classes.subtitle}>
        {answer.type === 'SINGLE_CHOICE'
          ? 'Escolha apenas uma opção.'
          : 'Pode escolher quantas quiser.'}
      </Typography>

      <Grid container spacing={2} className={classes.grid}>
        {state?.options?.map((i) => (
          <Grid item xs={12} sm={3} key={i.value}>
            <Paper className={classes.paper} onClick={() => handleChange(i.value)}>
              <img src={`${cloudFrontApi}/${i.image}`} alt={i.title} />
              <Divider className={classNames(classes.divider, { [classes.active]: i.checked })} />
              <Typography align="center">{i?.title}</Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { beautyProfileAnswer } = state.profile;
  return { beautyProfileAnswer };
};

const mapDispatchToProps = (dispatch) => ({
  onSetBeautyProfileAnswer: (answer) => dispatch(setBeautyProfileAnswer(answer)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BeautyImageCard);
