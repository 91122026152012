import React from 'react';
import { Typography, Divider, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { black, silverChalice } from '../../themes/colors';
import { getPlanName } from '../../models/subscription';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '1rem 0',
  },
  mainTitle: {
    color: silverChalice,
    fontSize: '1.5em',
    fontWeight: 'bold',
  },
  title: {
    color: silverChalice,
  },
  titleHighlight: {
    color: black,
    fontSize: '1.2em',
  },
  buttonBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: '2rem 0',
    '& button': {
      fontSize: '0.8em',
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      padding: '1rem 0',
      margin: 0,
      marginLeft: '25%', // grid size
      '& button': {
        marginRight: '0.5rem',
      },
    },
  },
}));

const Subscription = ({ subscription, navigateTo }) => {
  const classes = useStyles();
  const desktop = useMediaQuery((theme) => theme.breakpoints.up('md'));
  //const isAnnual = subscription?.plan?.recurrencePeriod === 'ANNUAL';

  return (
    <div className={classes.root}>
      <Grid
        container
        alignItems={desktop ? 'center' : 'flex-start'}
        direction={desktop ? 'row' : 'column'}
        spacing={2}
      >
        <Grid item xs={12} sm={6} md={3}>
          <Typography className={classes.mainTitle} gutterBottom>
            Plano
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Typography className={classes.title} gutterBottom>
            Plano atual
          </Typography>
          <Typography className={classes.titleHighlight} gutterBottom>
            {getPlanName(subscription?.plan)}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Typography className={classes.title} gutterBottom>
            Data de cobrança
          </Typography>
          <Typography className={classes.titleHighlight} gutterBottom>
            {subscription.billingDay > 0
              ? `Dia ${subscription.billingDay}`
              : 'Sem próxima fatura prevista'}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3}></Grid>
      </Grid>
      <div className={classes.buttonBox}>
        {/* <Button
          disabled={isAnnual}
          variant="outlined"
          color="primary"
          onClick={() => navigateTo('/perfil/assinatura')}
        >
          EDITAR PLANO
        </Button> */}
        {/* <Button
          variant="outlined"
          color="primary"
          onClick={() => navigateTo('/perfil/assinatura/data-cobranca')}
        >
          EDITAR data de cobrança
        </Button> */}
      </div>
      <Divider />
    </div>
  );
};

export default Subscription;
