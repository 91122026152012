// @flow

export const BUY_EXTRA_PRODUCT = '[PURCHASE] Buy extra products';
export const BUY_EXTRA_PRODUCT_SUCCESS = '[PURCHASE] Buy extra products success';
export const BUY_EXTRA_PRODUCT_ERROR = '[PURCHASE] Buy extra products error';

export const CANCEL_EXTRA_PRODUCT = '[PURCHASE] Cancel extra products';
export const CANCEL_EXTRA_PRODUCT_SUCCESS = '[PURCHASE] Cancel extra products success';
export const CANCEL_EXTRA_PRODUCT_ERROR = '[PURCHASE] Cancel extra products error';

export const SELECT_RETENTION_OFFER = '[PURCHASE] Select retention offer';
export const SELECT_RETENTION_OFFER_SUCCESS = '[PURCHASE] Select retention offer success';
export const SELECT_RETENTION_OFFER_ERROR = '[PURCHASE] Select retention offer error';

export const FETCH_EXTRA_PRODUCTS = '[PURCHASE] Fetch extra products';
export const FETCH_EXTRA_PRODUCTS_SUCCESS = '[PURCHASE] Fetch extra products success';
export const FETCH_EXTRA_PRODUCTS_ERROR = '[PURCHASE] Fetch extra products error';

export const BUY_SINGLE_PRODUCT = '[PURCHASE] Buy single products';
export const BUY_SINGLE_PRODUCT_SUCCESS = '[PURCHASE] Buy single products success';
export const BUY_SINGLE_PRODUCT_ERROR = '[PURCHASE] Buy single products error';

export const CANCEL_SINGLE_PRODUCT = '[PURCHASE] Cancel single products';
export const CANCEL_SINGLE_PRODUCT_SUCCESS = '[PURCHASE] Cancel single products success';
export const CANCEL_SINGLE_PRODUCT_ERROR = '[PURCHASE] Cancel single products error';

type FetchExtraProducts = {
  type: typeof FETCH_EXTRA_PRODUCTS,
};

type FetchExtraProductsSuccess = {
  type: typeof FETCH_EXTRA_PRODUCTS_SUCCESS,
  payload: any,
};

type FetchExtraProductsError = {
  type: typeof FETCH_EXTRA_PRODUCTS_ERROR,
  payload: any,
};

type BuyExtraProduct = {
  type: typeof BUY_EXTRA_PRODUCT,
  id: number,
  sku: string,
};

type BuyExtraProductSuccess = {
  type: typeof BUY_EXTRA_PRODUCT_SUCCESS,
  payload: any,
};

type BuyExtraProductError = {
  type: typeof BUY_EXTRA_PRODUCT_ERROR,
  payload: any,
};

type CancelExtraProduct = {
  type: typeof CANCEL_EXTRA_PRODUCT,
};

type CancelExtraProductSuccess = {
  type: typeof CANCEL_EXTRA_PRODUCT_SUCCESS,
  payload: any,
};

type CancelExtraProductError = {
  type: typeof CANCEL_EXTRA_PRODUCT_ERROR,
  payload: any,
};

type SelectRetentionOffer = {
  type: typeof SELECT_RETENTION_OFFER,
  id: Number,
  offer: Object,
};

type SelectRetentionOfferSuccess = {
  type: typeof SELECT_RETENTION_OFFER_SUCCESS,
  payload: any,
};

type SelectRetentionOfferError = {
  type: typeof SELECT_RETENTION_OFFER_ERROR,
  payload: any,
};

type BuySingleProduct = {
  type: typeof BUY_SINGLE_PRODUCT_SUCCESS,
  id: number,
};

type BuySingleProductSuccess = {
  type: typeof BUY_SINGLE_PRODUCT_SUCCESS,
  payload: any,
};

type BuySingleProductError = {
  type: typeof BUY_SINGLE_PRODUCT_ERROR,
  payload: any,
};

type CancelSingleProduct = {
  type: typeof CANCEL_SINGLE_PRODUCT,
  invoiceId: Number,
};

type CancelSingleProductSuccess = {
  type: typeof CANCEL_SINGLE_PRODUCT_SUCCESS,
  payload: any,
};

type CancelSingleProductError = {
  type: typeof CANCEL_SINGLE_PRODUCT_ERROR,
  payload: any,
};

export type PurchaseAction =
  | FetchExtraProducts
  | FetchExtraProductsSuccess
  | FetchExtraProductsError
  | BuyExtraProduct
  | BuyExtraProductSuccess
  | BuyExtraProductError
  | CancelExtraProduct
  | CancelExtraProductSuccess
  | CancelExtraProductError
  | SelectRetentionOffer
  | SelectRetentionOfferSuccess
  | SelectRetentionOfferError
  | BuySingleProduct
  | BuySingleProductSuccess
  | BuySingleProductError
  | CancelSingleProduct
  | CancelSingleProductSuccess
  | CancelSingleProductError;
