import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import BannerCouponLink from '../../components/BannerCouponLink';
import BoxReferralCard from '../../components/BoxReferralCard';
import Loading from '../../components/Loading';
import { alto, black, redOrange, tundora } from '../../themes/colors';
import { fetchReferrals } from '../../store/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    boxShadow: `1px 3px 6px ${alto}`,
    minHeight: '100vh',
  },
  title: {
    color: black,
    fontSize: '2em',
    padding: '0 2.5rem',
    marginBottom: '2rem',
    [theme.breakpoints.up('lg')]: {
      padding: 0,
    },
  },
  titleHighlight: {
    color: redOrange,
    fontSize: '1em',
  },
  subtitle: {
    color: tundora,
    fontSize: '1.3em',
  },
  trackingBox: {
    background: redOrange,
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  contentBox: {
    padding: '1.5rem 2rem 0',
    flex: 1,
  },
  button: {
    marginTop: '1.5rem',
    fontSize: '1rem',
    fontWeight: 'bold',
  },
  imageBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    flex: 1,
    '& img': {
      position: 'relative',
      bottom: '-1rem',
      right: '1rem',
      [theme.breakpoints.up('md')]: {
        width: ' 20rem',
        right: '5rem',
        bottom: '-1.5rem',
      },
    },
  },
  previousBox: {
    marginTop: '1rem',
    padding: '2rem',
  },
  previousBoxTitle: {
    color: tundora,
    fontSize: '1.2em',
  },
  previousBoxsubtitle: {
    color: tundora,
    fontWeight: 'bold',
    fontSize: '2em',
  },
  indicationLink: {
    display: 'block',
    color: black,
    fontSize: '1rem',
    marginTop: '1rem',
  },
}));

const Referral = ({ onFetchReferrals, referrals, loading }) => {
  const classes = useStyles();

  useEffect(() => {
    onFetchReferrals();
  }, [onFetchReferrals]);

  return (
    <Loading isLoading={loading}>
      <div className={classes.root}>
        <BannerCouponLink />
        <div className={classes.previousBox}>
          <Typography className={classes.previousBoxTitle} gutterBottom>
            Lista de Indicações
          </Typography>

          {referrals?.referrals?.length > 0 ? (
            referrals?.referrals?.map((i, index) => <BoxReferralCard key={index} {...i} />)
          ) : (
            <Typography className={classes.previousBoxsubtitle} gutterBottom>
              Você ainda não possui indicações
            </Typography>
          )}
        </div>
      </div>
    </Loading>
  );
};

const mapStateToProps = (state) => {
  const { referrals, loading } = state.dashboard;
  return { referrals, loading };
};

const mapDispatchToProps = (dispatch) => ({
  onFetchReferrals: () => dispatch(fetchReferrals()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Referral);
