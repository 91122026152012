import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip, ClickAwayListener } from '@material-ui/core';
import { doveGray, white } from '../../themes/colors';
import copy from 'clipboard-copy';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: white,
    color: doveGray,
    border: `1px solid ${doveGray}`,
    fontSize: '1em',
    borderRadius: theme.spacing(1),
  },
}));

const CopyToClipboard = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const onCopy = (content) => {
    copy(content);
    setOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Tooltip
        open={open}
        title={props.title}
        leaveDelay={1500}
        onClose={() => setOpen(false)}
        classes={classes}
        disableFocusListener
        disableHoverListener
        disableTouchListener
      >
        {props.children({ copy: onCopy })}
      </Tooltip>
    </ClickAwayListener>
  );
};

export default CopyToClipboard;
