import React, { useEffect } from 'react';
import { Typography, Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { tundora } from '../../themes/colors';
import { confirmEmail } from '../../store/actions';
import { push } from 'react-router-redux';
import Loading from '../../components/Loading';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    height: '100vh',
    '& h1': {
      fontSize: '2em',
      margin: '2rem 0 1rem',
    },
    '& img': {
      maxHeight: '768px',
    },
  },
  mainBox: {
    maxWidth: '25rem',
    margin: 'auto',
  },
  logoUau: {
    display: 'flex',
    justifyContent: 'center',
    margin: '3rem',
    position: 'relative',
  },
  formBox: {
    padding: '0 1.5rem',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '1rem',
    '& button': {
      marginTop: theme.spacing(2),
      padding: '1rem',
      fontSize: '1.3em',
      fontWeight: 'bold',
    },
  },
  infoBox: {
    display: 'flex',
    justifyContent: 'center',
    '& div': {
      display: 'flex',
      flexDirection: 'column',
      width: 'fit-content',
    },
    '& button': {
      margin: theme.spacing(1, 0),
    },
    '& p': {
      margin: theme.spacing(1, 0),
      color: tundora,
    },
    '& a': {
      textDecoration: 'none',
      justifyContent: 'center',
      display: 'flex',
    },
  },
  grid: {
    height: '100%',
    overflow: 'hidden',
  },
  subscribeBtn: {
    padding: '0.8rem 1rem',
    fontSize: '1.2em',
  },
}));

const ConfirmEmail = ({ loading, isConfirmad, onConfirmEmail, match, navigateTo }) => {
  const classes = useStyles();
  const highDevice = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const tenant = match?.params?.tenant;
  const token = match?.params?.token;

  const getImages = (type) => {
    let url = '';

    if (type === 'logo') {
      url =
        tenant === 'uaubox'
          ? 'https://d2ivdm85owtv7v.cloudfront.net/images/uaubox/logo_uau_coral.png'
          : 'https://d2ivdm85owtv7v.cloudfront.net/images/scarlet/logo-scarlet.png';
    } else {
      const imageData = {
        uaubox: 'https://d2ivdm85owtv7v.cloudfront.net/images/customer-area/sign_in_image.png',
        scarlet:
          'https://d2ivdm85owtv7v.cloudfront.net/images/customer-area/sign_in_image_scarlet.jpg',
      };
      url = imageData[tenant];
    }
    return url;
  };

  useEffect(() => {
    onConfirmEmail(tenant, token);
  }, [onConfirmEmail, tenant, token]);

  return (
    <Loading isLoading={loading}>
      <div className={classes.root}>
        <Grid container justify="center" alignItems="center" className={classes.grid}>
          <Grid item xs={12} md={6}>
            <div className={classes.mainBox}>
              <div className={classes.logoUau}>
                {}
                <img
                  src={getImages('logo')}
                  alt={tenant === 'uaubox' ? 'UAUBox Default' : 'Scarlet by UAUBox'}
                />
              </div>
              {isConfirmad && (
                <React.Fragment>
                  <Typography variant="h1" align="center">
                    Yeeey
                  </Typography>

                  <div className={classes.formBox}>
                    <Typography variant="h6" align="center">
                      E-mail confirmado com sucesso.
                    </Typography>

                    <Button
                      variant="contained"
                      className={classes.loginBtn}
                      color="secondary"
                      type="button"
                      onClick={() => navigateTo('/')}
                    >
                      Ir para o login
                    </Button>
                  </div>
                </React.Fragment>
              )}
            </div>
          </Grid>

          {highDevice ? (
            <Grid item xs={12} md={6}>
              <img
                src={getImages('image')}
                alt={tenant === 'uaubox' ? 'UAUBox Default' : ' Scarlet by UAUBox'}
              />
            </Grid>
          ) : null}
        </Grid>
      </div>
    </Loading>
  );
};

const mapStateToProps = (state) => {
  const { isConfirmad, loading } = state.auth;

  return {
    loading,
    isConfirmad,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onConfirmEmail: (tenant, token) => dispatch(confirmEmail(tenant, token)),
  navigateTo: (path) => dispatch(push(path)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(ConfirmEmail);
