import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogContent, Typography, IconButton } from '@material-ui/core';
import { Close, FavoriteBorder } from '@material-ui/icons';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import { black, wildSand, silverChalice } from '../../themes/colors';

const useStyles = makeStyles((theme) => ({
  title: {
    color: black,
    fontSize: '1.8em',
  },
  subtitle: {
    color: silverChalice,
    fontSize: '1.2em',
  },
  boxHeader: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '1rem',
  },
  dialogContent: {
    minHeight: '15rem',
    maxHeight: '35rem',
  },
  iconBox: {
    display: 'flex',
    background: wildSand,
    borderRadius: '50%',
    width: 'fit-content',
    margin: 'auto',
    marginBottom: '1rem',
    padding: '1.5rem',
    '& svg': {
      fontSize: '5em',
      fill: silverChalice,
    },
  },
  buttonBox: {
    display: 'flex',
    justifyContent: 'center',
    padding: '1rem',
    '& button': {
      marginTop: '1rem',
      padding: '0.5rem 1.5rem',
      fontWeight: 'bold',
      fontSize: '1.2em',
    },
  },
}));

const FeedbackSubmitCard = ({ onClose }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const tablet = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  // eslint-disable-next-line
  const handleClose = () => {
    setOpen(false);
    onClose(false);
  };

  useEffect(() => {
    return () => handleClose();
  }, [handleClose]);

  return (
    <Dialog
      maxWidth="sm"
      open={open}
      aria-labelledby="feedback-dialog-title"
      fullWidth={tablet}
      fullScreen={!tablet}
    >
      <div className={classes.boxHeader}>
        <IconButton color="primary" onClick={handleClose}>
          <Close />
        </IconButton>
      </div>

      <DialogContent className={classes.dialogContent}>
        <div className={classes.iconBox}>
          <FavoriteBorder />
        </div>
        <Typography className={classes.title} align="center" gutterBottom>
          Muito obrigada por sua avaliação!
        </Typography>
        <Typography className={classes.subtitle} align="center" gutterBottom>
          Analisaremos por carinho para te oferecermos sempre melhores produtos.
        </Typography>

        <div className={classes.buttonBox}>
          <Button color="secondary" variant="contained" onClick={handleClose}>
            Voltar
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default FeedbackSubmitCard;
