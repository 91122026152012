// @flow
import * as fromTypes from '../types/notification';

type State = {
  loading: boolean,
  notifications: Array<Object>,
  notification: Object,
  notificationUnread: number,
};

export const initialState = {
  loading: false,
  notifications: [],
  notification: {},
  notificationUnread: 0,
};

const setLoading = (state, loading) => ({ ...state, loading });

const reducer = (state: State = initialState, action: fromTypes.NotificationAction) => {
  switch (action.type) {
    case fromTypes.FETCH_USER_NOTIFICATIONS:
    case fromTypes.FETCH_USER_NOTIFICATION:
    case fromTypes.MARK_USER_NOTIFICATION_AS_READ:
      return setLoading(state, true);
    // success
    case fromTypes.FETCH_USER_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: action.payload,
        notificationUnread: action?.payload?.filter((i) => !i.read).length,
        loading: false,
      };
    case fromTypes.FETCH_USER_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notification: action.payload,
        loading: false,
      };
    case fromTypes.MARK_USER_NOTIFICATION_AS_READ_SUCCESS:
      const notifications = state?.notifications?.map((i) =>
        i?.id === action.payload ? { ...i, read: true } : i
      );
      return {
        ...state,
        notifications,
        notificationUnread: notifications?.filter((i) => !i.read).length,
        loading: false,
      };
    // error
    case fromTypes.FETCH_USER_NOTIFICATIONS_ERROR:
    case fromTypes.FETCH_USER_NOTIFICATION_ERROR:
    case fromTypes.MARK_USER_NOTIFICATION_AS_READ_ERROR:
      return setLoading(state, false);
    default:
      return state;
  }
};

export default reducer;
