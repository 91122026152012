// @flow
import * as fromTypes from '../types/profile';

export const fetchAddressByZipcode = (zipcode: string): fromTypes.ProfileAction => ({
  type: fromTypes.FETCH_ADDRESS_BY_ZIPCODE,
  zipcode,
});

export const fetchAddressByZipcodeSuccess = (payload: any): fromTypes.ProfileAction => ({
  type: fromTypes.FETCH_ADDRESS_BY_ZIPCODE_SUCCESS,
  payload,
});

export const fetchAddressByZipcodeError = (): fromTypes.ProfileAction => ({
  type: fromTypes.FETCH_ADDRESS_BY_ZIPCODE_ERROR,
});

export const fetchProfile = (): fromTypes.ProfileAction => ({
  type: fromTypes.FETCH_PROFILE,
});

export const fetchProfileSuccess = (payload: any): fromTypes.ProfileAction => ({
  type: fromTypes.FETCH_PROFILE_SUCCESS,
  payload,
});

export const fetchProfileError = (payload: any): fromTypes.ProfileAction => ({
  type: fromTypes.FETCH_PROFILE_ERROR,
  payload,
});

export const updateProfile = (profile: any): fromTypes.ProfileAction => ({
  type: fromTypes.UPDATE_PROFILE,
  profile,
});

export const updateProfileSuccess = (payload: any): fromTypes.ProfileAction => ({
  type: fromTypes.UPDATE_PROFILE_SUCCESS,
  payload,
});

export const updateProfileError = (payload: any): fromTypes.ProfileAction => ({
  type: fromTypes.UPDATE_PROFILE_ERROR,
  payload,
});

export const fetchProfileAnswers = (): fromTypes.ProfileAction => ({
  type: fromTypes.FETCH_PROFILE_ANSWERS,
});

export const fetchProfileAnswersSuccess = (payload: any): fromTypes.ProfileAction => ({
  type: fromTypes.FETCH_PROFILE_ANSWERS_SUCCESS,
  payload,
});

export const fetchProfileAnswersError = (payload: any): fromTypes.ProfileAction => ({
  type: fromTypes.FETCH_PROFILE_ANSWERS_ERROR,
  payload,
});

export const fetchProfileAnswer = (id: number): fromTypes.ProfileAction => ({
  type: fromTypes.FETCH_PROFILE_ANSWER,
  id,
});

export const fetchProfileAnswerSuccess = (payload: any): fromTypes.ProfileAction => ({
  type: fromTypes.FETCH_PROFILE_ANSWER_SUCCESS,
  payload,
});

export const fetchProfileAnswerError = (payload: any): fromTypes.ProfileAction => ({
  type: fromTypes.FETCH_PROFILE_ANSWER_ERROR,
  payload,
});

export const fetchProfileDetails = (): fromTypes.ProfileAction => ({
  type: fromTypes.FETCH_PROFILE_DETAILS,
});

export const fetchProfileDetailsSuccess = (payload: any): fromTypes.ProfileAction => ({
  type: fromTypes.FETCH_PROFILE_DETAILS_SUCCESS,
  payload,
});

export const fetchProfileDetailsError = (payload: any): fromTypes.ProfileAction => ({
  type: fromTypes.FETCH_PROFILE_DETAILS_ERROR,
  payload,
});

export const fetchShippingAddresses = (): fromTypes.ProfileAction => ({
  type: fromTypes.FETCH_SHIPPING_ADDRESSES,
});

export const fetchShippingAddressesSuccess = (payload: any): fromTypes.ProfileAction => ({
  type: fromTypes.FETCH_SHIPPING_ADDRESSES_SUCCESS,
  payload,
});

export const fetchShippingAddressesError = (payload: any): fromTypes.ProfileAction => ({
  type: fromTypes.FETCH_SHIPPING_ADDRESSES_ERROR,
  payload,
});

export const fetchShippingAddress = (id: number): fromTypes.ProfileAction => ({
  type: fromTypes.FETCH_SHIPPING_ADDRESS,
  id,
});

export const fetchShippingAddressSuccess = (payload: any): fromTypes.ProfileAction => ({
  type: fromTypes.FETCH_SHIPPING_ADDRESS_SUCCESS,
  payload,
});

export const fetchShippingAddressError = (payload: any): fromTypes.ProfileAction => ({
  type: fromTypes.FETCH_SHIPPING_ADDRESS_ERROR,
  payload,
});

export const createShippingAddress = (shippingAddress: Object): fromTypes.ProfileAction => ({
  type: fromTypes.CREATE_SHIPPING_ADDRESS,
  shippingAddress,
});

export const createShippingAddressSuccess = (payload: any): fromTypes.ProfileAction => ({
  type: fromTypes.CREATE_SHIPPING_ADDRESS_SUCCESS,
  payload,
});

export const createShippingAddressError = (payload: any): fromTypes.ProfileAction => ({
  type: fromTypes.CREATE_SHIPPING_ADDRESS_ERROR,
  payload,
});

export const updateShippingAddress = (shippingAddress: Object): fromTypes.ProfileAction => ({
  type: fromTypes.UPDATE_SHIPPING_ADDRESS,
  shippingAddress,
});

export const updateShippingAddressSuccess = (payload: any): fromTypes.ProfileAction => ({
  type: fromTypes.UPDATE_SHIPPING_ADDRESS_SUCCESS,
  payload,
});

export const updateShippingAddressError = (payload: any): fromTypes.ProfileAction => ({
  type: fromTypes.UPDATE_SHIPPING_ADDRESS_ERROR,
  payload,
});

export const deleteShippingAddress = (id: number): fromTypes.ProfileAction => ({
  type: fromTypes.DELETE_SHIPPING_ADDRESS,
  id,
});

export const deleteShippingAddressSuccess = (payload: any): fromTypes.ProfileAction => ({
  type: fromTypes.DELETE_SHIPPING_ADDRESS_SUCCESS,
  payload,
});

export const deleteShippingAddressError = (payload: any): fromTypes.ProfileAction => ({
  type: fromTypes.DELETE_SHIPPING_ADDRESS_ERROR,
  payload,
});

export const fetchBoxOrders = (): fromTypes.ProfileAction => ({
  type: fromTypes.FETCH_BOX_ORDERS,
});

export const fetchBoxOrdersSuccess = (payload: any): fromTypes.ProfileAction => ({
  type: fromTypes.FETCH_BOX_ORDERS_SUCCESS,
  payload,
});

export const fetchBoxOrdersError = (payload: any): fromTypes.ProfileAction => ({
  type: fromTypes.FETCH_BOX_ORDERS_ERROR,
  payload,
});

export const fetchBoxOrder = (id: number): fromTypes.ProfileAction => ({
  type: fromTypes.FETCH_BOX_ORDER,
  id,
});

export const fetchBoxOrderSuccess = (payload: any): fromTypes.ProfileAction => ({
  type: fromTypes.FETCH_BOX_ORDER_SUCCESS,
  payload,
});

export const fetchBoxOrderError = (payload: any): fromTypes.ProfileAction => ({
  type: fromTypes.FETCH_BOX_ORDER_ERROR,
  payload,
});

export const fetchFeedbackQuestionsByOrderId = (orderId: number): fromTypes.ProfileAction => ({
  type: fromTypes.FETCH_FEEDBACK_QUESTIONS_BY_ORDER_ID,
  orderId,
});

export const fetchFeedbackQuestionsByOrderIdSuccess = (payload: any): fromTypes.ProfileAction => ({
  type: fromTypes.FETCH_FEEDBACK_QUESTIONS_BY_ORDER_ID_SUCCESS,
  payload,
});

export const fetchFeedbackQuestionsByOrderIdError = (payload: any): fromTypes.ProfileAction => ({
  type: fromTypes.FETCH_FEEDBACK_QUESTIONS_BY_ORDER_ID_ERROR,
  payload,
});

export const createFeedback = (
  orderId: number,
  feedback: Object,
  page: string
): fromTypes.ProfileAction => ({
  type: fromTypes.CREATE_FEEDBACK,
  orderId,
  feedback,
  page,
});

export const createFeedbackSuccess = (payload: any): fromTypes.ProfileAction => ({
  type: fromTypes.CREATE_FEEDBACK_SUCCESS,
  payload,
});

export const createFeedbackError = (payload: any): fromTypes.ProfileAction => ({
  type: fromTypes.CREATE_FEEDBACK_ERROR,
  payload,
});

export const setFeedbackSubmit = (feedbackSubmitted: boolean): fromTypes.ProfileAction => ({
  type: fromTypes.SET_FEEDBACK_SUBMIT,
  feedbackSubmitted,
});

export const setBeautyProfileAnswer = (payload: any): fromTypes.ProfileAction => ({
  type: fromTypes.SET_BEAUTY_PROFILE_ANSWER,
  payload,
});

export const updateBeautyProfile = (shouldGoNext: any): fromTypes.ProfileAction => ({
  type: fromTypes.UPDATE_BEAUTY_PROFILE,
  payload: { shouldGoNext },
});

export const deliveryFeedback = (payload: any): fromTypes.ProfileAction => ({
  type: fromTypes.SET_DELIVERY_FEEDBACK,
  payload,
});

export const setDeliveryFeedbackSuccess = (payload: any): fromTypes.ProfileAction => ({
  type: fromTypes.SET_DELIVERY_FEEDBACK_SUCCESS,
  payload,
});

export const setDeliveryFeedbackError = (payload: any): fromTypes.ProfileAction => ({
  type: fromTypes.SET_DELIVERY_FEEDBACK_ERROR,
  payload,
});

export const fetchOrderId = (): fromTypes.ProfileAction => ({
  type: fromTypes.FETCH_ORDER_ID,
});

export const fetchOrderIdSuccess = (payload: any): fromTypes.ProfileAction => ({
  type: fromTypes.FETCH_ORDER_ID_SUCCESS,
  payload,
});

export const fetchOrderIdError = (payload: any): fromTypes.ProfileAction => ({
  type: fromTypes.FETCH_ORDER_ID_ERROR,
  payload,
});
