import React from 'react';
import { Typography, Box, Radio, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { tundora } from '../../themes/colors';
import { Field } from 'redux-form';
import { RenderedTextField, RenderedRating, RenderedRadioGroup } from '../wrapped';
import { toString } from 'lodash';
import { required } from '../utils/validators.js';

const useStyles = makeStyles((theme) => ({
  box: {
    marginBottom: '1rem',
  },
  cardTitle: {
    color: tundora,
    fontSize: '1.2em',
    marginBottom: '1rem',
  },
}));

const TenantQuestion = ({ questions = [], lastStep }) => {
  const classes = useStyles();

  return lastStep
    ? questions?.map((item, index) => {
        const { id, text, componentType, options } = item;
        switch (componentType) {
          case 'TEXT_AREA':
            return (
              <Box key={index} className={classes.box}>
                <Typography className={classes.cardTitle} align="left" gutterBottom>
                  {text}
                </Typography>
                <Field
                  name={`${toString(id)}-text`}
                  type="text"
                  component={RenderedTextField}
                  placeholder="Deixe aqui seu comentário (opcional)"
                  multiline
                  rowsMax={3}
                  rows={3}
                />
              </Box>
            );
          case 'INPUT_RADIO':
            return (
              <Box key={index} className={classes.box}>
                <Typography className={classes.cardTitle} align="left" gutterBottom>
                  {text}
                </Typography>
                <Field name={`${toString(id)}-optionId`} component={RenderedRadioGroup}>
                  {options?.map((choice) => (
                    <FormControlLabel
                      key={choice.id}
                      className={classes.formControlLabel}
                      value={toString(choice.id)}
                      control={<Radio color="primary" />}
                      label={choice.text}
                      validate={[required]}
                    />
                  ))}
                </Field>
              </Box>
            );
          case 'STAR_RATING':
            return (
              <Box key={index} className={classes.box}>
                <Typography className={classes.cardTitle} align="left" gutterBottom>
                  {text}
                </Typography>
                <Field
                  name={`${toString(id)}-optionId`}
                  component={RenderedRating}
                  subtitle="Escolha de 1 a 5 para classificar."
                  validate={[required]}
                />
              </Box>
            );
          default:
            return null;
        }
      })
    : null;
};

export default TenantQuestion;
