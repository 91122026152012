import { makeStyles } from '@material-ui/core';

const useStylesHeaderDashboardNotification = makeStyles((theme) => ({
  notificationPopover: {
    padding: '0.5rem',
    maxWidth: '30rem',
    minWidth: '15rem',
  },
  notificationMaintitle: {
    fontWeight: 600,
    marginBottom: '0.5rem',
  },
  opacity: {
    opacity: 0.5,
  },
  notificationImage: {
    maxWidth: '80px',
    paddingRight: theme.spacing(1),
  },
  notificationMessage: {
    display: 'flex',
    alignItems: 'center',
  },
  notificationBox: {
    '& svg': {
      fontSize: '1.5em',
    },
  },
  notificationRead: {
    width: theme.spacing(2),
    '& button': {
      fontSize: '1em',
      padding: 0,
      '& svg': {
        fontSize: '1em',
        fill: theme.palette.primary.main,
      },
    },
  },
  notificationTitle: {
    fontWeight: 600,
    fontSize: '1em',
  },
  notificationBody: {
    fontSize: '1em',
    display: 'box',
    lineClamp: '2',
    boxOrient: 'vertical',
    whiteSpace: 'break-spaces',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  notificationLink: {
    width: '100%',
    marginRight: '0.5rem',
  },
  notificationLinkCursor: {
    cursor: 'pointer',
  },
  noNotification: {
    fontSize: '1em',
    opacity: 0.5,
  },
}));

export default useStylesHeaderDashboardNotification;
