// @flow

export const CREATE_SUBSCRIPTION = '[REGISTRATION] Subscribe';
export const CREATE_SUBSCRIPTION_SUCCESS = '[REGISTRATION] Subscribe success';
export const CREATE_SUBSCRIPTION_ERROR = '[REGISTRATION] Subscribe error';

export const CREATE_VINDI_SUBSCRIPTION = '[REGISTRATION] Create vindi subscription';
export const CREATE_VINDI_SUBSCRIPTION_SUCCESS = '[REGISTRATION] Create vindi subscription success';
export const CREATE_VINDI_SUBSCRIPTION_ERROR = '[REGISTRATION] Create vindi subscription error';

export const CREATE_STRIPE_SUBSCRIPTION = '[REGISTRATION] Create stripe subscription';
export const CREATE_STRIPE_SUBSCRIPTION_SUCCESS =
  '[REGISTRATION] Create stripe subscription success';
export const CREATE_STRIPE_SUBSCRIPTION_ERROR = '[REGISTRATION] Create stripe subscription error';

export const SELECT_PLAN = '[REGISTRATION] Select plan';
export const SELECT_PLAN_SUCCESS = '[REGISTRATION] Select plan sucess';
export const SELECT_PLAN_ERROR = '[REGISTRATION] Select plan error';

export const FETCH_PLAN_LIST = '[REGISTRATION] Fetch plan list ';
export const FETCH_PLAN_LIST_ERROR = '[REGISTRATION] Fetch plan list error';
export const FETCH_PLAN_LIST_SUCCESS = '[REGISTRATION] Fetch plan list success';

export const FETCH_MAIN_ADDRESS = '[REGISTRATION] Fetch main address';
export const FETCH_MAIN_ADDRESS_ERROR = '[REGISTRATION] Fetch main address error';
export const FETCH_MAIN_ADDRESS_SUCCESS = '[REGISTRATION] Fetch main address success';

export const FETCH_SHIPPING_COST = '[REGISTRATION] Fetch shipping cost';
export const FETCH_SHIPPING_COST_SUCCESS = '[REGISTRATION] Fetch shipping cost success';
export const FETCH_SHIPPING_COST_ERROR = '[REGISTRATION] Fetch shipping cost error';

export const FETCH_COUPON = '[REGISTRATION] Fetch coupon';
export const FETCH_COUPON_SUCCESS = '[REGISTRATION] Fetch coupon success';
export const FETCH_COUPON_ERROR = '[REGISTRATION] Fetch coupon error';

export const FETCH_COUPON_PLAN_AVAILABLE = '[REGISTRATION] Fetch coupon plan available';

export const CLEAR_COUPON = '[REGISTRATION] Clear coupon';

export const FETCH_ADDITIONAL_ITEM_AVAILABLE =
  '[ADDITIONAL ITEM] Fetch for available additional item number';
export const FETCH_ADDITIONAL_ITEM_AVAILABLE_SUCCESS =
  '[ADDITIONAL ITEM] Fetch for available additional item number success';
export const SELECT_ADDITIONAL_ITEM = '[REGISTRATION] Select checkbox additional item';
export const UPDATE_REGISTRATION_STATE = '[REGISTRATION] Update registration state';

type CreateSubscription = {
  type: typeof CREATE_SUBSCRIPTION,
  registration: Object,
};
type CreateSubscriptionSuccess = {
  type: typeof CREATE_SUBSCRIPTION_SUCCESS,
  payload: any,
};

type CreateSubscriptionError = {
  type: typeof CREATE_SUBSCRIPTION_ERROR,
  payload: any,
};

type CreateVindiSubscription = {
  type: typeof CREATE_VINDI_SUBSCRIPTION,
  cardDetails: Object,
};

type CreateVindiSubscriptionSuccess = {
  type: typeof CREATE_VINDI_SUBSCRIPTION_SUCCESS,
  payload: any,
};

type CreateVindiSubscriptionError = {
  type: typeof CREATE_VINDI_SUBSCRIPTION_ERROR,
  payload: any,
};

type CreateStripeSubscription = {
  type: typeof CREATE_STRIPE_SUBSCRIPTION,
  subscription: any,
  cardDetails: Object,
  plan: any,
  paymentMethod: string,
  coupon: string,
  couponDiscount: number,
  mediaTracking: Object,
  specialBoxEdition: string,
  customer: any,
};

type CreateStripeSubscriptionSuccess = {
  type: typeof CREATE_STRIPE_SUBSCRIPTION_SUCCESS,
  payload: any,
};

type CreateStripeSubscriptionError = {
  type: typeof CREATE_STRIPE_SUBSCRIPTION_ERROR,
  payload: any,
};

type SelectPlan = {
  type: typeof SELECT_PLAN,
  plan: Object,
};

type SelectPlanSucess = {
  type: typeof SELECT_PLAN_SUCCESS,
  payload: any,
};

type SelectPlanError = {
  type: typeof SELECT_PLAN_ERROR,
  payload: any,
};

type FetchPlanList = {
  type: typeof FETCH_PLAN_LIST,
};

type FetchPlanListError = {
  type: typeof FETCH_PLAN_LIST_ERROR,
  payload: any,
};

type FetchPlanListSuccess = {
  type: typeof FETCH_PLAN_LIST_SUCCESS,
  payload: any,
};

type FetchMainAddress = {
  type: typeof FETCH_MAIN_ADDRESS,
};

type FetchMainAddressError = {
  type: typeof FETCH_MAIN_ADDRESS_ERROR,
  payload: any,
};

type FetchMainAddressSuccess = {
  type: typeof FETCH_MAIN_ADDRESS_SUCCESS,
  payload: any,
};

type FetchShippingCost = {
  type: typeof FETCH_SHIPPING_COST,
  shippingAddress: Object,
};

type FetchShippingCostSuccess = {
  type: typeof FETCH_SHIPPING_COST_SUCCESS,
  payload: any,
};

type FetchShippingCostError = {
  type: typeof FETCH_SHIPPING_COST_ERROR,
  payload: any,
};

type FetchCoupon = {
  type: typeof FETCH_COUPON,
  planId: string,
  coupon: string,
  cpf: string,
  email: string,
};
type FetchCouponSuccess = {
  type: typeof FETCH_COUPON_SUCCESS,
  payload: any,
};

type FetchCouponError = {
  type: typeof FETCH_COUPON_ERROR,
  payload: any,
};

type FetchCouponPlanAvailable = {
  type: typeof FETCH_COUPON_PLAN_AVAILABLE,
  planId: string,
  coupon: string,
  cpf: string,
  email: string,
};

type ClearCoupon = {
  type: typeof CLEAR_COUPON,
};

type SelectAdditionalItem = {
  type: typeof SELECT_ADDITIONAL_ITEM,
  isSelected: boolean,
};

type FetchAdditionalItemAvailable = {
  type: typeof FETCH_ADDITIONAL_ITEM_AVAILABLE,
};

type FetchAdditionalItemAvailableSuccess = {
  type: typeof FETCH_ADDITIONAL_ITEM_AVAILABLE_SUCCESS,
  payload: number,
};

type UpdatedRegistrationState = {
  type: typeof UPDATE_REGISTRATION_STATE,
  payload: Object,
};

export type RegistrationAction =
  | CreateSubscription
  | CreateSubscriptionSuccess
  | CreateSubscriptionError
  | CreateVindiSubscription
  | CreateVindiSubscriptionSuccess
  | CreateVindiSubscriptionError
  | CreateStripeSubscription
  | CreateStripeSubscriptionSuccess
  | CreateStripeSubscriptionError
  | SelectPlan
  | SelectPlanSucess
  | SelectPlanError
  | FetchPlanList
  | FetchPlanListError
  | FetchPlanListSuccess
  | FetchMainAddress
  | FetchMainAddressError
  | FetchMainAddressSuccess
  | FetchShippingCost
  | FetchShippingCostSuccess
  | FetchShippingCostError
  | FetchCoupon
  | FetchCouponSuccess
  | FetchCouponError
  | FetchCouponPlanAvailable
  | ClearCoupon
  | SelectAdditionalItem
  | FetchAdditionalItemAvailable
  | FetchAdditionalItemAvailableSuccess
  | UpdatedRegistrationState;
