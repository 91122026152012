// @flow
import React, { Fragment } from 'react';
import { TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { tundora, silverChalice, alto } from '../../themes/colors';

const useStyles = makeStyles(
  {
    root: {
      color: tundora,
      fontSize: '1em',
    },
    label: {
      color: tundora,
    },
    disabled: {
      background: alto,
      color: silverChalice,
    },
    title: {
      color: tundora,
      fontSize: '1em',
    },
  },
  { name: 'MuiTextField' }
);

type Props = {
  title: string,
};
const CustomTextField = (props: Props) => {
  const classes = useStyles();

  return (
    <Fragment>
      <Typography className={classes.title}>{props.title}</Typography>
      <TextField {...props} />
    </Fragment>
  );
};
export default CustomTextField;
