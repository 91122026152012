// @flow
import * as fromTypes from '../types/auth';

type State = {
  loading: boolean,
  isConfirmad: Boolean,
};

export const initialState = {
  loading: false,
  isConfirmad: false,
};

const updateUser = (state, { payload, tenant }) => ({
  ...state,
  ...payload,
  tenantName: tenant,
  loading: false,
});

const updateTenant = (state, { tenant }) => ({
  ...state,
  tenantName: tenant,
});

const setConfirmEmail = (state) => ({
  ...state,
  isConfirmad: true,
  loading: false,
});

const setLoading = (state, loading) => ({ ...state, loading });

const reducer = (state: State = initialState, action: fromTypes.AuthAction) => {
  switch (action.type) {
    case fromTypes.SIGN_IN:
    case fromTypes.SIGN_OUT:
    case fromTypes.UPDATE_PASSWORD:
    case fromTypes.CONFIRM_EMAIL:
      return setLoading(state, true);
    // success
    case fromTypes.SIGN_IN_SUCCESS:
      return updateUser(state, action);
    case fromTypes.SET_TENANT_NAME_WITH_TOKEN:
      return updateTenant(state, action);
    case fromTypes.UPDATE_PASSWORD_SUCCESS:
    case fromTypes.SIGN_OUT_SUCCESS:
      return setLoading(state, false);
    case fromTypes.CONFIRM_EMAIL_SUCCESS:
      return setConfirmEmail(state);
    // error
    case fromTypes.SIGN_IN_ERROR:
    case fromTypes.SIGN_OUT_ERROR:
    case fromTypes.UPDATE_PASSWORD_ERROR:
    case fromTypes.CONFIRM_EMAIL_ERROR:
      return setLoading(state, false);
    default:
      return state;
  }
};

export default reducer;
