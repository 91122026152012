import { makeStyles } from '@material-ui/core/styles';
import { redOrange } from '../../themes/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '2.5rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },

  cardPromo: {
    background: redOrange,
    borderRadius: '0.25rem',
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '0.625rem',
    height: '15rem',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '15rem',
    },
  },

  title: {
    fontSize: '1.25rem',
    fontWeight: '600',
    color: 'white',
    margin: 0,
  },

  description: {
    fontSize: '0.9rem',
    fontWeight: '500',
    color: 'white',
    textAlign: 'center',
    margin: 0,
  },

  link: {
    width: '100%',
  },

  button: {
    height: '2.5rem',
    background: 'white',
    width: '100%',
    borderRadius: '0.25rem',
    outline: 'none',
    border: 'none',
    fontWeight: '700',
    '&:hover': {
      background: '#fefefe',
    },
  },
}));

export default useStyles;
