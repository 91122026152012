// @flow
import React, { Fragment } from 'react';
import { Typography } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Favorite } from '@material-ui/icons';
import Rating from '@material-ui/lab/Rating';
import { redOrange, mercury, silverChalice } from '../../themes/colors';

const StyledRating = withStyles({
  iconEmpty: {
    color: mercury,
  },
  icon: {
    fontSize: '1.2em',
  },
  iconFilled: {
    color: redOrange,
  },
  iconHover: {
    color: redOrange,
  },
})(Rating);

const useStyles = makeStyles(
  {
    root: {
      color: silverChalice,
      fontSize: '1em',
    },
    label: {
      color: silverChalice,
    },
    title: {
      color: silverChalice,
      fontSize: '1em',
      marginTop: '0.5rem',
    },
  },
  { name: 'MuiTextField' }
);

type Props = {
  subtitle: string,
};
const CustomRating = (props: Props) => {
  const classes = useStyles();
  return (
    <Fragment>
      <StyledRating
        name="customized-color"
        icon={<Favorite fontSize="inherit" />}
        size="large"
        {...props}
      />
      <Typography className={classes.title} gutterBottom>
        {props.subtitle}
      </Typography>
    </Fragment>
  );
};

export default CustomRating;
