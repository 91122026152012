import { scroller } from 'react-scroll';

const flatten = (c, d = '.') => {
  const r = {};
  (function f(o, p) {
    Object.keys(o).forEach((k) =>
      o[k] && typeof o[k] === 'object'
        ? f(o[k], p ? `${p}${d}${k}` : k)
        : (r[p ? `${p}${d}${k}` : k] = o[k])
    );
  })(c);
  return r;
};

export const scrollToFirstError = (errors) => {
  const errorEl = document.querySelector(
    Object.keys(flatten(errors))
      .map((fieldName) => `[name="${fieldName}"]`)
      .join(',')
  );
  if (errorEl && errorEl.focus) {
    scroller.scrollTo(errorEl.name, { offset: -200, smooth: true });
    errorEl.focus();
  }
};
