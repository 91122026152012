import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useStyles from '../styles/Loading';

import { Box, Grow } from '@material-ui/core';

import { handleVerifyStatus } from '../../../store/actions';

const Loading = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const { finishedLoading } = useSelector((state) => state.face);

  useEffect(() => {
    if (finishedLoading) return;

    const interval = setInterval(() => {
      dispatch(handleVerifyStatus());
    }, 1000);
    return () => clearInterval(interval);
  }, [dispatch, finishedLoading]);

  return (
    <Box className={classes.container}>
      <LogoLoading />
    </Box>
  );
};

export default Loading;

function LogoLoading() {
  const classes = useStyles();

  const [checked, setChecked] = React.useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setChecked((checked) => !checked);
    }, 750);
    return () => clearInterval(interval);
  }, []);

  return (
    <Grow className={classes.grow} in={checked}>
      <img
        className={classes.logoLoading}
        src="https://d2ivdm85owtv7v.cloudfront.net/face/uau-logo.svg"
        alt="Logo da UAUBox"
      />
    </Grow>
  );
}
