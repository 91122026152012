import { Button, Divider, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import moment from 'moment';
import 'moment/locale/pt-br';
import React from 'react';
import { phoneMask } from '../../../components/utils/validators.js';
import { black, silverChalice } from '../../../themes/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '1rem 0',
  },
  mainTitle: {
    color: silverChalice,
    fontSize: '1.5em',
    fontWeight: 'bold',
  },
  title: {
    color: silverChalice,
  },
  titleHighlight: {
    color: black,
    fontSize: '1.2em',
  },
  buttonBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: '2rem 0',
    '& button': {
      margin: '0.5rem 0',
      fontSize: '0.8em',
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      padding: '1rem 0',
      margin: 0,
      marginLeft: '25%', // grid size
      '& button': {
        marginRight: '0.5rem',
      },
    },
  },
}));

const PersonalData = ({ navigateTo, customer, tenantName }) => {
  const classes = useStyles();
  const desktop = useMediaQuery((theme) => theme.breakpoints.up('md'));

  return (
    <div className={classes.root}>
      <Grid
        container
        alignItems={desktop ? 'center' : 'flex-start'}
        direction={desktop ? 'row' : 'column'}
        spacing={2}
      >
        <Grid item xs={12} sm={6} md={3}>
          <Typography className={classes.mainTitle} gutterBottom>
            Dados pessoais
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Typography className={classes.title} gutterBottom>
            Nome
          </Typography>
          <Typography className={classes.titleHighlight} gutterBottom>
            {customer.firstName}
          </Typography>
          <Typography className={classes.title} gutterBottom>
            Sobrenome
          </Typography>
          <Typography className={classes.titleHighlight} gutterBottom>
            {customer.lastName}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Typography className={classes.title} gutterBottom>
            E-mail
          </Typography>
          <Typography className={classes.titleHighlight} gutterBottom>
            {customer.email}
          </Typography>
          <Typography className={classes.title} gutterBottom>
            CPF
          </Typography>
          <Typography className={classes.titleHighlight} gutterBottom>
            {customer.cpf}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Typography className={classes.title} gutterBottom>
            Data de Nascimento
          </Typography>
          <Typography className={classes.titleHighlight} gutterBottom>
            {`${moment(customer.birthdate).locale('pt').format('DD/MM/YYYY')}`}
          </Typography>
          <Typography className={classes.title} gutterBottom>
            Telefone
          </Typography>
          <Typography className={classes.titleHighlight} gutterBottom>
            {phoneMask(customer?.telephone)}
          </Typography>
        </Grid>
      </Grid>
      <div className={classes.buttonBox}>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => navigateTo('/perfil/dados-pessoais')}
        >
          EDITAR DADOS
        </Button>
        {tenantName !== 'scarlet' ? (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => navigateTo('/perfil/perfil-de-beleza')}
          >
            EDITAR PERFIL DE BELEZA
          </Button>
        ) : null}

        <Button
          variant="outlined"
          color="primary"
          onClick={() => navigateTo('/perfil/atualizar-senha')}
        >
          alterar senha
        </Button>
      </div>
      <Divider />
    </div>
  );
};

export default PersonalData;
