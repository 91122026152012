import React, { useEffect } from 'react';
import { Typography, Button, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { push } from 'react-router-redux';
import { Field, reduxForm } from 'redux-form';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  fetchSubscriptionBillingDate,
  updateSubscriptionBillingDate,
} from '../../../../store/actions';
import {
  black,
  redOrange,
  tundora,
  pippin,
  pirateGold,
  oldLace,
  alto,
} from '../../../../themes/colors';
import { WarningIcon } from '../../../../components/icons';
import { required } from '../../../../components/utils/validators';
import { RenderedSelect } from '../../../../components/wrapped';
import moment from 'moment';
import 'moment/locale/pt-br';
import Loading from '../../../../components/Loading';
import { BackButton, BackButtonMobile } from '../../../../components/material';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    boxShadow: `1px 3px 6px ${alto}`,
  },
  mainBox: {
    background: pippin,
    padding: '1.5rem 2rem',
  },
  titleBox: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '2rem',
  },
  title: {
    color: black,
    fontSize: '2em',
    padding: '0 2.5rem',
    [theme.breakpoints.up('lg')]: {
      padding: '0 1rem',
    },
  },
  subtitle: {
    color: tundora,
    fontSize: '1.2em',
  },
  contentBox: {
    padding: '2rem',
    [theme.breakpoints.up('md')]: {
      maxWidth: '60%',
    },
  },
  contentButtonBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '1.5rem 0',
    '& button': {
      margin: '0.5rem 0',
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start',
      flexDirection: 'row',
      '& button': {
        marginRight: '0.5rem',
      },
    },
  },
  warningBox: {
    color: pirateGold,
    background: oldLace,
    border: `1px solid ${pirateGold}`,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    margin: '1rem 0',
    display: 'flex',
    '& span': {
      color: redOrange,
    },
  },
  warningIconBox: {
    marginRight: theme.spacing(2),
    '& svg': {
      width: '2em',
      fill: pirateGold,
    },
  },
  billingDateBox: {
    display: 'flex',
    alignItems: 'center',
    '& span': {
      color: black,
      margin: theme.spacing(2, 0, 0, 2),
    },
  },
}));

const SubscriptionBillingDate = ({
  onFetchSubscriptionBillingDate,
  onUpdateSubscriptionBillingDate,
  handleSubmit,
  submitting,
  navigateTo,
  billingDate,
  loading,
}) => {
  const classes = useStyles();
  const showArrow = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  useEffect(() => {
    onFetchSubscriptionBillingDate();
  }, [onFetchSubscriptionBillingDate]);

  const onSubmit = ({ dayOfMonth }) => onUpdateSubscriptionBillingDate(dayOfMonth);

  return (
    <Loading isLoading={loading}>
      <div className={classes.root}>
        <BackButtonMobile onClick={() => navigateTo('/perfil')} visible={!showArrow} />
        <div className={classes.mainBox}>
          <div className={classes.titleBox}>
            <BackButton onClick={() => navigateTo('/perfil')} visible={showArrow} />
            <Typography className={classes.title}>Data de cobrança</Typography>
          </div>
          <Typography className={classes.subtitle} gutterBottom>
            Altere sua data de cobrança
          </Typography>
        </div>

        <div className={classes.contentBox}>
          <Typography className={classes.subtitle} gutterBottom>
            {` Atualmente, sua assinatura é cobrada no dia ${billingDate?.currentDayOfMonth} de cada mês, mas você pode escolher uma
          data que seja mais conveniente.`}
          </Typography>

          <div className={classes.warningBox}>
            <div className={classes.warningIconBox}>
              <WarningIcon />
            </div>
            <Typography>
              A mudança de data de cobrança será aplicada apenas no próximo mês de cobrança. Ou
              seja, caso já tenha pago a mensalidade do mês atual, a próxima já virá com a nova
              data.
            </Typography>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={classes.billingDateBox}>
              <Field
                name="dayOfMonth"
                component={RenderedSelect}
                label="Data de cobrança"
                placeholder="Selecionar data de cobrança"
                validate={[required]}
              >
                <MenuItem value={moment(billingDate?.possibleBillingDates?.first).format('DD')}>
                  {moment(billingDate?.possibleBillingDates?.first).format('DD')}
                </MenuItem>
                <MenuItem value={moment(billingDate?.possibleBillingDates?.last).format('DD')}>
                  {moment(billingDate?.possibleBillingDates?.last).format('DD')}
                </MenuItem>
              </Field>

              <span>de cada mês</span>
            </div>

            <div className={classes.contentButtonBox}>
              <Button variant="contained" color="secondary" type="submit">
                alterar data
              </Button>
              <Button variant="outlined" color="secondary" onClick={() => navigateTo(`/perfil`)}>
                voltar
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Loading>
  );
};

const mapStateToProps = (state) => {
  const { billingDate, loading } = state.subscription;
  return { billingDate, loading };
};

const mapDispatchToProps = (dispatch) => ({
  onFetchSubscriptionBillingDate: () => dispatch(fetchSubscriptionBillingDate()),
  onUpdateSubscriptionBillingDate: (dayOfMonth) =>
    dispatch(updateSubscriptionBillingDate(dayOfMonth)),
  navigateTo: (path) => dispatch(push(path)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'billingDateForm',
  })
)(SubscriptionBillingDate);
